import { action, observable, decorate } from "mobx";
import { DialogProps, DialogAction } from "@/components";

class Dialog {
  show = (props: DialogProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise<DialogResult>((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  hide = () => {
    if (this.resolve) this.resolve({});

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };
}

decorate(Dialog, {
  open: observable,
  props: observable,
  show: action,
  hide: action,
});

export const dialog = new Dialog();

interface Dialog {
  open: boolean;
  props?: DialogProps;
  resolve?: Resolve;
  promise: Promise<DialogResult> | undefined;
}

type Resolve = (value: DialogResult) => void;

type DialogResult = { res?: any; action?: DialogAction };
