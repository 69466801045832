import React from "react";
import classNames from "classnames";
import { NumberField, NumberFieldProps } from "@/components";

export function Commission(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "commission");

  return <NumberField {...props} placeholder="e.g. 3.420" min={0} max={100} />;
}

export type Props = NumberFieldProps;
