import { action, observable, decorate } from "mobx";
import { TradeTermsetLayout, trade } from "@/api";
import { Response } from "@/axios";

class Layout {
  loading = false;
  data = undefined as TradeTermsetLayout | undefined;

  getData = async (): Promise<Response<TradeTermsetLayout> | undefined> => {
    if (typeof this.data?.id !== "number") return;

    this.loading = true;

    const res = await trade.getTermsetLayout(this.data.id);

    if (res.data) this.setData(res.data);

    this.loading = false;

    return res;
  };

  setData = (data: TradeTermsetLayout): void => {
    this.data = Object.assign(data, this.data);
  };
}

decorate(Layout, {
  loading: observable,
  data: observable,
  setData: action,
});

export { Layout };

interface Layout {
  loading: boolean;
  data: TradeTermsetLayout | undefined;
}
