import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { cn } from "@/util";
import { StripedLoader } from "@/components";
import "./Loading.overlay.scss";

const LoadingOverlay = (props: Props) => {
  const { threshold = 0, status = {} } = props;
  const { loading, message } = status;
  const [visible, setVisible] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);

  useEffect(reveal, []);

  function _setVisible(visible: boolean) {
    if (overlay.current) setVisible(visible);
  }

  function reveal() {
    setTimeout(_setVisible.bind(null, true), threshold);
  }

  return (
    <div className={cn("loading-overlay", { visible })} ref={overlay} hidden={visible && status && !loading && !message}>
      <StripedLoader status={props.status} />
    </div>
  );
};

const { setTimeout } = window;

const Observer = observer(LoadingOverlay);

export { Observer as LoadingOverlay };

interface Props {
  threshold?: number;
  status?: Status;
}
