import { action, observable, decorate } from "mobx";
import { GlobalDropdownProps, DropdownItem } from "@/components";

class GlobalDropdown {
  show = (props: GlobalDropdownProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  update = (props: GlobalDropdownProps) => {
    this.props = { ...this.props, ...props };
  };

  hide = () => {
    if (this.resolve) this.resolve();

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };

  select = (item: any) => {
    if (this.resolve) this.resolve(item);

    this.hide();
  };
}

decorate(GlobalDropdown, {
  props: observable.shallow,
  show: action,
  hide: action,
  update: action,
});

export const globalDropdown = new GlobalDropdown();

interface GlobalDropdown {
  promise: Promise<DropdownItem> | undefined;
  resolve: ((item?: DropdownItem) => void) | undefined;
  open: boolean;
  props?: GlobalDropdownProps;
}
