import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Response } from "@/axios";
import { auth } from "@/model";
import { config } from "@/config";

class Central {
  setup = async () => {
    this.axios = axios.create({ baseURL: config.centralAuthURL });

    await auth.central.promise;

    Object.assign(this.axios.defaults.headers.common, {
      "clarksons.cloud.logintoken": auth.central.token,
    });
  };

  getUser = async (centralToken = auth.central.token, config?: AxiosRequestConfig): GetUserType => {
    const res = await this.axios.get("/API/1_4/Security/GetCurrentUser", {
      headers: {
        "clarksons.cloud.logintoken": centralToken,
        ...config?.headers,
      },
      ...config,
    });

    return res;
  };
}

export const central = new Central();

type GetUserType = Promise<Response<CentralUser>>;

interface Central {
  axios: AxiosInstance;
}

export interface CentralUser {
  TokenExpiryUtc: string;
}
