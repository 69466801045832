import { decorate, action, observable, computed } from "mobx";
import { get } from "@/util";
import { routes } from "@/routes";
import { Route } from "./route";

class Router {
  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  get active() {
    return this._active || this.root;
  }

  setup = () => {
    this.root = new Route();

    this.root.setup(routes);

    iterate(this.root, undefined, this.root);

    this.resolve();
  };

  setActive = (route: Route) => {
    this._active = route;
  };

  get = (path: string): Route => getRoute(this.root, path);
}

decorate(Router, {
  root: observable,
  _active: observable,
  active: computed,
  setup: action,
  setActive: action,
});

const router = new Router();

export { router };

function iterate(route: Route, parent: Route | undefined, root: Route) {
  const { seed } = route;
  const { sidenav } = seed;

  route.sidenav = route.sidenav = (sidenav || []).map((path) => getRoute(root, path));

  if (route.redirect) {
    route.redirect.to = getRoute(root, route.redirect.to).absPath;
  }

  for (let i = 0; i < route.children.length; i += 1) {
    const child = route.children[i];

    iterate(child, route, root);
  }
}

function getRoute(root: Route, path: string): Route {
  let _path = path.split(".");

  _path = _path.reduce((acc: string[], name) => {
    acc.push("childrenMap", name);

    return acc;
  }, []);

  const route = get(root, _path);

  if (!route) {
    throw new Error(`route "${path}" does not exist`);
  }

  if (route.redirect) {
    throw new Error(`route "${path}" is a redirect, accessing it does not make sense`);
  }

  return route;
}

interface Router {
  root: Route;
  _active: Route;
  promise: Promise<unknown>;
  resolve: () => void;
}
