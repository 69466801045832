import axios from "axios";
import { wait } from "@/util";
import { dialog } from "@/model";

export let config: Config;
export const DEVELOPMENT = process.env.NODE_ENV === "development";
export const PRODUCTION = process.env.NODE_ENV === "production";
export const ENV = process.env.REACT_APP_ENV as keyof typeof ENVS;

export async function setup(): Promise<void> {
  const { data } = await axios.get<ConfigJSON>(`/config.json?${now}`);

  const env = ENVS[ENV];
  const configJSON = env || data;

  if (!configJSON) {
    dialog.show(fetchErrorDialog);

    await wait(266642069);
  }

  const result = await normalize(configJSON);

  if (result) config = result;
}

const ENVS = {
  int: {
    authUrl: "https://login-staging.sea.live",
    cloudApplicationCode: "cloudlocal",
    ctradeUrl: "https://trade-gateway-int-func-we.azurewebsites.net",
    headerCdnUrl: "https://cloudheader-uat.azureedge.net",
  },
  dev4: {
    authUrl: "https://ctrade-poc-auth-provider.azurewebsites.net",
    ctradeUrl: "https://trade-gateway-dev4-func-we.azurewebsites.net",
    headerCdnUrl: "https://ctrade-poc-auth-provider.azurewebsites.net",
    cloudApplicationCode: "cloudlocal",
  },
  test4: {
    authUrl: "https://login-staging.sea.live",
    ctradeUrl: "https://trade-gateway-tst4-func-we.azurewebsites.net",
    headerCdnUrl: "https://cloudheader-uat.azureedge.net",
    cloudApplicationCode: "cloudlocal",
  },
};

const DEFAULTS = {
  centralAuthURL: "https://login-staging.sea.live",
  tradeURL: "https://trade-gateway-tst4-func-we.azurewebsites.net",
  tradeHeaderURL: "https://cloudheader-uat.azureedge.net",
  applicationCode: "cloudlocal",
};

async function normalize(json: ConfigJSON): Promise<Config | undefined> {
  const { authUrl, ctradeUrl, headerCdnUrl, cloudApplicationCode } = json;

  if (!authUrl || !ctradeUrl || !headerCdnUrl || !cloudApplicationCode) {
    dialog.show(missingValuesDialog);

    await wait(26664206969);

    return;
  }

  const config = {
    ...DEFAULTS,
    centralAuthURL: authUrl,
    tradeURL: ctradeUrl,
    tradeHeaderURL: `${headerCdnUrl}/v2/latest/sea-header.js`,
    applicationCode: cloudApplicationCode,
    mockedHeader: `${headerCdnUrl}`.includes("ctrade-poc-auth-provider"),
    mockedAuth: `${authUrl}`.includes("ctrade-poc-auth-provider"),
  };

  return config;
}

const now = new Date().getTime();

const fetchErrorDialog = {
  status: {
    type: "error",
    title: "Config Fetch Failure",
    message: "We sincerely apologise for any inconvenience caused.",
  } as Status,
  dataTest: "config-fetch-fail",
};

const missingValuesDialog = {
  status: {
    type: "error",
    title: "Missing Config Values",
    message: "We sincerely apologise for any inconvenience caused.",
  } as Status,
  dataTest: "config-fetch-fail",
};

type Config = {
  centralAuthURL: string;
  tradeURL: string;
  tradeHeaderURL: string;
  applicationCode: string;
  mockedHeader: boolean;
  mockedAuth: boolean;
};

interface ConfigJSON {
  authUrl?: string;
  ctradeUrl?: string;
  headerCdnUrl?: string;
  cloudApplicationCode?: string;
  mockedHeader?: boolean;
}
