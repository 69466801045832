import React from "react";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../container";
import { Text, TextProps } from "@/components";

export function TextField(props: TextFieldProps): JSX.Element {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <Text {...rest} />
    </Container>
  );
}

export interface TextFieldProps extends FieldProps, TextProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
