import React from "react";
import classNames from "classnames";
import { SelectField, SelectFieldProps } from "@/components";

export function TradeTermsetHandlebar(props: SelectFieldProps): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-termset-handlebar");

  return <SelectField {...props} />;
}
