import React, { useEffect, useRef, createRef, MutableRefObject } from "react";
import classNames from "classnames";
import { SPECIAL_KEY, ARROW_KEY, HANDLEBAR_SYMBOLS } from "@/constants";
import { debounce, wait, useSimpleEffect, useShallowState, get } from "@/util";
import { globalDropdown } from "@/model";
import { InputProps } from "../../Input";
import { Container } from "../../container";
import "./Handlebar.scss";
import { DropdownProps, DropdownItem } from "@/components/dropdown";

export function Handlebar(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "handlebar");

  const { observableProps } = props;

  let initialValue = `${props.value || ""}`;
  initialValue = initialValue.replace(/🍓|🍒/g, "");
  const data = props.data || observableProps?.data;
  const input = useRef<HTMLDivElement>(null);
  const [state, setState] = useShallowState({
    ...defaultState,
    value: initialValue,
  });

  useEffect(initInputRef, []);
  useEffect(initObserver, []);
  useEffect(createElements, [state.value, state.caretOffset]);
  useEffect(propagateValue, [state.value]);
  useSimpleEffect(setInputValue, [state.value]);

  function propagateValue() {
    props.onChange?.(state.value);
  }

  async function setInputValue() {
    if (!input.current) return;

    input.current.textContent = `${state.value}`;
  }

  function initInputRef() {
    // @ts-ignore
    if (props.input) props.input.current = input.current;

    function unsetInputRef() {
      if (props.input) props.input.current = null;
    }

    return unsetInputRef;
  }

  function initObserver() {
    if (!input.current) return;

    function handleMutations() {
      const value = input.current?.textContent || "";

      setState({ value });
    }

    const observer = new MutationObserver(debounce(handleMutations));

    observer.observe(input.current, observerConfig);

    return () => observer.disconnect();
  }

  function createElements() {
    let replaced = state.value.replace(/{{[a-z0-9-_-\s]+}}/gi, tagCherry);
    replaced = replaced.replace(/{{(?!.*}})/gi, tagFruit);
    const strings = replaced.split(/🍓/g);

    let length = 0;
    const elements = strings.map((string, i) => {
      let type: ElementType = "string";

      if (string.includes("🍒")) type = "handlebar";
      if (string.includes("🍌")) type = "half-handlebar";

      if (type === "handlebar") string = string.replace("🍒", "");
      if (type === "half-handlebar") string = string.replace("🍌", "");

      const start = length;

      length += string.length;

      const end = length;

      const caretFocusStart = start === state.caretOffset;
      const caretFocusEnd = end === state.caretOffset;
      const caretFocus = start < state.caretOffset && state.caretOffset < end;

      async function update(next: string) {
        next = `${HANDLEBAR_SYMBOLS.Start}${next}${HANDLEBAR_SYMBOLS.End}`;

        const strings = elements.map((el) => (el.i === i ? next : el.string));
        const value = strings.join("");

        setState({ value });

        await wait(0);

        setCaretOffset(start + next.length);

        resolveCaretOffset();
      }

      const element = {
        i,
        string,
        caretFocus,
        caretFocusEnd,
        caretFocusStart,
        start,
        end,
        type,
        update,
        length: string.length,
        ref: createRef<HTMLSpanElement | null>(),
      };

      if (type === "half-handlebar" && caretFocusEnd) {
        setTimeout(showDropdown.bind(null, element));
      }

      return element;
    });

    setState({ elements });

    replaceInvalidHandlebars(elements);
  }

  async function replaceInvalidHandlebars(elements: Element[]) {
    let carretOffset = state.caretOffset;
    let value = state.value;

    elements
      .filter((element) => element.type === "handlebar")
      .forEach((element) => {
        const handlebarInnerValue = getHandlebarInnerValue(element.string);

        if (!isValidHandlebarValue(handlebarInnerValue)) {
          value = value.replace(element.string, HANDLEBAR_SYMBOLS.Start);

          if (element.caretFocusEnd || element.caretFocus) {
            carretOffset = element.start + HANDLEBAR_SYMBOLS.Start.length;
          }
        }
      });

    if (value !== state.value) {
      setState({ value });

      await wait(0);

      setCaretOffset(carretOffset);

      resolveCaretOffset();
    }
  }

  function getHandlebarInnerValue(handlebarValue: string) {
    return handlebarValue.replace(HANDLEBAR_SYMBOLS.Start, "").replace(HANDLEBAR_SYMBOLS.End, "").toUpperCase();
  }

  function isValidHandlebarValue(handlebarValue: string) {
    return !!data?.find((x) => x.key === handlebarValue);
  }

  async function showDropdown(handlebar: Element) {
    const target = handlebar.ref?.current;
    let status: Status = {};
    let search = "";
    let prevSearch = "";

    if (!data) {
      status = {
        message: "Loading",
        loading: true,
      };
    }

    if (!target) return;

    window.addEventListener("keydown", filter);

    const item = await globalDropdown.show({
      target,
      data,
      status,
      className: "handlebar",
      dataTest: "handlebar",
      display: props.display,
    });

    window.removeEventListener("keydown", filter);

    const string = item && props.getItemHandlebar ? props.getItemHandlebar(item) : item;

    if (string) handlebar.update(string);

    function filter(e: KeyboardEvent) {
      if (!globalDropdown.open) {
        window.removeEventListener("keydown", filter);

        return;
      }

      e.preventDefault();

      if (e.key === "Backspace") search = search.slice(0, search.length - 1);

      // prettier-ignore
      if (!SPECIAL_KEY[e.key] && !ARROW_KEY[e.key]) search = `${search}${e.key}`;

      if (prevSearch === search) return;

      prevSearch = search;

      const filtered = data?.filter((item) => {
        if (typeof props.filterKey === "function") {
          item = props.filterKey(item);

          //
        } else if (props.filterKey) {
          item = get(item, props.filterKey);
        }

        return item.toLowerCase().startsWith(search.toLowerCase());
      });

      // prettier-ignore
      let status: Status = {};

      if (!filtered?.length) {
        status = { message: "No matching handlebar" };
      }

      globalDropdown.update({
        target,
        data: filtered,
        status,
        className: "handlebar",
        dataTest: "handlebar",
      });
    }
  }

  async function removeHandlebar(params: RemoveHandlebarParams) {
    let removed = null as null | Element;
    const filtered = state.elements.filter((el) => {
      if (el.type !== "handlebar") return true;

      const matchCaretFocusStart = params.caretFocusStart && el.caretFocusStart;
      const matchCaretFocusEnd = params.caretFocusEnd && el.caretFocusEnd;

      if (el.caretFocus || matchCaretFocusStart || matchCaretFocusEnd) {
        removed = el;

        return false;
      }

      return true;
    });
    const next = filtered.map((el) => el.string).join("");

    setState({ value: next });

    await wait(0);

    if (!removed) return;

    setCaretOffset(removed.start);
  }

  async function resolveCaretOffset() {
    await wait(0);

    const selection = window.getSelection();

    if (selection) setState({ caretOffset: selection.focusOffset });
  }

  async function setCaretOffset(offset: number) {
    const selection = window.getSelection();
    const text = input.current?.firstChild as Text;

    if (!text || !selection) return;

    input.current?.normalize();

    const nextOffset = Math.min(Math.max(0, offset), text.length);

    const range = document.createRange();
    range.setStart(text, nextOffset);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  function handleEnterKey(e: React.KeyboardEvent<HTMLDivElement>) {
    e.preventDefault();

    const focusedHandlebar = state.elements.find(recogniseFocusedHandlebar);
    const dropdownWillOpen = !globalDropdown.open && focusedHandlebar;
    const allowLineBreak = !focusedHandlebar && !globalDropdown.open;

    if (allowLineBreak) document.execCommand("insertLineBreak");

    if (dropdownWillOpen) e.stopPropagation();

    if (focusedHandlebar) showDropdown(focusedHandlebar);

    setCaretOffset(state.caretOffset + 1);
  }

  function handleDelete() {
    removeHandlebar({ caretFocusStart: true });

    globalDropdown.hide();
  }

  function handleBackspace() {
    removeHandlebar({ caretFocusEnd: true });

    globalDropdown.hide();
  }

  function hideDropdown() {
    globalDropdown.hide();
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    const action = KEYBOARD_ACTIONS[e.key];

    action?.(e);

    resolveCaretOffset();
  }

  async function onMouseDown() {
    await resolveCaretOffset();

    await wait(0);

    const focusedHandlebar = state.elements.find(recogniseFocusedHandlebar);

    if (focusedHandlebar) showDropdown(focusedHandlebar);
  }

  function focusInput() {
    input.current?.focus();
  }

  const KEYBOARD_ACTIONS = {
    Delete: handleDelete,
    Backspace: handleBackspace,
    Enter: handleEnterKey,
    ArrowLeft: hideDropdown,
    ArrowRight: hideDropdown,
    " ": hideDropdown,
  } as Record<string, (() => void) | ((e: React.KeyboardEvent<HTMLDivElement>) => void)>;

  return (
    <Container {...props}>
      <div className="handlebar-input-layover input" onClick={focusInput}>
        {state.elements.map(Element)}
      </div>
      <div
        className="handlebar-input input"
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        tabIndex={props.tabIndex || 0}
        suppressContentEditableWarning={true}
        contentEditable={true}
        ref={input}
      />
    </Container>
  );
}

function Element(element: Element, i: number) {
  const { type, string, caretFocus, ref } = element;
  const key = string + i;

  if (type === "handlebar") {
    const className = classNames("handlebar", { active: caretFocus });

    return (
      <span className={className} key={key} ref={ref}>
        {string}
      </span>
    );
  }

  if (type === "half-handlebar") {
    return (
      <span ref={ref} key={key}>
        {string}
      </span>
    );
  }

  return string;
}

function tagCherry(handlebar: string) {
  return `🍓🍒${handlebar}🍓`;
}

function tagFruit(handlebar: string) {
  return `🍓🍌${handlebar}🍓`;
}

function recogniseFocusedHandlebar(el: Element) {
  return el.type === "handlebar" && el.caretFocus;
}

const observerConfig = {
  subtree: true,
  childList: true,
  characterData: true,
};

const defaultState = {
  elements: [],
  caretOffset: -1,
  keysPressed: [],
  value: "",
} as State;

const { setTimeout } = window;

interface Props extends InputProps<Value, string> {
  observableProps?: Props;
  data?: DropdownProps["data"];
  display?: DropdownProps["display"];
  getItemHandlebar?: (item: DropdownItem) => string;
  filterKey?: string | number | ((item: DropdownItem) => string | number);
}

type Value = string | number | null;

interface State {
  elements: Element[];
  caretOffset: number;
  keysPressed: string[];
  value: string;
}

interface Element {
  i: number;
  string: string;
  caretFocus: boolean;
  caretFocusStart: boolean;
  caretFocusEnd: boolean;
  start: number;
  end: number;
  update: (string: string) => void;
  type: ElementType;
  ref?: MutableRefObject<HTMLSpanElement | null>;
}

type ElementType = "string" | "handlebar" | "half-handlebar";

interface RemoveHandlebarParams {
  caretFocusEnd?: boolean;
  caretFocusStart?: boolean;
}

export type HandlebarProps = Props;
