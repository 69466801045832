import React, { memo } from "react";
import { Button, ButtonMouseEvent } from "@/components";
import { dialog } from "@/model";
import { DialogProps, DialogAction } from "../";
import "./Actions.scss";

function Actions(props: ActionsProps) {
  if (!props.actions && !props.status) return null;

  props = { ...props };

  props.actions = props.actions || [];

  const statusType = props.status?.type;
  const statusActions = props.suppressStatusActions ? [] : (statusType && STATUS_ACTIONS[statusType]) || [];
  const actions = [...statusActions, ...props.actions]?.map(mapAction);

  return <div className="dialog-box-actions">{actions.map(ActionHOC)}</div>;
}

function ActionHOC(props: DialogAction, i: number) {
  const { label, ...rest } = props;

  return (
    <Button {...rest} key={i}>
      {label}
    </Button>
  );
}

function mapAction(action: DialogAction) {
  return {
    ...action,
    onClick: async (e: ButtonMouseEvent) => {
      const res = action.onClick ? await action.onClick(e) : undefined;

      dialog.resolve?.({ res, action });

      dialog.hide();
    },
  };
}

const STATUS_ACTIONS = {
  confirm: [
    { label: "Cancel", onClick: () => false },
    { label: "Confirm", variant: "contained", onClick: () => true },
  ],
  error: [
    { label: "Reload", onClick: () => window.location.reload() },
    { label: "Confirm", variant: "contained" },
  ],
  warning: [
    { label: "Cancel", onClick: () => false },
    { label: "Confirm", variant: "contained", onClick: () => true },
  ],
  info: [{ label: "Confirm", variant: "contained" }],
  success: [{ label: "Confirm", variant: "contained" }],
} as StatusActions;

const Memo = memo(Actions);

export { Memo as Actions };

interface ActionsProps {
  status?: Status;
  actions: DialogProps["actions"];
  suppressStatusActions: DialogProps["suppressStatusActions"];
}

type StatusActions = Record<string, DialogAction[]>;
