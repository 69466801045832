import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useSimpleEffect, isEqual } from "@/util";
import { Form, LoadingOverlay, Status } from "@/components";
import { Config } from "../../Entity.layout";
import { useEntityActions } from "../../entity.actions";
import "./Body.scss";

function Body(props: Props) {
  const { id, companyId } = useParams();
  const sanitizedCompanyId = companyId.includes("$") ? "" : companyId;
  const { view, parent } = props.config;
  const { form, apiErrorDef } = view.entity;
  const isNew = `${id}`.includes("$");
  const entity = parent?.entitiesById[`${sanitizedCompanyId}@${id}`];
  const actions = useEntityActions(entity, props.config);
  const tempStatus = entity?.tempStatus;
  const status = entity?.status;
  const schema = entity?.schema;
  const dataToDerive = isNew ? null : { ...entity?.data };
  const [data, setData] = useState(dataToDerive);
  const entityDataChanged = !isEqual(data, dataToDerive);
  const isCodeHandled = apiErrorDef?.[`${status?.code}`];
  const errorExists = status?.type === "error";
  const unhandledErrorExists = errorExists && !isCodeHandled;
  const isFormHidden = !schema || tempStatus?.loading || unhandledErrorExists;
  const isStatusHidden = !unhandledErrorExists;

  useSimpleEffect(actions?.getData, [entity?.entityKey]);
  useEffect(deriveData, [entityDataChanged]);
  useEffect(handleStatusCode, [status?.code]);

  function deriveData() {
    if (entityDataChanged) setData(dataToDerive);
  }

  function handleStatusCode() {
    if (status?.code === "DUPLICATE_NAME") {
      form.context.current?.setFieldError("name", "Name already exists");
    }
  }

  return (
    <div className="entity-body">
      <Status status={status} hidden={isStatusHidden} />
      <LoadingOverlay status={tempStatus} />
      <Form
        {...form}
        JSONSchema={entity?.schema}
        initialValues={data}
        onSubmit={actions?.postData}
        hidden={isFormHidden}
        enableReinitialize
      />
    </div>
  );
}

const Observer = observer(Body);

export { Observer as Body };

interface Props {
  config: Config;
}
