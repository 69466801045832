import React from "react";
import { config } from "@/config";
import { history } from "@/history";
import { toJS } from "mobx";
import { trade } from "@/api";
import {
  auth,
  dialog,
  sidebar,
  router,
  lang,
  unsavedChanges,
  termsetLayouts,
  companies,
  cargoTypes,
  termsets,
  orderTemplates,
} from "@/model";

export function setup() {
  window.config = config;
  window.auth = auth;
  window.dialog = dialog;
  window.sidebar = sidebar;
  window.router = router;
  window.lang = lang;
  window.companies = companies;
  window.cargoTypes = cargoTypes;
  window.termsetLayouts = termsetLayouts;
  window.unsavedChanges = unsavedChanges;
  window._history = history;
  window.toJS = toJS;
  window.trade = trade;
  window.termsets = termsets;
  window.orderTemplates = orderTemplates;
}

export function pre(object) {
  return (
    <pre
      style={{
        border: "2px solid",
        position: "absolute",
        background: "white",
        top: "100%",
        right: "100%",
        zIndex: 9999999999,
      }}
    >
      {JSON.stringify(object, null, "  ")}
    </pre>
  );
}
