import React from "react";
import "./Row.scss";

export function Row(props: Props): JSX.Element {
  return <sea-field-row class={props.className}>{props.children}</sea-field-row>;
}

interface Props {
  className?: string;
  children: React.ReactNode;
}
