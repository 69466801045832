import { action, observable, decorate } from "mobx";

class Lang {
  code = "en";

  set = (code: string) => {
    this.code = code;
  };
}

decorate(Lang, {
  code: observable,
  set: action,
});

export const lang = new Lang();
