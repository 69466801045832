import React, { useState } from "react";
import classNames from "classnames";
import { SelectField, SelectFieldProps } from "@/components";
import vesselSizes from "./vessel.sizes.json";

export function VesselSize(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "vessel-size");

  const [data, setData] = useState(vesselSizes);

  function onFilter(search: string) {
    if (!search) {
      setData(vesselSizes);

      return;
    }

    const data = vesselSizes.filter((el) => el.name?.toLocaleLowerCase().startsWith(search.toLowerCase()));

    setData(data);
  }

  return (
    <SelectField
      {...props}
      value={props.value}
      data={data}
      onFilter={onFilter}
      display={display}
      noDataStatus={noDataStatus}
      dataTest={`${props.dataTest}/vessel-size`}
    />
  );
}

const noDataStatus = { message: "No matching vessel sizes" };

const display = (vesselSize: VesselSize) => vesselSize?.abbreviation;

export interface Props extends Omit<SelectFieldProps, "data" | "value"> {
  data?: VesselSize[];
  value?: VesselSize | null;
}
interface VesselSize {
  name?: null | string;
  from?: null | number;
  to?: null | number;
  abbreviation?: null | string;
}

export type VesselSizeType = VesselSize;
