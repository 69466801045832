import { observer } from "mobx-react";
import { lang } from "@/model";

function Translation(props: Props) {
  const { source } = props;
  const { code } = lang;
  const translation = source[code];

  if (typeof translation !== "string") return null;

  return translation;
}

const Observer = observer(Translation);

export { Observer as Translation };

interface Props {
  source: Source;
}

interface Source {
  [name: string]: Source;
}
