import React from "react";
import { Router } from "react-router-dom";
import { observer } from "mobx-react";
import { history } from "@/history";
import { router } from "@/model";
import { Footer, Sidenav, Route, Dialog, Sidebar, GlobalDropdown } from "@/components";
import "./Root.scss";

function Root() {
  return (
    <Router history={history}>
      <Sidenav />
      <Route route={router.root} />
      <Footer />
      <Sidebar />
      <Dialog />
      <GlobalDropdown />
    </Router>
  );
}

const Observer = observer(Root);

export { Observer as Root };
