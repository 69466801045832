import React from "react";
import { LoadingOverlay, Dialog } from "@/components";

export function Preauth(): JSX.Element {
  return (
    <>
      <LoadingOverlay status={{ loading: true }} />
      <Dialog />
    </>
  );
}
