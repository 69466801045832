import React from "react";
import { TradeAccount, Props } from "../trade.account";

export function TradeCharterer(props: Props): JSX.Element {
  props = { ...props };

  props.className = `${props.className} trade-charterer`;

  return <TradeAccount {...props} />;
}
