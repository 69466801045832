import React from "react";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../container";
import { Handlebar, HandlebarProps } from "@/components";

export function HandlebarField(props: HandlebarFieldProps): JSX.Element {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <Handlebar {...rest} />
    </Container>
  );
}

export interface HandlebarFieldProps extends FieldProps, HandlebarProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
