import React from "react";
import { observer } from "mobx-react";
import { StatusIcon } from "@/components";
import "./Striped.loader.scss";

const StripedLoader = (props: Props) => {
  const { status = {}, size = "x-large" } = props;
  const { loading, message } = status;

  return (
    <div className={`striped-loader-wrapper ${size}`}>
      {loading && (
        <div className="striped-loader">
          <div className="stripe">
            <div className="stripe-inner" />
          </div>
          <div className="stripe">
            <div className="stripe-inner" />
          </div>
          <div className="stripe">
            <div className="stripe-inner" />
          </div>
          <div className="stripe">
            <div className="stripe-inner" />
          </div>
          <div className="stripe">
            <div className="stripe-inner" />
          </div>
        </div>
      )}

      {message && (
        <label>
          <StatusIcon status={status} />
          {message}
        </label>
      )}
    </div>
  );
};

const Observer = observer(StripedLoader);

export { Observer as StripedLoader };

interface Props {
  status?: Status;
  size?: "x-small" | "small" | "medium" | "large" | "x-large";
}

export type StripedLoaderProps = Props;
