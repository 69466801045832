import React from "react";
import { FieldProps } from "../../Field";
import { Container } from "../../container";
import { Select, SelectProps } from "@/components";

export function SelectField(props: SelectFieldProps): JSX.Element {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <Select {...rest} />
    </Container>
  );
}

export interface SelectFieldProps extends FieldProps, SelectProps {}
