import { TradeAccount, TradeLocation, TradeCargoType, TradeTermsetLayout, TradeCompany, ArcAccountByName } from "@/api";

export const TRADE_DATA_DEF = {
  account: {
    arcContactId: {
      type: "text",
      disabled: true,
      label: "Contact Id",
      desc: "Contact Id",
    },
    accountName: {
      type: "text",
      disabled: true,
      label: "Account Name",
      desc: "Account Name",
    },
    gainAccountId: {
      type: "text",
      disabled: true,
      label: "Gain Account Id",
      desc: "Gain Account Id",
    },
    isLegalEntity: {
      type: "trade-account-legal-state",
      label: "Is Legal Entity",
      desc: "Is Legal Entity",
    },
    relatedArcAccount: {
      type: "arc-account",
      desc: "Arc Account",
      display: (arcAccount: ArcAccountByName) => arcAccount?.name,
    },
    display: (account: TradeAccount) => account.accountName,
  },
  location: {
    display: (location: TradeLocation): string | null | undefined => location.name,
  },
  cargoType: {
    display: (cargoType: TradeCargoType): string | null | undefined => cargoType.name,
  },
  termsetLayout: {
    display: (termsetLayout: TradeTermsetLayout): string | null | undefined => termsetLayout.name,
  },
  company: {
    display: (company: TradeCompany): string | null | undefined => company.name,
  },
  termset: {
    companyId: {
      type: "trade-company",
      label: "Company",
      desc: "The company the termset belongs to",
    },
    typeId: {
      type: "trade-order-type2",
      label: "Order Type",
      desc: "The type of the order",
    },
    name: {
      type: "text",
      label: "Termset Name",
      desc: "Termset Name",
    },
    "content.proformaLayoutId": {
      type: "trade-termset-layout",
      label: "Proforma Layout",
      desc: "The name of the Proforma Layout that will be used to create Charter Party in Sea Contracts",
    },
    "content.mainTermTemplates": {
      type: "termset",
    },
  },
  orderTemplate: {
    id: {
      type: "text",
      label: "ID",
      desc: "Unique order identifier in the system",
      disabled: true,
    },
    name: {
      type: "text",
      label: "Template Name",
      desc: "Template name",
      placeholder: "e.g. CAPE | COA | BHP | Iron Ore | C5",
    },
    companyId: {
      type: "trade-company",
      label: "Company",
      desc: "The company the template belongs to",
    },
    orderType: {
      type: "trade-order-type",
      label: "Order Type",
      desc: "The type of the order",
    },
    "template.order.chartererAccount": {
      type: "trade-charterer",
      label: "Charterer",
      desc: "The entity looking for a vessel to transport their cargo",
    },
    "template.order.laycan": {
      type: "trade-period",
      label: "Laycan",
      desc: "Laycan or L/C - Laydays Canceling - period during which the shipowner must tender notice of readiness to the charterer that the ship has arrived at the pod of loading and is ready to load",
    },
    "template.order.cargoType": {
      type: "trade-cargo-type",
      label: "Cargo Type",
      desc: "The goods to transport",
    },
    "template.order.addressCommission": {
      type: "trade-commission",
      label: "Address Commission %",
      desc: "Commission paid by the shipowner to the charterer, expressed as a percentage of the freight or hire. This commission is a reimbursement to the charterer for costs incurred in relation to the chartering of the vessel either to third party brokers or by the charterer's shipping department",
    },
    "template.order.brokerCommission": {
      type: "trade-commission",
      label: "Broker Commission %",
      desc: "Comission paid to the broker, expressed as a percentage of the transaction amount",
    },
    "template.voyage.cargoSize": {
      type: "trade-cargo-size",
      label: "Cargo Size",
      desc: "Weight of the cargo",
    },
    "template.voyage.laycan": {
      type: "trade-period",
      label: "Laycan",
      desc: "Laycan or L/C - Laydays Canceling - period during which the shipowner must tender notice of readiness to the charterer that the ship has arrived at the pod of loading and is ready to load",
    },
    "template.voyage.loadLocation": {
      type: "trade-location",
      label: "Load Location",
      desc: "Cargo pick-up location",
    },
    "template.voyage.dischargeLocation": {
      type: "trade-location",
      label: "Discharge Location",
      desc: "Cargo destination location",
    },
    "template.voyage.notes": {
      type: "textarea",
      label: "VOY Notes",
      desc: "Extra information about the voyage",
    },
    "template.tct.vesselSize": {
      type: "trade-vessel-size",
      label: "Vessel Size",
      desc: "The size of the vessel - describes the amount of cargo it can transport",
    },
    "template.tct.deliveryLocation": {
      type: "trade-location",
      hideSafes: true,
      label: "Delivery Location",
      desc: "Delivery location",
    },
    "template.tct.viaLocation": {
      type: "trade-location",
      hideSafes: true,
      label: "Via Location",
      desc: "Via location",
    },
    "template.tct.redeliveryLocation": {
      type: "trade-location",
      hideSafes: true,
      label: "Redelivery Location",
      desc: "Redelivery Location",
    },
    "template.tct.duration": {
      type: "trade-unit-duration",
      label: "Duration",
      desc: "TC duration",
    },
    "template.tct.notes": {
      type: "textarea",
      label: "TC Notes",
      desc: "Extra information about the TC",
    },
    "template.coa.coaCargoSize": {
      type: "trade-coa-cargo-size",
      label: "Cargo Size",
      desc: "Weight of the cargo",
    },
    "template.coa.loadLocation": {
      type: "trade-location",
      label: "Load Location",
      desc: "Cargo pick-up location",
    },
    "template.coa.dischargeLocation": {
      type: "trade-location",
      label: "Discharge Location",
      desc: "Cargo destination location",
    },
    "template.coa.vesselSize": {
      type: "trade-vessel-size",
      label: "Vessel Size",
      desc: "The size of the vessel - describes the amount of cargo it can transport",
    },
    "template.coa.period": {
      type: "trade-period",
      label: "Period",
      desc: "COA period",
    },
    "template.coa.liftings": {
      type: "trade-liftings",
      label: "Liftings",
      desc: "Liftings",
    },
    "template.coa.nominations": {
      type: "trade-nominations",
      label: "Nominations ",
      desc: "Nominations",
    },
    "template.coa.notes": {
      type: "textarea",
      label: "COA Notes",
      desc: "Extra information about the COA",
    },
  },
};
