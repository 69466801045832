import { RouteSeed } from "@/model";
import { Main, orderTemplatesRoute, termsetsRoute, ownerAccountsRoute, unauthorisedRoute } from "@/components";

export const routes: RouteSeed = {
  comp: Main,
  sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
  children: {
    orderTemplates: orderTemplatesRoute,
    termsets: termsetsRoute,
    ownerAccounts: ownerAccountsRoute,
    unauthorised: unauthorisedRoute,
    redirectToOrders: {
      redirect: {
        to: "orderTemplates",
        from: "/",
        exact: true,
      },
    },
  },
};
