import React, { useRef, useEffect } from "react";
import { cn } from "@/util";
import { InputChangeEvent, InputProps } from "../../Input";
import { Container } from "../../container";
import "./Textarea.scss";

export function Textarea(props: Props): JSX.Element {
  props = { ...props };

  props.className = cn(props.className, "textarea");

  const value = props.value || "";
  const input = useRef<HTMLTextAreaElement | null>(null);

  useEffect(setInputRef, []);

  function setInputRef() {
    if (props.input) props.input.current = input.current;

    return unsetInputRef;
  }

  function unsetInputRef() {
    if (props.input) props.input.current = null;
  }

  function onChange(e: InputChangeEvent) {
    props.onChange?.(e.currentTarget.value);
  }

  return (
    <Container {...props}>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        ref={input}
        data-test={`${props.dataTest}/input`}
      />
    </Container>
  );
}

type Props = InputProps<Value, string>;

type Value = string | number | null;

export type TextareaProps = Props;
