import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { cloneDeep, debounce, keyBy } from "@/util";
import { useFormikContext } from "formik";
import { termsetLayouts, termsetHandlebars } from "@/model";
import { Aggrid, LoadingOverlay, Button } from "@/components";
import uuid from "uuid";
import "./Termset.scss";

function Termset() {
  const [toRemove, setToRemove] = useState();
  const context = useFormikContext();
  const { values, setFieldValue, initialValues } = context;
  const value = values?.content?.mainTermTemplates;
  const clone = useMemo(() => cloneDeep(value), [value]);
  const layoutId = values?.content?.proformaLayoutId;
  const initialLayoutId = initialValues?.content?.proformaLayoutId;
  const layout = layoutId !== undefined && termsetLayouts.dataById[layoutId];
  const layoutKeys = layout?.data?.keys;
  const loading = layout?.loading;
  const typeId = values?.typeId;
  const handlebars = termsetHandlebars.data[orderTypeMap[typeId]];

  useEffect(getLayoutData, [layout]);
  useEffect(clearTermsLayoutKey, [layoutId]);
  useEffect(consumeToRemove, [toRemove]);

  layoutKeyProps.id = layoutId;
  handlebarProps.data = handlebars;

  function clearTermsLayoutKey() {
    if (!layoutId) return;

    if (layoutId === initialLayoutId) return;

    const layoutKeyMap = keyBy(layoutKeys);

    const next = value?.map((term) => {
      if (layoutKeyMap[term.cpmProformaKey]) return term;

      return { ...term, cpmProformaKey: null };
    });

    setFieldValue("content.mainTermTemplates", next);
  }

  function getLayoutData() {
    if (layout) layout.getData();
  }

  function onRowDragEnd(params) {
    const { api } = params;

    const value = [];

    api.forEachNode((node, i) => (value[i] = node?.data));

    setFieldValue("content.mainTermTemplates", value);
  }

  function onCellValueChanged(params) {
    const { colDef, rowIndex, value: cellValue } = params;

    setFieldValue(`content.mainTermTemplates.${rowIndex}.${colDef.field}`, cellValue);
  }

  function onCellFocused(params) {
    const { api, column, rowIndex } = params;
    const { colId } = column || {};

    if (colId === undefined) return;

    api.startEditingCell({ rowIndex, colKey: colId });

    const nodes = api.getRenderedNodes();

    const node = nodes.find((node) => node.rowIndex === rowIndex);

    if (node) node.setSelected(true);
  }

  function addTerm() {
    if (!value) {
      const next = [{ termId: uuid() }, ...(value || [])];
      setFieldValue("content.mainTermTemplates", next);
      return;
    }

    if (value.some((term) => !term.title)) return;
    const next = [{ termId: uuid() }, ...(value || [])];

    setFieldValue("content.mainTermTemplates", next);
  }

  function consumeToRemove() {
    if (!toRemove) return;

    const next = value.filter((term) => term.termId !== toRemove.data?.termId);

    setFieldValue("content.mainTermTemplates", next);
  }

  columnDefs[0].props.onClick = setToRemove;

  return (
    <div className="form-termset">
      <Button onClick={addTerm} icon="plus" type="button">
        Term
      </Button>
      <Aggrid
        rowData={clone}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onRowDragEnd={onRowDragEnd}
        onCellValueChanged={onCellValueChanged}
        onCellFocused={debounce(onCellFocused, 1)}
        getRowNodeId={getRowNodeId}
      />
      {loading && <LoadingOverlay threshold={500} />}
    </div>
  );
}

const layoutKeyProps = observable({
  id: undefined,
});

const handlebarProps = observable({
  data: undefined,
});

const columnDefs = [
  {
    width: 26,
    cellRenderer: "icon",
    props: {
      name: "close",
    },

    pinned: true,

    editable: false,
  },
  {
    rowDrag: true,
    width: 36,
    editable: false,
    rowDragText: ({ rowNode }) => rowNode?.data.title || "",
  },
  {
    field: "title",
    headerName: "Title",
    cellEditor: "input",
    cellEditorProps: { type: "text" },
    flex: 2,
  },
  {
    field: "content",
    headerName: "Content",
    cellEditor: "input",
    cellEditorProps: {
      type: "handlebar",
      filterKey: "name",
      getItemHandlebar,
      display,
      observableProps: handlebarProps,
    },
    flex: 3,
  },
  {
    field: "cpmProformaKey",
    headerName: "Proforma Key",
    cellEditor: "input",
    cellEditorProps: {
      type: "trade-termset-layout-key",
      observableProps: layoutKeyProps,
    },
    flex: 2,
  },
];

const orderTypeMap = {
  1: "voy",
  2: "tct",
  3: "coa",
};

const defaultColDef = {
  suppressMenu: true,
  suppressMovable: true,
  editable: true,
};

function getRowNodeId(item) {
  return item.termId;
}

function display(handlebar) {
  return handlebar.name;
}

function getItemHandlebar(handlebar) {
  return handlebar.key;
}

const Observer = observer(Termset);

export { Observer as Termset };
