import React from "react";
import { observer } from "mobx-react";
import { globalDropdown } from "@/model";
import { Dropdown, DropdownProps } from "@/components";

function GlobalDropdown() {
  const { props, open } = globalDropdown;

  if (!props || !open) return null;

  return <Dropdown {...props} hide={globalDropdown.hide} select={globalDropdown.select} />;
}

const Observer = observer(GlobalDropdown);

export { Observer as GlobalDropdown };

type Props = Omit<DropdownProps, "hide" | "select">;

export type GlobalDropdownProps = Props;
