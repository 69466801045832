import React, { memo, SyntheticEvent } from "react";
import { cn, useShallowState } from "@/util";
import "./Img.scss";

function Img(props: Props) {
  let { img } = props;

  img = typeof img === "string" ? { src: img } : img;

  props = { ...props, ...img };

  const [state, setState] = useShallowState<State>(defaultState);
  const { width, height } = state;

  if (!props.src) return null;

  function onLoad(e: SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;

    setState({ width, height, loading: false });
  }

  let biggerDimensionClass;

  if (width > height) biggerDimensionClass = "width-is-bigger";
  if (height > width) biggerDimensionClass = "height-is-bigger";

  props.className = cn(props.className, biggerDimensionClass);

  return <img {...props} onLoad={onLoad} loading="lazy" />;
}

const defaultState = {
  loading: true,
  width: 0,
  height: 0,
};

const Memo = memo(Img);

export { Memo as Img };

interface Props {
  img?: string | Omit<Props, "img">;
  src?: string;
  className?: string;
}

interface State {
  loading: boolean;
  width: number;
  height: number;
}

export type ImgProps = Props;
