import React from "react";
import { observer } from "mobx-react";
import { Route as RRRoute } from "react-router-dom";
import { history } from "@/history";
import { router, Route as RouteModel } from "@/model";

function Route(props: Props) {
  const { route } = props;
  const { comp: Comp, absPath } = route;

  function render() {
    if (!Comp) return null;

    const children = route.children.filter((child) => !child.redirect);
    const redirects = route.children.filter((child) => child.redirect);

    return (
      <CompWrapper route={route}>
        <Comp>
          {children.map(RouteHOC)}
          {redirects.map(RouteHOC)}
        </Comp>
      </CompWrapper>
    );
  }

  return <RRRoute path={absPath} render={render} />;
}

function CompWrapper(props: CompWrapperProps) {
  const { route, children } = props;
  const { pageTitle, authorised } = route;

  if (!authorised) {
    router.get("unauthorised").go();

    return null;
  }

  function onRender() {
    if (pageTitle) document.title = `Sea/Trade Admin ${pageTitle}`;

    router.setActive(route);
  }

  setTimeout(onRender);

  return children;
}

function RouteHOC(route: RouteModel): JSX.Element | null {
  if (route.redirect) {
    const { to, from } = route.redirect;
    const pathname = window.location.pathname;

    if (pathname === from && !pathname.includes(to)) {
      history.replace(to);
    }

    return null;
    // return <Redirect {...route.redirect} key={i} />;
  }

  return <Route route={route} key={route.path} />;
}

const Observer = observer(Route);

export { Observer as Route, RouteHOC };

interface Props {
  route: RouteModel;
}

interface CompWrapperProps {
  route: RouteModel;
  children: JSX.Element;
}
