import React, { useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { useShallowState } from "@/util";
import { ArcAccountByName, trade } from "@/api";
import { TRADE_DATA_DEF } from "@/constants";
import { Group } from "../../group";
import { InputFocusEvent, SelectField, SelectFieldProps, TextField } from "@/components";
import { dialog } from "@/model";

export function ArcContact(props: Props) {
  const className = classNames(props.className, "trade-arc-contact");
  const { value } = props;
  const [data, setData] = useState<ArcAccountProps["data"]>(undefined);
  const [dropdownStatus, setDropdownStatus] = useShallowState<Status>({
    message: "Please enter contact name",
  });

  const canceller = useRef(axios.CancelToken.source());

  async function onFilter(search: string) {
    canceller.current.cancel();
    canceller.current = axios.CancelToken.source();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false });

    if (!search) {
      setDropdownStatus({ message: "Please enter contact name" });

      return;
    }

    setDropdownStatus({ message: "Searching for Arc contacts", loading: true });

    const takeCount = 1000;
    const res = await trade.searchArcAccountByName(search, takeCount, canceller.current.token);

    if (res.cancelled) return;

    if (!res.ok) {
      dialog.show({
        status: {
          type: "error",
          overrideBaseBehavior: true,
          title: "Search failed",
          message: `Some internal server error occured`,
        },
        dataTest: `search-failed`,
      });

      setData(undefined);
      setDropdownStatus({ message: "No contact found", loading: false });

      return;
    }

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: "No contact found", loading: false });

      return;
    }

    setData(res.data.sort((x, y) => x.name.localeCompare(y.name)));
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    canceller.current.cancel();

    setData(undefined);
    setDropdownStatus({ message: "Please enter contact name", loading: false });

    props.onBlur && props.onBlur(e);
  }

  async function onChange(arcAccount: ArcAccountByName) {
    if (arcAccount?.name) {
      const res = await trade.getArcAccountByName(arcAccount.name);

      if (res.ok && res.data && res.data.length > 0) {
        arcAccount = res.data[0];
      }
    }

    props.onChange?.(
      arcAccount
        ? {
            ...value,
            ...arcAccount,
          }
        : null
    );
  }

  return (
    <Group className={className} label={props.label}>
      <SelectField
        {...props}
        label="Arc Contact Name"
        className="trade-arc-contact"
        value={props.value}
        onChange={onChange}
        data={data}
        placeholder="e.g. NYK LINE (Benelux) B.V."
        dropdownStatus={{ ...dropdownStatus }}
        display={TRADE_DATA_DEF.account.relatedArcAccount.display}
        onFilter={onFilter}
        onBlur={onBlur}
        clearable={false}
        dataTest={`${props.dataTest}/trade-arc-contact-select`}
      />
      {!!props.value?.active && (
        <TextField
          label="Is Active"
          value={`${props.value?.active ?? ""}`}
          disabled
          dataTest={`${props.dataTest}/trade-arc-contact-name`}
        />
      )}
      {!!props.value?.contactId && (
        <TextField
          label="Contact Id"
          value={props.value?.contactId}
          disabled
          dataTest={`${props.dataTest}/trade-arc-contact-id`}
        />
      )}
      {!!props.value?.gainAccountId && (
        <TextField
          label="Gain Account Id"
          value={props.value?.gainAccountId}
          disabled
          dataTest={`${props.dataTest}/trade-arc-contact-name`}
        />
      )}
    </Group>
  );
}

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: ArcAccountByName | null;
  onChange?: (value: ArcAccountByName | null) => void;
}

export type ArcAccountProps = Props;
