import React from "react";
import dayjs, { extend } from "dayjs";
import { useParams } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import { observer } from "mobx-react";
import { Config } from "../../Entity.layout";
import { EntityContextMenu } from "../../entity.context.menu";
import { FormControl } from "./form.control";
import "./Header.scss";

extend(utc);

function Header(props: Props) {
  const { id, companyId } = useParams();
  const { parent } = props.config;
  const entity = parent?.entitiesById[`${companyId}@${id}`];
  const isNew = id && id.includes("$");

  if (!entity) return null;

  const { data } = entity;
  const created = data?.created;
  const updated = data?.updated;
  const published = data?.published;
  const format = "DD/MM/YY hh:mm A";

  return (
    <div className="entity-header" data-test-uuid={entity?.entityKey}>
      <div className="entity-header-details">
        <div className="entity-header-details-row">
          <span>{data?.name}</span>
          {data?.companyName && (
            <div className="entity-header-detail">
              <span>Owner:</span>
              <span>{data?.companyName}</span>
            </div>
          )}
        </div>

        <div className="entity-header-details-row">
          {created && (
            <div className="entity-header-detail">
              <span>Created:</span>
              <span>
                {dayjs.utc(created).local().format(format)} by {data?.createdBy?.name}
              </span>
            </div>
          )}

          {updated && (
            <div className="entity-header-detail">
              <span>Updated:</span>
              <span>
                {dayjs.utc(updated).local().format(format)} by {data?.updatedBy?.name}
              </span>
            </div>
          )}

          {published && (
            <div className="entity-header-detail">
              <span>Published:</span>
              <span>
                {dayjs.utc(published).local().format(format)} by {data?.publishedBy?.name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="entity-header-controls">
        <FormControl config={props.config} entity={entity} />
        <EntityContextMenu config={props.config} entity={entity} hidden={!!isNew || !parent?.contextMenuEnabled} />
      </div>
    </div>
  );
}

const Observer = observer(Header);

export { Observer as Header };

interface Props {
  config: Config;
}
