import React, { useMemo } from "react";
import { get } from "lodash-es";
import { Config } from "../Entity.layout";
import { dialog, auth } from "@/model";
import { TradeLocalFilter } from "@/api";

export function useParentActions(config: Config): ParentActions {
  return useMemo(createParentActions.bind(null, config), [config]);
}

function createParentActions(config: Config) {
  return new ParentActions(config);
}

class ParentActions {
  constructor(config: Config) {
    this.config = config;
    this.parent = config.parent;
  }

  searchEntities = async (searchText: string, localFilter: TradeLocalFilter, pageNumber?: number) => {
    const isMaritechUser = auth.trade.user?.isMaritechUser;
    // shows filters only for termsets and order templates excluding owner tab
    const isFilterApplied =
      localFilter.companyId || localFilter.orderType || localFilter.termsetType || localFilter["content.proformaLayoutId"];

    if (!this.parent) return;

    searchText = searchText.trim();

    this.parent.setEntities([]);
    this.parent.searchStatus.loading = true;

    if (!searchText) {
      if ((isMaritechUser && isFilterApplied) || !isMaritechUser) {
        await this.search("", localFilter, pageNumber);
      } else {
        this.parent.searchStatus.message = "Waiting for your input";
        this.parent.searchStatus.loading = false;
        this.parent.setTotal(0);
      }
      return;
    }

    await this.search(searchText, localFilter, pageNumber);
  };

  search = async (searchText: string, localFilter: TradeLocalFilter, pageNumber?: number) => {
    if (this.parent) {
      this.parent.searchStatus.type = null;
      this.parent.searchStatus.message = `Searching for ${this.config.view.entity.name}s`;

      const res = await this.parent.searchEntities(searchText, localFilter, pageNumber);

      if (!res.cancelled && !res.ok) {
        const status = {
          type: "error",
          title: `${this.config.view.entity.name} search failure`,
          message: (
            <i>
              {res?.config?.url}, {res?.status}
            </i>
          ),
        } as Status;

        dialog.show({
          status,
          dataTest: `${this.config.dataTest}-search-fail`,
        });

        this.parent.searchStatus.type = "error";
        this.parent.searchStatus.message = "Something went wrong, try again";
      }

      if (res.cancelled) return;

      this.parent.searchStatus.type = null;
      this.parent.searchStatus.message = null;
      this.parent.searchStatus.loading = false;

      let data = res.data;

      if (res.ok) {
        if ((searchText === "" || localFilter) && this.config.view.entity.name !== "Termset") {
          const localFilterEntries = Object.entries(localFilter);

          for (let i = 0; i < localFilterEntries.length; i++) {
            const [key, value] = localFilterEntries[i];

            data = data.filter((x) => filterByKeyValue(x, key, value));
          }
        }

        if (this.config.view.entity.name === "Termset") {
          this.parent.setTotal(data.value.totalCount);
          data = data.value.content;
        }

        this.parent.setEntities(data);
      }

      if (res.ok && !data?.length) {
        this.parent.searchStatus.message = `No ${this.config.view.entity.name}s found`;
        this.parent.searchStatus.type = "info";
      }
    }
  };
}

function filterByKeyValue(entity, key, value) {
  if (typeof value !== "boolean" && !value) return true;

  const entityValue = get(entity, key);

  return entityValue === value;
}

interface ParentActions {
  parent: Config["parent"];
  config: Config;
}
