import React from "react";
import { observer } from "mobx-react";
import { isEqual } from "@/util";
import { Button } from "@/components";
import { Config, Entity } from "../../../Entity.layout";
import { useEntityActions } from "../../../entity.actions";
import "./Form.control.scss";

function FormControl(props: Props) {
  const { entity, config } = props;
  const { view, dataTest } = config;
  const { current } = view.entity.form.context;
  const publishable = entity?.data?.published !== undefined;
  const isPublished = entity?.data?.isPublished;
  const actions = useEntityActions(entity, config);

  if (!current) return null;

  const { initialValues, values, resetForm, submitForm, isSubmitting } = current;

  const hasChanged = !isEqual(initialValues, values);

  return (
    <div className="entity-form-control">
      {hasChanged && (
        <Button onClick={() => resetForm()} data-test={`${dataTest}-entity-form-button-cancel`} disabled={isSubmitting}>
          Cancel
        </Button>
      )}
      {hasChanged && (
        <Button
          onClick={() => submitForm()}
          variant="contained"
          data-test={`${dataTest}-entity-form-button-save`}
          disabled={isSubmitting}
        >
          Save
        </Button>
      )}
      {publishable && (
        <Button
          onClick={actions?.[isPublished ? "unpublish" : "publish"]}
          variant="contained"
          data-test={`${dataTest}-entity-form-button-publish`}
          disabled={isSubmitting}
        >
          {isPublished ? "Unpublish" : "Publish"}
        </Button>
      )}
    </div>
  );
}

const Observer = observer(FormControl);

export { Observer as FormControl };

interface Props {
  config: Config;
  entity: Entity;
}
