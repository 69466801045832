import { config } from "@/config";
import { auth, dialog, unsavedChanges } from "@/model";
import "./sea.header.scss";

export function setup(): void {
  const script = document.createElement("script");
  script.id = "ClarksonsNavbarScript";
  script.src = config.tradeHeaderURL;
  script.async = true;
  script.onload = onLoad;
  document.body.appendChild(script);
}

async function onLoad() {
  const header = document.querySelector("header");

  new window.Sea.HeaderComponent.Header({
    container: document.querySelector("header"),
    apiServer: config.centralAuthURL,
    applicationCode: config.applicationCode,
    getTokenCallback,
    logoutCallback,
  });

  if (config.mockedHeader) {
    // this is until SeaTrade adds logoutCallback to the Header
    const logout = header?.querySelector("a");

    if (logout) logout.onclick = logoutCallback;

    //
  } else {
    // this is until SeaHeader team fixes logoutCallback destroying the Header itself
    const selector = "a.sea__header-bar__icon-logout";
    const logout = header?.querySelector(selector) as HTMLAnchorElement;

    if (!logout) return;

    const clone = logout.cloneNode(true) as HTMLAnchorElement;

    logout.parentNode?.replaceChild(clone, logout);

    clone.onclick = logoutCallback;
  }
}

async function getTokenCallback() {
  await auth.central.promise;

  return auth.central.token;
}

async function logoutCallback(e: Event) {
  e.preventDefault();

  if (unsavedChanges.exist) {
    const { res: shouldNotStop } = await dialog.show({
      status: {
        type: "warning",
        title: "Unsaved Changes",
        message: "You have unsaved changes, are you sure you want to sign out?",
      },
      dataTest: "unsaved-changes",
    });

    if (!shouldNotStop) return;
  }

  auth.reset(false);
}
