import React from "react";
import classNames from "classnames";
import { TradeNominations as TradeNominationsType } from "@/api";
import { TextareaField, NumberField, NumberFieldProps } from "@/components";
import { Group } from "../../group";
import { Row } from "../../row";
import "./Trade.nominations.scss";

export function TradeNominations(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-nominations");

  const { value, status, ...rest } = props;

  const { noticePerLaycan, laycanSpread, finalLaycanNotice } = value || {};

  function onValueChange(name: keyof TradeNominationsType, inputValue: number | null) {
    const next = {
      ...value,
      [name]: inputValue,
    };

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...props.value, notes });
  }

  return (
    <Group className={props.className} label={props.label}>
      <Row className="trade-nominations-value">
        <NumberField
          {...rest}
          label="Notice per laycan"
          className="trade-nominations-notice"
          placeholder="e.g. 30"
          desc="Nominations notice per laycan"
          status={status?.children?.noticePerLaycan}
          value={noticePerLaycan}
          onChange={onValueChange.bind(null, "noticePerLaycan")}
          dataTest={`${props.dataTest}/trade-nominations-notice`}
        />
        <NumberField
          {...rest}
          label="Laycan spread"
          className="trade-nominations-spread"
          desc="Nominations laycan spread"
          placeholder="e.g. 10"
          status={status?.children?.laycanSpread}
          value={laycanSpread}
          onChange={onValueChange.bind(null, "laycanSpread")}
          dataTest={`${props.dataTest}/trade-nominations-spread`}
        />
        <NumberField
          {...rest}
          label="Final laycan notice"
          className="trade-nominations-final"
          desc="Nominations final laycan notice"
          placeholder="e.g. 10"
          status={status?.children?.finalLaycanNotice}
          value={finalLaycanNotice}
          onChange={onValueChange.bind(null, "finalLaycanNotice")}
          dataTest={`${props.dataTest}/trade-nominations-final`}
        />
      </Row>
      <TextareaField
        {...props}
        label="Notes"
        desc="Nominations notes"
        className="trade-nominations-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-nominations-notes`}
      />
    </Group>
  );
}

interface Props extends Omit<NumberFieldProps, "value" | "onChange"> {
  value?: TradeNominationsType;
  onChange?: (value: TradeNominationsType) => void;
}

export type FocusEvent = React.FocusEvent<HTMLInputElement>;
