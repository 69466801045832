import React, { useRef, useState } from "react";
import axios from "axios";
import { useShallowState } from "@/util";
import { trade } from "@/api";
import { TRADE_DATA_DEF } from "@/constants";
import { SelectField, SelectFieldProps, InputFocusEvent } from "@/components";

export function TradeAccount(props: Props): JSX.Element {
  props = { ...props };

  props.className = `${props.className} trade-account`;

  const [data, setData] = useState<SelectFieldProps["data"]>(undefined);
  const [dropdownStatus, setDropdownStatus] = useShallowState<Status>({
    message: "Please enter a charterer's name",
  });

  // eslint-disable-next-line import/no-named-as-default-member
  const canceller = useRef(axios.CancelToken.source());

  async function onFilter(search: string) {
    canceller.current.cancel();

    // eslint-disable-next-line import/no-named-as-default-member
    canceller.current = axios.CancelToken.source();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: "Please enter a charterer's name" });

      return;
    }

    setDropdownStatus({ message: "Searching for charterers", loading: true });

    const res = await trade.searchAccounts(search, canceller.current.token);

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: "No charterers found", loading: false });

      return;
    }

    const data = res.data.map((el) => el.document);

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    canceller.current.cancel();

    setData(undefined);
    setDropdownStatus({
      message: "Please enter a charterer's name",
      loading: false,
    });

    props.onBlur && props.onBlur(e);
  }

  return (
    <SelectField
      {...props}
      value={props.value}
      data={data}
      placeholder="e.g. Clarksons"
      display={TRADE_DATA_DEF.account.display}
      dropdownStatus={{ ...dropdownStatus }}
      onFilter={onFilter}
      onBlur={onBlur}
    />
  );
}

export type Props = SelectFieldProps;
