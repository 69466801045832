import React from "react";
import { observer } from "mobx-react";
import { popup } from "@/model";
import "./Popup.scss";

function Popup() {
  const { content, open, hide } = popup;

  // const rect = field.current.getBoundingClientRect();
  // next.isBottomHalf = rect.top > window.innerHeight / 2;

  if (!open) return null;

  return (
    <div className="popup">
      {open && <div className="popup-backdrop" onClick={hide} />}
      <div className="popup-content">{content}</div>
    </div>
  );
}

const Observer = observer(Popup);

export { Observer as Popup };
