import { keyBy } from "@/util";

export const COUNTRIES = [
  {
    name: "Afghanistan",
    officialStateName: "The Islamic Republic of Afghanistan",
    sovereignty: "UN member state",
    alpha2Code: "AF",
    alpha3Code: "AFG",
    numericCode: "004",
    subdivisionCodeLink: "ISO 3166-2:AF",
  },
  {
    name: "Åland Islands",
    officialStateName: "Åland",
    sovereignty: "Finland",
    alpha2Code: "AX",
    alpha3Code: "ALA",
    numericCode: "248",
    subdivisionCodeLink: "ISO 3166-2:AX",
  },
  {
    name: "Albania",
    officialStateName: "The Republic of Albania",
    sovereignty: "UN member state",
    alpha2Code: "AL",
    alpha3Code: "ALB",
    numericCode: "008",
    subdivisionCodeLink: "ISO 3166-2:AL",
  },
  {
    name: "Algeria",
    officialStateName: "The People's Democratic Republic of Algeria",
    sovereignty: "UN member state",
    alpha2Code: "DZ",
    alpha3Code: "DZA",
    numericCode: "012",
    subdivisionCodeLink: "ISO 3166-2:DZ",
  },
  {
    name: "American Samoa",
    officialStateName: "The Territory of American Samoa",
    sovereignty: "United States",
    alpha2Code: "AS",
    alpha3Code: "ASM",
    numericCode: "016",
    subdivisionCodeLink: "ISO 3166-2:AS",
  },
  {
    name: "Andorra",
    officialStateName: "The Principality of Andorra",
    sovereignty: "UN member state",
    alpha2Code: "AD",
    alpha3Code: "AND",
    numericCode: "020",
    subdivisionCodeLink: "ISO 3166-2:AD",
  },
  {
    name: "Angola",
    officialStateName: "The Republic of Angola",
    sovereignty: "UN member state",
    alpha2Code: "AO",
    alpha3Code: "AGO",
    numericCode: "024",
    subdivisionCodeLink: "ISO 3166-2:AO",
  },
  {
    name: "Anguilla",
    officialStateName: "Anguilla",
    sovereignty: "United Kingdom",
    alpha2Code: "AI",
    alpha3Code: "AIA",
    numericCode: "660",
    subdivisionCodeLink: "ISO 3166-2:AI",
  },
  {
    name: "Antarctica [a]",
    officialStateName: "All land and ice shelves south of the 60th parallel south",
    sovereignty: "Antarctic Treaty",
    alpha2Code: "AQ",
    alpha3Code: "ATA",
    numericCode: "010",
    subdivisionCodeLink: "ISO 3166-2:AQ",
  },
  {
    name: "Antigua and Barbuda",
    officialStateName: "Antigua and Barbuda",
    sovereignty: "UN member state",
    alpha2Code: "AG",
    alpha3Code: "ATG",
    numericCode: "028",
    subdivisionCodeLink: "ISO 3166-2:AG",
  },
  {
    name: "Argentina",
    officialStateName: "The Argentine Republic",
    sovereignty: "UN member state",
    alpha2Code: "AR",
    alpha3Code: "ARG",
    numericCode: "032",
    subdivisionCodeLink: "ISO 3166-2:AR",
  },
  {
    name: "Armenia",
    officialStateName: "The Republic of Armenia",
    sovereignty: "UN member state",
    alpha2Code: "AM",
    alpha3Code: "ARM",
    numericCode: "051",
    subdivisionCodeLink: "ISO 3166-2:AM",
  },
  {
    name: "Aruba",
    officialStateName: "Aruba",
    sovereignty: "Netherlands",
    alpha2Code: "AW",
    alpha3Code: "ABW",
    numericCode: "533",
    subdivisionCodeLink: "ISO 3166-2:AW",
  },
  {
    name: "Australia [b]",
    officialStateName: "The Commonwealth of Australia",
    sovereignty: "UN member state",
    alpha2Code: "AU",
    alpha3Code: "AUS",
    numericCode: "036",
    subdivisionCodeLink: "ISO 3166-2:AU",
  },
  {
    name: "Austria",
    officialStateName: "The Republic of Austria",
    sovereignty: "UN member state",
    alpha2Code: "AT",
    alpha3Code: "AUT",
    numericCode: "040",
    subdivisionCodeLink: "ISO 3166-2:AT",
  },
  {
    name: "Azerbaijan",
    officialStateName: "The Republic of Azerbaijan",
    sovereignty: "UN member state",
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    numericCode: "031",
    subdivisionCodeLink: "ISO 3166-2:AZ",
  },
  {
    name: "Bahamas (the)",
    officialStateName: "The Commonwealth of The Bahamas",
    sovereignty: "UN member state",
    alpha2Code: "BS",
    alpha3Code: "BHS",
    numericCode: "044",
    subdivisionCodeLink: "ISO 3166-2:BS",
  },
  {
    name: "Bahrain",
    officialStateName: "The Kingdom of Bahrain",
    sovereignty: "UN member state",
    alpha2Code: "BH",
    alpha3Code: "BHR",
    numericCode: "048",
    subdivisionCodeLink: "ISO 3166-2:BH",
  },
  {
    name: "Bangladesh",
    officialStateName: "The People's Republic of Bangladesh",
    sovereignty: "UN member state",
    alpha2Code: "BD",
    alpha3Code: "BGD",
    numericCode: "050",
    subdivisionCodeLink: "ISO 3166-2:BD",
  },
  {
    name: "Barbados",
    officialStateName: "Barbados",
    sovereignty: "UN member state",
    alpha2Code: "BB",
    alpha3Code: "BRB",
    numericCode: "052",
    subdivisionCodeLink: "ISO 3166-2:BB",
  },
  {
    name: "Belarus",
    officialStateName: "The Republic of Belarus",
    sovereignty: "UN member state",
    alpha2Code: "BY",
    alpha3Code: "BLR",
    numericCode: "112",
    subdivisionCodeLink: "ISO 3166-2:BY",
  },
  {
    name: "Belgium",
    officialStateName: "The Kingdom of Belgium",
    sovereignty: "UN member state",
    alpha2Code: "BE",
    alpha3Code: "BEL",
    numericCode: "056",
    subdivisionCodeLink: "ISO 3166-2:BE",
  },
  {
    name: "Belize",
    officialStateName: "Belize",
    sovereignty: "UN member state",
    alpha2Code: "BZ",
    alpha3Code: "BLZ",
    numericCode: "084",
    subdivisionCodeLink: "ISO 3166-2:BZ",
  },
  {
    name: "Benin",
    officialStateName: "The Republic of Benin",
    sovereignty: "UN member state",
    alpha2Code: "BJ",
    alpha3Code: "BEN",
    numericCode: "204",
    subdivisionCodeLink: "ISO 3166-2:BJ",
  },
  {
    name: "Bermuda",
    officialStateName: "Bermuda",
    sovereignty: "United Kingdom",
    alpha2Code: "BM",
    alpha3Code: "BMU",
    numericCode: "060",
    subdivisionCodeLink: "ISO 3166-2:BM",
  },
  {
    name: "Bhutan",
    officialStateName: "The Kingdom of Bhutan",
    sovereignty: "UN member state",
    alpha2Code: "BT",
    alpha3Code: "BTN",
    numericCode: "064",
    subdivisionCodeLink: "ISO 3166-2:BT",
  },
  {
    name: "Bolivia (Plurinational State of)",
    officialStateName: "The Plurinational State of Bolivia",
    sovereignty: "UN member state",
    alpha2Code: "BO",
    alpha3Code: "BOL",
    numericCode: "068",
    subdivisionCodeLink: "ISO 3166-2:BO",
  },
  {
    name: "Bonaire\n Sint Eustatius\n Saba",
    officialStateName: "Bonaire, Sint Eustatius and Saba",
    sovereignty: "Netherlands",
    alpha2Code: "BQ",
    alpha3Code: "BES",
    numericCode: "535",
    subdivisionCodeLink: "ISO 3166-2:BQ",
  },
  {
    name: "Bosnia and Herzegovina",
    officialStateName: "Bosnia and Herzegovina",
    sovereignty: "UN member state",
    alpha2Code: "BA",
    alpha3Code: "BIH",
    numericCode: "070",
    subdivisionCodeLink: "ISO 3166-2:BA",
  },
  {
    name: "Botswana",
    officialStateName: "The Republic of Botswana",
    sovereignty: "UN member state",
    alpha2Code: "BW",
    alpha3Code: "BWA",
    numericCode: "072",
    subdivisionCodeLink: "ISO 3166-2:BW",
  },
  {
    name: "Bouvet Island",
    officialStateName: "Bouvet Island",
    sovereignty: "Norway",
    alpha2Code: "BV",
    alpha3Code: "BVT",
    numericCode: "074",
    subdivisionCodeLink: "ISO 3166-2:BV",
  },
  {
    name: "Brazil",
    officialStateName: "The Federative Republic of Brazil",
    sovereignty: "UN member state",
    alpha2Code: "BR",
    alpha3Code: "BRA",
    numericCode: "076",
    subdivisionCodeLink: "ISO 3166-2:BR",
  },
  {
    name: "British Indian Ocean Territory (the)",
    officialStateName: "The British Indian Ocean Territory",
    sovereignty: "United Kingdom",
    alpha2Code: "IO",
    alpha3Code: "IOT",
    numericCode: "086",
    subdivisionCodeLink: "ISO 3166-2:IO",
  },
  {
    name: "Brunei Darussalam [e]",
    officialStateName: "The Nation of Brunei, the Abode of Peace",
    sovereignty: "UN member state",
    alpha2Code: "BN",
    alpha3Code: "BRN",
    numericCode: "096",
    subdivisionCodeLink: "ISO 3166-2:BN",
  },
  {
    name: "Bulgaria",
    officialStateName: "The Republic of Bulgaria",
    sovereignty: "UN member state",
    alpha2Code: "BG",
    alpha3Code: "BGR",
    numericCode: "100",
    subdivisionCodeLink: "ISO 3166-2:BG",
  },
  {
    name: "Burkina Faso",
    officialStateName: "Burkina Faso",
    sovereignty: "UN member state",
    alpha2Code: "BF",
    alpha3Code: "BFA",
    numericCode: "854",
    subdivisionCodeLink: "ISO 3166-2:BF",
  },
  {
    name: "Burundi",
    officialStateName: "The Republic of Burundi",
    sovereignty: "UN member state",
    alpha2Code: "BI",
    alpha3Code: "BDI",
    numericCode: "108",
    subdivisionCodeLink: "ISO 3166-2:BI",
  },
  {
    name: "Cabo Verde [f]",
    officialStateName: "The Republic of Cabo Verde",
    sovereignty: "UN member state",
    alpha2Code: "CV",
    alpha3Code: "CPV",
    numericCode: "132",
    subdivisionCodeLink: "ISO 3166-2:CV",
  },
  {
    name: "Cambodia",
    officialStateName: "The Kingdom of Cambodia",
    sovereignty: "UN member state",
    alpha2Code: "KH",
    alpha3Code: "KHM",
    numericCode: "116",
    subdivisionCodeLink: "ISO 3166-2:KH",
  },
  {
    name: "Cameroon",
    officialStateName: "The Republic of Cameroon",
    sovereignty: "UN member state",
    alpha2Code: "CM",
    alpha3Code: "CMR",
    numericCode: "120",
    subdivisionCodeLink: "ISO 3166-2:CM",
  },
  {
    name: "Canada",
    officialStateName: "Canada",
    sovereignty: "UN member state",
    alpha2Code: "CA",
    alpha3Code: "CAN",
    numericCode: "124",
    subdivisionCodeLink: "ISO 3166-2:CA",
  },
  {
    name: "Cayman Islands (the)",
    officialStateName: "The Cayman Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "KY",
    alpha3Code: "CYM",
    numericCode: "136",
    subdivisionCodeLink: "ISO 3166-2:KY",
  },
  {
    name: "Central African Republic (the)",
    officialStateName: "The Central African Republic",
    sovereignty: "UN member state",
    alpha2Code: "CF",
    alpha3Code: "CAF",
    numericCode: "140",
    subdivisionCodeLink: "ISO 3166-2:CF",
  },
  {
    name: "Chad",
    officialStateName: "The Republic of Chad",
    sovereignty: "UN member state",
    alpha2Code: "TD",
    alpha3Code: "TCD",
    numericCode: "148",
    subdivisionCodeLink: "ISO 3166-2:TD",
  },
  {
    name: "Chile",
    officialStateName: "The Republic of Chile",
    sovereignty: "UN member state",
    alpha2Code: "CL",
    alpha3Code: "CHL",
    numericCode: "152",
    subdivisionCodeLink: "ISO 3166-2:CL",
  },
  {
    name: "China",
    officialStateName: "The People's Republic of China",
    sovereignty: "UN member state",
    alpha2Code: "CN",
    alpha3Code: "CHN",
    numericCode: "156",
    subdivisionCodeLink: "ISO 3166-2:CN",
  },
  {
    name: "Christmas Island",
    officialStateName: "The Territory of Christmas Island",
    sovereignty: "Australia",
    alpha2Code: "CX",
    alpha3Code: "CXR",
    numericCode: "162",
    subdivisionCodeLink: "ISO 3166-2:CX",
  },
  {
    name: "Cocos (Keeling) Islands (the)",
    officialStateName: "The Territory of Cocos (Keeling) Islands",
    sovereignty: "Australia",
    alpha2Code: "CC",
    alpha3Code: "CCK",
    numericCode: "166",
    subdivisionCodeLink: "ISO 3166-2:CC",
  },
  {
    name: "Colombia",
    officialStateName: "The Republic of Colombia",
    sovereignty: "UN member state",
    alpha2Code: "CO",
    alpha3Code: "COL",
    numericCode: "170",
    subdivisionCodeLink: "ISO 3166-2:CO",
  },
  {
    name: "Comoros (the)",
    officialStateName: "The Union of the Comoros",
    sovereignty: "UN member state",
    alpha2Code: "KM",
    alpha3Code: "COM",
    numericCode: "174",
    subdivisionCodeLink: "ISO 3166-2:KM",
  },
  {
    name: "Congo (the Democratic Republic of the)",
    officialStateName: "The Democratic Republic of the Congo",
    sovereignty: "UN member state",
    alpha2Code: "CD",
    alpha3Code: "COD",
    numericCode: "180",
    subdivisionCodeLink: "ISO 3166-2:CD",
  },
  {
    name: "Congo (the) [g]",
    officialStateName: "The Republic of the Congo",
    sovereignty: "UN member state",
    alpha2Code: "CG",
    alpha3Code: "COG",
    numericCode: "178",
    subdivisionCodeLink: "ISO 3166-2:CG",
  },
  {
    name: "Cook Islands (the)",
    officialStateName: "The Cook Islands",
    sovereignty: "New Zealand",
    alpha2Code: "CK",
    alpha3Code: "COK",
    numericCode: "184",
    subdivisionCodeLink: "ISO 3166-2:CK",
  },
  {
    name: "Costa Rica",
    officialStateName: "The Republic of Costa Rica",
    sovereignty: "UN member state",
    alpha2Code: "CR",
    alpha3Code: "CRI",
    numericCode: "188",
    subdivisionCodeLink: "ISO 3166-2:CR",
  },
  {
    name: "Côte d'Ivoire [h]",
    officialStateName: "The Republic of Côte d'Ivoire",
    sovereignty: "UN member state",
    alpha2Code: "CI",
    alpha3Code: "CIV",
    numericCode: "384",
    subdivisionCodeLink: "ISO 3166-2:CI",
  },
  {
    name: "Croatia",
    officialStateName: "The Republic of Croatia",
    sovereignty: "UN member state",
    alpha2Code: "HR",
    alpha3Code: "HRV",
    numericCode: "191",
    subdivisionCodeLink: "ISO 3166-2:HR",
  },
  {
    name: "Cuba",
    officialStateName: "The Republic of Cuba",
    sovereignty: "UN member state",
    alpha2Code: "CU",
    alpha3Code: "CUB",
    numericCode: "192",
    subdivisionCodeLink: "ISO 3166-2:CU",
  },
  {
    name: "Curaçao",
    officialStateName: "The Country of Curaçao",
    sovereignty: "Netherlands",
    alpha2Code: "CW",
    alpha3Code: "CUW",
    numericCode: "531",
    subdivisionCodeLink: "ISO 3166-2:CW",
  },
  {
    name: "Cyprus",
    officialStateName: "The Republic of Cyprus",
    sovereignty: "UN member state",
    alpha2Code: "CY",
    alpha3Code: "CYP",
    numericCode: "196",
    subdivisionCodeLink: "ISO 3166-2:CY",
  },
  {
    name: "Czechia [i]",
    officialStateName: "The Czech Republic",
    sovereignty: "UN member state",
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    numericCode: "203",
    subdivisionCodeLink: "ISO 3166-2:CZ",
  },
  {
    name: "Denmark",
    officialStateName: "The Kingdom of Denmark",
    sovereignty: "UN member state",
    alpha2Code: "DK",
    alpha3Code: "DNK",
    numericCode: "208",
    subdivisionCodeLink: "ISO 3166-2:DK",
  },
  {
    name: "Djibouti",
    officialStateName: "The Republic of Djibouti",
    sovereignty: "UN member state",
    alpha2Code: "DJ",
    alpha3Code: "DJI",
    numericCode: "262",
    subdivisionCodeLink: "ISO 3166-2:DJ",
  },
  {
    name: "Dominica",
    officialStateName: "The Commonwealth of Dominica",
    sovereignty: "UN member state",
    alpha2Code: "DM",
    alpha3Code: "DMA",
    numericCode: "212",
    subdivisionCodeLink: "ISO 3166-2:DM",
  },
  {
    name: "Dominican Republic (the)",
    officialStateName: "The Dominican Republic",
    sovereignty: "UN member state",
    alpha2Code: "DO",
    alpha3Code: "DOM",
    numericCode: "214",
    subdivisionCodeLink: "ISO 3166-2:DO",
  },
  {
    name: "Ecuador",
    officialStateName: "The Republic of Ecuador",
    sovereignty: "UN member state",
    alpha2Code: "EC",
    alpha3Code: "ECU",
    numericCode: "218",
    subdivisionCodeLink: "ISO 3166-2:EC",
  },
  {
    name: "Egypt",
    officialStateName: "The Arab Republic of Egypt",
    sovereignty: "UN member state",
    alpha2Code: "EG",
    alpha3Code: "EGY",
    numericCode: "818",
    subdivisionCodeLink: "ISO 3166-2:EG",
  },
  {
    name: "El Salvador",
    officialStateName: "The Republic of El Salvador",
    sovereignty: "UN member state",
    alpha2Code: "SV",
    alpha3Code: "SLV",
    numericCode: "222",
    subdivisionCodeLink: "ISO 3166-2:SV",
  },
  {
    name: "Equatorial Guinea",
    officialStateName: "The Republic of Equatorial Guinea",
    sovereignty: "UN member state",
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    numericCode: "226",
    subdivisionCodeLink: "ISO 3166-2:GQ",
  },
  {
    name: "Eritrea",
    officialStateName: "The State of Eritrea",
    sovereignty: "UN member state",
    alpha2Code: "ER",
    alpha3Code: "ERI",
    numericCode: "232",
    subdivisionCodeLink: "ISO 3166-2:ER",
  },
  {
    name: "Estonia",
    officialStateName: "The Republic of Estonia",
    sovereignty: "UN member state",
    alpha2Code: "EE",
    alpha3Code: "EST",
    numericCode: "233",
    subdivisionCodeLink: "ISO 3166-2:EE",
  },
  {
    name: "Eswatini [j]",
    officialStateName: "The Kingdom of Eswatini",
    sovereignty: "UN member state",
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    numericCode: "748",
    subdivisionCodeLink: "ISO 3166-2:SZ",
  },
  {
    name: "Ethiopia",
    officialStateName: "The Federal Democratic Republic of Ethiopia",
    sovereignty: "UN member state",
    alpha2Code: "ET",
    alpha3Code: "ETH",
    numericCode: "231",
    subdivisionCodeLink: "ISO 3166-2:ET",
  },
  {
    name: "Falkland Islands (the) [Malvinas] [k]",
    officialStateName: "The Falkland Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "FK",
    alpha3Code: "FLK",
    numericCode: "238",
    subdivisionCodeLink: "ISO 3166-2:FK",
  },
  {
    name: "Faroe Islands (the)",
    officialStateName: "The Faroe Islands",
    sovereignty: "Denmark",
    alpha2Code: "FO",
    alpha3Code: "FRO",
    numericCode: "234",
    subdivisionCodeLink: "ISO 3166-2:FO",
  },
  {
    name: "Fiji",
    officialStateName: "The Republic of Fiji",
    sovereignty: "UN member state",
    alpha2Code: "FJ",
    alpha3Code: "FJI",
    numericCode: "242",
    subdivisionCodeLink: "ISO 3166-2:FJ",
  },
  {
    name: "Finland",
    officialStateName: "The Republic of Finland",
    sovereignty: "UN member state",
    alpha2Code: "FI",
    alpha3Code: "FIN",
    numericCode: "246",
    subdivisionCodeLink: "ISO 3166-2:FI",
  },
  {
    name: "France [l]",
    officialStateName: "The French Republic",
    sovereignty: "UN member state",
    alpha2Code: "FR",
    alpha3Code: "FRA",
    numericCode: "250",
    subdivisionCodeLink: "ISO 3166-2:FR",
  },
  {
    name: "French Guiana",
    officialStateName: "Guyane",
    sovereignty: "France",
    alpha2Code: "GF",
    alpha3Code: "GUF",
    numericCode: "254",
    subdivisionCodeLink: "ISO 3166-2:GF",
  },
  {
    name: "French Polynesia",
    officialStateName: "French Polynesia",
    sovereignty: "France",
    alpha2Code: "PF",
    alpha3Code: "PYF",
    numericCode: "258",
    subdivisionCodeLink: "ISO 3166-2:PF",
  },
  {
    name: "French Southern Territories (the) [m]",
    officialStateName: "The French Southern and Antarctic Lands",
    sovereignty: "France",
    alpha2Code: "TF",
    alpha3Code: "ATF",
    numericCode: "260",
    subdivisionCodeLink: "ISO 3166-2:TF",
  },
  {
    name: "Gabon",
    officialStateName: "The Gabonese Republic",
    sovereignty: "UN member state",
    alpha2Code: "GA",
    alpha3Code: "GAB",
    numericCode: "266",
    subdivisionCodeLink: "ISO 3166-2:GA",
  },
  {
    name: "Gambia (the)",
    officialStateName: "The Republic of The Gambia",
    sovereignty: "UN member state",
    alpha2Code: "GM",
    alpha3Code: "GMB",
    numericCode: "270",
    subdivisionCodeLink: "ISO 3166-2:GM",
  },
  {
    name: "Georgia",
    officialStateName: "Georgia",
    sovereignty: "UN member state",
    alpha2Code: "GE",
    alpha3Code: "GEO",
    numericCode: "268",
    subdivisionCodeLink: "ISO 3166-2:GE",
  },
  {
    name: "Germany",
    officialStateName: "The Federal Republic of Germany",
    sovereignty: "UN member state",
    alpha2Code: "DE",
    alpha3Code: "DEU",
    numericCode: "276",
    subdivisionCodeLink: "ISO 3166-2:DE",
  },
  {
    name: "Ghana",
    officialStateName: "The Republic of Ghana",
    sovereignty: "UN member state",
    alpha2Code: "GH",
    alpha3Code: "GHA",
    numericCode: "288",
    subdivisionCodeLink: "ISO 3166-2:GH",
  },
  {
    name: "Gibraltar",
    officialStateName: "Gibraltar",
    sovereignty: "United Kingdom",
    alpha2Code: "GI",
    alpha3Code: "GIB",
    numericCode: "292",
    subdivisionCodeLink: "ISO 3166-2:GI",
  },
  {
    name: "Greece",
    officialStateName: "The Hellenic Republic",
    sovereignty: "UN member state",
    alpha2Code: "GR",
    alpha3Code: "GRC",
    numericCode: "300",
    subdivisionCodeLink: "ISO 3166-2:GR",
  },
  {
    name: "Greenland",
    officialStateName: "Kalaallit Nunaat",
    sovereignty: "Denmark",
    alpha2Code: "GL",
    alpha3Code: "GRL",
    numericCode: "304",
    subdivisionCodeLink: "ISO 3166-2:GL",
  },
  {
    name: "Grenada",
    officialStateName: "Grenada",
    sovereignty: "UN member state",
    alpha2Code: "GD",
    alpha3Code: "GRD",
    numericCode: "308",
    subdivisionCodeLink: "ISO 3166-2:GD",
  },
  {
    name: "Guadeloupe",
    officialStateName: "Guadeloupe",
    sovereignty: "France",
    alpha2Code: "GP",
    alpha3Code: "GLP",
    numericCode: "312",
    subdivisionCodeLink: "ISO 3166-2:GP",
  },
  {
    name: "Guam",
    officialStateName: "The Territory of Guam",
    sovereignty: "United States",
    alpha2Code: "GU",
    alpha3Code: "GUM",
    numericCode: "316",
    subdivisionCodeLink: "ISO 3166-2:GU",
  },
  {
    name: "Guatemala",
    officialStateName: "The Republic of Guatemala",
    sovereignty: "UN member state",
    alpha2Code: "GT",
    alpha3Code: "GTM",
    numericCode: "320",
    subdivisionCodeLink: "ISO 3166-2:GT",
  },
  {
    name: "Guernsey",
    officialStateName: "The Bailiwick of Guernsey",
    sovereignty: "British Crown",
    alpha2Code: "GG",
    alpha3Code: "GGY",
    numericCode: "831",
    subdivisionCodeLink: "ISO 3166-2:GG",
  },
  {
    name: "Guinea",
    officialStateName: "The Republic of Guinea",
    sovereignty: "UN member state",
    alpha2Code: "GN",
    alpha3Code: "GIN",
    numericCode: "324",
    subdivisionCodeLink: "ISO 3166-2:GN",
  },
  {
    name: "Guinea-Bissau",
    officialStateName: "The Republic of Guinea-Bissau",
    sovereignty: "UN member state",
    alpha2Code: "GW",
    alpha3Code: "GNB",
    numericCode: "624",
    subdivisionCodeLink: "ISO 3166-2:GW",
  },
  {
    name: "Guyana",
    officialStateName: "The Co-operative Republic of Guyana",
    sovereignty: "UN member state",
    alpha2Code: "GY",
    alpha3Code: "GUY",
    numericCode: "328",
    subdivisionCodeLink: "ISO 3166-2:GY",
  },
  {
    name: "Haiti",
    officialStateName: "The Republic of Haiti",
    sovereignty: "UN member state",
    alpha2Code: "HT",
    alpha3Code: "HTI",
    numericCode: "332",
    subdivisionCodeLink: "ISO 3166-2:HT",
  },
  {
    name: "Heard Island and McDonald Islands",
    officialStateName: "The Territory of Heard Island and McDonald Islands",
    sovereignty: "Australia",
    alpha2Code: "HM",
    alpha3Code: "HMD",
    numericCode: "334",
    subdivisionCodeLink: "ISO 3166-2:HM",
  },
  {
    name: "Holy See (the) [n]",
    officialStateName: "The Holy See",
    sovereignty: "UN observer",
    alpha2Code: "VA",
    alpha3Code: "VAT",
    numericCode: "336",
    subdivisionCodeLink: "ISO 3166-2:VA",
  },
  {
    name: "Honduras",
    officialStateName: "The Republic of Honduras",
    sovereignty: "UN member state",
    alpha2Code: "HN",
    alpha3Code: "HND",
    numericCode: "340",
    subdivisionCodeLink: "ISO 3166-2:HN",
  },
  {
    name: "Hong Kong",
    officialStateName: "The Hong Kong Special Administrative Region of China[10]",
    sovereignty: "China",
    alpha2Code: "HK",
    alpha3Code: "HKG",
    numericCode: "344",
    subdivisionCodeLink: "ISO 3166-2:HK",
  },
  {
    name: "Hungary",
    officialStateName: "Hungary",
    sovereignty: "UN member state",
    alpha2Code: "HU",
    alpha3Code: "HUN",
    numericCode: "348",
    subdivisionCodeLink: "ISO 3166-2:HU",
  },
  {
    name: "Iceland",
    officialStateName: "Iceland",
    sovereignty: "UN member state",
    alpha2Code: "IS",
    alpha3Code: "ISL",
    numericCode: "352",
    subdivisionCodeLink: "ISO 3166-2:IS",
  },
  {
    name: "India",
    officialStateName: "The Republic of India",
    sovereignty: "UN member state",
    alpha2Code: "IN",
    alpha3Code: "IND",
    numericCode: "356",
    subdivisionCodeLink: "ISO 3166-2:IN",
  },
  {
    name: "Indonesia",
    officialStateName: "The Republic of Indonesia",
    sovereignty: "UN member state",
    alpha2Code: "ID",
    alpha3Code: "IDN",
    numericCode: "360",
    subdivisionCodeLink: "ISO 3166-2:ID",
  },
  {
    name: "Iran (Islamic Republic of)",
    officialStateName: "The Islamic Republic of Iran",
    sovereignty: "UN member state",
    alpha2Code: "IR",
    alpha3Code: "IRN",
    numericCode: "364",
    subdivisionCodeLink: "ISO 3166-2:IR",
  },
  {
    name: "Iraq",
    officialStateName: "The Republic of Iraq",
    sovereignty: "UN member state",
    alpha2Code: "IQ",
    alpha3Code: "IRQ",
    numericCode: "368",
    subdivisionCodeLink: "ISO 3166-2:IQ",
  },
  {
    name: "Ireland",
    officialStateName: "Ireland",
    sovereignty: "UN member state",
    alpha2Code: "IE",
    alpha3Code: "IRL",
    numericCode: "372",
    subdivisionCodeLink: "ISO 3166-2:IE",
  },
  {
    name: "Isle of Man",
    officialStateName: "The Isle of Man",
    sovereignty: "British Crown",
    alpha2Code: "IM",
    alpha3Code: "IMN",
    numericCode: "833",
    subdivisionCodeLink: "ISO 3166-2:IM",
  },
  {
    name: "Israel",
    officialStateName: "The State of Israel",
    sovereignty: "UN member state",
    alpha2Code: "IL",
    alpha3Code: "ISR",
    numericCode: "376",
    subdivisionCodeLink: "ISO 3166-2:IL",
  },
  {
    name: "Italy",
    officialStateName: "The Italian Republic",
    sovereignty: "UN member state",
    alpha2Code: "IT",
    alpha3Code: "ITA",
    numericCode: "380",
    subdivisionCodeLink: "ISO 3166-2:IT",
  },
  {
    name: "Jamaica",
    officialStateName: "Jamaica",
    sovereignty: "UN member state",
    alpha2Code: "JM",
    alpha3Code: "JAM",
    numericCode: "388",
    subdivisionCodeLink: "ISO 3166-2:JM",
  },
  {
    name: "Japan",
    officialStateName: "Japan",
    sovereignty: "UN member state",
    alpha2Code: "JP",
    alpha3Code: "JPN",
    numericCode: "392",
    subdivisionCodeLink: "ISO 3166-2:JP",
  },
  {
    name: "Jersey",
    officialStateName: "The Bailiwick of Jersey",
    sovereignty: "British Crown",
    alpha2Code: "JE",
    alpha3Code: "JEY",
    numericCode: "832",
    subdivisionCodeLink: "ISO 3166-2:JE",
  },
  {
    name: "Jordan",
    officialStateName: "The Hashemite Kingdom of Jordan",
    sovereignty: "UN member state",
    alpha2Code: "JO",
    alpha3Code: "JOR",
    numericCode: "400",
    subdivisionCodeLink: "ISO 3166-2:JO",
  },
  {
    name: "Kazakhstan",
    officialStateName: "The Republic of Kazakhstan",
    sovereignty: "UN member state",
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    numericCode: "398",
    subdivisionCodeLink: "ISO 3166-2:KZ",
  },
  {
    name: "Kenya",
    officialStateName: "The Republic of Kenya",
    sovereignty: "UN member state",
    alpha2Code: "KE",
    alpha3Code: "KEN",
    numericCode: "404",
    subdivisionCodeLink: "ISO 3166-2:KE",
  },
  {
    name: "Kiribati",
    officialStateName: "The Republic of Kiribati",
    sovereignty: "UN member state",
    alpha2Code: "KI",
    alpha3Code: "KIR",
    numericCode: "296",
    subdivisionCodeLink: "ISO 3166-2:KI",
  },
  {
    name: "Korea (the Democratic People's Republic of) [o]",
    officialStateName: "The Democratic People's Republic of Korea",
    sovereignty: "UN member state",
    alpha2Code: "KP",
    alpha3Code: "PRK",
    numericCode: "408",
    subdivisionCodeLink: "ISO 3166-2:KP",
  },
  {
    name: "Korea (the Republic of) [p]",
    officialStateName: "The Republic of Korea",
    sovereignty: "UN member state",
    alpha2Code: "KR",
    alpha3Code: "KOR",
    numericCode: "410",
    subdivisionCodeLink: "ISO 3166-2:KR",
  },
  {
    name: "Kuwait",
    officialStateName: "The State of Kuwait",
    sovereignty: "UN member state",
    alpha2Code: "KW",
    alpha3Code: "KWT",
    numericCode: "414",
    subdivisionCodeLink: "ISO 3166-2:KW",
  },
  {
    name: "Kyrgyzstan",
    officialStateName: "The Kyrgyz Republic",
    sovereignty: "UN member state",
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    numericCode: "417",
    subdivisionCodeLink: "ISO 3166-2:KG",
  },
  {
    name: "Lao People's Democratic Republic (the) [q]",
    officialStateName: "The Lao People's Democratic Republic",
    sovereignty: "UN member state",
    alpha2Code: "LA",
    alpha3Code: "LAO",
    numericCode: "418",
    subdivisionCodeLink: "ISO 3166-2:LA",
  },
  {
    name: "Latvia",
    officialStateName: "The Republic of Latvia",
    sovereignty: "UN member state",
    alpha2Code: "LV",
    alpha3Code: "LVA",
    numericCode: "428",
    subdivisionCodeLink: "ISO 3166-2:LV",
  },
  {
    name: "Lebanon",
    officialStateName: "The Lebanese Republic",
    sovereignty: "UN member state",
    alpha2Code: "LB",
    alpha3Code: "LBN",
    numericCode: "422",
    subdivisionCodeLink: "ISO 3166-2:LB",
  },
  {
    name: "Lesotho",
    officialStateName: "The Kingdom of Lesotho",
    sovereignty: "UN member state",
    alpha2Code: "LS",
    alpha3Code: "LSO",
    numericCode: "426",
    subdivisionCodeLink: "ISO 3166-2:LS",
  },
  {
    name: "Liberia",
    officialStateName: "The Republic of Liberia",
    sovereignty: "UN member state",
    alpha2Code: "LR",
    alpha3Code: "LBR",
    numericCode: "430",
    subdivisionCodeLink: "ISO 3166-2:LR",
  },
  {
    name: "Libya",
    officialStateName: "The State of Libya",
    sovereignty: "UN member state",
    alpha2Code: "LY",
    alpha3Code: "LBY",
    numericCode: "434",
    subdivisionCodeLink: "ISO 3166-2:LY",
  },
  {
    name: "Liechtenstein",
    officialStateName: "The Principality of Liechtenstein",
    sovereignty: "UN member state",
    alpha2Code: "LI",
    alpha3Code: "LIE",
    numericCode: "438",
    subdivisionCodeLink: "ISO 3166-2:LI",
  },
  {
    name: "Lithuania",
    officialStateName: "The Republic of Lithuania",
    sovereignty: "UN member state",
    alpha2Code: "LT",
    alpha3Code: "LTU",
    numericCode: "440",
    subdivisionCodeLink: "ISO 3166-2:LT",
  },
  {
    name: "Luxembourg",
    officialStateName: "The Grand Duchy of Luxembourg",
    sovereignty: "UN member state",
    alpha2Code: "LU",
    alpha3Code: "LUX",
    numericCode: "442",
    subdivisionCodeLink: "ISO 3166-2:LU",
  },
  {
    name: "Macao [r]",
    officialStateName: "Macao Special Administrative Region of China[11]",
    sovereignty: "China",
    alpha2Code: "MO",
    alpha3Code: "MAC",
    numericCode: "446",
    subdivisionCodeLink: "ISO 3166-2:MO",
  },
  {
    name: "North Macedonia [s]",
    officialStateName: "Republic of North Macedonia[12]",
    sovereignty: "UN member state",
    alpha2Code: "MK",
    alpha3Code: "MKD",
    numericCode: "807",
    subdivisionCodeLink: "ISO 3166-2:MK",
  },
  {
    name: "Madagascar",
    officialStateName: "The Republic of Madagascar",
    sovereignty: "UN member state",
    alpha2Code: "MG",
    alpha3Code: "MDG",
    numericCode: "450",
    subdivisionCodeLink: "ISO 3166-2:MG",
  },
  {
    name: "Malawi",
    officialStateName: "The Republic of Malawi",
    sovereignty: "UN member state",
    alpha2Code: "MW",
    alpha3Code: "MWI",
    numericCode: "454",
    subdivisionCodeLink: "ISO 3166-2:MW",
  },
  {
    name: "Malaysia",
    officialStateName: "Malaysia",
    sovereignty: "UN member state",
    alpha2Code: "MY",
    alpha3Code: "MYS",
    numericCode: "458",
    subdivisionCodeLink: "ISO 3166-2:MY",
  },
  {
    name: "Maldives",
    officialStateName: "The Republic of Maldives",
    sovereignty: "UN member state",
    alpha2Code: "MV",
    alpha3Code: "MDV",
    numericCode: "462",
    subdivisionCodeLink: "ISO 3166-2:MV",
  },
  {
    name: "Mali",
    officialStateName: "The Republic of Mali",
    sovereignty: "UN member state",
    alpha2Code: "ML",
    alpha3Code: "MLI",
    numericCode: "466",
    subdivisionCodeLink: "ISO 3166-2:ML",
  },
  {
    name: "Malta",
    officialStateName: "The Republic of Malta",
    sovereignty: "UN member state",
    alpha2Code: "MT",
    alpha3Code: "MLT",
    numericCode: "470",
    subdivisionCodeLink: "ISO 3166-2:MT",
  },
  {
    name: "Marshall Islands (the)",
    officialStateName: "The Republic of the Marshall Islands",
    sovereignty: "UN member state",
    alpha2Code: "MH",
    alpha3Code: "MHL",
    numericCode: "584",
    subdivisionCodeLink: "ISO 3166-2:MH",
  },
  {
    name: "Martinique",
    officialStateName: "Martinique",
    sovereignty: "France",
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    numericCode: "474",
    subdivisionCodeLink: "ISO 3166-2:MQ",
  },
  {
    name: "Mauritania",
    officialStateName: "The Islamic Republic of Mauritania",
    sovereignty: "UN member state",
    alpha2Code: "MR",
    alpha3Code: "MRT",
    numericCode: "478",
    subdivisionCodeLink: "ISO 3166-2:MR",
  },
  {
    name: "Mauritius",
    officialStateName: "The Republic of Mauritius",
    sovereignty: "UN member state",
    alpha2Code: "MU",
    alpha3Code: "MUS",
    numericCode: "480",
    subdivisionCodeLink: "ISO 3166-2:MU",
  },
  {
    name: "Mayotte",
    officialStateName: "The Department of Mayotte",
    sovereignty: "France",
    alpha2Code: "YT",
    alpha3Code: "MYT",
    numericCode: "175",
    subdivisionCodeLink: "ISO 3166-2:YT",
  },
  {
    name: "Mexico",
    officialStateName: "The United Mexican States",
    sovereignty: "UN member state",
    alpha2Code: "MX",
    alpha3Code: "MEX",
    numericCode: "484",
    subdivisionCodeLink: "ISO 3166-2:MX",
  },
  {
    name: "Micronesia (Federated States of)",
    officialStateName: "The Federated States of Micronesia",
    sovereignty: "UN member state",
    alpha2Code: "FM",
    alpha3Code: "FSM",
    numericCode: "583",
    subdivisionCodeLink: "ISO 3166-2:FM",
  },
  {
    name: "Moldova (the Republic of)",
    officialStateName: "The Republic of Moldova",
    sovereignty: "UN member state",
    alpha2Code: "MD",
    alpha3Code: "MDA",
    numericCode: "498",
    subdivisionCodeLink: "ISO 3166-2:MD",
  },
  {
    name: "Monaco",
    officialStateName: "The Principality of Monaco",
    sovereignty: "UN member state",
    alpha2Code: "MC",
    alpha3Code: "MCO",
    numericCode: "492",
    subdivisionCodeLink: "ISO 3166-2:MC",
  },
  {
    name: "Mongolia",
    officialStateName: "The State of Mongolia",
    sovereignty: "UN member state",
    alpha2Code: "MN",
    alpha3Code: "MNG",
    numericCode: "496",
    subdivisionCodeLink: "ISO 3166-2:MN",
  },
  {
    name: "Montenegro",
    officialStateName: "Montenegro",
    sovereignty: "UN member state",
    alpha2Code: "ME",
    alpha3Code: "MNE",
    numericCode: "499",
    subdivisionCodeLink: "ISO 3166-2:ME",
  },
  {
    name: "Montserrat",
    officialStateName: "Montserrat",
    sovereignty: "United Kingdom",
    alpha2Code: "MS",
    alpha3Code: "MSR",
    numericCode: "500",
    subdivisionCodeLink: "ISO 3166-2:MS",
  },
  {
    name: "Morocco",
    officialStateName: "The Kingdom of Morocco",
    sovereignty: "UN member state",
    alpha2Code: "MA",
    alpha3Code: "MAR",
    numericCode: "504",
    subdivisionCodeLink: "ISO 3166-2:MA",
  },
  {
    name: "Mozambique",
    officialStateName: "The Republic of Mozambique",
    sovereignty: "UN member state",
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    numericCode: "508",
    subdivisionCodeLink: "ISO 3166-2:MZ",
  },
  {
    name: "Myanmar [t]",
    officialStateName: "The Republic of the Union of Myanmar",
    sovereignty: "UN member state",
    alpha2Code: "MM",
    alpha3Code: "MMR",
    numericCode: "104",
    subdivisionCodeLink: "ISO 3166-2:MM",
  },
  {
    name: "Namibia",
    officialStateName: "The Republic of Namibia",
    sovereignty: "UN member state",
    alpha2Code: "NA",
    alpha3Code: "NAM",
    numericCode: "516",
    subdivisionCodeLink: "ISO 3166-2:NA",
  },
  {
    name: "Nauru",
    officialStateName: "The Republic of Nauru",
    sovereignty: "UN member state",
    alpha2Code: "NR",
    alpha3Code: "NRU",
    numericCode: "520",
    subdivisionCodeLink: "ISO 3166-2:NR",
  },
  {
    name: "Nepal",
    officialStateName: "The Federal Democratic Republic of Nepal",
    sovereignty: "UN member state",
    alpha2Code: "NP",
    alpha3Code: "NPL",
    numericCode: "524",
    subdivisionCodeLink: "ISO 3166-2:NP",
  },
  {
    name: "Netherlands (the)",
    officialStateName: "The Kingdom of the Netherlands",
    sovereignty: "UN member state",
    alpha2Code: "NL",
    alpha3Code: "NLD",
    numericCode: "528",
    subdivisionCodeLink: "ISO 3166-2:NL",
  },
  {
    name: "New Caledonia",
    officialStateName: "New Caledonia",
    sovereignty: "France",
    alpha2Code: "NC",
    alpha3Code: "NCL",
    numericCode: "540",
    subdivisionCodeLink: "ISO 3166-2:NC",
  },
  {
    name: "New Zealand",
    officialStateName: "New Zealand",
    sovereignty: "UN member state",
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    numericCode: "554",
    subdivisionCodeLink: "ISO 3166-2:NZ",
  },
  {
    name: "Nicaragua",
    officialStateName: "The Republic of Nicaragua",
    sovereignty: "UN member state",
    alpha2Code: "NI",
    alpha3Code: "NIC",
    numericCode: "558",
    subdivisionCodeLink: "ISO 3166-2:NI",
  },
  {
    name: "Niger (the)",
    officialStateName: "The Republic of the Niger",
    sovereignty: "UN member state",
    alpha2Code: "NE",
    alpha3Code: "NER",
    numericCode: "562",
    subdivisionCodeLink: "ISO 3166-2:NE",
  },
  {
    name: "Nigeria",
    officialStateName: "The Federal Republic of Nigeria",
    sovereignty: "UN member state",
    alpha2Code: "NG",
    alpha3Code: "NGA",
    numericCode: "566",
    subdivisionCodeLink: "ISO 3166-2:NG",
  },
  {
    name: "Niue",
    officialStateName: "Niue",
    sovereignty: "New Zealand",
    alpha2Code: "NU",
    alpha3Code: "NIU",
    numericCode: "570",
    subdivisionCodeLink: "ISO 3166-2:NU",
  },
  {
    name: "Norfolk Island",
    officialStateName: "The Territory of Norfolk Island",
    sovereignty: "Australia",
    alpha2Code: "NF",
    alpha3Code: "NFK",
    numericCode: "574",
    subdivisionCodeLink: "ISO 3166-2:NF",
  },
  {
    name: "Northern Mariana Islands (the)",
    officialStateName: "The Commonwealth of the Northern Mariana Islands",
    sovereignty: "United States",
    alpha2Code: "MP",
    alpha3Code: "MNP",
    numericCode: "580",
    subdivisionCodeLink: "ISO 3166-2:MP",
  },
  {
    name: "Norway",
    officialStateName: "The Kingdom of Norway",
    sovereignty: "UN member state",
    alpha2Code: "NO",
    alpha3Code: "NOR",
    numericCode: "578",
    subdivisionCodeLink: "ISO 3166-2:NO",
  },
  {
    name: "Oman",
    officialStateName: "The Sultanate of Oman",
    sovereignty: "UN member state",
    alpha2Code: "OM",
    alpha3Code: "OMN",
    numericCode: "512",
    subdivisionCodeLink: "ISO 3166-2:OM",
  },
  {
    name: "Pakistan",
    officialStateName: "The Islamic Republic of Pakistan",
    sovereignty: "UN member state",
    alpha2Code: "PK",
    alpha3Code: "PAK",
    numericCode: "586",
    subdivisionCodeLink: "ISO 3166-2:PK",
  },
  {
    name: "Palau",
    officialStateName: "The Republic of Palau",
    sovereignty: "UN member state",
    alpha2Code: "PW",
    alpha3Code: "PLW",
    numericCode: "585",
    subdivisionCodeLink: "ISO 3166-2:PW",
  },
  {
    name: "Palestine, State of",
    officialStateName: "The State of Palestine",
    sovereignty: "UN observer",
    alpha2Code: "PS",
    alpha3Code: "PSE",
    numericCode: "275",
    subdivisionCodeLink: "ISO 3166-2:PS",
  },
  {
    name: "Panama",
    officialStateName: "The Republic of Panamá",
    sovereignty: "UN member state",
    alpha2Code: "PA",
    alpha3Code: "PAN",
    numericCode: "591",
    subdivisionCodeLink: "ISO 3166-2:PA",
  },
  {
    name: "Papua New Guinea",
    officialStateName: "The Independent State of Papua New Guinea",
    sovereignty: "UN member state",
    alpha2Code: "PG",
    alpha3Code: "PNG",
    numericCode: "598",
    subdivisionCodeLink: "ISO 3166-2:PG",
  },
  {
    name: "Paraguay",
    officialStateName: "The Republic of Paraguay",
    sovereignty: "UN member state",
    alpha2Code: "PY",
    alpha3Code: "PRY",
    numericCode: "600",
    subdivisionCodeLink: "ISO 3166-2:PY",
  },
  {
    name: "Peru",
    officialStateName: "The Republic of Perú",
    sovereignty: "UN member state",
    alpha2Code: "PE",
    alpha3Code: "PER",
    numericCode: "604",
    subdivisionCodeLink: "ISO 3166-2:PE",
  },
  {
    name: "Philippines (the)",
    officialStateName: "The Republic of the Philippines",
    sovereignty: "UN member state",
    alpha2Code: "PH",
    alpha3Code: "PHL",
    numericCode: "608",
    subdivisionCodeLink: "ISO 3166-2:PH",
  },
  {
    name: "Pitcairn [u]",
    officialStateName: "The Pitcairn, Henderson, Ducie and Oeno Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "PN",
    alpha3Code: "PCN",
    numericCode: "612",
    subdivisionCodeLink: "ISO 3166-2:PN",
  },
  {
    name: "Poland",
    officialStateName: "The Republic of Poland",
    sovereignty: "UN member state",
    alpha2Code: "PL",
    alpha3Code: "POL",
    numericCode: "616",
    subdivisionCodeLink: "ISO 3166-2:PL",
  },
  {
    name: "Portugal",
    officialStateName: "The Portuguese Republic",
    sovereignty: "UN member state",
    alpha2Code: "PT",
    alpha3Code: "PRT",
    numericCode: "620",
    subdivisionCodeLink: "ISO 3166-2:PT",
  },
  {
    name: "Puerto Rico",
    officialStateName: "The Commonwealth of Puerto Rico",
    sovereignty: "United States",
    alpha2Code: "PR",
    alpha3Code: "PRI",
    numericCode: "630",
    subdivisionCodeLink: "ISO 3166-2:PR",
  },
  {
    name: "Qatar",
    officialStateName: "The State of Qatar",
    sovereignty: "UN member state",
    alpha2Code: "QA",
    alpha3Code: "QAT",
    numericCode: "634",
    subdivisionCodeLink: "ISO 3166-2:QA",
  },
  {
    name: "Réunion",
    officialStateName: "Réunion",
    sovereignty: "France",
    alpha2Code: "RE",
    alpha3Code: "REU",
    numericCode: "638",
    subdivisionCodeLink: "ISO 3166-2:RE",
  },
  {
    name: "Romania",
    officialStateName: "Romania",
    sovereignty: "UN member state",
    alpha2Code: "RO",
    alpha3Code: "ROU",
    numericCode: "642",
    subdivisionCodeLink: "ISO 3166-2:RO",
  },
  {
    name: "Russian Federation (the) [v]",
    officialStateName: "The Russian Federation",
    sovereignty: "UN member state",
    alpha2Code: "RU",
    alpha3Code: "RUS",
    numericCode: "643",
    subdivisionCodeLink: "ISO 3166-2:RU",
  },
  {
    name: "Rwanda",
    officialStateName: "The Republic of Rwanda",
    sovereignty: "UN member state",
    alpha2Code: "RW",
    alpha3Code: "RWA",
    numericCode: "646",
    subdivisionCodeLink: "ISO 3166-2:RW",
  },
  {
    name: "Saint Barthélemy",
    officialStateName: "The Collectivity of Saint-Barthélemy",
    sovereignty: "France",
    alpha2Code: "BL",
    alpha3Code: "BLM",
    numericCode: "652",
    subdivisionCodeLink: "ISO 3166-2:BL",
  },
  {
    name: "Saint Helena\n Ascension Island\n Tristan da Cunha",
    officialStateName: "Saint Helena, Ascension and Tristan da Cunha",
    sovereignty: "United Kingdom",
    alpha2Code: "SH",
    alpha3Code: "SHN",
    numericCode: "654",
    subdivisionCodeLink: "ISO 3166-2:SH",
  },
  {
    name: "Saint Kitts and Nevis",
    officialStateName: "Saint Kitts and Nevis",
    sovereignty: "UN member state",
    alpha2Code: "KN",
    alpha3Code: "KNA",
    numericCode: "659",
    subdivisionCodeLink: "ISO 3166-2:KN",
  },
  {
    name: "Saint Lucia",
    officialStateName: "Saint Lucia",
    sovereignty: "UN member state",
    alpha2Code: "LC",
    alpha3Code: "LCA",
    numericCode: "662",
    subdivisionCodeLink: "ISO 3166-2:LC",
  },
  {
    name: "Saint Martin (French part)",
    officialStateName: "The Collectivity of Saint-Martin",
    sovereignty: "France",
    alpha2Code: "MF",
    alpha3Code: "MAF",
    numericCode: "663",
    subdivisionCodeLink: "ISO 3166-2:MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    officialStateName: "The Overseas Collectivity of Saint-Pierre and Miquelon",
    sovereignty: "France",
    alpha2Code: "PM",
    alpha3Code: "SPM",
    numericCode: "666",
    subdivisionCodeLink: "ISO 3166-2:PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    officialStateName: "Saint Vincent and the Grenadines",
    sovereignty: "UN member state",
    alpha2Code: "VC",
    alpha3Code: "VCT",
    numericCode: "670",
    subdivisionCodeLink: "ISO 3166-2:VC",
  },
  {
    name: "Samoa",
    officialStateName: "The Independent State of Samoa",
    sovereignty: "UN member state",
    alpha2Code: "WS",
    alpha3Code: "WSM",
    numericCode: "882",
    subdivisionCodeLink: "ISO 3166-2:WS",
  },
  {
    name: "San Marino",
    officialStateName: "The Republic of San Marino",
    sovereignty: "UN member state",
    alpha2Code: "SM",
    alpha3Code: "SMR",
    numericCode: "674",
    subdivisionCodeLink: "ISO 3166-2:SM",
  },
  {
    name: "Sao Tome and Principe",
    officialStateName: "The Democratic Republic of São Tomé and Príncipe",
    sovereignty: "UN member state",
    alpha2Code: "ST",
    alpha3Code: "STP",
    numericCode: "678",
    subdivisionCodeLink: "ISO 3166-2:ST",
  },
  {
    name: "Saudi Arabia",
    officialStateName: "The Kingdom of Saudi Arabia",
    sovereignty: "UN member state",
    alpha2Code: "SA",
    alpha3Code: "SAU",
    numericCode: "682",
    subdivisionCodeLink: "ISO 3166-2:SA",
  },
  {
    name: "Senegal",
    officialStateName: "The Republic of Senegal",
    sovereignty: "UN member state",
    alpha2Code: "SN",
    alpha3Code: "SEN",
    numericCode: "686",
    subdivisionCodeLink: "ISO 3166-2:SN",
  },
  {
    name: "Serbia",
    officialStateName: "The Republic of Serbia",
    sovereignty: "UN member state",
    alpha2Code: "RS",
    alpha3Code: "SRB",
    numericCode: "688",
    subdivisionCodeLink: "ISO 3166-2:RS",
  },
  {
    name: "Seychelles",
    officialStateName: "The Republic of Seychelles",
    sovereignty: "UN member state",
    alpha2Code: "SC",
    alpha3Code: "SYC",
    numericCode: "690",
    subdivisionCodeLink: "ISO 3166-2:SC",
  },
  {
    name: "Sierra Leone",
    officialStateName: "The Republic of Sierra Leone",
    sovereignty: "UN member state",
    alpha2Code: "SL",
    alpha3Code: "SLE",
    numericCode: "694",
    subdivisionCodeLink: "ISO 3166-2:SL",
  },
  {
    name: "Singapore",
    officialStateName: "The Republic of Singapore",
    sovereignty: "UN member state",
    alpha2Code: "SG",
    alpha3Code: "SGP",
    numericCode: "702",
    subdivisionCodeLink: "ISO 3166-2:SG",
  },
  {
    name: "Sint Maarten (Dutch part)",
    officialStateName: "Sint Maarten",
    sovereignty: "Netherlands",
    alpha2Code: "SX",
    alpha3Code: "SXM",
    numericCode: "534",
    subdivisionCodeLink: "ISO 3166-2:SX",
  },
  {
    name: "Slovakia",
    officialStateName: "The Slovak Republic",
    sovereignty: "UN member state",
    alpha2Code: "SK",
    alpha3Code: "SVK",
    numericCode: "703",
    subdivisionCodeLink: "ISO 3166-2:SK",
  },
  {
    name: "Slovenia",
    officialStateName: "The Republic of Slovenia",
    sovereignty: "UN member state",
    alpha2Code: "SI",
    alpha3Code: "SVN",
    numericCode: "705",
    subdivisionCodeLink: "ISO 3166-2:SI",
  },
  {
    name: "Solomon Islands",
    officialStateName: "The Solomon Islands",
    sovereignty: "UN member state",
    alpha2Code: "SB",
    alpha3Code: "SLB",
    numericCode: "090",
    subdivisionCodeLink: "ISO 3166-2:SB",
  },
  {
    name: "Somalia",
    officialStateName: "The Federal Republic of Somalia",
    sovereignty: "UN member state",
    alpha2Code: "SO",
    alpha3Code: "SOM",
    numericCode: "706",
    subdivisionCodeLink: "ISO 3166-2:SO",
  },
  {
    name: "South Africa",
    officialStateName: "The Republic of South Africa",
    sovereignty: "UN member state",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    numericCode: "710",
    subdivisionCodeLink: "ISO 3166-2:ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    officialStateName: "South Georgia and the South Sandwich Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "GS",
    alpha3Code: "SGS",
    numericCode: "239",
    subdivisionCodeLink: "ISO 3166-2:GS",
  },
  {
    name: "South Sudan",
    officialStateName: "The Republic of South Sudan",
    sovereignty: "UN member state",
    alpha2Code: "SS",
    alpha3Code: "SSD",
    numericCode: "728",
    subdivisionCodeLink: "ISO 3166-2:SS",
  },
  {
    name: "Spain",
    officialStateName: "The Kingdom of Spain",
    sovereignty: "UN member state",
    alpha2Code: "ES",
    alpha3Code: "ESP",
    numericCode: "724",
    subdivisionCodeLink: "ISO 3166-2:ES",
  },
  {
    name: "Sri Lanka",
    officialStateName: "The Democratic Socialist Republic of Sri Lanka",
    sovereignty: "UN member state",
    alpha2Code: "LK",
    alpha3Code: "LKA",
    numericCode: "144",
    subdivisionCodeLink: "ISO 3166-2:LK",
  },
  {
    name: "Sudan (the)",
    officialStateName: "The Republic of the Sudan",
    sovereignty: "UN member state",
    alpha2Code: "SD",
    alpha3Code: "SDN",
    numericCode: "729",
    subdivisionCodeLink: "ISO 3166-2:SD",
  },
  {
    name: "Suriname",
    officialStateName: "The Republic of Suriname",
    sovereignty: "UN member state",
    alpha2Code: "SR",
    alpha3Code: "SUR",
    numericCode: "740",
    subdivisionCodeLink: "ISO 3166-2:SR",
  },
  {
    name: "Svalbard\n Jan Mayen",
    officialStateName: "Svalbard and Jan Mayen",
    sovereignty: "Norway",
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    numericCode: "744",
    subdivisionCodeLink: "ISO 3166-2:SJ",
  },
  {
    name: "Sweden",
    officialStateName: "The Kingdom of Sweden",
    sovereignty: "UN member state",
    alpha2Code: "SE",
    alpha3Code: "SWE",
    numericCode: "752",
    subdivisionCodeLink: "ISO 3166-2:SE",
  },
  {
    name: "Switzerland",
    officialStateName: "The Swiss Confederation",
    sovereignty: "UN member state",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    numericCode: "756",
    subdivisionCodeLink: "ISO 3166-2:CH",
  },
  {
    name: "Syrian Arab Republic (the) [x]",
    officialStateName: "The Syrian Arab Republic",
    sovereignty: "UN member state",
    alpha2Code: "SY",
    alpha3Code: "SYR",
    numericCode: "760",
    subdivisionCodeLink: "ISO 3166-2:SY",
  },
  {
    name: "Taiwan (Province of China) [y]",
    officialStateName: "The Republic of China",
    sovereignty: "disputed [z]",
    alpha2Code: "TW",
    alpha3Code: "TWN",
    numericCode: "158",
    subdivisionCodeLink: "ISO 3166-2:TW",
  },
  {
    name: "Tajikistan",
    officialStateName: "The Republic of Tajikistan",
    sovereignty: "UN member state",
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    numericCode: "762",
    subdivisionCodeLink: "ISO 3166-2:TJ",
  },
  {
    name: "Tanzania, the United Republic of",
    officialStateName: "The United Republic of Tanzania",
    sovereignty: "UN member state",
    alpha2Code: "TZ",
    alpha3Code: "TZA",
    numericCode: "834",
    subdivisionCodeLink: "ISO 3166-2:TZ",
  },
  {
    name: "Thailand",
    officialStateName: "The Kingdom of Thailand",
    sovereignty: "UN member state",
    alpha2Code: "TH",
    alpha3Code: "THA",
    numericCode: "764",
    subdivisionCodeLink: "ISO 3166-2:TH",
  },
  {
    name: "Timor-Leste [aa]",
    officialStateName: "The Democratic Republic of Timor-Leste",
    sovereignty: "UN member state",
    alpha2Code: "TL",
    alpha3Code: "TLS",
    numericCode: "626",
    subdivisionCodeLink: "ISO 3166-2:TL",
  },
  {
    name: "Togo",
    officialStateName: "The Togolese Republic",
    sovereignty: "UN member state",
    alpha2Code: "TG",
    alpha3Code: "TGO",
    numericCode: "768",
    subdivisionCodeLink: "ISO 3166-2:TG",
  },
  {
    name: "Tokelau",
    officialStateName: "Tokelau",
    sovereignty: "New Zealand",
    alpha2Code: "TK",
    alpha3Code: "TKL",
    numericCode: "772",
    subdivisionCodeLink: "ISO 3166-2:TK",
  },
  {
    name: "Tonga",
    officialStateName: "The Kingdom of Tonga",
    sovereignty: "UN member state",
    alpha2Code: "TO",
    alpha3Code: "TON",
    numericCode: "776",
    subdivisionCodeLink: "ISO 3166-2:TO",
  },
  {
    name: "Trinidad and Tobago",
    officialStateName: "The Republic of Trinidad and Tobago",
    sovereignty: "UN member state",
    alpha2Code: "TT",
    alpha3Code: "TTO",
    numericCode: "780",
    subdivisionCodeLink: "ISO 3166-2:TT",
  },
  {
    name: "Tunisia",
    officialStateName: "The Republic of Tunisia",
    sovereignty: "UN member state",
    alpha2Code: "TN",
    alpha3Code: "TUN",
    numericCode: "788",
    subdivisionCodeLink: "ISO 3166-2:TN",
  },
  {
    name: "Turkey",
    officialStateName: "The Republic of Turkey",
    sovereignty: "UN member state",
    alpha2Code: "TR",
    alpha3Code: "TUR",
    numericCode: "792",
    subdivisionCodeLink: "ISO 3166-2:TR",
  },
  {
    name: "Turkmenistan",
    officialStateName: "Turkmenistan",
    sovereignty: "UN member state",
    alpha2Code: "TM",
    alpha3Code: "TKM",
    numericCode: "795",
    subdivisionCodeLink: "ISO 3166-2:TM",
  },
  {
    name: "Turks and Caicos Islands (the)",
    officialStateName: "The Turks and Caicos Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "TC",
    alpha3Code: "TCA",
    numericCode: "796",
    subdivisionCodeLink: "ISO 3166-2:TC",
  },
  {
    name: "Tuvalu",
    officialStateName: "Tuvalu",
    sovereignty: "UN member state",
    alpha2Code: "TV",
    alpha3Code: "TUV",
    numericCode: "798",
    subdivisionCodeLink: "ISO 3166-2:TV",
  },
  {
    name: "Uganda",
    officialStateName: "The Republic of Uganda",
    sovereignty: "UN member state",
    alpha2Code: "UG",
    alpha3Code: "UGA",
    numericCode: "800",
    subdivisionCodeLink: "ISO 3166-2:UG",
  },
  {
    name: "Ukraine",
    officialStateName: "Ukraine",
    sovereignty: "UN member state",
    alpha2Code: "UA",
    alpha3Code: "UKR",
    numericCode: "804",
    subdivisionCodeLink: "ISO 3166-2:UA",
  },
  {
    name: "United Arab Emirates (the)",
    officialStateName: "The United Arab Emirates",
    sovereignty: "UN member state",
    alpha2Code: "AE",
    alpha3Code: "ARE",
    numericCode: "784",
    subdivisionCodeLink: "ISO 3166-2:AE",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    officialStateName: "The United Kingdom of Great Britain and Northern Ireland",
    sovereignty: "UN member state",
    alpha2Code: "GB",
    alpha3Code: "GBR",
    numericCode: "826",
    subdivisionCodeLink: "ISO 3166-2:GB",
  },
  {
    name: "United States Minor Outlying Islands (the) [ac]",
    officialStateName:
      "Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Atoll, Navassa Island, Palmyra Atoll, and Wake Island",
    sovereignty: "United States",
    alpha2Code: "UM",
    alpha3Code: "UMI",
    numericCode: "581",
    subdivisionCodeLink: "ISO 3166-2:UM",
  },
  {
    name: "United States of America (the)",
    officialStateName: "The United States of America",
    sovereignty: "UN member state",
    alpha2Code: "US",
    alpha3Code: "USA",
    numericCode: "840",
    subdivisionCodeLink: "ISO 3166-2:US",
  },
  {
    name: "Uruguay",
    officialStateName: "The Oriental Republic of Uruguay",
    sovereignty: "UN member state",
    alpha2Code: "UY",
    alpha3Code: "URY",
    numericCode: "858",
    subdivisionCodeLink: "ISO 3166-2:UY",
  },
  {
    name: "Uzbekistan",
    officialStateName: "The Republic of Uzbekistan",
    sovereignty: "UN member state",
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    numericCode: "860",
    subdivisionCodeLink: "ISO 3166-2:UZ",
  },
  {
    name: "Vanuatu",
    officialStateName: "The Republic of Vanuatu",
    sovereignty: "UN member state",
    alpha2Code: "VU",
    alpha3Code: "VUT",
    numericCode: "548",
    subdivisionCodeLink: "ISO 3166-2:VU",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    officialStateName: "The Bolivarian Republic of Venezuela",
    sovereignty: "UN member state",
    alpha2Code: "VE",
    alpha3Code: "VEN",
    numericCode: "862",
    subdivisionCodeLink: "ISO 3166-2:VE",
  },
  {
    name: "Viet Nam [ae]",
    officialStateName: "The Socialist Republic of Viet Nam",
    sovereignty: "UN member state",
    alpha2Code: "VN",
    alpha3Code: "VNM",
    numericCode: "704",
    subdivisionCodeLink: "ISO 3166-2:VN",
  },
  {
    name: "Virgin Islands (British) [af]",
    officialStateName: "The Virgin Islands",
    sovereignty: "United Kingdom",
    alpha2Code: "VG",
    alpha3Code: "VGB",
    numericCode: "092",
    subdivisionCodeLink: "ISO 3166-2:VG",
  },
  {
    name: "Virgin Islands (U.S.) [ag]",
    officialStateName: "The Virgin Islands of the United States",
    sovereignty: "United States",
    alpha2Code: "VI",
    alpha3Code: "VIR",
    numericCode: "850",
    subdivisionCodeLink: "ISO 3166-2:VI",
  },
  {
    name: "Wallis and Futuna",
    officialStateName: "The Territory of the Wallis and Futuna Islands",
    sovereignty: "France",
    alpha2Code: "WF",
    alpha3Code: "WLF",
    numericCode: "876",
    subdivisionCodeLink: "ISO 3166-2:WF",
  },
  {
    name: "Western Sahara [ah]",
    officialStateName: "The Sahrawi Arab Democratic Republic",
    sovereignty: "disputed [ai]",
    alpha2Code: "EH",
    alpha3Code: "ESH",
    numericCode: "732",
    subdivisionCodeLink: "ISO 3166-2:EH",
  },
  {
    name: "Yemen",
    officialStateName: "The Republic of Yemen",
    sovereignty: "UN member state",
    alpha2Code: "YE",
    alpha3Code: "YEM",
    numericCode: "887",
    subdivisionCodeLink: "ISO 3166-2:YE",
  },
  {
    name: "Zambia",
    officialStateName: "The Republic of Zambia",
    sovereignty: "UN member state",
    alpha2Code: "ZM",
    alpha3Code: "ZMB",
    numericCode: "894",
    subdivisionCodeLink: "ISO 3166-2:ZM",
  },
  {
    name: "Zimbabwe",
    officialStateName: "The Republic of Zimbabwe",
    sovereignty: "UN member state",
    alpha2Code: "ZW",
    alpha3Code: "ZWE",
    numericCode: "716",
    subdivisionCodeLink: "ISO 3166-2:ZW",
  },
];
export const COUNTRIES_BY_NAME = keyBy(COUNTRIES, "name");
export const COUNTRIES_BY_ALPHA2_CODE = keyBy(COUNTRIES, "alpha2Code");
export const COUNTRIES_BY_ALPHA3_CODE = keyBy(COUNTRIES, "alpha3Code");
