import { useState } from "react";

export function useUndoHistory<T>(initial: T): UndoHistory<T> {
  const [history, setHistory] = useState<T[]>([initial]);
  const [index, setIndex] = useState(0);
  const length = history.length - 1;
  const value = history[index];

  return {
    value,

    do(value: T): void {
      setHistory((history) => {
        let next;

        if (index < length) {
          next = history.slice(0, index).concat(value);
        } else {
          next = history.concat(value);
        }

        return next;
      });

      setIndex((index) => min(index + 1));
    },
    undo(): void {
      setIndex((index) => max(0, index - 1));
    },
    redo(): void {
      setIndex((index) => min(index + 1, length));
    },
  };
}

const { min, max } = Math;

interface UndoHistory<T> {
  value: T;
  do(value: T): void;
  undo(): void;
  redo(): void;
}
