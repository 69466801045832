import React from "react";
import classNames from "classnames";
import { TradeCOACargoSize } from "@/api";
import { TextareaField, NumberField, SelectField, SelectFieldProps } from "@/components";
import { Group } from "../../group";
import { Row } from "../../row";
import "./Trade.coa.cargo.size.scss";
import { StatusMessages } from "@/components/status.messages";

export function TradeCoaCargoSize(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-coa-cargo-size");

  const { status, ...rest } = props;
  let { value } = props;

  value = value || defaultValue;

  function onChange(name: keyof TradeCOACargoSize, inputValue: string | number | null) {
    props.onChange?.({ ...value, [name]: inputValue });
  }

  function onOptionChange(option: Option) {
    const next = { ...value, option };

    if (option === "MIN/MAX") next.variance = null;

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...props.value, notes });
  }

  return (
    <Group className={props.className} label={props.label}>
      <Row>
        <NumberField
          {...rest}
          label="Min"
          desc="COA cargo min weight"
          className="trade-coa-cargo-size-min-value"
          placeholder="Metric tons, e.g. 120,000"
          status={status?.children?.min}
          value={value.min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}/trade-coa-cargo-size-min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="COA cargo max weight"
          className="trade-coa-cargo-size-max-value"
          placeholder="e.g. 200,000"
          status={status?.children?.max}
          value={value.max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}/trade-coa-cargo-size-max`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          className={classNames("trade-coa-cargo-size-variance", {
            hidden: value.option === "MIN/MAX",
          })}
          label="+ / - %"
          placeholder="e.g. 3"
          desc="COA cargo weight variance (%)"
          status={status?.children?.variance}
          value={value.variance}
          tabIndex={value.option === "MIN/MAX" ? -1 : props.tabIndex}
          onChange={onChange.bind(null, "variance")}
          dataTest={`${props.dataTest}/trade-coa-cargo-size-variance`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Option"
          className="trade-coa-cargo-size-option"
          desc={`COA cargo size option
      
      MIN/MAX - the lowest / highest acceptable load weight.
      
      MOLCHOP - More or Less in Charterer's Option - Option allowed to a voyage charterer to load up to a certain quantity, normally expressed as a percentage or a number of tons, over or under a quantity specified in the contract of carriage. This option may be sought if the charterer is not certain of the exact quantity that will be available at the time of loading.
      
      MOLOO - More or Less in Owner's Option - Option allowed to a shipowner to carry up to a certain quantity, normally expressed as a percentage or number of tons, over or under a quantity specified in the voyage charter. This option may be sought if the shipowner is not certain what the ship's cargo capacity will be, taking into consideration bunkers, stores and fresh water, or if he wants flexibility to adjust the ship's trim.
      `}
          data={OPTIONS}
          status={status?.children?.option}
          value={value.option}
          onChange={onOptionChange}
          dataTest={`${props.dataTest}/trade-coa-cargo-size-option`}
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="COA cargo size notes"
        className="trade-coa-cargo-size-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-coa-cargo-size-notes`}
      />
    </Group>
  );
}

const defaultValue = {
  value: null,
  variance: null,
  option: null,
};

const OPTIONS = ["MIN/MAX", "MOLOO", "MOLCHOPT"];

type Option = (typeof OPTIONS)[number];

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: TradeCOACargoSize;
  onChange?: (value: TradeCOACargoSize) => void;
}

export type TradeCOACargoSizeProps = Props;
