import { auth } from "./auth";
import { cargoTypes } from "./cargo.types";
import { companies } from "./companies";
import { termsetLayouts } from "./termset.layouts";
import { router } from "./router";
import { unsavedChanges } from "./unsaved.changes";
import { termsetHandlebars } from "./termset.handlebars/termset.handlebars";

export async function setup(): Promise<void> {
  router.setup();

  if (auth.authorised) {
    // load static data
    Promise.all([cargoTypes.getData(), termsetHandlebars.getData(), companies.getData(), termsetLayouts.getData()]);
  }

  setupUnsavedChanges();
}

function setupUnsavedChanges() {
  window.addEventListener("beforeunload", function (e) {
    if (!unsavedChanges.exist) {
      return undefined;
    }

    const message = "In the end, it doesn't even maAaAAaatter...";

    (e || window.event).returnValue = message;

    return message;
  });
}
