import { decorate, observable, action } from "mobx";
import { trade, TradeTermsetLayout } from "@/api";
import { keyBy } from "@/util";
import { Layout as LayoutModel } from "./layout";

class TermsetLayouts {
  loading = false;
  data = [] as Data;
  dataById = {} as DataById;

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = async () => {
    const res = await trade.getTermsetLayouts();

    if (res.data) this.setData(res.data);
  };

  setData = (data: TradeTermsetLayout[]) => {
    const layouts = data.map(mapLayout);
    const layoutsById = keyBy(layouts, "data.id");

    this.data = layouts;
    this.dataById = proxyDataById(layoutsById);

    this.resolve();
  };
}

decorate(TermsetLayouts, {
  data: observable,
  loading: observable,
  setData: action,
});

function mapLayout(data: TradeTermsetLayout) {
  const layout = new LayoutModel();

  layout.setData(data);

  return layout;
}

function proxyDataById(dataById: DataById) {
  return new Proxy(observable(dataById), {
    get: (dataById: DataById, id: number) => {
      if (!dataById[id]) {
        const layout = new LayoutModel();

        layout.setData({ id: id });

        dataById[id] = layout;
      }

      return dataById[id];
    },
  });
}

export const termsetLayouts = new TermsetLayouts();

type Data = LayoutModel[];

interface DataById {
  [name: string]: LayoutModel;
}

export type Layout = TradeTermsetLayout;

interface TermsetLayouts {
  resolve: () => void;
}
