import React from "react";
import { observer } from "mobx-react";
import { ListChildComponentProps } from "react-window";
import { cn, get } from "@/util";
import { NavLink } from "@/components";
import { EntityContextMenu } from "../../../entity.context.menu";
import { Props as BodyProps } from "../Body";
import "./Entity.scss";

function Entity(props: Props) {
  const { data: bodyProps, index, style } = props;
  const { config } = bodyProps;
  const { parent, routes } = config;
  const { view } = config;
  const { list } = view;
  const { columns } = list;

  if (!parent) return null;

  const { entities } = parent;
  const entity = entities[index];

  if (!entity.data) return null;

  const { deleted, data } = entity;
  const { id, companyId, name } = data;

  if (!id) return null;

  const to = routes.entity?.interpolate({ id, companyId });

  const cells = columns.map((column, i) => {
    const { key, value, name } = column;
    const className = `entity-list-entity-${name}`;
    let content = value;

    if (key) content = get(entity, key);

    if (typeof value === "function") content = value(entity);

    return (
      <div className={className} key={i}>
        {content}
      </div>
    );
  });

  return (
    <NavLink to={to} className={cn("entity-list-body-entity", { deleted })} style={style} title={name} data-uuid={id}>
      {cells}
      <EntityContextMenu entity={entity} config={bodyProps.config} tabIndex={-1} hidden={!parent?.contextMenuEnabled} />
    </NavLink>
  );
}

const Observer = observer(Entity);

export { Observer as Entity };

interface Props extends Omit<ListChildComponentProps, "data"> {
  data: BodyProps;
}
