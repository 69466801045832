class _Storage {
  constructor() {
    this.local = new BrowserStorage(localStorage);
    this.session = new BrowserStorage(sessionStorage);
  }
}

class BrowserStorage {
  constructor(browserStorage: Storage) {
    function get(key: key, defaultValue?: any) {
      let val = browserStorage.getItem(`${key}`);

      if (typeof val !== "string") {
        return defaultValue !== undefined ? defaultValue : val;
      }

      try {
        val = JSON.parse(val);
      } catch {
        console.error(
          `Stored value '${val}' at '${key}' could not be parsed by JSON.parse. JSON.stringify the value before storing it or use BrowserStorage.set to store the value`
        );

        val = null;
      }

      return val;
    }

    function set(key: key, val: any) {
      if (val === undefined) val = null;

      browserStorage.setItem(`${key}`, JSON.stringify(val));
    }

    function remove(key: key) {
      browserStorage.removeItem(`${key}`);
    }

    this.get = get;
    this.set = set;
    this.remove = remove;
  }
}

interface _Storage {
  local: BrowserStorage;
  session: BrowserStorage;
}
interface BrowserStorage {
  get: (key: key, defaultValue?: any) => any;
  set: (key: key, val: any) => void;
  remove: (key: key) => void;
}
type key = string | number;

export const storage = new _Storage();

export { _Storage };
