import React from "react";
import { IconProps, ImgProps, INPUT_TYPE, InputType } from "@/components";

export function Input(props: Props): JSX.Element | null {
  props = { ...props };

  const Type = props.type && INPUT_TYPE[props.type];

  if (!Type) {
    console.error(`Unrecognized input type: "${props.type}"`, props);

    return null;
  }

  return <Type {...props} />;
}

interface Props extends InputProps {
  type: InputType;
}

export interface InputProps<Value = any, ChangeValue = Value> {
  value?: Value;
  onChange?: (value: ChangeValue) => void;
  onFocus?: (e: InputFocusEvent) => void;
  onBlur?: (e: InputFocusEvent) => void;
  onKeyDown?: (e: InputKeyboardEvent) => void;
  status?: Status;
  className?: string;
  placeholder?: string;
  desc?: string;
  tabIndex?: number;
  disabled?: boolean;
  icon?: IconProps["icon"];
  img?: ImgProps["img"];
  clearable?: boolean;
  input?: InputDOMRef;
  dataTest: string;
}

export type InputChangeEvent = React.ChangeEvent<CommonHTMLInputElement>;
export type InputMouseEvent = React.MouseEvent<CommonHTMLInputElement>;
export type InputKeyboardEvent = React.KeyboardEvent<CommonHTMLInputElement>;
export type InputFocusEvent = React.FocusEvent<CommonHTMLInputElement>;
export type InputDOMRef = React.MutableRefObject<CommonHTMLInputElement | null>;
