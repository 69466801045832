import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useSimpleEffect, wait } from "@/util";
import { Body } from "./body";
import { Header } from "./header";
import { Config } from "../Entity.layout";
import "./Entity.scss";

function Entity(props: Props) {
  const { id, companyId } = useParams();
  const { name } = props.config.view.entity;
  const entity = props.config.parent?.entitiesById[`${companyId}@${id}`];

  useSimpleEffect(setPageTitle, [entity?.data?.name]);

  async function setPageTitle() {
    if (`${id}`.includes(`$`)) return;

    await wait(0);

    if (entity?.data?.name) document.title = `Sea/Trade Admin - ${name} - ${entity?.data?.name}`;
  }

  return (
    <div className="entity" data-test-uuid={id}>
      <Header config={props.config} />
      <Body config={props.config} />
    </div>
  );
}

const Observer = observer(Entity);

export { Observer as Entity };

interface Props {
  config: Config;
}
