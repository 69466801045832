import { ComponentType } from "react";
import { RedirectProps, RouteProps } from "react-router";
import { computed, action, decorate, observable } from "mobx";
import { history, HistoryState } from "@/history";
import { auth, Auth } from "@/model";
import { router } from "../Router";

class Route {
  setup = (seed: Seed, parent?: Route): void => {
    this.seed = seed;
    this.pageTitle = seed.pageTitle;
    this.path = seed.path;
    this.icon = seed.icon;
    this.desc = seed.desc;
    this.exact = seed.exact;
    this.comp = seed.comp;
    this.auth = seed.auth;
    this.parent = parent;
    this.absPath = `${parent?.path || ""}${seed.path || ""}`;
    this.params = [...this.absPath.matchAll(/:[a-z0-9]+/gi)];
    this.redirect = seed.redirect;

    const { arr, map } = createChildren(seed.children, this);

    this.children = arr;
    this.childrenMap = map;
  };

  get active(): boolean {
    return router.active === this;
  }

  get authorised(): boolean {
    if (!this.auth) return true;

    return this.auth(auth);
  }

  interpolate = (params_?: InterpolateParams): string => {
    const params = params_ || {};

    const to = this.params.reduce((absPath, match) => {
      const param = match[0];

      return absPath.replace(param, `${params[param.slice(1)]}`);
    }, this.absPath);

    return to;
  };

  go = (params?: InterpolateParams, state?: HistoryState): void => {
    const push = history.push.bind(null, this.interpolate(params), state);

    push();
  };
}

function createChildren(children: SeedChildren | undefined, parent: Route) {
  const arr: RouteChildren = [];
  const map: RouteChildrenMap = {};
  const res = { arr, map };

  if (!children) return res;

  const entries = Object.entries(children);

  for (let i = 0; i < entries.length; i += 1) {
    const [name, route] = entries[i];
    const instance = createChild(route, parent);

    arr[i] = instance;
    map[name] = instance;
  }

  return res;
}

function createChild(route: Seed, parent: Route) {
  const child = new Route();

  child.setup(route, parent);

  return child;
}

decorate(Route, {
  active: computed,
  exact: observable,
  pageTitle: observable,
  sidenav: observable,
  icon: observable,
  path: observable,
  auth: observable,
  comp: observable,
  children: observable,
  childrenMap: observable,
  authorised: computed,
  setup: action,
});

export { Route };

interface Route extends Omit<Seed, "children" | "sidenav"> {
  parent?: Route;
  seed: Seed;
  active: boolean;
  children: RouteChildren;
  childrenMap: RouteChildrenMap;
  sidenav: Route[];
  absPath: string;
  params: RegExpMatchArray[];
}

type RouteChildren = Route[];

interface RouteChildrenMap {
  [name: string]: Route;
}

interface Seed extends Omit<RouteProps, "component"> {
  path?: string;
  exact?: boolean;
  comp?: ComponentType<any>;
  children?: SeedChildren;
  pageTitle?: string;
  icon?: string;
  desc?: string;
  sidenav?: string[];
  redirect?: Redirect;
  auth?: (auth: Auth) => boolean;
}

type SeedChildren = {
  [name: string]: Seed;
};

interface InterpolateParams {
  [param: string]: string | number | undefined | null;
}

interface Redirect extends RedirectProps {
  to: string;
}

export type RouteSeed = Seed;
