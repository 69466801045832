import { action, observable, decorate } from "mobx";
import { ReactNode } from "react";

class Popup {
  content: Content = null;
  open = false;

  show = (params: ShowParams) => {
    this.content = params.content;
    this.open = true;
  };

  hide = () => {
    this.content = null;
    this.open = false;
  };
}

decorate(Popup, {
  content: observable.shallow,
  open: observable,
  show: action,
  hide: action,
});

export const popup = new Popup();

interface ShowParams {
  content: Content;
}

type Content = ReactNode;
