import { createBrowserHistory } from "history"; // is installed by react-router
import { dialog, unsavedChanges } from "@/model";

export const history = createBrowserHistory({
  async getUserConfirmation(status, callback) {
    const { res } = await dialog.show({
      status: STATUS[status],
      dataTest: "unsaved-changes",
    });

    callback(res);
  },
});

history.block((location) => {
  const state = location.state as HistoryState | null;

  let ignoreUnsavedChanges = false;

  if (typeof state?.ignoreUnsavedChanges === "boolean") {
    ignoreUnsavedChanges = state?.ignoreUnsavedChanges;
  }

  if (unsavedChanges.exist && !ignoreUnsavedChanges) {
    return "unsavedChanges";
  }
});

const STATUS = {
  unsavedChanges: {
    type: "warning",
    title: "Unsaved Changes",
    message: "You have unsaved changes, are you sure you want to navigate away?",
  },
} as Record<string, Status>;

export interface HistoryState {
  ignoreUnsavedChanges?: boolean;
}
