import React from "react";

export const DisplayedRowsFragment = ({ from, to, totalRecords }: DisplayedRowsFragmentProps) => {
  const loadingDataPlaceholder = "...";

  return (
    <span className="displayed-rows">
      {from && to ? `${from}-${to}` : loadingDataPlaceholder} from {totalRecords} results
    </span>
  );
};

interface DisplayedRowsFragmentProps {
  totalRecords: number;
  from?: number;
  to?: number;
}
