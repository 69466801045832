import React from "react";
import classNames from "classnames";
import { CargoSize, Props as CargoSizeProps, CargoSizeType } from "../cargo.size";
import { TradeCargoSize as TradeCargoSizeType } from "@/api";

export function TradeCargoSize(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-cargo-size");

  function onChange(value: CargoSizeType) {
    props.onChange?.(value);
  }

  return <CargoSize {...props} value={props.value} onChange={onChange} />;
}

interface Props extends Omit<CargoSizeProps, "value" | "onChange"> {
  value?: TradeCargoSizeType;
  onChange?: (value: TradeCargoSizeType) => void;
}
