import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { auth } from "@/model";

export function setup(): void {
  axios.interceptors.request.use(interceptReqSuccess, interceptReqFailure);
  // @ts-ignore
  axios.interceptors.response.use(interceptResSuccess, interceptResFailure);
}

// @ts-ignore
axios.originalCreate = axios.create;
// @ts-ignore
axios.create = function customCreate(...args) {
  // @ts-ignore
  const instance = axios.originalCreate(...args);

  instance.interceptors.request.use(interceptReqSuccess, interceptReqFailure);
  instance.interceptors.response.use(interceptResSuccess, interceptResFailure);

  return instance;
};

function interceptReqSuccess(config: AxiosRequestConfig) {
  // trade gateway sometimes doesn't like ;charset=utf-8, which is applied by default to application/json
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }

  return config;
}

function interceptReqFailure(error: AxiosError) {
  return error;
}

function interceptResSuccess(axiosResponse: AxiosResponse) {
  const res = axiosResponse as Response;

  res.ok = true;
  res.cancelled = false;

  return res;
}

function interceptResFailure(axiosError: AxiosError) {
  let err = axiosError as Response;

  err.ok = false;
  err.cancelled = err.__CANCEL__;

  const status = err.response?.status;
  const ignore401 = err.config?.ignore401;

  if (status === 401 && !ignore401) {
    auth.reset();
  }

  err = { ...err, ...err.response };

  return err;
}

export type Response<T = any> = {
  // AxiosResponse
  data?: T;
  status?: number;
  statusText?: string;
  headers?: any;

  // AxiosError
  code?: string;
  response?: AxiosResponse<T>;
  isAxiosError?: boolean;
  toJSON?: () => object;

  // common between AxiosResponse and AxiosError
  config: Config;
  request?: any;

  // custom
  ok?: boolean;
  cancelled?: boolean;

  // undocumented AxiosError
  __CANCEL__?: boolean;
};

interface Config extends AxiosRequestConfig {
  ignore401?: boolean;
}
