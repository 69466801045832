import React, { useMemo } from "react";
import { observable } from "mobx";
import { TRADE_DATA_DEF } from "@/constants";
import { RouteSeed, ownerAccounts, router } from "@/model";
import { EntityLayout, Entity, EntityLayoutConfig } from "@/components/entity.layout";
import { TradeAccount } from "@/api";

export const ownerAccountsRoute: RouteSeed = {
  path: "/owner-accounts",
  comp: OwnerAccounts,
  sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
  icon: "contact-dialer",
  desc: "Owner Accounts",
  pageTitle: "Owner Accounts",
  children: {
    ownerAccount: {
      path: "/:companyId/:id",
      comp: OwnerAccount,
      sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
      pageTitle: "Owner Accounts",
    },
  },
  auth: (auth) => auth.authorised,
};

function OwnerAccounts(routeProps: any) {
  const config = useMemo(getConfig, []);

  return <EntityLayout config={config} {...routeProps} />;
}

function OwnerAccount() {
  const config = useMemo(getConfig, []);

  return <Entity config={config} />;
}

function getConfig(): EntityLayoutConfig {
  config.parent = ownerAccounts;
  config.routes.parent = router.get("ownerAccounts");
  config.routes.entity = router.get("ownerAccounts.ownerAccount");

  return config;
}

const config = {
  className: "owner-accounts",
  dataTest: "owner-accounts",
  routes: {},
  view: {
    storageKey: "owner-accounts",
    list: {
      columns: [
        {
          key: "data.accountName",
          label: "Owner Account name",
        },
      ],
      header: {
        title: "Owner Accounts",
        button: {
          label: "New Owner Account",
        },
      },
      search: {
        placeholder: "Search by name",
      },
      body: {
        loadingMessage: "Searching for owner accounts",
        header: {
          title: "Owner Account name",
        },
      },
    },
    entity: {
      name: "Owner Account",
      newEntityData: {
        name: "New Owner Account",
      },
      form: {
        elements: [
          {
            type: "row",
            children: [
              {
                type: "column",
                children: ["accountName", "isLegalEntity", "arcContactId", "gainAccountId"],
                label: "Trade Account Info",
              },
              {
                type: "column",
                children: ["relatedArcAccount"],
                label: "Arc Account Info",
              },
            ],
          },
        ],
        mutateInitialValues: mutateValues,
        mutateValues,
        def: TRADE_DATA_DEF.account,
        context: observable({ current: null }),
      },
    },
  },
} as EntityLayoutConfig;

function mutateValues(data?: TradeAccount) {
  if (data?.relatedArcAccount) {
    data.arcContactId = `${data?.relatedArcAccount?.contactId ?? ""}`;
    data.accountName = `${data?.relatedArcAccount?.name ?? ""}`;
    data.gainAccountId = `${data?.relatedArcAccount?.gainAccountId ?? ""}`;
  }
}
