import React, { useEffect, useRef } from "react";
import { cn } from "@/util";
import { InputProps, InputChangeEvent } from "../../Input";
import { Container } from "../../container";

export function Text(props: TextProps): JSX.Element {
  props = { ...props };

  props.className = cn(props.className, "text");

  const value = props.value || "";
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(onMount, []);

  function onMount() {
    if (props.input) props.input.current = input.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.input) props.input.current = null;
  }

  function onChange(e: InputChangeEvent) {
    props.onChange?.(e.currentTarget.value);
  }

  return (
    <Container {...props}>
      <input
        value={value}
        onChange={onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        data-test={`${props.dataTest}/input`}
        ref={input}
      />
    </Container>
  );
}

export type TextProps = InputProps<Value, string>;

type Value = string | number | null;
