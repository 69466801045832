import { FIELD_TYPE } from "@/components";
import { Period } from "./period";
import { Location } from "./location";
import { CargoSize } from "./cargo.size";
import { Commission } from "./commission";
import { VesselSize } from "./vessel.size";
import { TradeAccount } from "./trade.account";
import { TradeCompany } from "./trade.company";
import { TradePeriod } from "./trade.period";
import { TradeLiftings } from "./trade.liftings";
import { TradeLocation } from "./trade.location";
import { TradeCharterer } from "./trade.charterer";
import { TradeOrderType } from "./trade.order.type";
import { TradeOrderType2 } from "./trade.order.type2";
import { TradeCargoType } from "./trade.cargo.type";
import { TradeCargoSize } from "./trade.cargo.size";
import { TradeCommission } from "./trade.commission";
import { TradeVesselSize } from "./trade.vessel.size";
import { TradeNominations } from "./trade.nominations";
import { TradeUnitDuration } from "./trade.unit.duration";
import { TradeCoaCargoSize } from "./trade.coa.cargo.size";
import { TradeTermsetLayout } from "./trade.termset.layout";
import { TradeTermsetHandlebar } from "./trade.termset.handlebar";
import { TradeTermsetLayoutKey } from "./trade.termset.layout.key";
import { TradeAccountLegalState } from "./trade.account.legal.state";
import { ArcContact } from "./arc.contact";

export const EDITOR_TYPE = {
  ...FIELD_TYPE,
  period: Period,
  location: Location,
  commission: Commission,
  "cargo-size": CargoSize,
  "vessel-size": VesselSize,
  "trade-period": TradePeriod,
  "trade-account": TradeAccount,
  "arc-account": ArcContact,
  "trade-company": TradeCompany,
  "trade-liftings": TradeLiftings,
  "trade-location": TradeLocation,
  "trade-charterer": TradeCharterer,
  "trade-order-type": TradeOrderType,
  "trade-order-type2": TradeOrderType2,
  "trade-cargo-size": TradeCargoSize,
  "trade-cargo-type": TradeCargoType,
  "trade-commission": TradeCommission,
  "trade-vessel-size": TradeVesselSize,
  "trade-nominations": TradeNominations,
  "trade-unit-duration": TradeUnitDuration,
  "trade-coa-cargo-size": TradeCoaCargoSize,
  "trade-termset-layout": TradeTermsetLayout,
  "trade-termset-handlebar": TradeTermsetHandlebar,
  "trade-termset-layout-key": TradeTermsetLayoutKey,
  "trade-account-legal-state": TradeAccountLegalState,
};

export type EditorType = keyof typeof EDITOR_TYPE;
