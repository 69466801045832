import React, { useEffect, useState, ReactNode, useRef, useMemo } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useSimpleEffect } from "@/util";
import { dialog } from "@/model";
import { ButtonProps, Status } from "@/components";
import { Actions } from "./actions";
import "./Dialog.scss";

function Dialog() {
  const { status, dataTest, content: providedContent } = dialog.props || {};
  const container = useRef<HTMLDialogElement>(null);
  const dimensions = useMemo(createObservableDimensions, [providedContent]);
  const [visible, setVisible] = useState(false);

  useEffect(setupEvents, [dialog.open]);
  useSimpleEffect(controlVisibility, [dialog.open]);

  const res = (
    <dialog className={classNames({ visible })} open={dialog.open} ref={container} data-test={`${dataTest}-dialog`}>
      <div className="dialog-backdrop" onClick={dialog.hide} />
      <div
        className="dialog-box-wrapper"
        style={{
          height: dimensions.height + 3,
          width: dimensions.width + 3,
        }}
        hidden={!providedContent && !status}
      >
        <div className="dialog-box">
          <Content {...dialog.props} dimensions={dimensions}>
            {providedContent}
          </Content>
        </div>
      </div>
    </dialog>
  );

  async function controlVisibility() {
    setVisible(dialog.open);
  }

  function setupEvents() {
    if (!dialog.open) return;

    container.current?.addEventListener("mousedown", mouseDownHandler);
    window.addEventListener("keydown", keyDownHandler);

    function mouseDownHandler(e: MouseEvent) {
      e.preventDefault();
    }

    function keyDownHandler(e: KeyboardEvent) {
      const handler = KEYBOARD_EVENT_HANDLER[e.key];

      if (!handler) e.preventDefault();

      if (handler) handler(e);
    }

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
      container.current?.removeEventListener("mousedown", mouseDownHandler);
    };
  }

  function createObservableDimensions() {
    return observable(defaultObservableDimensions);
  }

  const KEYBOARD_EVENT_HANDLER: KeyboardEventMap = {
    Escape: (e: KeyboardEvent) => {
      e.preventDefault();

      dialog.hide();
    },
  };

  return res;
}

function Content(props: ContentProps) {
  const { status, children, actions, suppressStatusActions, dimensions } = props;
  const content = useRef<HTMLDivElement>(null);

  function setDimensions() {
    if (!content.current) return;

    dimensions.height = content.current.clientHeight;
    dimensions.width = content.current.clientWidth;
  }

  // trigger rerenders to get the dimensions, (human eye roughly sees 23 fps)
  setTimeout(setDimensions, 1000 / 23);

  return (
    <div className="dialog-box-content" ref={content}>
      <Status status={status} />
      {children}
      <Actions actions={actions} status={status} suppressStatusActions={suppressStatusActions} />
    </div>
  );
}

const defaultObservableDimensions = {
  height: 0,
  width: 0,
} as ObservableDimensions;

const Observer = observer(Dialog);

export { Observer as Dialog };

interface Props {
  content?: ReactNode;
  actions?: Action[];
  status?: Status;
  suppressStatusActions?: boolean;
  dataTest: string;
}

interface ContentProps extends Omit<Props, "dataTest"> {
  children?: ReactNode;
  dimensions: ObservableDimensions;
  dataTest?: string;
}

interface ObservableDimensions {
  width: number;
  height: number;
}

type Actions = Action[];

interface Action extends Omit<ButtonProps, "children"> {
  label: string;
}

interface KeyboardEventMap {
  [key: string]: (e: KeyboardEvent) => void;
}

export type DialogProps = Props;
export type DialogAction = Action;
