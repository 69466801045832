import axios from "axios";
import { action, observable, decorate } from "mobx";
import { keyBy } from "@/util";
import { trade, TradeLocalFilter, TradeTermset } from "@/api";
import { Termset } from "./termset";

class Termsets {
  entities = [] as Termset[];
  entitiesById = proxyEntitiesById({}) as EntitiesById;
  searchStatus = {} as Status;
  totalRecords = 0;

  // eslint-disable-next-line import/no-named-as-default-member
  cancel = axios.CancelToken.source();
  contextMenuEnabled = true;

  searchEntities = async (search: string, localFilter: TradeLocalFilter, pageNumber?: number) => {
    this.cancel.cancel();

    // eslint-disable-next-line import/no-named-as-default-member
    this.cancel = axios.CancelToken.source();

    this.entities = [];

    const res = await trade.searchTermsets(search, localFilter, this.cancel.token, pageNumber);

    return res;
  };

  setEntities = (data: any[]) => {
    const termsets = data.map(mapTermset);
    const termsetsById = keyBy(termsets, "entityKey");

    this.entities = termsets;
    this.entitiesById = proxyEntitiesById({
      ...this.entitiesById,
      ...termsetsById,
    });
  };

  setTotal = (total: number) => {
    this.totalRecords = total;
  };

  deleteEntity = (id: string) => {
    if (this.entities) {
      this.entities = this.entities.filter((item) => item.data?.id !== id);
    }
  };
}

decorate(Termsets, {
  entities: observable,
  searchStatus: observable,
  totalRecords: observable,
  searchEntities: action,
  setEntities: action,
  deleteEntity: action,
  setTotal: action,
});

function mapTermset(data: TradeTermset) {
  const termset = new Termset();

  termset.data = data;
  termset.entityKey = `${data.companyId}@${data.id}`;

  return termset;
}

function proxyEntitiesById(entitiesById: EntitiesById) {
  return new Proxy(observable(entitiesById), {
    get: (target: EntitiesById, entityKey: string) => {
      if (!target[entityKey]) {
        const [companyId, id] = entityKey.split("@");

        const termset = new Termset();

        termset.entityKey = entityKey;
        termset.data = { companyId, id };

        target[entityKey] = termset;
      }

      return target[entityKey];
    },
  });
}

export const termsets = new Termsets();

type EntitiesById = Record<string, Termset>;
