import React from "react";
import classNames from "classnames";
import { Icon, Img } from "@/components";
import { InputProps } from "../Input";
import "./Icon.scss";

export function InputIcon(props: Props): JSX.Element | null {
  const { img, icon, hidden, align = "left" } = props;

  if (hidden) return null;
  if (!img && !icon) return null;

  let content;

  if (img) content = <Img img={img} />;
  if (icon) content = <Icon icon={icon} />;

  const dataTest = `${props.dataTest}/input-clear-value`;
  const className = classNames(props.className, img, icon, align);

  return (
    <input-icon class={className} onClick={props.onClick} data-test={dataTest}>
      {content}
    </input-icon>
  );
}

export { InputIcon as Icon };

interface Props {
  img?: InputProps["img"];
  icon?: InputProps["icon"];
  dataTest?: InputProps["dataTest"];
  onClick?: () => void;
  hidden?: boolean;
  align?: "left" | "right";
  className?: string;
}
