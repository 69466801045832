import React, { Children, cloneElement, ReactNode } from "react";
import classNames from "classnames";
import { useShallowState } from "@/util";
import { StatusMessages, InputFocusEvent } from "@/components";
import { FieldProps } from "../Field";
import { Label } from "../label";
import "./Container.scss";

export function Container(props: Props): JSX.Element {
  const { desc, status, suppressStatusMessages } = props;
  const [state, setState] = useShallowState(defaultState);
  const className = classNames(props.className, status?.type, {
    status,
    ...state,
  });
  const children = Children.map(props.children, mapChild);

  function mapChild(child: ReactNode) {
    // @ts-ignore
    return cloneElement(child, { ...child?.props, onFocus, onBlur });
  }

  function onFocus(e: InputFocusEvent) {
    setState({ focus: true });

    props?.onFocus?.(e);
  }

  function onBlur(e: InputFocusEvent) {
    setState({ focus: false });

    props?.onBlur?.(e);
  }

  return (
    <sea-field class={className} title={desc}>
      <Label {...props} />
      {children}
      <StatusMessages status={status} hidden={suppressStatusMessages} />
    </sea-field>
  );
}

const defaultState = {
  focus: false,
} as State;

interface Props extends FieldProps {
  children: React.ReactNode;
}

interface State {
  focus: boolean;
}
