import { action, observable, decorate } from "mobx";
import { trade, TradeTermsetHandlebarMap, TradeTermsetHandlebar } from "@/api";

class TermsetHandlebars {
  data = {} as TradeTermsetHandlebarMap;
  // dataByKey = {} as DataByKey;

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = async () => {
    const res = await trade.getTermsetHandlebars();

    if (res.data) this.setData(res.data);
  };

  setData = (data: TradeTermsetHandlebarMap) => {
    this.data = data;

    this.resolve();
  };
}

decorate(TermsetHandlebars, {
  data: observable,
  setData: action,
});

export const termsetHandlebars = new TermsetHandlebars();

// interface DataByKey {
//   [name: string]: TradeTermsetHandlebar;
// }

export type Handlebar = TradeTermsetHandlebar;

interface TermsetHandlebars {
  resolve: () => void;
}
