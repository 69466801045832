import React from "react";
import { ICellEditorParams, ColDef } from "@ag-grid-enterprise/all-modules";
import { cn } from "@/util";
import { Icon } from "@/components";
import "./Icon.scss";

function IconFrameworkComponent(props: ICellEditorParams): JSX.Element {
  const colDef = props.colDef as FancyColDef;

  colDef.props = { ...colDef.props };

  colDef.props.className = cn(colDef.props.className, "aggrid-icon", {
    visible: true,
  });

  function onClick() {
    colDef.props.onClick(props);
  }

  return (
    <div className="aggrid-icon-wrapper">
      <Icon {...colDef.props} onClick={onClick} />
    </div>
  );
}

export { IconFrameworkComponent as Icon };

interface FancyColDef extends ColDef {
  props: any;
}
