import React from "react";
import { Commission, Props as CommissionProps } from "../commission";
import { TradeCommission as TradeCommissionType } from "@/api";

export function TradeCommission(props: Props): JSX.Element {
  props = { ...props };

  props.className = `${props.className} trade-comission`;

  function onChange(value: number | null) {
    props.onChange?.({ value });
  }

  return <Commission {...props} value={props.value?.value} onChange={onChange} />;
}

interface Props extends Omit<CommissionProps, "value" | "onChange"> {
  value?: TradeCommissionType;
  onChange?: (value: TradeCommissionType) => void;
}
