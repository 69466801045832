import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import uuid from "uuid";
import { NavLink, Button } from "@/components";
import { Config } from "../../Entity.layout";
import "./Header.scss";

function Header(props: Props) {
  const [flag, rerender] = useState(false);
  const params = useParams(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const isNew = window.location.pathname.includes("$");
  const { view, routes, dataTest } = props.config;
  const { list } = view;
  const { header } = list;
  const { button, title } = header;
  const { label } = button;

  const to = routes.entity?.interpolate({
    id: `$${uuid()}`,
    companyId: `$${uuid()}`,
  });

  return (
    <div className="entity-list-header">
      <h1>{title}</h1>
      {!isNew && (
        <NavLink to={to} onClick={rerender.bind(null, !flag)}>
          <Button icon="add-circle-outline" data-test={`${dataTest}-new-entity-button`}>
            {label}
          </Button>
        </NavLink>
      )}
    </div>
  );
}

const Memo = memo(Header);

export { Memo as Header };

interface Props {
  config: Config;
}
