import React, { useMemo } from "react";
import { observable } from "mobx";
import { TradeTermset } from "@/api";
import { TRADE_DATA_DEF } from "@/constants";
import { RouteSeed, companies, auth, termsets, router, termsetLayouts } from "@/model";
import { EntityLayout, Entity, EntityLayoutConfig } from "@/components/entity.layout";
import "./termsets.scss";

export const termsetsRoute: RouteSeed = {
  path: "/termsets",
  comp: Termsets,
  sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
  icon: "library-terms",
  desc: "Termsets",
  pageTitle: "Termsets",
  children: {
    termset: {
      path: "/:companyId/:id",
      comp: Termset,
      sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
      pageTitle: "Termsets",
    },
  },
  auth: (auth) => auth.authorised,
};

function Termsets(routeProps: any): JSX.Element {
  const config = useMemo(getConfig, []);

  return <EntityLayout config={config} {...routeProps} />;
}

function Termset(): JSX.Element {
  const config = useMemo(getConfig, []);

  return <Entity config={config} />;
}

function getConfig() {
  config.parent = termsets;
  config.routes.parent = router.get("termsets");
  config.routes.entity = router.get("termsets.termset");

  return config;
}

const config = {
  className: "termsets",
  dataTest: "termset",
  storageKey: "order-template",
  routes: {},
  view: {
    storageKey: "termset",
    list: {
      columns: [
        {
          key: "data.name",
          label: "Termset name",
          name: "name",
        },
        {
          key: "data.content.mainTermTemplates.length",
          name: "term-count",
          label: "Term count",
        },
      ],
      header: {
        title: "Termsets",
        button: {
          label: "New Termset",
        },
      },
      search: {
        placeholder: "Search by name",
      },
      body: {
        loadingMessage: "Searching for termsets",
        header: {
          title: "Termset name",
        },
      },
    },
    entity: {
      name: "Termset",
      newEntityData: {
        name: "New Termset",
      },
      form: {
        mutateInitialValues: mutateValues,
        mutateValues,
        def: TRADE_DATA_DEF.termset,
        context: observable({ current: null }),
        elements: [
          [
            "name",
            "typeId",
            {
              name: "companyId",
              visible: () => auth.trade.user?.isMaritechUser,
            },
            "content.proformaLayoutId",
          ],
          "content.mainTermTemplates",
        ],
      },
    },
  },
} as EntityLayoutConfig;

function mutateValues(data?: TradeTermset) {
  if (typeof data !== "object") return;

  if (!data.typeId) data.typeId = 1;

  if (!data.companyId) data.companyId = auth.trade.user?.CompanyId;

  const companyById = companies.dataById[`${data.companyId}`];

  data.companyName = companyById?.name;

  if (!data.content) return;

  if (data.content.proformaLayoutId)
    data.content.proformaLayoutName = termsetLayouts.dataById[data.content.proformaLayoutId]?.data?.name;
}
