import React from "react";
import classNames from "classnames";
import { keyBy } from "@/util";
import { SelectField, SelectFieldProps } from "@/components";

export function TradeOrderType2(props: SelectFieldProps): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-order-type2");

  function onChange(orderType: OrderType) {
    props.onChange?.(orderType?.value);
  }

  return (
    <SelectField
      {...props}
      clearable={props.clearable}
      value={orderTypesById[props.value]}
      onChange={onChange}
      data={orderTypes}
      display={display}
      filterKey="name"
      nonFilterSelect
    />
  );
}

function display(orderType: OrderType) {
  return orderType.name;
}

const orderTypes = [
  { value: 1, name: "Voyage" },
  { value: 2, name: "TC" },
  { value: 3, name: "COA" },
];

const orderTypesById = keyBy(orderTypes, "value");

interface OrderType {
  value: string;
  name: string;
}
