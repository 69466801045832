import React from "react";
import classNames from "classnames";
import { keyBy } from "@/util";
import { SelectField, SelectFieldProps } from "@/components";

export function TradeAccountLegalState(props: SelectFieldProps) {
  props = { ...props };

  props.className = classNames(props.className, "trade-account-legal-state");

  function onChange(accountLegalState: AccountLegalState) {
    props.onChange?.(accountLegalState?.value);
  }

  return (
    <SelectField
      {...props}
      clearable={props.clearable}
      value={accountLegalStateByKey[props.value]}
      onChange={onChange}
      data={accountLegalStates}
      display={display}
      filterKey="key"
      nonFilterSelect
    />
  );
}

function display(accountLegalState: AccountLegalState) {
  return accountLegalState.key;
}

const accountLegalStates = [
  { key: "Legal", value: true },
  { key: "Illegal", value: false },
];

const accountLegalStateByKey = keyBy(accountLegalStates, "value");

interface AccountLegalState {
  key: string;
  value: boolean;
}
