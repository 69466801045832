import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { TRADE_DATA_DEF } from "@/constants";
import { companies, Company } from "@/model";
import { SelectField, SelectFieldProps } from "@/components";

function TradeCompany(props: SelectFieldProps) {
  props = { ...props };

  props.className = classNames(props.className, "trade-company");

  function onChange(company: Company) {
    props.onChange?.(company?.companyId || "");
  }

  return (
    <SelectField
      {...props}
      clearable={props.clearable}
      value={companies.dataById[props.value]}
      onChange={onChange}
      data={companies.data}
      display={TRADE_DATA_DEF.company.display}
      filterKey="name"
      noDataStatus={noDataStatus}
    />
  );
}

const noDataStatus = { message: "No Companies found" };

const Observer = observer(TradeCompany);

export { Observer as TradeCompany };
