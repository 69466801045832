import React, { memo } from "react";
import { Config } from "../../../Entity.layout";
import "./Header.scss";

function Header(props: Props) {
  const { visible } = props;
  const { view } = props.config;
  const { list } = view;
  const { columns } = list;

  if (!visible) return null;

  const cells = columns.map((column, i) => {
    const { label, name } = column;
    const className = `entity-list-entity-${name}`;

    return (
      <div className={className} key={i}>
        {label}
      </div>
    );
  });

  return <div className="entity-list-body-header">{cells}</div>;
}

const Memo = memo(Header);

export { Memo as Header };

interface Props {
  config: Config;
  visible: boolean;
}
