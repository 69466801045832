import React, { useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { useShallowState } from "@/util";
import { trade, TradeLocation as TradeLocationType } from "@/api";
import { TRADE_DATA_DEF } from "@/constants";
import { Group } from "../../group";
import { TextareaField, SelectField, InputFocusEvent } from "@/components";
import { Row } from "../../row";
import { Location, LocationProps } from "../location";
import "./Trade.location.scss";

export function TradeLocation(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-location");

  const { className, value, status } = props;
  const [data, setData] = useState<LocationProps["data"]>(undefined);
  const [dropdownStatus, setDropdownStatus] = useShallowState<Status>({
    message: "Please enter a location",
  });

  // eslint-disable-next-line import/no-named-as-default-member
  const canceller = useRef(axios.CancelToken.source());

  async function onFilter(search: string) {
    canceller.current.cancel();

    // eslint-disable-next-line import/no-named-as-default-member
    canceller.current = axios.CancelToken.source();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false });

    if (!search) {
      setDropdownStatus({ message: "Please enter a location" });

      return;
    }

    setDropdownStatus({ message: "Searching for locations", loading: true });

    const res = await trade.searchLocations(search, canceller.current.token);

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: "No locations found", loading: false });

      return;
    }

    const data = res.data.map((el) => el.document);

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    canceller.current.cancel();

    setData(undefined);
    setDropdownStatus({ message: "Please enter a location", loading: false });

    props.onBlur && props.onBlur(e);
  }

  function onChange(location: TradeLocationType) {
    props.onChange?.(
      location
        ? {
            ...value,
            ...location,
            notes: value?.notes || location?.notes || null,
          }
        : null
    );
  }

  function onOptionsChange(minName: string, maxName: string, option: Option) {
    props.onChange?.({
      ...value,
      [minName]: option?.min || null,
      [maxName]: option?.max || null,
    });
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...value, notes });
  }

  return (
    <Group className={className} label={props.label}>
      <Location
        {...props}
        label="Location"
        className="trade-location-location"
        value={props.value}
        onChange={onChange}
        data={data}
        dropdownStatus={{ ...dropdownStatus }}
        display={TRADE_DATA_DEF.location.display}
        onFilter={onFilter}
        onBlur={onBlur}
        dataTest={`${props.dataTest}/trade-location`}
      />
      {!props.hideSafes && (
        <Row>
          <SelectField
            {...props}
            label="Berth(s)"
            desc="Safe Berth(s)"
            className="trade-location-options-berth"
            filterKey="label"
            data={OPTIONS}
            display={display}
            value={OPTION_BY_MIN_MAX[`${value?.safeBerthsMin}${value?.safeBerthsMax}`]}
            onChange={onOptionsChange.bind(null, "safeBerthsMin", "safeBerthsMax")}
            status={status?.children?.safeBerthsMin || status?.children?.safeBerthsMax}
            dataTest={`${props.dataTest}/trade-location-berth`}
            key="berth"
            nonFilterSelect
          />
          <SelectField
            {...props}
            label="Port(s)"
            desc="Safe Port(s)"
            className="trade-location-options-port"
            filterKey="label"
            data={OPTIONS}
            display={display}
            value={OPTION_BY_MIN_MAX[`${value?.safePortsMin}${value?.safePortsMax}`]}
            onChange={onOptionsChange.bind(null, "safePortsMin", "safePortsMax")}
            status={status?.children?.safePortsMin || status?.children?.safePortsMax}
            dataTest={`${props.dataTest}/trade-location-port`}
            key="port"
            nonFilterSelect
          />
          <SelectField
            {...props}
            label="Anchorage(s)"
            desc="Safe Anchorage(s)"
            className="trade-location-options-anchorage"
            filterKey="label"
            data={OPTIONS}
            display={display}
            value={OPTION_BY_MIN_MAX[`${value?.safeAnchoragesMin}${value?.safeAnchoragesMax}`]}
            onChange={onOptionsChange.bind(null, "safeAnchoragesMin", "safeAnchoragesMax")}
            status={status?.children?.safeAnchoragesMin || status?.children?.safeAnchoragesMax}
            key="anchorage"
            dataTest={`${props.dataTest}/trade-location-anchorage`}
            nonFilterSelect
          />
        </Row>
      )}
      <TextareaField
        {...props}
        label="Notes"
        desc="Location notes"
        className="trade-location-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-location-notes`}
      />
    </Group>
  );
}

const display = (option: Option) => option.label;

const OPTIONS = [
  {
    label: "1",
    min: 1,
    max: 1,
  },
  {
    label: "1-2",
    min: 1,
    max: 2,
  },
  {
    label: "1-3",
    min: 1,
    max: 3,
  },
  {
    label: "1-4",
    min: 1,
    max: 4,
  },
  {
    label: "1-5",
    min: 1,
    max: 5,
  },
  {
    label: "2",
    min: 2,
    max: 2,
  },
  {
    label: "2-3",
    min: 2,
    max: 3,
  },
  {
    label: "2-4",
    min: 2,
    max: 4,
  },
  {
    label: "2-5",
    min: 2,
    max: 5,
  },
  {
    label: "3",
    min: 3,
    max: 3,
  },
  {
    label: "3-4",
    min: 3,
    max: 4,
  },
  {
    label: "3-5",
    min: 3,
    max: 5,
  },
  {
    label: "4",
    min: 4,
    max: 4,
  },
  {
    label: "4-5",
    min: 4,
    max: 5,
  },
  {
    label: "5",
    min: 5,
    max: 5,
  },
];

const OPTION_BY_MIN_MAX = OPTIONS.reduce((acc, el) => {
  acc[`${el.min}${el.max}`] = el;

  return acc;
}, {} as LabelByMinMax);

interface Props extends Omit<LocationProps, "value" | "onChange"> {
  value?: TradeLocationType | null;
  onChange?: (value: TradeLocationType | null) => void;
  hideSafes?: boolean;
}

interface LabelByMinMax {
  [minmax: string]: Option;
}

interface Option {
  label: string;
  min: number;
  max: number;
}

export type TradeLocationProps = Props;
