import React, { memo } from "react";
import { cn } from "@/util";
import "./Icon.scss";

function Icon(props: Props) {
  let { icon } = props;

  icon = typeof icon === "string" ? { name: icon } : icon;

  props = { ...props, ...icon };

  const { className, name } = props;

  if (!name) return null;

  props = {
    ...props,
    className: cn(className, "icon", "has-icon", `icon--${name}`),
  };

  return <span {...props} />;
}

const Memo = memo(Icon);

export { Memo as Icon };

interface Props {
  icon?: string | Omit<Props, "icon">;
  name?: string;
  className?: string;
  onClick?: () => void;
}

export type IconProps = Props;
