import React from "react";
import classNames from "classnames";
import { TradeUnitDuration as TradeUnitDurationType } from "@/api";
import { StatusMessages, NumberField, SelectField, SelectFieldProps, TextareaField } from "@/components";
import { Group } from "../../group";
import { Row } from "../../row";
import "./Trade.unit.duration.scss";

export function TradeUnitDuration(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-unit-duration");

  const { className, value, status, ...rest } = props;

  const { min, max, unit } = value || {};

  function onChange(name: keyof TradeUnitDurationType, inputValue: number | null) {
    const next = {
      ...value,
      [name]: inputValue,
    };

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...value, notes });
  }

  return (
    <Group className={className} label={props.label}>
      <Row>
        <NumberField
          {...rest}
          label="Min"
          desc="Duration min"
          className="trade-unit-duration-min"
          placeholder="e.g. 3"
          status={status?.children?.min}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}/trade-unit-duration-min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Duration max"
          placeholder="e.g. 4"
          className="trade-unit-duration-max"
          status={status?.children?.max}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}/trade-unit-duration-max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Unit"
          desc="Duration unit"
          className="trade-unit-duration-unit"
          data={OPTIONS}
          status={status?.children?.unit}
          value={unit}
          onChange={onChange.bind(null, "unit")}
          dataTest={`${props.dataTest}/trade-unit-duration-unit`}
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <TextareaField
        {...props}
        label="Notes"
        desc="Duration notes"
        className="trade-duration-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-duration-notes`}
      />
      <StatusMessages status={status} />
    </Group>
  );
}

const OPTIONS = ["Days", "Months"];

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: TradeUnitDurationType;
  onChange?: (value: TradeUnitDurationType) => void;
}

export type FocusEvent = React.FocusEvent<HTMLInputElement>;
