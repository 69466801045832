import React from "react";
import { auth } from "@/model";
import { history } from "@/history";
import "./Unauthorised.scss";

export function Unauthorised(): JSX.Element {
  if (auth.authorised) history.push("/");

  return (
    <div className="unauthorised">
      You do not have the required privileges to access this page
      <p>
        If you have any questions, please contact <a href="mailto:support@sea.live">support@sea.live</a>
      </p>
    </div>
  );
}
