import React from "react";
import classNames from "classnames";
import { TradeVesselSize as TradeVesselSizeType } from "@/api";
import { TextareaField } from "@/components";
import { Group } from "../../group";
import { VesselSize, Props as VesselSizeProps, VesselSizeType } from "../vessel.size";
import "./Trade.vessel.size.scss";

export function TradeVesselSize(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-vessel-size");

  const { value, status } = props;

  function onChange(vesselSize: VesselSizeType) {
    const tradeVesselSize = {
      ...value,
      vesselSizeFull: vesselSize?.name,
      vesselSizeAbbreviation: vesselSize?.abbreviation,
      sizeFrom: vesselSize?.from,
      sizeTo: vesselSize?.to,
    };

    props.onChange?.(tradeVesselSize);
  }

  const vesselSize = props.value
    ? {
        name: props.value?.vesselSizeFull,
        abbreviation: props.value?.vesselSizeAbbreviation,
        from: props.value?.sizeFrom,
        to: props.value?.sizeTo,
      }
    : props.value;

  function onNotesChange(notes: string) {
    props.onChange?.({ ...props.value, notes });
  }

  return (
    <Group className={props.className} label={props.label}>
      <VesselSize
        {...props}
        label="Size"
        className="trade-vessel-size-value"
        value={vesselSize}
        onChange={onChange}
        dataTest={`${props.dataTest}/trade-vessel-size-value`}
      />
      <TextareaField
        {...props}
        label="Notes"
        desc="Vessel size notes"
        className="trade-vessel-size-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-vessel-size-notes`}
      />
    </Group>
  );
}

interface Props extends Omit<VesselSizeProps, "value" | "onChange"> {
  value?: TradeVesselSizeType | null;
  onChange?: (value: TradeVesselSizeType | null) => void;
}
