import React, { useRef } from "react";
import { Button, ButtonProps } from "@/components";
import { globalDropdown } from "@/model";
import { Entity, Config } from "../Entity.layout";
import { useEntityActions } from "../entity.actions";
import "./Entity.context.menu.scss";

export function EntityContextMenu(props: Props): JSX.Element | null {
  const { entity, hidden } = props;
  const { dataTest } = props.config;
  const actions = useEntityActions(entity, props.config);
  const ref = useRef<HTMLButtonElement>(null);

  if (!entity || hidden) return null;

  const { data } = entity;

  if (!data?.id || data.id.startsWith("$")) return null;

  const ACTIONS = [
    { label: "Delete", icon: "trash", action: actions?.deleteData },
    { label: "Clone", icon: "content-copy", action: actions?.cloneData },
  ];

  async function onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!ref.current) return;

    const res = await globalDropdown.show({
      target: ref.current,
      data: ACTIONS,
      className: "entity-context-menu",
      display,
      getIcon,
      dataTest,
    });

    if (res) res.action();
  }

  return (
    <Button
      onClick={onClick}
      className="entity-context-menu"
      icon="more-vert"
      dom={ref}
      tabIndex={-1}
      data-test={`${dataTest}-entity-context-menu-button`}
    />
  );
}

const display = (item: Action) => item.label;
const getIcon = (item: Action) => item.icon;

interface Props extends ButtonProps {
  entity: Entity;
  config: Config;
  hidden?: boolean;
}

interface Action {
  label: string;
  icon: string;
  action: (() => Promise<void>) | undefined;
}

type MouseEvent = React.MouseEvent<HTMLButtonElement>;
