import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { TRADE_DATA_DEF } from "@/constants";
import { cargoTypes } from "@/model";
import { TradeCargoType as TradeCargoTypeType } from "@/api";
import { TextareaField, SelectField, SelectFieldProps } from "@/components";
import { Group } from "../../group";
import "./Trade.cargo.type.scss";

function TradeCargoType(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "trade-cargo-type");

  const { value, status } = props;

  function onValueChange(cargoType: TradeCargoTypeType) {
    props.onChange?.({
      ...props.value,
      arcId: cargoType?.arcId,
      name: cargoType?.name,
    });
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...props.value, notes });
  }

  return (
    <Group className={props.className} label="Cargo Type">
      <SelectField
        {...props}
        label="Type"
        className="trade-cargo-type-value"
        data={cargoTypes.data}
        onChange={onValueChange}
        display={TRADE_DATA_DEF.cargoType.display}
        status={status?.children?.arcId || status?.children?.name}
        dataTest={`${props.dataTest}/trade-cargo-type-value`}
        filterKey="name"
        noDataStatus={noDataStatus}
      />
      <TextareaField
        {...props}
        label="Notes"
        desc="Cargo type notes"
        className="trade-cargo-type-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-cargo-type-notes`}
      />
    </Group>
  );
}

const noDataStatus = { message: "No matching cargo types" };

const Observer = observer(TradeCargoType);

export { Observer as TradeCargoType };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: TradeCargoTypeType | null;
  onChange?: (value: TradeCargoTypeType | null) => void;
}
