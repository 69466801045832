import React from "react";
import { observer } from "mobx-react";
import { router, Route as RouteModel } from "@/model";
import { NavLink } from "@/components";
import "./Sidenav.scss";

function Sidenav() {
  const { active } = router;
  const { sidenav } = active;

  return (
    <nav id="sidenav" data-test="sidenav">
      {sidenav.map(NavLinkHOC)}
    </nav>
  );
}

function NavLinkHOC(route: RouteModel) {
  const { absPath, icon, desc } = route;

  return <NavLink to={absPath} icon={icon} key={absPath} title={desc} />;
}

const Observer = observer(Sidenav);

export { Observer as Sidenav };
