import { action, observable, decorate, computed } from "mobx";
import { JSONSchema7 } from "json-schema";
import { TradeTermset, trade } from "@/api";
import { dialog } from "@/model";
import { termsets } from "../termsets";
import * as _ from "lodash";
import { Response } from "@/axios";

class Termset {
  entityKey = null as string | null;
  status = {} as Status;
  tempStatus = {} as Status;
  data = null as TradeTermset | null;

  get deleted(): boolean {
    return !!this.data?.isDeleted;
  }

  get schema(): JSONSchema7 {
    return schema;
  }

  publish = async (): Promise<Response<TradeTermset> | undefined> => {
    const clone = { ...this.data };

    clone.isPublished = true;

    const res = await this.postData(clone);

    if (res?.ok && res.data) this.data = res.data;

    return res;
  };

  unpublish = async (): Promise<Response<TradeTermset> | undefined> => {
    const clone = { ...this.data };

    clone.isPublished = false;

    const res = await this.postData(clone);

    if (res?.ok && res.data) this.data = res.data;

    return res;
  };

  getSchema = async (): Promise<Response<JSONSchema7> | undefined> => {
    if (schema) return;

    const res = await trade.getTermsetSchema();

    return res;
  };

  getData = async (): Promise<Response<TradeTermset> | undefined> => {
    if (!this.data?.id) return;

    const res = await trade.getTermset(this.data.id, this.data?.companyId);

    if (res.ok && res.data) this.data = res.data;

    return res;
  };

  postData = async (data: TradeTermset): Promise<Response<TradeTermset> | undefined> => {
    const validateRes = await trade.validateTermset(data);
    if (!validateRes?.ok) {
      dialog.show({
        status: {
          type: "error",
          title: "Validation Failed",
          message: `Termset did not pass validation`,
        },
        dataTest: `validate-error`,
      });
      return;
    }

    const res = await trade.putTermset(data, this.data?.companyId);

    if (res.ok && res?.data) this.data = res.data;

    return res;
  };

  deleteData = async (): Promise<Response<undefined> | undefined> => {
    if (!this.data?.id) return;

    const res = await trade.deleteTermset(this.data?.id, this.data?.companyId);

    if (res.ok) {
      this.data.isDeleted = true;

      setTimeout(termsets.deleteEntity.bind(null, this.data.id), 500);
    }

    return res;
  };

  cloneData = async (): Promise<Response<TradeTermset> | undefined> => {
    if (!this.data?.id) return;

    const dataRes = await this.getData();

    if (!dataRes?.ok) return dataRes;

    // eslint-disable-next-line import/namespace
    const clone = _.omit(this.data, "id");

    clone.name = `Copy of ${clone.name}`;
    clone.updated = undefined;
    clone.updatedBy = undefined;

    if (!clone.isPublished) {
      clone.published = undefined;
      clone.publishedBy = undefined;
    }

    const putRes = await trade.putTermset(clone, this.data?.companyId);

    return putRes;
  };
}

const schema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://tradeadmin.sea.live/ordertemplate.schema.json",
  type: "object",
  properties: {
    name: {
      type: ["string", "null"],
    },
    companyId: {
      type: ["string", "null"],
    },
  },
  required: ["name", "companyId"],
} as JSONSchema7;

decorate(Termset, {
  data: observable,
  status: observable,
  tempStatus: observable,
  schema: computed,
  deleted: computed,
  getData: action,
  publish: action,
  unpublish: action,
  postData: action,
  deleteData: action,
  cloneData: action,
});

export { Termset };
