import { observable, decorate, computed } from "mobx";
import { config, ENV, PRODUCTION } from "@/config";
import { storage as storageUtil, jwtDecode, wait } from "@/util";
import { history } from "@/history";
import { trade, GetTokenRes, central, CentralUser, TradeUser as APITradeUser } from "@/api";
import { dialog } from "../dialog";

export class Auth {
  get authenticated(): boolean {
    return this.trade.authenticated;
  }

  get authorised(): boolean {
    return !!this.trade.user?.isCtradeAdministrator;
  }

  setup(): Promise<unknown> {
    this.authenticate();

    return this.promise;
  }

  authenticate = async (): Promise<void> => {
    this.env.check();
    this.version.check();

    await this.central.login();
    this.trade.login();
  };

  reset = async (showDialog = false): Promise<void> => {
    await wait(1);

    if (showDialog) await dialog.show(sessionExpiredDialog);

    this.trade.logout();
    this.central.logout();
  };

  central = {
    user: null as CentralUser | null,
    token: null as Token,
    authenticated: false,

    login: async (): Promise<void> => {
      let { token, user } = storage.get(CENTRAL_STORAGE_KEY, {});
      const expired = user && isExpired(user?.TokenExpiryUtc);
      const invalid = !token || expired;

      if (invalid) {
        const newToken = new URLSearchParams(search).get("token");

        if (!newToken) return this.reset();

        if (!config.mockedAuth) {
          const res = await central.getUser(newToken);

          if (res?.ok === false) return this.reset();

          user = res.data;
        }

        token = newToken;
      }

      storage.set(CENTRAL_STORAGE_KEY, { token, user });

      history.push(window.location.pathname);

      this.central.token = token;
      this.central.user = user;
      this.central.authenticated = true;

      this.centralResolve();
    },

    logout: (): void => {
      this.central.clearStorage();

      this.central.redirect("/logout");
    },

    redirect: (to = ""): void => {
      this.central.clearStorage();

      window.location.href = `${config.centralAuthURL}${to}?redirect=${window.location}`;
    },

    clearStorage: (): void => {
      storage.remove(CENTRAL_STORAGE_KEY);
    },

    promise: new Promise((resolve) => {
      this.centralResolve = resolve;
    }),
  };

  trade = {
    token: null as Token,
    user: null as TradeUser | null,
    response: null as Unpack<GetTokenRes> | null | undefined,
    authenticated: false,

    login: async (): Promise<void> => {
      if (!this.central.token) return;

      let token = storage.get(TRADE_STORAGE_KEY);
      let response;

      let user = jwtDecode(token, {}) as TradeUser;

      if (!token || isExpired(user.exp)) {
        const res = await trade.getToken(this.central.token);
        const { data, ok } = res;

        response = res;

        if (ok) {
          token = data;
          user = jwtDecode(data, {});
        }
      }

      // sequence matters
      this.trade.user = user;
      this.trade.token = token;
      this.trade.response = response;
      this.trade.authenticated = true;

      storage.set(TRADE_STORAGE_KEY, token);

      this.tradeResolve();
    },

    logout: (): void => {
      this.trade.clearStorage();
    },

    clearStorage: (): void => {
      storage.remove(TRADE_STORAGE_KEY);
    },

    promise: new Promise((resolve) => {
      this.tradeResolve = resolve;
    }),
  };

  promise = Promise.all([this.trade.promise]);

  env = {
    check: (): void => {
      if (PRODUCTION) return;

      const storedEnv = storage.get(AUTH_ENV_KEY);

      storage.set(AUTH_ENV_KEY, ENV);

      if (storedEnv && storedEnv !== ENV) this.reset();
    },
  };

  version = {
    check: (): void => {
      const storedVersion = storage.get(VERSION_KEY);

      storage.set(VERSION_KEY, VERSION);

      if (storedVersion !== VERSION) this.reset();
    },
  };
}

decorate(Auth, {
  authenticated: computed,
  authorised: computed,
  trade: observable,
  central: observable,
});

function isExpired(date: any) {
  if (typeof date !== "number" && typeof date !== "string") return true;

  return new Date() > new Date(date);
}

const search = window.location.search;
const storage = storageUtil.local;
const sessionExpiredDialog = {
  status: {
    type: "info",
    title: "Session Expired",
    message: "Your session has expired. You will have to sign in again.",
  } as Status,
  dataTest: "session-expired",
};

const VERSION = "Xxx420xxX";
const VERSION_KEY = "auth-version";
const AUTH_ENV_KEY = "auth-env";
const CENTRAL_STORAGE_KEY = "central-token";
const TRADE_STORAGE_KEY = "trade-token";

export const auth = new Auth();

export interface Auth {
  tradeResolve: () => void;
  centralResolve: () => void;
}

type Token = null | string;

type TradeUser = APITradeUser | EmptyObject;
