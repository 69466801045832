import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { termsetLayouts } from "@/model";
import { TradeTermsetLayout } from "@/api";
import { SelectField, SelectFieldProps } from "@/components";
import "./Trade.termset.layout.key.scss";

function TradeTermsetLayoutKey(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "trade-termset-layout-key");

  const { dataById } = termsetLayouts;

  const key = props.id !== undefined ? props.id : props.observableProps?.id;
  const data = key ? dataById[key].data?.keys : undefined;

  return (
    <SelectField
      {...props}
      data={data}
      dataTest={`${props.dataTest}/trade-termset-layout-key`}
      noDataStatus={{
        message: key ? "No matching proforma key" : "Select a proforma layout",
      }}
    />
  );
}

const Observer = observer(TradeTermsetLayoutKey);

export { Observer as TradeTermsetLayoutKey };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: string) => void;
  id?: TradeTermsetLayout["id"];
  observableProps?: Omit<Props, "observableProps">;
}
