import React from "react";
import { observer } from "mobx-react";
import { FixedSizeList } from "react-window";
import Autosize, { Size } from "react-virtualized-auto-sizer";
import { LoadingOverlay, Pagination } from "@/components";
import { PAGINATION_SECTION_HEIGHT, RECORDS_PER_PAGE } from "@/constants";
import { Config } from "../../Entity.layout";
import { Header } from "./header";
import { Entity } from "./entity";
import "./Body.scss";

function Body(props: Props) {
  const { parent } = props.config;
  const { toDefaultPage, savedPageNumber, onPageChange } = props;

  if (!parent) return null;

  const { entities, searchStatus } = parent;
  const { message, loading } = searchStatus;
  const entity = props.config.view.entity.name;
  const isTermsetsTab = entity === "Termset";
  const totalRecords = isTermsetsTab ? parent.totalRecords : undefined;
  const isPaginationNeeded = !!(isTermsetsTab && totalRecords && totalRecords > RECORDS_PER_PAGE);

  const Virtualize = (size: Size) => {
    return (
      <FixedSizeList
        itemData={props}
        itemCount={entities?.length}
        itemSize={36}
        itemKey={itemKey}
        {...size}
        height={isPaginationNeeded ? size.height - PAGINATION_SECTION_HEIGHT : size.height}
      >
        {Entity}
      </FixedSizeList>
    );
  };

  return (
    <div className="entity-list-body" style={{ position: "relative" }}>
      <Header visible={!message && !loading} config={props.config} />
      <Autosize>{Virtualize}</Autosize>
      {isPaginationNeeded && (
        <div className="pagination-wrapper">
          <Pagination
            totalCount={totalRecords || 0}
            shownItemsCount={entities.length}
            toDefaultPage={toDefaultPage}
            savedPageNumber={savedPageNumber}
            onPageChange={onPageChange}
          />
        </div>
      )}
      <LoadingOverlay status={searchStatus} />
    </div>
  );
}

function itemKey(i: number, props: Props) {
  const data = props.config.parent?.entities?.[i].data;

  return data?.id + data?.companyId;
}

const Observer = observer(Body);

export { Observer as Body };

export interface Props {
  config: Config;
  toDefaultPage: boolean;
  savedPageNumber: number;
  onPageChange: (newPageNumber: number) => void;
}
