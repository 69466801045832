import React from "react";
import { EDITOR_TYPE } from "@/components";
import { Row } from "./row";
import { Column } from "./column";
import { Field } from "./field";
import { Termset } from "./termset";

function ElementHOC(element, i) {
  let Comp;

  if (element.visible && !element.visible()) return null;

  if (EDITOR_TYPE[element.type]) Comp = Field;
  if (element.type === "row") Comp = Row;
  if (element.type === "column") Comp = Column;
  if (element.type === "termset") Comp = Termset;

  if (!Comp) {
    console.error(`Unrecognized form element type: ${element.type}`, element);

    return null;
  }

  return <Comp element={element} key={i} />;
}

export { ElementHOC };
