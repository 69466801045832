export const CARGO_TYPES: CargoType[] = [
  {
    cargoTypeId: 1,
    commodity: "Agribulks",
  },
  {
    cargoTypeId: 2,
    commodity: "Chemicals",
  },
  {
    cargoTypeId: 3,
    commodity: "Containers TEU/FEU",
  },
  {
    cargoTypeId: 4,
    commodity: "Fertilisers",
  },
  {
    cargoTypeId: 5,
    commodity: "Forest Products",
  },
  {
    cargoTypeId: 6,
    commodity: "Fruit & Vegetables",
  },
  {
    cargoTypeId: 7,
    commodity: "Gas LPG/LNG",
  },
  {
    cargoTypeId: 8,
    commodity: "Manufactured Goods",
  },
  {
    cargoTypeId: 9,
    commodity: "Meat/Seafood/Dairy",
  },
  {
    cargoTypeId: 10,
    commodity: "Metal Products",
  },
  {
    cargoTypeId: 11,
    commodity: "Mineral Oil",
  },
  {
    cargoTypeId: 12,
    commodity: "Minerals, Ores & Earths",
  },
  {
    cargoTypeId: 13,
    commodity: "Oil Products",
  },
  {
    cargoTypeId: 14,
    commodity: "Vegetable Oils",
  },
  {
    cargoTypeId: 15,
    commodity: "Barley",
  },
  {
    cargoTypeId: 16,
    commodity: "Beans",
  },
  {
    cargoTypeId: 17,
    commodity: "Beet Pulp",
  },
  {
    cargoTypeId: 18,
    commodity: "Broad Beans",
  },
  {
    cargoTypeId: 19,
    commodity: "Buckwheat",
  },
  {
    cargoTypeId: 20,
    commodity: "Canola",
  },
  {
    cargoTypeId: 21,
    commodity: "Cassava",
  },
  {
    cargoTypeId: 22,
    commodity: "Copra",
  },
  {
    cargoTypeId: 23,
    commodity: "Copra (dry)",
  },
  {
    cargoTypeId: 24,
    commodity: "Copra Meal",
  },
  {
    cargoTypeId: 25,
    commodity: "Corn",
  },
  {
    cargoTypeId: 26,
    commodity: "Cotton Seed",
  },
  {
    cargoTypeId: 27,
    commodity: "Cotton Seed Meal",
  },
  {
    cargoTypeId: 28,
    commodity: "Gluten Pellets",
  },
  {
    cargoTypeId: 29,
    commodity: "Grain",
  },
  {
    cargoTypeId: 30,
    commodity: "Haricot Beans",
  },
  {
    cargoTypeId: 31,
    commodity: "Horse Beans",
  },
  {
    cargoTypeId: 32,
    commodity: "Linseed",
  },
  {
    cargoTypeId: 33,
    commodity: "Linseed Meal",
  },
  {
    cargoTypeId: 34,
    commodity: "Logs",
  },
  {
    cargoTypeId: 35,
    commodity: "Maize",
  },
  {
    cargoTypeId: 36,
    commodity: "Niger Seed Meal",
  },
  {
    cargoTypeId: 37,
    commodity: "Oats",
  },
  {
    cargoTypeId: 38,
    commodity: "Oil Cake",
  },
  {
    cargoTypeId: 39,
    commodity: "Oilseed",
  },
  {
    cargoTypeId: 40,
    commodity: "Palm Kernel Meal",
  },
  {
    cargoTypeId: 41,
    commodity: "Peanut Meal",
  },
  {
    cargoTypeId: 42,
    commodity: "Peanuts",
  },
  {
    cargoTypeId: 43,
    commodity: "Rice",
  },
  {
    cargoTypeId: 44,
    commodity: "Rice (bagged)",
  },
  {
    cargoTypeId: 45,
    commodity: "Rice (unmilled)",
  },
  {
    cargoTypeId: 46,
    commodity: "Rice Bran",
  },
  {
    cargoTypeId: 47,
    commodity: "Rice Bran Meal",
  },
  {
    cargoTypeId: 48,
    commodity: "Rye",
  },
  {
    cargoTypeId: 49,
    commodity: "Sawdust",
  },
  {
    cargoTypeId: 50,
    commodity: "Small Red Beans",
  },
  {
    cargoTypeId: 51,
    commodity: "Sorghum",
  },
  {
    cargoTypeId: 52,
    commodity: "Soya Bean Meal",
  },
  {
    cargoTypeId: 53,
    commodity: "Soya Beans",
  },
  {
    cargoTypeId: 54,
    commodity: "Sugar",
  },
  {
    cargoTypeId: 55,
    commodity: "Sugar (bagged)",
  },
  {
    cargoTypeId: 56,
    commodity: "Sugar (raw)",
  },
  {
    cargoTypeId: 57,
    commodity: "Sugar (white)",
  },
  {
    cargoTypeId: 58,
    commodity: "Sunflower Seed Meal",
  },
  {
    cargoTypeId: 59,
    commodity: "Sunflower Seeds",
  },
  {
    cargoTypeId: 60,
    commodity: "Sweet Potatoes",
  },
  {
    cargoTypeId: 61,
    commodity: "Wheat",
  },
  {
    cargoTypeId: 62,
    commodity: "Woodchips",
  },
  {
    cargoTypeId: 63,
    commodity: "Woodpulp Pellets",
  },
  {
    cargoTypeId: 64,
    commodity: "Soya Bean Oil",
  },
  {
    cargoTypeId: 65,
    commodity: "Acorn Kernels",
  },
  {
    cargoTypeId: 66,
    commodity: "Alumina",
  },
  {
    cargoTypeId: 67,
    commodity: "Alumina Silica",
  },
  {
    cargoTypeId: 69,
    commodity: "Aluminium Ingots",
  },
  {
    cargoTypeId: 70,
    commodity: "Bauxite",
  },
  {
    cargoTypeId: 71,
    commodity: "Borax",
  },
  {
    cargoTypeId: 72,
    commodity: "Brown Manganese",
  },
  {
    cargoTypeId: 73,
    commodity: "Chromium Ore",
  },
  {
    cargoTypeId: 74,
    commodity: "Iron Ore Pellets",
  },
  {
    cargoTypeId: 75,
    commodity: "Iron Oxides",
  },
  {
    cargoTypeId: 76,
    commodity: "Iron Spongs",
  },
  {
    cargoTypeId: 77,
    commodity: "Lead Nitrate",
  },
  {
    cargoTypeId: 79,
    commodity: "Manganese Ore",
  },
  {
    cargoTypeId: 80,
    commodity: "Salt",
  },
  {
    cargoTypeId: 81,
    commodity: "Talc",
  },
  {
    cargoTypeId: 82,
    commodity: "Coal",
  },
  {
    cargoTypeId: 83,
    commodity: "Coal (Briquettes)",
  },
  {
    cargoTypeId: 84,
    commodity: "Coal (Slurry)",
  },
  {
    cargoTypeId: 85,
    commodity: "Coal (Tar)",
  },
  {
    cargoTypeId: 86,
    commodity: "Coal (America)",
  },
  {
    cargoTypeId: 87,
    commodity: "Coal (Wales)",
  },
  {
    cargoTypeId: 88,
    commodity: "Coal (Scotland)",
  },
  {
    cargoTypeId: 89,
    commodity: "Coal (Anthracite)",
  },
  {
    cargoTypeId: 90,
    commodity: "Coal (Australia)",
  },
  {
    cargoTypeId: 91,
    commodity: "Coal (Japan)",
  },
  {
    cargoTypeId: 92,
    commodity: "Coal (Poland)",
  },
  {
    cargoTypeId: 93,
    commodity: "Coal (Coking)",
  },
  {
    cargoTypeId: 94,
    commodity: "Coal (Bituminous)",
  },
  {
    cargoTypeId: 95,
    commodity: "Acetic Acid CP",
  },
  {
    cargoTypeId: 96,
    commodity: "Acetic Acid LP",
  },
  {
    cargoTypeId: 97,
    commodity: "Acetic Acid Food Grade",
  },
  {
    cargoTypeId: 98,
    commodity: "Acetic Anhydride",
  },
  {
    cargoTypeId: 99,
    commodity: "Caradol SP44-10V",
  },
  {
    cargoTypeId: 100,
    commodity: "Caradol SC56-15",
  },
  {
    cargoTypeId: 101,
    commodity: "Caradol SC48-08",
  },
  {
    cargoTypeId: 102,
    commodity: "Caradol SP30-45",
  },
  {
    cargoTypeId: 103,
    commodity: "MEG FG",
  },
  {
    cargoTypeId: 104,
    commodity: "Xylene",
  },
  {
    cargoTypeId: 105,
    commodity: "Toluene",
  },
  {
    cargoTypeId: 106,
    commodity: "Ethyl Benzene",
  },
  {
    cargoTypeId: 107,
    commodity: "Benzene",
  },
  {
    cargoTypeId: 108,
    commodity: "Pyrolisis Gasoline ",
  },
  {
    cargoTypeId: 109,
    commodity: "Hexane",
  },
  {
    cargoTypeId: 110,
    commodity: "Hexene",
  },
  {
    cargoTypeId: 111,
    commodity: "Methyl Proxitol Acetate",
  },
  {
    cargoTypeId: 112,
    commodity: "Methyl Proxitol ",
  },
  {
    cargoTypeId: 113,
    commodity: "Methyl Di Proxitol",
  },
  {
    cargoTypeId: 114,
    commodity: "Iso Propyl Alcohol",
  },
  {
    cargoTypeId: 115,
    commodity: "Di Methyl Linears",
  },
  {
    cargoTypeId: 116,
    commodity: "Di Methyl Cyclix",
  },
  {
    cargoTypeId: 117,
    commodity: "Phenol",
  },
  {
    cargoTypeId: 118,
    commodity: "TO BE RENAMED",
  },
  {
    cargoTypeId: 119,
    commodity: "Shellsol A150",
  },
  {
    cargoTypeId: 120,
    commodity: "Shellsol A100",
  },
  {
    cargoTypeId: 121,
    commodity: "Shellsol D100",
  },
  {
    cargoTypeId: 122,
    commodity: "Glysantin",
  },
  {
    cargoTypeId: 123,
    commodity: "MVIN 40EQ",
  },
  {
    cargoTypeId: 124,
    commodity: "SN150",
  },
  {
    cargoTypeId: 125,
    commodity: "SN500",
  },
  {
    cargoTypeId: 126,
    commodity: "BS150",
  },
  {
    cargoTypeId: 127,
    commodity: "DINP",
  },
  {
    cargoTypeId: 128,
    commodity: "Methanol",
  },
  {
    cargoTypeId: 129,
    commodity: "Escaid 110",
  },
  {
    cargoTypeId: 130,
    commodity: "GTL BO4",
  },
  {
    cargoTypeId: 131,
    commodity: "GTL BO8",
  },
  {
    cargoTypeId: 132,
    commodity: "Neodene 6",
  },
  {
    cargoTypeId: 133,
    commodity: "Neodene 8",
  },
  {
    cargoTypeId: 134,
    commodity: "Neodene 10",
  },
  {
    cargoTypeId: 135,
    commodity: "Neodene 103",
  },
  {
    cargoTypeId: 136,
    commodity: "Neodene 1112IO",
  },
  {
    cargoTypeId: 137,
    commodity: "Neodene 12",
  },
  {
    cargoTypeId: 138,
    commodity: "Neodene 1214",
  },
  {
    cargoTypeId: 139,
    commodity: "Neodene 1314IO",
  },
  {
    cargoTypeId: 140,
    commodity: "Neodene 14",
  },
  {
    cargoTypeId: 141,
    commodity: "Neodene 1416",
  },
  {
    cargoTypeId: 142,
    commodity: "Neodene 141618",
  },
  {
    cargoTypeId: 143,
    commodity: "Neodene 2022",
  },
  {
    cargoTypeId: 144,
    commodity: "Neodol 23",
  },
  {
    cargoTypeId: 145,
    commodity: "Neodol 25",
  },
  {
    cargoTypeId: 146,
    commodity: "Neodol 45",
  },
  {
    cargoTypeId: 147,
    commodity: "Neodol 911",
  },
  {
    cargoTypeId: 148,
    commodity: "Linevol 911",
  },
  {
    cargoTypeId: 149,
    commodity: "Linevol 711",
  },
  {
    cargoTypeId: 150,
    commodity: "Linevol 79",
  },
  {
    cargoTypeId: 151,
    commodity: "Linevol 9 ",
  },
  {
    cargoTypeId: 152,
    commodity: "IP Feed",
  },
  {
    cargoTypeId: 153,
    commodity: "Styrene Monomer",
  },
  {
    cargoTypeId: 154,
    commodity: "N-Pentane",
  },
  {
    cargoTypeId: 155,
    commodity: "Mixed Xylene",
  },
  {
    cargoTypeId: 156,
    commodity: "Waxy Raffinate",
  },
  {
    cargoTypeId: 157,
    commodity: "Vinyl Acetate Monomer",
  },
  {
    cargoTypeId: 158,
    commodity: "Ethyl Acetate",
  },
  {
    cargoTypeId: 159,
    commodity: "Heptane",
  },
  {
    cargoTypeId: 160,
    commodity: "Heptene",
  },
  {
    cargoTypeId: 161,
    commodity: "MIBK",
  },
  {
    cargoTypeId: 162,
    commodity: "Shellsol D60",
  },
  {
    cargoTypeId: 163,
    commodity: "DEG",
  },
  {
    cargoTypeId: 164,
    commodity: "Octene",
  },
  {
    cargoTypeId: 165,
    commodity: "MMM",
  },
  {
    cargoTypeId: 166,
    commodity: "Naphtha",
  },
  {
    cargoTypeId: 167,
    commodity: "C5 Condensate",
  },
  {
    cargoTypeId: 168,
    commodity: "Jet",
  },
  {
    cargoTypeId: 169,
    commodity: "Reformate",
  },
  {
    cargoTypeId: 170,
    commodity: "IsoPentane",
  },
  {
    cargoTypeId: 171,
    commodity: "Mogas 95",
  },
  {
    cargoTypeId: 172,
    commodity: "Mogas 98",
  },
  {
    cargoTypeId: 173,
    commodity: "Diesel",
  },
  {
    cargoTypeId: 174,
    commodity: "Core 150",
  },
  {
    cargoTypeId: 175,
    commodity: "Core 600",
  },
  {
    cargoTypeId: 176,
    commodity: "Core 2500",
  },
  {
    cargoTypeId: 177,
    commodity: "Nexbtl",
  },
  {
    cargoTypeId: 178,
    commodity: "Used Cooking Oil",
  },
  {
    cargoTypeId: 179,
    commodity: "Sulphuric Acid",
  },
  {
    cargoTypeId: 180,
    commodity: "Phosphoric Acid",
  },
  {
    cargoTypeId: 181,
    commodity: "Asphalt",
  },
  {
    cargoTypeId: 182,
    commodity: "Bitumen",
  },
  {
    cargoTypeId: 183,
    commodity: "Acrylonitrile",
  },
  {
    cargoTypeId: 184,
    commodity: "Crude benzene",
  },
  {
    cargoTypeId: 185,
    commodity: "Cyclohexane",
  },
  {
    cargoTypeId: 186,
    commodity: "Crude Coal Tar",
  },
  {
    cargoTypeId: 187,
    commodity: "Coal Tar",
  },
  {
    cargoTypeId: 188,
    commodity: "MVIN 45EQ",
  },
  {
    cargoTypeId: 189,
    commodity: "XHVI 4",
  },
  {
    cargoTypeId: 190,
    commodity: "XHVI 8",
  },
  {
    cargoTypeId: 191,
    commodity: "Hygold 60",
  },
  {
    cargoTypeId: 192,
    commodity: "Fuel Oil ",
  },
  {
    cargoTypeId: 193,
    commodity: "Crude Oil",
  },
  {
    cargoTypeId: 194,
    commodity: "Vacuum Gasoil ",
  },
  {
    cargoTypeId: 195,
    commodity: "Heavy Fuel Oil ",
  },
  {
    cargoTypeId: 196,
    commodity: "High Sulphur Fuel Oil ",
  },
  {
    cargoTypeId: 197,
    commodity: "Low Sulphur Fuel Oil ",
  },
  {
    cargoTypeId: 198,
    commodity: "Carbon Black Feedstock",
  },
  {
    cargoTypeId: 199,
    commodity: "Light Cycle Oil ",
  },
  {
    cargoTypeId: 200,
    commodity: "Hydrocracker bottoms",
  },
  {
    cargoTypeId: 201,
    commodity: ".",
  },
  {
    cargoTypeId: 202,
    commodity: "delete",
  },
  {
    cargoTypeId: 203,
    commodity: "Condensed Molasses Solution",
  },
  {
    cargoTypeId: 204,
    commodity: "Betaine Glycerol Fraction",
  },
  {
    cargoTypeId: 205,
    commodity: "RBD Palm Kernel Stearin Oil",
  },
  {
    cargoTypeId: 206,
    commodity: "RBD Palm Stearin",
  },
  {
    cargoTypeId: 207,
    commodity: "Palm Mid Fraction ",
  },
  {
    cargoTypeId: 208,
    commodity: "NBD Palm Oil SG",
  },
  {
    cargoTypeId: 209,
    commodity: "NBD Olein SG",
  },
  {
    cargoTypeId: 210,
    commodity: "RDB Sustainable Stearin SG",
  },
  {
    cargoTypeId: 211,
    commodity: "RBD Palm Olein",
  },
  {
    cargoTypeId: 212,
    commodity: "Palm Oil Crude",
  },
  {
    cargoTypeId: 213,
    commodity: "Crude Palm Kernel Oil",
  },
  {
    cargoTypeId: 214,
    commodity: "Palm Oil Fatty Acid Methyl Ester",
  },
  {
    cargoTypeId: 215,
    commodity: "CSPO",
  },
  {
    cargoTypeId: 216,
    commodity: "Soyabean Oil",
  },
  {
    cargoTypeId: 217,
    commodity: "Crude Degummed Soyabean Oil",
  },
  {
    cargoTypeId: 218,
    commodity: "Crude Soyabean Oil",
  },
  {
    cargoTypeId: 219,
    commodity: "Refined Soyabean Oil",
  },
  {
    cargoTypeId: 220,
    commodity: "Crude Sunflower Seed Oil",
  },
  {
    cargoTypeId: 221,
    commodity: "Rapeseed Oil ",
  },
  {
    cargoTypeId: 222,
    commodity: "Castor Oil",
  },
  {
    cargoTypeId: 223,
    commodity: "Refined Coconut Oil",
  },
  {
    cargoTypeId: 224,
    commodity: "Glycerine",
  },
  {
    cargoTypeId: 225,
    commodity: "TO BE RENAMED 2",
  },
  {
    cargoTypeId: 226,
    commodity: "FAME",
  },
  {
    cargoTypeId: 227,
    commodity: "Caustic Soda",
  },
  {
    cargoTypeId: 228,
    commodity: "Adiponitrile",
  },
  {
    cargoTypeId: 229,
    commodity: "600R",
  },
  {
    cargoTypeId: 230,
    commodity: "220R",
  },
  {
    cargoTypeId: 231,
    commodity: "100R ",
  },
  {
    cargoTypeId: 232,
    commodity: "AS 305BD",
  },
  {
    cargoTypeId: 233,
    commodity: "AL 305",
  },
  {
    cargoTypeId: 234,
    commodity: "AL 305B",
  },
  {
    cargoTypeId: 235,
    commodity: "OLOA 273",
  },
  {
    cargoTypeId: 236,
    commodity: "OLOA 16305",
  },
  {
    cargoTypeId: 237,
    commodity: "OLOA 16300",
  },
  {
    cargoTypeId: 238,
    commodity: "OLOA 219C",
  },
  {
    cargoTypeId: 239,
    commodity: "Orthoxylene",
  },
  {
    cargoTypeId: 240,
    commodity: "Drilling Fluid",
  },
  {
    cargoTypeId: 241,
    commodity: "Acetic Acid",
  },
  {
    cargoTypeId: 242,
    commodity: "Acetone",
  },
  {
    cargoTypeId: 243,
    commodity: "Ammonium Nitrate",
  },
  {
    cargoTypeId: 244,
    commodity: "Acetyls",
  },
  {
    cargoTypeId: 245,
    commodity: "Acid",
  },
  {
    cargoTypeId: 246,
    commodity: "Aromatics",
  },
  {
    cargoTypeId: 247,
    commodity: "Biodiesel",
  },
  {
    cargoTypeId: 248,
    commodity: "Caradol",
  },
  {
    cargoTypeId: 249,
    commodity: "Canola Methyl Ester",
  },
  {
    cargoTypeId: 250,
    commodity: "CPP",
  },
  {
    cargoTypeId: 251,
    commodity: "DPP",
  },
  {
    cargoTypeId: 252,
    commodity: "Ethanol",
  },
  {
    cargoTypeId: 253,
    commodity: "Glycol",
  },
  {
    cargoTypeId: 256,
    commodity: "Lube Add",
  },
  {
    cargoTypeId: 257,
    commodity: "Lube Oil",
  },
  {
    cargoTypeId: 258,
    commodity: "Molasses",
  },
  {
    cargoTypeId: 259,
    commodity: "Shellsols",
  },
  {
    cargoTypeId: 260,
    commodity: "Soya Methyl Ester",
  },
  {
    cargoTypeId: 261,
    commodity: "Tallow",
  },
  {
    cargoTypeId: 262,
    commodity: "Urea",
  },
  {
    cargoTypeId: 263,
    commodity: "Vegetable Oil",
  },
  {
    cargoTypeId: 264,
    commodity: "Vinasses",
  },
  {
    cargoTypeId: 265,
    commodity: "Base Oils",
  },
  {
    cargoTypeId: 266,
    commodity: "Palm Oil",
  },
  {
    cargoTypeId: 267,
    commodity: "Paraxylene",
  },
  {
    cargoTypeId: 268,
    commodity: "LPG",
  },
  {
    cargoTypeId: 269,
    commodity: "LPG mix",
  },
  {
    cargoTypeId: 270,
    commodity: "Propane",
  },
  {
    cargoTypeId: 271,
    commodity: "Butane",
  },
  {
    cargoTypeId: 272,
    commodity: "Petrochemicals",
  },
  {
    cargoTypeId: 273,
    commodity: "Butene-1",
  },
  {
    cargoTypeId: 274,
    commodity: "Ethane",
  },
  {
    cargoTypeId: 275,
    commodity: "Ethylene",
  },
  {
    cargoTypeId: 276,
    commodity: "Polymer Grade Propylene",
  },
  {
    cargoTypeId: 277,
    commodity: "Chemical Grade Propylene",
  },
  {
    cargoTypeId: 278,
    commodity: "Refinery Grade Propylene",
  },
  {
    cargoTypeId: 279,
    commodity: "Propylene Oxide",
  },
  {
    cargoTypeId: 280,
    commodity: "Butadiene",
  },
  {
    cargoTypeId: 281,
    commodity: "Crude C4",
  },
  {
    cargoTypeId: 282,
    commodity: "VCM",
  },
  {
    cargoTypeId: 283,
    commodity: "Raffinate 1",
  },
  {
    cargoTypeId: 284,
    commodity: "Raffinate 2",
  },
  {
    cargoTypeId: 285,
    commodity: "Raffinate 2/3",
  },
  {
    cargoTypeId: 286,
    commodity: "Ammonia",
  },
  {
    cargoTypeId: 287,
    commodity: "Urea Ammonium Nitrate",
  },
  {
    cargoTypeId: 288,
    commodity: "Isobutane",
  },
  {
    cargoTypeId: 289,
    commodity: "Cumene",
  },
  {
    cargoTypeId: 290,
    commodity: "Gasoil",
  },
  {
    cargoTypeId: 291,
    commodity: "Ultra Low Sulphur Diesel",
  },
  {
    cargoTypeId: 292,
    commodity: "Creosote",
  },
  {
    cargoTypeId: 293,
    commodity: "Slack Wax",
  },
  {
    cargoTypeId: 294,
    commodity: "Ethylene Dichloride",
  },
  {
    cargoTypeId: 295,
    commodity: "Mono Ethylene Glycol",
  },
  {
    cargoTypeId: 296,
    commodity: "BTX",
  },
  {
    cargoTypeId: 297,
    commodity: "Brightstock",
  },
  {
    cargoTypeId: 298,
    commodity: "Canola Oil",
  },
  {
    cargoTypeId: 299,
    commodity: "Cement (Bagged)",
  },
  {
    cargoTypeId: 300,
    commodity: "MTBE (Methyl tert-butyl ether)",
  },
  {
    cargoTypeId: 301,
    commodity: "Alkanes",
  },
  {
    cargoTypeId: 302,
    commodity: "Paraffin Wax",
  },
  {
    cargoTypeId: 303,
    commodity: "Petcoke",
  },
  {
    cargoTypeId: 304,
    commodity: "Meta-Xylene",
  },
  {
    cargoTypeId: 305,
    commodity: "Isopar L",
  },
  {
    cargoTypeId: 306,
    commodity: "Epichlorohydrin",
  },
  {
    cargoTypeId: 307,
    commodity: "Methylene Chloride",
  },
  {
    cargoTypeId: 308,
    commodity: "Isopropanol",
  },
  {
    cargoTypeId: 309,
    commodity: "Calcium nitrate solution",
  },
  {
    cargoTypeId: 310,
    commodity: "Palm Fatty Acid Distillate",
  },
  {
    cargoTypeId: 311,
    commodity: "alcohol poly ethoxylate",
  },
  {
    cargoTypeId: 312,
    commodity: "Heavy Aromatics",
  },
  {
    cargoTypeId: 313,
    commodity: "Alpha olefin C20-C24",
  },
  {
    cargoTypeId: 314,
    commodity: "Parachlorobenzotrifluoride",
  },
  {
    cargoTypeId: 315,
    commodity: "Nonene",
  },
  {
    cargoTypeId: 316,
    commodity: "Linear Alkyl Benzene",
  },
  {
    cargoTypeId: 317,
    commodity: "2-EH",
  },
  {
    cargoTypeId: 318,
    commodity: "Reformate Benzene Heart Cut",
  },
  {
    cargoTypeId: 319,
    commodity: "Di-Ethylene Glycol",
  },
  {
    cargoTypeId: 320,
    commodity: "Ethylhexanol",
  },
  {
    cargoTypeId: 321,
    commodity: "Iso-Butanol",
  },
  {
    cargoTypeId: 322,
    commodity: "Methyl Ethyl Ketone",
  },
  {
    cargoTypeId: 323,
    commodity: "Adipol-2-EH",
  },
  {
    cargoTypeId: 324,
    commodity: "Dioctyl phthalate",
  },
  {
    cargoTypeId: 325,
    commodity: "Secondary Butyl Acetate",
  },
  {
    cargoTypeId: 1000,
    commodity: "Dirty",
  },
  {
    cargoTypeId: 1001,
    commodity: "Clean",
  },
  {
    cargoTypeId: 1002,
    commodity: "Specialised",
  },
  {
    cargoTypeId: 1003,
    commodity: "Asphalt/Bitumen",
  },
  {
    cargoTypeId: 1004,
    commodity: "Low Sulphur Waxy Residual",
  },
  {
    cargoTypeId: 1005,
    commodity: "Orimulsion",
  },
  {
    cargoTypeId: 1006,
    commodity: "Residuum",
  },
  {
    cargoTypeId: 1007,
    commodity: "Automotive Diesel Oil",
  },
  {
    cargoTypeId: 1008,
    commodity: "Aviation Gasoline (Avgas)",
  },
  {
    cargoTypeId: 1009,
    commodity: "Distillate",
  },
  {
    cargoTypeId: 1010,
    commodity: "Diesel Oil",
  },
  {
    cargoTypeId: 1011,
    commodity: "Gasahol (Gasoline/Ethanol blend)",
  },
  {
    cargoTypeId: 1012,
    commodity: "GasOil",
  },
  {
    cargoTypeId: 1013,
    commodity: "Gas to Liquid",
  },
  {
    cargoTypeId: 1014,
    commodity: "High Speed Diesel",
  },
  {
    cargoTypeId: 1015,
    commodity: "Jet Propulsion Fuel Type 8",
  },
  {
    cargoTypeId: 1016,
    commodity: "Kerosine",
  },
  {
    cargoTypeId: 1017,
    commodity: "Leaded Gasoline",
  },
  {
    cargoTypeId: 1018,
    commodity: "Marine Diesel Oil",
  },
  {
    cargoTypeId: 1019,
    commodity: "Motor Gasoline Blending Component",
  },
  {
    cargoTypeId: 1020,
    commodity: "Motor Gasoline (Mogas)",
  },
  {
    cargoTypeId: 1021,
    commodity: "Reformulated Gasoline",
  },
  {
    cargoTypeId: 1022,
    commodity: "Superior Kerosene Oil",
  },
  {
    cargoTypeId: 1023,
    commodity: "Ultra Low Sulfur Diesel",
  },
  {
    cargoTypeId: 1024,
    commodity: "Unleaded Motor Spirit",
  },
  {
    cargoTypeId: 1025,
    commodity: "Biomass Based Diesel Fuel",
  },
  {
    cargoTypeId: 1026,
    commodity: "Benzene, Toluene, Xylene",
  },
  {
    cargoTypeId: 1027,
    commodity: "Ethyl Tertiary Butyl Ether",
  },
  {
    cargoTypeId: 1028,
    commodity: "Miscellaneous",
  },
  {
    cargoTypeId: 1029,
    commodity: "Methyl Tertiary Butyl Ether",
  },
  {
    cargoTypeId: 1030,
    commodity: "Oxygenate",
  },
  {
    cargoTypeId: 1031,
    commodity: "Pentanes Plus",
  },
  {
    cargoTypeId: 1032,
    commodity: "Specialised",
  },
  {
    cargoTypeId: 1033,
    commodity: "Tertiary Amyl Methyl Ether",
  },
  {
    cargoTypeId: 1034,
    commodity: "Tertiary Butyle Alcohol",
  },
  {
    cargoTypeId: 1035,
    commodity: "Urea Ammonium Nitrate",
  },
  {
    cargoTypeId: 1036,
    commodity: "Water",
  },
  {
    cargoTypeId: 1037,
    commodity: "Methyl Metacrylate",
  },
  {
    cargoTypeId: 1038,
    commodity: "Sulphur (Prilled)",
  },
  {
    cargoTypeId: 1039,
    commodity: "limestone",
  },
  {
    cargoTypeId: 1040,
    commodity: "Dolomite",
  },
  {
    cargoTypeId: 1041,
    commodity: "Slag",
  },
  {
    cargoTypeId: 1042,
    commodity: "C9",
  },
  {
    cargoTypeId: 1043,
    commodity: "C9+",
  },
  {
    cargoTypeId: 1044,
    commodity: "Lysine",
  },
  {
    cargoTypeId: 1045,
    commodity: "Fish Oil",
  },
  {
    cargoTypeId: 1046,
    commodity: "Heavy White Oil",
  },
  {
    cargoTypeId: 1047,
    commodity: "Butyl Acrylate",
  },
  {
    cargoTypeId: 1048,
    commodity: "Dimethylformamide",
  },
  {
    cargoTypeId: 1049,
    commodity: "Light White Oil",
  },
  {
    cargoTypeId: 1050,
    commodity: "Cement (Clinker)",
  },
  {
    cargoTypeId: 1051,
    commodity: "Gypsum",
  },
  {
    cargoTypeId: 1052,
    commodity: "Cement (Bulk)",
  },
  {
    cargoTypeId: 1053,
    commodity: "Used Cooking Oil Methyl Ester",
  },
  {
    cargoTypeId: 1054,
    commodity: "Palm Acid Oil",
  },
  {
    cargoTypeId: 1055,
    commodity: "Oleo Chemicals",
  },
  {
    cargoTypeId: 1056,
    commodity: "Platformate",
  },
  {
    cargoTypeId: 1057,
    commodity: "Alkylate",
  },
  {
    cargoTypeId: 1058,
    commodity: "Naphtha Tops",
  },
  {
    cargoTypeId: 1059,
    commodity: "Isomerate",
  },
  {
    cargoTypeId: 1060,
    commodity: "Raffinate",
  },
  {
    cargoTypeId: 1061,
    commodity: "Cat Cracked Spirit",
  },
  {
    cargoTypeId: 1062,
    commodity: "Light Cat Cracked Gasoline",
  },
  {
    cargoTypeId: 1063,
    commodity: "Kerosene",
  },
  {
    cargoTypeId: 1064,
    commodity: "Dual Purpose Kerosene",
  },
  {
    cargoTypeId: 1065,
    commodity: "Heavy Gasoil",
  },
  {
    cargoTypeId: 1066,
    commodity: "C7 Condensate",
  },
  {
    cargoTypeId: 1067,
    commodity: "Clean Condensate",
  },
  {
    cargoTypeId: 1068,
    commodity: "LCO Undarker 2 npa",
  },
  {
    cargoTypeId: 1069,
    commodity: "LCO Undarker 2.5 npa",
  },
  {
    cargoTypeId: 1070,
    commodity: "LCO Undarker 3 npa",
  },
  {
    cargoTypeId: 1071,
    commodity: "LCO Undarker 3.5 npa",
  },
  {
    cargoTypeId: 1072,
    commodity: "Fuel Oil Slurry",
  },
  {
    cargoTypeId: 1073,
    commodity: "Low Sulphur Atmospheric Residues",
  },
  {
    cargoTypeId: 1074,
    commodity: "LS VGO",
  },
  {
    cargoTypeId: 1075,
    commodity: "MS VGO",
  },
  {
    cargoTypeId: 1076,
    commodity: "HS VGO",
  },
  {
    cargoTypeId: 1077,
    commodity: "LS FO",
  },
  {
    cargoTypeId: 1078,
    commodity: "MS FO",
  },
  {
    cargoTypeId: 1079,
    commodity: "HS FO",
  },
  {
    cargoTypeId: 1080,
    commodity: "Dirty LCO",
  },
  {
    cargoTypeId: 1081,
    commodity: "Dirty Gasoil",
  },
  {
    cargoTypeId: 1082,
    commodity: "Dirty Gas Condensate",
  },
  {
    cargoTypeId: 1083,
    commodity: "Clarified Oil",
  },
  {
    cargoTypeId: 1084,
    commodity: "Decant Oil",
  },
  {
    cargoTypeId: 1085,
    commodity: "Coker Feed",
  },
  {
    cargoTypeId: 1086,
    commodity: "ibal",
  },
  {
    cargoTypeId: 1087,
    commodity: "TEA",
  },
  {
    cargoTypeId: 1088,
    commodity: "DEA",
  },
  {
    cargoTypeId: 1089,
    commodity: "MEA",
  },
  {
    cargoTypeId: 1090,
    commodity: "Benzene Concentrate",
  },
  {
    cargoTypeId: 1091,
    commodity: "Coal (Bolivar)",
  },
  {
    cargoTypeId: 1092,
    commodity: "Normal Paraffins",
  },
  {
    cargoTypeId: 1093,
    commodity: "Butanediol",
  },
  {
    cargoTypeId: 1094,
    commodity: "DO DECENE-1",
  },
  {
    cargoTypeId: 1095,
    commodity: "Triethylene Glycol",
  },
  {
    cargoTypeId: 1096,
    commodity: "Transformer Oil",
  },
  {
    cargoTypeId: 1097,
    commodity: "Butanol",
  },
  {
    cargoTypeId: 1098,
    commodity: "Condensate",
  },
  {
    cargoTypeId: 1099,
    commodity: "Fatty Acid Methyl Ether",
  },
  {
    cargoTypeId: 1100,
    commodity: "Jet Propulsion Fuel Type A-1",
  },
  {
    cargoTypeId: 1101,
    commodity: "Jet Propulsion Fuel Type A",
  },
  {
    cargoTypeId: 1102,
    commodity: "Jet Propulsion Fuel Type B",
  },
  {
    cargoTypeId: 1103,
    commodity: "Jet Propulsion Fuel Type TS-1",
  },
  {
    cargoTypeId: 1104,
    commodity: "Jet Propulsion Fuel Type 5",
  },
  {
    cargoTypeId: 1105,
    commodity: "Fuel Oil No 1 (distillate)",
  },
  {
    cargoTypeId: 1106,
    commodity: "Fuel Oil No 2 (distillate)",
  },
  {
    cargoTypeId: 1107,
    commodity: "Fuel Oil No 3 (distillate)",
  },
  {
    cargoTypeId: 1108,
    commodity: "Fuel Oil No 4 (heavy heating oil)",
  },
  {
    cargoTypeId: 1109,
    commodity: "Fuel Oil No 5 (heavy fuel oil)",
  },
  {
    cargoTypeId: 1110,
    commodity: "Fuel Oil No 6 (heavy fuel oil)",
  },
  {
    cargoTypeId: 1111,
    commodity: "Mixed CPP",
  },
  {
    cargoTypeId: 1112,
    commodity: "Steel Coils",
  },
  {
    cargoTypeId: 1113,
    commodity: "Phosphate Rock",
  },
  {
    cargoTypeId: 1114,
    commodity: "Basrah Light",
  },
  {
    cargoTypeId: 1115,
    commodity: "Basrah Heavy",
  },
  {
    cargoTypeId: 1116,
    commodity: "Wood Pellets",
  },
  {
    cargoTypeId: 1117,
    commodity: "Crude Tall Oil",
  },
  {
    cargoTypeId: 1118,
    commodity: "Gasoline",
  },
  {
    cargoTypeId: 1119,
    commodity: "No Heat Crude",
  },
  {
    cargoTypeId: 1120,
    commodity: "Nitrobenzene",
  },
  {
    cargoTypeId: 1121,
    commodity: "Diphenylmethane diisocyanate",
  },
  {
    cargoTypeId: 1122,
    commodity: "Toluene Diisocyanate",
  },
  {
    cargoTypeId: 1123,
    commodity: "Polyols",
  },
  {
    cargoTypeId: 1124,
    commodity: "Barites",
  },
  {
    cargoTypeId: 1125,
    commodity: "Liquid Virgin Naptha",
  },
  {
    cargoTypeId: 1126,
    commodity: "Mixed Aromatics",
  },
  {
    cargoTypeId: 1127,
    commodity: "HSGO",
  },
  {
    cargoTypeId: 1130,
    commodity: "LCN",
  },
  {
    cargoTypeId: 1131,
    commodity: "MGO",
  },
  {
    cargoTypeId: 1132,
    commodity: "MGO",
  },
  {
    cargoTypeId: 1133,
    commodity: "Shale Gasoline",
  },
  {
    cargoTypeId: 1134,
    commodity: "HSS",
  },
  {
    cargoTypeId: 1135,
    commodity: "Scrap",
  },
  {
    cargoTypeId: 1136,
    commodity: "Granite",
  },
  {
    cargoTypeId: 1137,
    commodity: "Rape Methyl Ester",
  },
  {
    cargoTypeId: 1138,
    commodity: "Cat Cracked Gasoline",
  },
  {
    cargoTypeId: 1139,
    commodity: "Olivin Sand",
  },
  {
    cargoTypeId: 1140,
    commodity: "Heavy Cycle oil",
  },
  {
    cargoTypeId: 1141,
    commodity: "Cutter Stock",
  },
  {
    cargoTypeId: 1142,
    commodity: "Heavy Coker Gas Oil",
  },
  {
    cargoTypeId: 1143,
    commodity: "Low Sulfur Straight Run Fuel Oil",
  },
  {
    cargoTypeId: 1144,
    commodity: "Hexacholorobenzene",
  },
  {
    cargoTypeId: 1145,
    commodity: "Ultra Low Sulfur Fuel Oil",
  },
  {
    cargoTypeId: 1146,
    commodity: "Gas Condensate",
  },
  {
    cargoTypeId: 1147,
    commodity: "Wide Range Gasoil",
  },
  {
    cargoTypeId: 1148,
    commodity: "6e Feedstock",
  },
  {
    cargoTypeId: 1149,
    commodity: "Full Range Naphtha",
  },
  {
    cargoTypeId: 1150,
    commodity: "RBHC",
  },
  {
    cargoTypeId: 1151,
    commodity: "HVN",
  },
  {
    cargoTypeId: 1152,
    commodity: "BHC",
  },
  {
    cargoTypeId: 1153,
    commodity: "2-Ethylhexanoic acid",
  },
  {
    cargoTypeId: 1154,
    commodity: "1,4-Butanediol",
  },
  {
    cargoTypeId: 1155,
    commodity: "Base Oil 150N",
  },
  {
    cargoTypeId: 1156,
    commodity: "2-EHA 10-20",
  },
  {
    cargoTypeId: 1157,
    commodity: "2-Ethylhexyl Acrylate",
  },
  {
    cargoTypeId: 1158,
    commodity: "2-PH",
  },
  {
    cargoTypeId: 1159,
    commodity: "2-Propylheptanol",
  },
  {
    cargoTypeId: 1160,
    commodity: "Base Oil 500N",
  },
  {
    cargoTypeId: 1161,
    commodity: "Base Oil 600N",
  },
  {
    cargoTypeId: 1162,
    commodity: "95 Octane RON",
  },
  {
    cargoTypeId: 1163,
    commodity: "AC150",
  },
  {
    cargoTypeId: 1164,
    commodity: "AC2500",
  },
  {
    cargoTypeId: 1165,
    commodity: "AC600",
  },
  {
    cargoTypeId: 1166,
    commodity: "Mixed Acid Oil",
  },
  {
    cargoTypeId: 1167,
    commodity: "Acrylic Acid",
  },
  {
    cargoTypeId: 1168,
    commodity: "Alkyl (C8-C9) Phenylamine in Aromatic Solvents",
  },
  {
    cargoTypeId: 1169,
    commodity: "Alkyl (C9+) Benzenes",
  },
  {
    cargoTypeId: 1170,
    commodity: "Benzyl Chloride",
  },
  {
    cargoTypeId: 1171,
    commodity: "Formic Acid",
  },
  {
    cargoTypeId: 1172,
    commodity: "Amasil 85",
  },
  {
    cargoTypeId: 1173,
    commodity: "Amasil NA 1",
  },
  {
    cargoTypeId: 1174,
    commodity: "Anhydrous Ammonia",
  },
  {
    cargoTypeId: 1175,
    commodity: "Aniline",
  },
  {
    cargoTypeId: 1176,
    commodity: "alpha-Methylstyrene",
  },
  {
    cargoTypeId: 1177,
    commodity: "Core 100",
  },
  {
    cargoTypeId: 1178,
    commodity: "AP/E Core 150",
  },
  {
    cargoTypeId: 1179,
    commodity: "AP/E Core 2500",
  },
  {
    cargoTypeId: 1180,
    commodity: "AP/E Core 600",
  },
  {
    cargoTypeId: 1181,
    commodity: "Aromatic Naphtha (having less than 10% Benzene)",
  },
  {
    cargoTypeId: 1182,
    commodity: "Aryl Polyolefins (C11-C50)",
  },
  {
    cargoTypeId: 1183,
    commodity: "Atmospheric Residue",
  },
  {
    cargoTypeId: 1184,
    commodity: "Base Oil",
  },
  {
    cargoTypeId: 1185,
    commodity: "Base Oil KS150",
  },
  {
    cargoTypeId: 1186,
    commodity: "Butyl Glycol Ether",
  },
  {
    cargoTypeId: 1187,
    commodity: "Brightstock 150",
  },
  {
    cargoTypeId: 1188,
    commodity: "BS150 (BASE OIL)",
  },
  {
    cargoTypeId: 1189,
    commodity: "BS150 (Oil No. 4)",
  },
  {
    cargoTypeId: 1190,
    commodity: "BT150 (BASE OIL)",
  },
  {
    cargoTypeId: 1191,
    commodity: "Ethylene glycol monoalkyl ethers",
  },
  {
    cargoTypeId: 1192,
    commodity: "Butyl Cellosolve",
  },
  {
    cargoTypeId: 1193,
    commodity: "Butyl Glycol",
  },
  {
    cargoTypeId: 1194,
    commodity: "C5NA Raffinate",
  },
  {
    cargoTypeId: 1195,
    commodity: "C6 Raffinate",
  },
  {
    cargoTypeId: 1196,
    commodity: "Canthus 1000",
  },
  {
    cargoTypeId: 1197,
    commodity: "Potassium Hydroxide Solution",
  },
  {
    cargoTypeId: 1198,
    commodity: "Caustic Potash",
  },
  {
    cargoTypeId: 1199,
    commodity: "Chloroform",
  },
  {
    cargoTypeId: 1200,
    commodity: "Palm Oils",
  },
  {
    cargoTypeId: 1201,
    commodity: "Coconut Oil",
  },
  {
    cargoTypeId: 1202,
    commodity: "Crude Glycerine",
  },
  {
    cargoTypeId: 1203,
    commodity: "Crude High Oleic Sunflower Oil",
  },
  {
    cargoTypeId: 1204,
    commodity: "Ethyl Alcohol",
  },
  {
    cargoTypeId: 1205,
    commodity: "Crude Rapeseed Oil",
  },
  {
    cargoTypeId: 1206,
    commodity: "Cyclohexanone",
  },
  {
    cargoTypeId: 1207,
    commodity: "Dicyclopentadiene, Resin Grade, 81-89%",
  },
  {
    cargoTypeId: 1208,
    commodity: "DHC Bottoms",
  },
  {
    cargoTypeId: 1209,
    commodity: "Diala",
  },
  {
    cargoTypeId: 1210,
    commodity: "1,3-Cyclopentadiene dimer (molten)",
  },
  {
    cargoTypeId: 1211,
    commodity: "Dicyclopentadiene",
  },
  {
    cargoTypeId: 1212,
    commodity: "Dialkyl (C7-C13) phthalates",
  },
  {
    cargoTypeId: 1213,
    commodity: "Diisodecyl Phthalates",
  },
  {
    cargoTypeId: 1214,
    commodity: "Dipropylene Glycol",
  },
  {
    cargoTypeId: 1215,
    commodity: "Distilled Coco Fatty Acid",
  },
  {
    cargoTypeId: 1216,
    commodity: "Propylene glycol monoalkyl ether",
  },
  {
    cargoTypeId: 1217,
    commodity: "Dowanol PM",
  },
  {
    cargoTypeId: 1218,
    commodity: "Propylene glycol methyl ether acetate",
  },
  {
    cargoTypeId: 1219,
    commodity: "Dowanol PMA",
  },
  {
    cargoTypeId: 1220,
    commodity: "Perchloroethylene",
  },
  {
    cargoTypeId: 1221,
    commodity: "Dowper",
  },
  {
    cargoTypeId: 1222,
    commodity: "Diphenyl/Diphenyl ether mixtures",
  },
  {
    cargoTypeId: 1223,
    commodity: "Dowtherm A",
  },
  {
    cargoTypeId: 1224,
    commodity: "Drilling Fluid HDF 2000",
  },
  {
    cargoTypeId: 1225,
    commodity: "EDC 95-11",
  },
  {
    cargoTypeId: 1226,
    commodity: "EHC 110",
  },
  {
    cargoTypeId: 1227,
    commodity: "EHC 45",
  },
  {
    cargoTypeId: 1228,
    commodity: "EHC 50",
  },
  {
    cargoTypeId: 1229,
    commodity: "Ethyl Acrylate",
  },
  {
    cargoTypeId: 1230,
    commodity: "Ethylenediamine",
  },
  {
    cargoTypeId: 1231,
    commodity: "Ethylene Glycol Methyl Ether",
  },
  {
    cargoTypeId: 1232,
    commodity: "Exxal 10",
  },
  {
    cargoTypeId: 1233,
    commodity: "Exxsol D40",
  },
  {
    cargoTypeId: 1234,
    commodity: "Exxsol D80",
  },
  {
    cargoTypeId: 1235,
    commodity: "Exxsol DSP 80/110",
  },
  {
    cargoTypeId: 1236,
    commodity: "Fatty Acids, C12+",
  },
  {
    cargoTypeId: 1237,
    commodity: "FH 230 HC",
  },
  {
    cargoTypeId: 1238,
    commodity: "Gasoline Blending Fraction",
  },
  {
    cargoTypeId: 1239,
    commodity: "Gasoline Super Unleaded",
  },
  {
    cargoTypeId: 1240,
    commodity: "Glycerol, Propoxylated and Ethoxylated",
  },
  {
    cargoTypeId: 1241,
    commodity: "Glyoxal Solution (40% or less)",
  },
  {
    cargoTypeId: 1242,
    commodity: "GTL Fluid G70",
  },
  {
    cargoTypeId: 1243,
    commodity: "GTL Fuel",
  },
  {
    cargoTypeId: 1244,
    commodity: "Heating Oil No.2.",
  },
  {
    cargoTypeId: 1245,
    commodity: "Hexamethylenediamine solution",
  },
  {
    cargoTypeId: 1246,
    commodity: "Hexamethylenediamine",
  },
  {
    cargoTypeId: 1247,
    commodity: "Residual Fuel Oil",
  },
  {
    cargoTypeId: 1248,
    commodity: "HVI 105",
  },
  {
    cargoTypeId: 1249,
    commodity: "HVI 120",
  },
  {
    cargoTypeId: 1250,
    commodity: "HVI 160",
  },
  {
    cargoTypeId: 1251,
    commodity: "HVI 160B",
  },
  {
    cargoTypeId: 1252,
    commodity: "HVI 160S",
  },
  {
    cargoTypeId: 1253,
    commodity: "HVI 60",
  },
  {
    cargoTypeId: 1254,
    commodity: "HVI 650",
  },
  {
    cargoTypeId: 1255,
    commodity: "HYDROSEAL G3H",
  },
  {
    cargoTypeId: 1256,
    commodity: "IP Extraction Feed",
  },
  {
    cargoTypeId: 1257,
    commodity: "Isononanol",
  },
  {
    cargoTypeId: 1258,
    commodity: "Isoprene",
  },
  {
    cargoTypeId: 1259,
    commodity: "Isopropyl Acetate",
  },
  {
    cargoTypeId: 1260,
    commodity: "Jayflex DIDP",
  },
  {
    cargoTypeId: 1261,
    commodity: "Kerocom Piba",
  },
  {
    cargoTypeId: 1262,
    commodity: "Lard",
  },
  {
    cargoTypeId: 1263,
    commodity: "Lecithin",
  },
  {
    cargoTypeId: 1264,
    commodity: "Light Virgin Naphtha",
  },
  {
    cargoTypeId: 1265,
    commodity: "Low Sulphur Gasoil",
  },
  {
    cargoTypeId: 1266,
    commodity: "Lubeoil KS 150",
  },
  {
    cargoTypeId: 1267,
    commodity: "Lupranat M20R",
  },
  {
    cargoTypeId: 1268,
    commodity: "Lupranat M20S",
  },
  {
    cargoTypeId: 1269,
    commodity: "Marine Diesel Oil",
  },
  {
    cargoTypeId: 1270,
    commodity: "Methyl diethanolamine",
  },
  {
    cargoTypeId: 1271,
    commodity: "Methyl Acetate",
  },
  {
    cargoTypeId: 1272,
    commodity: "Methyl Acrylate",
  },
  {
    cargoTypeId: 1273,
    commodity: "Methyl Carbitol",
  },
  {
    cargoTypeId: 1274,
    commodity: "Butylated Hydroxytoluene",
  },
  {
    cargoTypeId: 1275,
    commodity: "Base Oil MVIN-170",
  },
  {
    cargoTypeId: 1276,
    commodity: "n-Butyl alcohol",
  },
  {
    cargoTypeId: 1277,
    commodity: "n-Butyl acetate",
  },
  {
    cargoTypeId: 1278,
    commodity: "Neodene 1418",
  },
  {
    cargoTypeId: 1279,
    commodity: "Neodene 16 Alpha Olefin",
  },
  {
    cargoTypeId: 1280,
    commodity: "Neodene 18",
  },
  {
    cargoTypeId: 1281,
    commodity: "Neodol 23-2",
  },
  {
    cargoTypeId: 1282,
    commodity: "Neodol 23-6.5",
  },
  {
    cargoTypeId: 1283,
    commodity: "Neodol 25-7",
  },
  {
    cargoTypeId: 1284,
    commodity: "Neodol 25-9",
  },
  {
    cargoTypeId: 1285,
    commodity: "Neodol 45-7",
  },
  {
    cargoTypeId: 1286,
    commodity: "Neopentylglycol (90%)",
  },
  {
    cargoTypeId: 1287,
    commodity: "NExBTL Renewable Diesel",
  },
  {
    cargoTypeId: 1288,
    commodity: "Nitric Acid",
  },
  {
    cargoTypeId: 1289,
    commodity: "Nitric acid (70% and over)",
  },
  {
    cargoTypeId: 1290,
    commodity: "Nitric acid (less than 70%)",
  },
  {
    cargoTypeId: 1291,
    commodity: "Brake Fluid 455",
  },
  {
    cargoTypeId: 1292,
    commodity: "Light Cat Cracked Cycle Oil",
  },
  {
    cargoTypeId: 1293,
    commodity: "Palm Olein",
  },
  {
    cargoTypeId: 1294,
    commodity: "Palm Kernel Olein",
  },
  {
    cargoTypeId: 1295,
    commodity: "Palm Kernel Oil",
  },
  {
    cargoTypeId: 1296,
    commodity: "Palm Oil Products",
  },
  {
    cargoTypeId: 1297,
    commodity: "Palm Stearin",
  },
  {
    cargoTypeId: 1298,
    commodity: "Prowax 312",
  },
  {
    cargoTypeId: 1299,
    commodity: "Prowax 540",
  },
  {
    cargoTypeId: 1300,
    commodity: "Prowax 561",
  },
  {
    cargoTypeId: 1301,
    commodity: "RBD Palm Kernel Stearin",
  },
  {
    cargoTypeId: 1302,
    commodity: "RBD Palm Oil",
  },
  {
    cargoTypeId: 1303,
    commodity: "RBD Palm Stearin IV15",
  },
  {
    cargoTypeId: 1304,
    commodity: "RBD Palm Kernel Oil",
  },
  {
    cargoTypeId: 1305,
    commodity: "RBD Soyabean Oil",
  },
  {
    cargoTypeId: 1306,
    commodity: "Sunflower Seed Oil",
  },
  {
    cargoTypeId: 1307,
    commodity: "Refined High Oleic Sunflower Oil",
  },
  {
    cargoTypeId: 1308,
    commodity: "Refined Rape Seed Oil",
  },
  {
    cargoTypeId: 1309,
    commodity: "Refined Sunflower Seed Oil",
  },
  {
    cargoTypeId: 1310,
    commodity: "Shell XHVI 4",
  },
  {
    cargoTypeId: 1311,
    commodity: "Shell XHVI 8",
  },
  {
    cargoTypeId: 1312,
    commodity: "S9 Base Oil",
  },
  {
    cargoTypeId: 1313,
    commodity: "SBS 150",
  },
  {
    cargoTypeId: 1314,
    commodity: "SN 100",
  },
  {
    cargoTypeId: 1315,
    commodity: "SN 130",
  },
  {
    cargoTypeId: 1316,
    commodity: "SN 150",
  },
  {
    cargoTypeId: 1317,
    commodity: "SN 300",
  },
  {
    cargoTypeId: 1318,
    commodity: "SN 400",
  },
  {
    cargoTypeId: 1319,
    commodity: "SN 500",
  },
  {
    cargoTypeId: 1320,
    commodity: "SN 600",
  },
  {
    cargoTypeId: 1321,
    commodity: "SN 800",
  },
  {
    cargoTypeId: 1322,
    commodity: "SN 900",
  },
  {
    cargoTypeId: 1323,
    commodity: "SN C9",
  },
  {
    cargoTypeId: 1324,
    commodity: "SN150 (BASE OIL)",
  },
  {
    cargoTypeId: 1325,
    commodity: "SN500 (BASE OIL)",
  },
  {
    cargoTypeId: 1326,
    commodity: "SN70",
  },
  {
    cargoTypeId: 1327,
    commodity: "Soyabean Oil (containing less than 0.5% Free Fatty Acids)",
  },
  {
    cargoTypeId: 1328,
    commodity: "Motiva Star 12",
  },
  {
    cargoTypeId: 1329,
    commodity: "Motiva Star 6",
  },
  {
    cargoTypeId: 1330,
    commodity: "Sugar Beet Molasses",
  },
  {
    cargoTypeId: 1331,
    commodity: "Sugar Cane Molasses",
  },
  {
    cargoTypeId: 1332,
    commodity: "YUBASE 4",
  },
  {
    cargoTypeId: 1333,
    commodity: "YUBASE 6",
  },
  {
    cargoTypeId: 1334,
    commodity: "Lub Oil ESN 130",
  },
  {
    cargoTypeId: 1335,
    commodity: "alpha-Chlorotoluene (Benzyl Chloride)",
  },
  {
    cargoTypeId: 1336,
    commodity: "Benzene and Mixtures Having 10% Benzene or more",
  },
  {
    cargoTypeId: 1337,
    commodity: "Butyl Acetate (all isomers)",
  },
  {
    cargoTypeId: 1338,
    commodity: "Butyl Acrylate 10-20",
  },
  {
    cargoTypeId: 1339,
    commodity: "Butyl Carbitol",
  },
  {
    cargoTypeId: 1340,
    commodity: "Butyldiglykol",
  },
  {
    cargoTypeId: 1341,
    commodity: "C42 Fatty Acid Methyl Ester",
  },
  {
    cargoTypeId: 1342,
    commodity: "C42 Lauric Acid Methyl Ester",
  },
  {
    cargoTypeId: 1343,
    commodity: "C65 FATTY ACID METHYL ESTER",
  },
  {
    cargoTypeId: 1344,
    commodity: "C9 Resinfeed",
  },
  {
    cargoTypeId: 1345,
    commodity: "Caprolactam Solution",
  },
  {
    cargoTypeId: 1346,
    commodity: "Caprylic Acid",
  },
  {
    cargoTypeId: 1347,
    commodity: "Caradol SC56-16",
  },
  {
    cargoTypeId: 1348,
    commodity: "CARADOL SC56-16S",
  },
  {
    cargoTypeId: 1349,
    commodity: "Caradol SC56-20",
  },
  {
    cargoTypeId: 1350,
    commodity: "Caradol SP44-10",
  },
  {
    cargoTypeId: 1351,
    commodity: "Caromax 20",
  },
  {
    cargoTypeId: 1352,
    commodity: "Caromax 28",
  },
  {
    cargoTypeId: 1353,
    commodity: "CAS29/75",
  },
  {
    cargoTypeId: 1354,
    commodity: "Conosol 340",
  },
  {
    cargoTypeId: 1355,
    commodity: "CPP Unleaded Undarker than 2.5NPA",
  },
  {
    cargoTypeId: 1356,
    commodity: "Crude Industrial Ethanol",
  },
  {
    cargoTypeId: 1357,
    commodity: "Crude MDI",
  },
  {
    cargoTypeId: 1358,
    commodity: "Denatured Ethyl Alcohol",
  },
  {
    cargoTypeId: 1359,
    commodity: "Diethanolamine",
  },
  {
    cargoTypeId: 1360,
    commodity: "Diethylene Glycol",
  },
  {
    cargoTypeId: 1361,
    commodity: "Diglycidyl Ether Of Bisphenol A",
  },
  {
    cargoTypeId: 1362,
    commodity: "EDC DIAMOND",
  },
  {
    cargoTypeId: 1363,
    commodity: "Ethanolamine",
  },
  {
    cargoTypeId: 1364,
    commodity: "Exxal 9 Alcohol",
  },
  {
    cargoTypeId: 1365,
    commodity: "Exxsol D100",
  },
  {
    cargoTypeId: 1366,
    commodity: "Exxsol D60",
  },
  {
    cargoTypeId: 1367,
    commodity: "Gas Oil",
  },
  {
    cargoTypeId: 1368,
    commodity: "Heavy Aromatic Naphtha Solvent",
  },
  {
    cargoTypeId: 1369,
    commodity: "Glysantin G103-26",
  },
  {
    cargoTypeId: 1370,
    commodity: "Isopropyl Alcohol",
  },
  {
    cargoTypeId: 1371,
    commodity: "Liav 200",
  },
  {
    cargoTypeId: 1372,
    commodity: "Liav 300",
  },
  {
    cargoTypeId: 1373,
    commodity: "MDI",
  },
  {
    cargoTypeId: 1374,
    commodity: "Methyl Alcohol",
  },
  {
    cargoTypeId: 1375,
    commodity: "Methyl Isobutyl Ketone",
  },
  {
    cargoTypeId: 1376,
    commodity: "Methyl Methacrylate",
  },
  {
    cargoTypeId: 1377,
    commodity: "Methyl Isobutyl Carbinol",
  },
  {
    cargoTypeId: 1378,
    commodity: "Monochlorobenzene",
  },
  {
    cargoTypeId: 1379,
    commodity: "Monoethylamine",
  },
  {
    cargoTypeId: 1380,
    commodity: "Monoethylene Glycol",
  },
  {
    cargoTypeId: 1381,
    commodity: "Monopropylene Glycol",
  },
  {
    cargoTypeId: 1382,
    commodity: "Mosstanol 120",
  },
  {
    cargoTypeId: 1383,
    commodity: "Mosstanol L",
  },
  {
    cargoTypeId: 1384,
    commodity: "n-Alkanes (C10+)",
  },
  {
    cargoTypeId: 1385,
    commodity: "Neoflo 4633",
  },
  {
    cargoTypeId: 1386,
    commodity: "Nonanol N",
  },
  {
    cargoTypeId: 1387,
    commodity: "Normal Paraffins C.10-13",
  },
  {
    cargoTypeId: 1388,
    commodity: "Normal Paraffins P-100 Q",
  },
  {
    cargoTypeId: 1389,
    commodity: "Normal Paraffins P-150 Q C.10-13",
  },
  {
    cargoTypeId: 1390,
    commodity: "Noxious Liquid, NF, (3/4) contains Alykl (C9+) Benzenes and Poly (2+) Cyclic Aromatics",
  },
  {
    cargoTypeId: 1391,
    commodity: "n-Paraffins (C10-C20)",
  },
  {
    cargoTypeId: 1392,
    commodity: "n-Propyl Acetate",
  },
  {
    cargoTypeId: 1393,
    commodity: "Olefins",
  },
  {
    cargoTypeId: 1394,
    commodity: "Olefin mixtures (C5-C15)",
  },
  {
    cargoTypeId: 1395,
    commodity: "OLOA 13000",
  },
  {
    cargoTypeId: 1396,
    commodity: "OLOA 249SX",
  },
  {
    cargoTypeId: 1397,
    commodity: "Ortho-Toluidine",
  },
  {
    cargoTypeId: 1398,
    commodity: "Palatinol 10 P",
  },
  {
    cargoTypeId: 1399,
    commodity: "Palm Methyl Ester",
  },
  {
    cargoTypeId: 1400,
    commodity: "Papi 27",
  },
  {
    cargoTypeId: 1401,
    commodity: "Paradichlorobenzene",
  },
  {
    cargoTypeId: 1402,
    commodity: "Petrelab 550",
  },
  {
    cargoTypeId: 1403,
    commodity: "Petrepar P-147",
  },
  {
    cargoTypeId: 1404,
    commodity: "Petrepar P-185",
  },
  {
    cargoTypeId: 1405,
    commodity: "Petrosol D-100/120",
  },
  {
    cargoTypeId: 1406,
    commodity: "Petrosol D-15/20",
  },
  {
    cargoTypeId: 1407,
    commodity: "Petrosol 15A 15/20",
  },
  {
    cargoTypeId: 1408,
    commodity: "Petrosol 16/18",
  },
  {
    cargoTypeId: 1409,
    commodity: "Petrosol 95A 16/18",
  },
  {
    cargoTypeId: 1410,
    commodity: "Petrosol 95A 18/20",
  },
  {
    cargoTypeId: 1411,
    commodity: "Petrosol D 19/22",
  },
  {
    cargoTypeId: 1412,
    commodity: "Petrosol D Isohexane",
  },
  {
    cargoTypeId: 1413,
    commodity: "Phosphorus, Yellow or White",
  },
  {
    cargoTypeId: 1414,
    commodity: "Palm Kernel Fatty Acid Distillate",
  },
  {
    cargoTypeId: 1415,
    commodity: "Palm Kernel Stearin",
  },
  {
    cargoTypeId: 1416,
    commodity: "PMF 45",
  },
  {
    cargoTypeId: 1417,
    commodity: "PMF 49",
  },
  {
    cargoTypeId: 1418,
    commodity: "Polymeric MDI",
  },
  {
    cargoTypeId: 1419,
    commodity: "Polymethylene Polyphenyl Isocyanate",
  },
  {
    cargoTypeId: 1420,
    commodity: "Potassium Formate Solution",
  },
  {
    cargoTypeId: 1421,
    commodity: "Premium Unleaded Gasoline",
  },
  {
    cargoTypeId: 1422,
    commodity: "Propanol",
  },
  {
    cargoTypeId: 1423,
    commodity: "Propionic Acid",
  },
  {
    cargoTypeId: 1424,
    commodity: "Propylene Glycol",
  },
  {
    cargoTypeId: 1425,
    commodity: "Propylene Glycol - Industrial",
  },
  {
    cargoTypeId: 1426,
    commodity: "Propylene Glycol MPG USP",
  },
  {
    cargoTypeId: 1427,
    commodity: "Propylene Tetramer",
  },
  {
    cargoTypeId: 1428,
    commodity: "Para-Xylene",
  },
  {
    cargoTypeId: 1429,
    commodity: "QHVI4",
  },
  {
    cargoTypeId: 1430,
    commodity: "QHVI8",
  },
  {
    cargoTypeId: 1431,
    commodity: "Raffinate II",
  },
  {
    cargoTypeId: 1432,
    commodity: "Resin Oil",
  },
  {
    cargoTypeId: 1433,
    commodity: "SBP 100/140",
  },
  {
    cargoTypeId: 1434,
    commodity: "sec-Butyl Alcohol",
  },
  {
    cargoTypeId: 1435,
    commodity: "Shell GTL Normal Paraffin 10-13",
  },
  {
    cargoTypeId: 1436,
    commodity: "Shellsol 60/145",
  },
  {
    cargoTypeId: 1437,
    commodity: "Shellsol D70",
  },
  {
    cargoTypeId: 1438,
    commodity: "Shellsol HF",
  },
  {
    cargoTypeId: 1439,
    commodity: "Shellsol HF 250 D",
  },
  {
    cargoTypeId: 1440,
    commodity: "Shellsol X55",
  },
  {
    cargoTypeId: 1441,
    commodity: "Sodium Bromide",
  },
  {
    cargoTypeId: 1442,
    commodity: "Sodium Hydroxide",
  },
  {
    cargoTypeId: 1443,
    commodity: "Sodium Methylate",
  },
  {
    cargoTypeId: 1444,
    commodity: "Sodium Silicate",
  },
  {
    cargoTypeId: 1445,
    commodity: "Solvent Naphtha",
  },
  {
    cargoTypeId: 1446,
    commodity: "Spent Caustic Soda",
  },
  {
    cargoTypeId: 1447,
    commodity: "Sylfat FA2",
  },
  {
    cargoTypeId: 1448,
    commodity: "Sylvablend PF60",
  },
  {
    cargoTypeId: 1449,
    commodity: "T110",
  },
  {
    cargoTypeId: 1450,
    commodity: "T150",
  },
  {
    cargoTypeId: 1451,
    commodity: "T22",
  },
  {
    cargoTypeId: 1452,
    commodity: "T9",
  },
  {
    cargoTypeId: 1453,
    commodity: "Tall Oil Fatty Acid (Resin Acids less than 20%)",
  },
  {
    cargoTypeId: 1454,
    commodity: "Tall Oil Pitch",
  },
  {
    cargoTypeId: 1455,
    commodity: "TDAE 150",
  },
  {
    cargoTypeId: 1456,
    commodity: "Telone",
  },
  {
    cargoTypeId: 1457,
    commodity: "TERGITOL L7",
  },
  {
    cargoTypeId: 1458,
    commodity: "TERGITOL L9",
  },
  {
    cargoTypeId: 1459,
    commodity: "Tergitol NP-9",
  },
  {
    cargoTypeId: 1460,
    commodity: "Tertiary Butyl Alcohol",
  },
  {
    cargoTypeId: 1461,
    commodity: "Tetrahydrofuran",
  },
  {
    cargoTypeId: 1462,
    commodity: "Tudalen 65",
  },
  {
    cargoTypeId: 1463,
    commodity: "Tudalene 65MV",
  },
  {
    cargoTypeId: 1464,
    commodity: "Tufflo 250",
  },
  {
    cargoTypeId: 1465,
    commodity: "TX Reformate",
  },
  {
    cargoTypeId: 1466,
    commodity: "UCAR Filmer IBT",
  },
  {
    cargoTypeId: 1467,
    commodity: "Unleaded Gasoline",
  },
  {
    cargoTypeId: 1468,
    commodity: "Ultra S-Oil 6",
  },
  {
    cargoTypeId: 1469,
    commodity: "Urea/Ammonium Nitrate Solution",
  },
  {
    cargoTypeId: 1470,
    commodity: "Used Lubricating Oils",
  },
  {
    cargoTypeId: 1471,
    commodity: "VARSOL 60",
  },
  {
    cargoTypeId: 1472,
    commodity: "Vegetable Acid Oils",
  },
  {
    cargoTypeId: 1473,
    commodity: "Versenex 100E",
  },
  {
    cargoTypeId: 1474,
    commodity: "Versenex 80",
  },
  {
    cargoTypeId: 1475,
    commodity: "Versenex 80E",
  },
  {
    cargoTypeId: 1476,
    commodity: "Visom 4",
  },
  {
    cargoTypeId: 1477,
    commodity: "Visom 6",
  },
  {
    cargoTypeId: 1478,
    commodity: "Vivatec 150",
  },
  {
    cargoTypeId: 1479,
    commodity: "Vivatec 180",
  },
  {
    cargoTypeId: 1480,
    commodity: "Vivatec 500",
  },
  {
    cargoTypeId: 1481,
    commodity: "Voralux HL 106 Polyol",
  },
  {
    cargoTypeId: 1482,
    commodity: "Voranol 3322",
  },
  {
    cargoTypeId: 1483,
    commodity: "Voranol 8136",
  },
  {
    cargoTypeId: 1484,
    commodity: "V-Power Diesel",
  },
  {
    cargoTypeId: 1485,
    commodity: "White Oil",
  },
  {
    cargoTypeId: 1486,
    commodity: "White Spirit High Aromatic",
  },
  {
    cargoTypeId: 1487,
    commodity: "White Spirit High Aromatic",
  },
  {
    cargoTypeId: 1488,
    commodity: "White Spirit Low (15-20%) Aromatic",
  },
  {
    cargoTypeId: 1489,
    commodity: "Xylenes/Ethylbenzene (10% or more) Mixture",
  },
  {
    cargoTypeId: 1490,
    commodity: "Xylenes (meta,para-Xylene Mixture)",
  },
  {
    cargoTypeId: 1491,
    commodity: "Methyl Diproxitol",
  },
  {
    cargoTypeId: 1492,
    commodity: "Pyrolysis Gasoline (containing Benzene)",
  },
  {
    cargoTypeId: 1493,
    commodity: "Monoethylene Glycol Fibre Grade",
  },
  {
    cargoTypeId: 1494,
    commodity: "Vinyl Acetate Monomer HQ 14-17",
  },
  {
    cargoTypeId: 1495,
    commodity: "Vopak Acetone",
  },
  {
    cargoTypeId: 1496,
    commodity: "Sabic Phenol",
  },
  {
    cargoTypeId: 1497,
    commodity: "Sealsands Phenol",
  },
  {
    cargoTypeId: 1498,
    commodity: "Shell Acetone",
  },
  {
    cargoTypeId: 1499,
    commodity: "Shell Phenol",
  },
  {
    cargoTypeId: 1500,
    commodity: "Benzene Heart Cut",
  },
  {
    cargoTypeId: 1501,
    commodity: "Benzene Reformate",
  },
  {
    cargoTypeId: 1502,
    commodity: "Brenntag Acetone",
  },
  {
    cargoTypeId: 1503,
    commodity: "Caldic Acetone",
  },
  {
    cargoTypeId: 1504,
    commodity: "Cepsa Phenol",
  },
  {
    cargoTypeId: 1505,
    commodity: "Condensed Molasses Solubles",
  },
  {
    cargoTypeId: 1506,
    commodity: "Covestro Phenol",
  },
  {
    cargoTypeId: 1507,
    commodity: "Crude Classic Sunflower Oiland Fats",
  },
  {
    cargoTypeId: 1508,
    commodity: "CRUDE NONENE",
  },
  {
    cargoTypeId: 1509,
    commodity: "Exxsol Hexane",
  },
  {
    cargoTypeId: 1510,
    commodity: "Formaldehyde",
  },
  {
    cargoTypeId: 1511,
    commodity: "Formic Acid (over 85%)",
  },
  {
    cargoTypeId: 1512,
    commodity: "Hexion Acetone",
  },
  {
    cargoTypeId: 1513,
    commodity: "Hexion Phenol",
  },
  {
    cargoTypeId: 1514,
    commodity: "Inter Tees Phenol",
  },
  {
    cargoTypeId: 1515,
    commodity: "Isobutanol",
  },
  {
    cargoTypeId: 1516,
    commodity: "ISOPAR H FLUID",
  },
  {
    cargoTypeId: 1517,
    commodity: "ISOPAR M FLUID",
  },
  {
    cargoTypeId: 1518,
    commodity: "Klink Acetone",
  },
  {
    cargoTypeId: 1519,
    commodity: "LBC Acetone",
  },
  {
    cargoTypeId: 1520,
    commodity: "LBC Phenol",
  },
  {
    cargoTypeId: 1521,
    commodity: "Linear Alkylbenzene (LAB) Bottoms",
  },
  {
    cargoTypeId: 1522,
    commodity: "Lucite Acetone",
  },
  {
    cargoTypeId: 1523,
    commodity: "Methyl Carbitol PET GR",
  },
  {
    cargoTypeId: 1524,
    commodity: "Mitsui Acetone",
  },
  {
    cargoTypeId: 1525,
    commodity: "Mitsui Phenol",
  },
  {
    cargoTypeId: 1526,
    commodity: "MMA 25 MEHQ",
  },
  {
    cargoTypeId: 1527,
    commodity: "MMA 50 AO-30",
  },
  {
    cargoTypeId: 1528,
    commodity: "MMA 50 MEHQ",
  },
  {
    cargoTypeId: 1529,
    commodity: "Monoethanolamine",
  },
  {
    cargoTypeId: 1530,
    commodity: "Coal Tar Naphta",
  },
  {
    cargoTypeId: 1531,
    commodity: "Navigator Acetone",
  },
  {
    cargoTypeId: 1532,
    commodity: "N-Butanol",
  },
  {
    cargoTypeId: 1533,
    commodity: "Neodene 1112",
  },
  {
    cargoTypeId: 1534,
    commodity: "Neodene 16",
  },
  {
    cargoTypeId: 1535,
    commodity: "Neodene IO134",
  },
  {
    cargoTypeId: 1536,
    commodity: "Neopenthylglycol",
  },
  {
    cargoTypeId: 1537,
    commodity: "N-Propyl alcohol",
  },
  {
    cargoTypeId: 1538,
    commodity: "Petrolab P-550",
  },
  {
    cargoTypeId: 1539,
    commodity: "Petroleum Naphtha",
  },
  {
    cargoTypeId: 1540,
    commodity: "Poly(4+)isobutylene (MW>224)",
  },
  {
    cargoTypeId: 1541,
    commodity: "Piperylene",
  },
  {
    cargoTypeId: 1542,
    commodity: "Propylene Dichloride",
  },
  {
    cargoTypeId: 1543,
    commodity: "Pyrolysis Gasoline",
  },
  {
    cargoTypeId: 1544,
    commodity: "Sulphur Free Diesel",
  },
  {
    cargoTypeId: 1545,
    commodity: "Sulphur in Bulk",
  },
  {
    cargoTypeId: 1546,
    commodity: "Sulphuric Acid 96-99%",
  },
  {
    cargoTypeId: 1547,
    commodity: "Paraffin wax SX 50",
  },
  {
    cargoTypeId: 1548,
    commodity: "Paraffin wax SX 70",
  },
  {
    cargoTypeId: 1549,
    commodity: "Triethanolamine",
  },
  {
    cargoTypeId: 1550,
    commodity: "Solvent Neutral 150",
  },
  {
    cargoTypeId: 1551,
    commodity: "Alpha Olefins",
  },
  {
    cargoTypeId: 1552,
    commodity: "E GLYCOL P/E GR X",
  },
  {
    cargoTypeId: 1553,
    commodity: "ETHANOL FUEL GRADE",
  },
  {
    cargoTypeId: 1554,
    commodity: "ETHANOL NEUTRAL GRADE",
  },
  {
    cargoTypeId: 1555,
    commodity: "ETHANOL REN GRADE",
  },
  {
    cargoTypeId: 1556,
    commodity: "Hydrocarbons, Liquid, Petroleum Naphtha",
  },
  {
    cargoTypeId: 1557,
    commodity: "Palm Stearin IV15",
  },
  {
    cargoTypeId: 1558,
    commodity: "Luprocid NA1",
  },
  {
    cargoTypeId: 1559,
    commodity: "Refinery Heavy Reformate",
  },
  {
    cargoTypeId: 1560,
    commodity: "Solvanol",
  },
  {
    cargoTypeId: 1561,
    commodity: "220N",
  },
  {
    cargoTypeId: 1562,
    commodity: "AE-3",
  },
  {
    cargoTypeId: 1563,
    commodity: "BUCS",
  },
  {
    cargoTypeId: 1564,
    commodity: "C10 (Wash Oil)",
  },
  {
    cargoTypeId: 1565,
    commodity: "C5FRACTION",
  },
  {
    cargoTypeId: 1566,
    commodity: "Caradol MC28-02",
  },
  {
    cargoTypeId: 1567,
    commodity: "Caradol SA34-05",
  },
  {
    cargoTypeId: 1568,
    commodity: "Crokvitol",
  },
  {
    cargoTypeId: 1569,
    commodity: "Ethox Polyhydric Alcohol",
  },
  {
    cargoTypeId: 1570,
    commodity: "HOSO",
  },
  {
    cargoTypeId: 1571,
    commodity: "N150",
  },
  {
    cargoTypeId: 1572,
    commodity: "NA40",
  },
  {
    cargoTypeId: 1573,
    commodity: "Rubber Process Oil",
  },
  {
    cargoTypeId: 1574,
    commodity: "CEPAREX",
  },
  {
    cargoTypeId: 1575,
    commodity: "DAC",
  },
  {
    cargoTypeId: 1576,
    commodity: "Dimethyl Cyclics",
  },
  {
    cargoTypeId: 1577,
    commodity: "Heavy Cat Cracked Spirit",
  },
  {
    cargoTypeId: 1578,
    commodity: "Heavy Platformate SDO",
  },
  {
    cargoTypeId: 1579,
    commodity: "NEXNAPTHA",
  },
  {
    cargoTypeId: 1580,
    commodity: "Liquid Chemical Wastes",
  },
  {
    cargoTypeId: 1581,
    commodity: "OV",
  },
  {
    cargoTypeId: 1582,
    commodity: "Time Charter Hire",
  },
  {
    cargoTypeId: 1583,
    commodity: "DF",
  },
  {
    cargoTypeId: 1584,
    commodity: "Methyl Ethyl Ketone",
  },
  {
    cargoTypeId: 1585,
    commodity: "Ethylene Glycol",
  },
  {
    cargoTypeId: 1586,
    commodity: "Alcohols (C14-C18), primary, linear and essentially linear",
  },
  {
    cargoTypeId: 1587,
    commodity: "Alkanes (C10-C26) linear and branched",
  },
  {
    cargoTypeId: 1588,
    commodity: "Alpha Olefin C12/C14",
  },
  {
    cargoTypeId: 1589,
    commodity: "Benzene Brenntag",
  },
  {
    cargoTypeId: 1590,
    commodity: "Benzene Huntsman",
  },
  {
    cargoTypeId: 1591,
    commodity: "Benzene Total",
  },
  {
    cargoTypeId: 1592,
    commodity: "Betaine Molasses",
  },
  {
    cargoTypeId: 1593,
    commodity: "Butyl Acrylate 15 MEHQ",
  },
  {
    cargoTypeId: 1594,
    commodity: "Butyraldehyde",
  },
  {
    cargoTypeId: 1595,
    commodity: "C12 - Dodecene-1",
  },
  {
    cargoTypeId: 1596,
    commodity: "Alphaplus C24-28",
  },
  {
    cargoTypeId: 1597,
    commodity: "Alphaplus C30",
  },
  {
    cargoTypeId: 1598,
    commodity: "DC 95/11 (Drilling Fluids)",
  },
  {
    cargoTypeId: 1599,
    commodity: "Decene",
  },
  {
    cargoTypeId: 1600,
    commodity: "Demineralised water",
  },
  {
    cargoTypeId: 1601,
    commodity: "Diethylamine",
  },
  {
    cargoTypeId: 1602,
    commodity: "Alphaplus Dodecene",
  },
  {
    cargoTypeId: 1603,
    commodity: "Dowanol EB",
  },
  {
    cargoTypeId: 1604,
    commodity: "Escaid 120",
  },
  {
    cargoTypeId: 1605,
    commodity: "Alais of FAME",
  },
  {
    cargoTypeId: 1606,
    commodity: "Flux Oil",
  },
  {
    cargoTypeId: 1607,
    commodity: "GTL BO3",
  },
  {
    cargoTypeId: 1608,
    commodity: "Heavy Aromatics 9C",
  },
  {
    cargoTypeId: 1609,
    commodity: "Heavy Aromatics 9E",
  },
  {
    cargoTypeId: 1610,
    commodity: "Heavy Aromatics 9F",
  },
  {
    cargoTypeId: 1611,
    commodity: "Heavy Aromatics 9V",
  },
  {
    cargoTypeId: 1612,
    commodity: "Alphaplus Hexadecene-1 (C16)",
  },
  {
    cargoTypeId: 1613,
    commodity: "Hexamethylendiamine (HMD)",
  },
  {
    cargoTypeId: 1614,
    commodity: "Isobutylaldehyde",
  },
  {
    cargoTypeId: 1615,
    commodity: "Ketrul D80",
  },
  {
    cargoTypeId: 1616,
    commodity: "Marcol 82",
  },
  {
    cargoTypeId: 1617,
    commodity: "MCCS",
  },
  {
    cargoTypeId: 1618,
    commodity: "Methenamine",
  },
  {
    cargoTypeId: 1619,
    commodity: "NS 3",
  },
  {
    cargoTypeId: 1620,
    commodity: "Octadecene-1 (C18)",
  },
  {
    cargoTypeId: 1621,
    commodity: "Olefine C2024",
  },
  {
    cargoTypeId: 1622,
    commodity: "OLOA 262",
  },
  {
    cargoTypeId: 1623,
    commodity: "Olone",
  },
  {
    cargoTypeId: 1624,
    commodity: "Paraffin",
  },
  {
    cargoTypeId: 1625,
    commodity: "Primol 382",
  },
  {
    cargoTypeId: 1626,
    commodity: "Prowax 512",
  },
  {
    cargoTypeId: 1627,
    commodity: "Prowax 750",
  },
  {
    cargoTypeId: 1628,
    commodity: "Shellsol TD",
  },
  {
    cargoTypeId: 1629,
    commodity: "SM 150",
  },
  {
    cargoTypeId: 1630,
    commodity: "Solventis Acetone",
  },
  {
    cargoTypeId: 1631,
    commodity: "Somentor 32",
  },
  {
    cargoTypeId: 1632,
    commodity: "Spirdane D40",
  },
  {
    cargoTypeId: 1633,
    commodity: "Star 4",
  },
  {
    cargoTypeId: 1634,
    commodity: "T 150",
  },
  {
    cargoTypeId: 1635,
    commodity: "T 3",
  },
  {
    cargoTypeId: 1636,
    commodity: "T 400",
  },
  {
    cargoTypeId: 1637,
    commodity: "Tetradecene-1 (C14)",
  },
  {
    cargoTypeId: 1638,
    commodity: "Toluene Brenntag",
  },
  {
    cargoTypeId: 1639,
    commodity: "Toluene Covestro",
  },
  {
    cargoTypeId: 1640,
    commodity: "Toluene Emerald",
  },
  {
    cargoTypeId: 1641,
    commodity: "Toluene Ravago",
  },
  {
    cargoTypeId: 1642,
    commodity: "Toluene Total",
  },
  {
    cargoTypeId: 1643,
    commodity: "Toluene Tricon",
  },
  {
    cargoTypeId: 1644,
    commodity: "Triethylamine",
  },
  {
    cargoTypeId: 1645,
    commodity: "XHVI-3 (Base Oil)",
  },
  {
    cargoTypeId: 1646,
    commodity: "XHVI-4 (Base Oil)",
  },
  {
    cargoTypeId: 1647,
    commodity: "XHVI-8 (Base Oil)",
  },
  {
    cargoTypeId: 1648,
    commodity: "Xylene Brenntag",
  },
  {
    cargoTypeId: 1649,
    commodity: "Xylene Ravago",
  },
  {
    cargoTypeId: 1650,
    commodity: "Xylene Solventis",
  },
  {
    cargoTypeId: 1651,
    commodity: "Xylene Total",
  },
  {
    cargoTypeId: 1652,
    commodity: "Xylene Tricon",
  },
  {
    cargoTypeId: 1653,
    commodity: "Butyraldehyde (all Isomers)",
  },
  {
    cargoTypeId: 1654,
    commodity: "Crude Ethylene Dichloride",
  },
  {
    cargoTypeId: 1655,
    commodity: "Crude Solvent Naphtha",
  },
  {
    cargoTypeId: 1656,
    commodity: "Heavy Vacuum Gas Oil",
  },
  {
    cargoTypeId: 1657,
    commodity: "Oxyde Acetone",
  },
  {
    cargoTypeId: 1658,
    commodity: "Ultra Low Sulphur Gasoline",
  },
  {
    cargoTypeId: 1659,
    commodity: "Unleaded 95 Gasoline",
  },
  {
    cargoTypeId: 1660,
    commodity: "Steel Products",
  },
  {
    cargoTypeId: 1661,
    commodity: "Steel Slabs",
  },
  {
    cargoTypeId: 1662,
    commodity: "Butyl Butyrate (all Isomers)",
  },
  {
    cargoTypeId: 1663,
    commodity: "Exxal 8",
  },
  {
    cargoTypeId: 1664,
    commodity: "Exxal 13",
  },
  {
    cargoTypeId: 1665,
    commodity: "Ultra S2",
  },
  {
    cargoTypeId: 1666,
    commodity: "Neo Meg",
  },
  {
    cargoTypeId: 1667,
    commodity: "Orion Meg",
  },
  {
    cargoTypeId: 1668,
    commodity: "Ekopolimers MEG",
  },
  {
    cargoTypeId: 1669,
    commodity: "Ammonium Sulphate",
  },
  {
    cargoTypeId: 1670,
    commodity: "Alpha Olefins (C6-C18) Mixtures (n)",
  },
  {
    cargoTypeId: 1671,
    commodity: "Olefins (C13+, all Isomers)",
  },
  {
    cargoTypeId: 1672,
    commodity: "Cochin Coconut Oil",
  },
  {
    cargoTypeId: 1673,
    commodity: "Kerocom PIBA 03",
  },
  {
    cargoTypeId: 1674,
    commodity: "EXXAL 9S",
  },
  {
    cargoTypeId: 1675,
    commodity: "Metcoke",
  },
  {
    cargoTypeId: 1676,
    commodity: "Iron Ore",
  },
  {
    cargoTypeId: 1677,
    commodity: "MP 45",
  },
  {
    cargoTypeId: 1678,
    commodity: "Safol 23",
  },
  {
    cargoTypeId: 1679,
    commodity: "Value CA",
  },
  {
    cargoTypeId: 1680,
    commodity: "Neoflo 1-58",
  },
  {
    cargoTypeId: 1681,
    commodity: "Acetic Acid Kosher Grade",
  },
  {
    cargoTypeId: 1682,
    commodity: "Fatty Alcohol Mascol 24",
  },
  {
    cargoTypeId: 1683,
    commodity: "Spectrasyn 6",
  },
  {
    cargoTypeId: 1684,
    commodity: "Cotton Oil",
  },
  {
    cargoTypeId: 1685,
    commodity: "Kogasol 56",
  },
  {
    cargoTypeId: 1686,
    commodity: "Aggregates",
  },
  {
    cargoTypeId: 1687,
    commodity: "Diammonium Phosphate",
  },
  {
    cargoTypeId: 1688,
    commodity: "Voralux HL 108",
  },
  {
    cargoTypeId: 1689,
    commodity: "Ilmenite",
  },
  {
    cargoTypeId: 1690,
    commodity: "PMDI M229",
  },
  {
    cargoTypeId: 1691,
    commodity: "PMDI 1100 S",
  },
  {
    cargoTypeId: 1692,
    commodity: "Carbon anode blocks",
  },
  {
    cargoTypeId: 1693,
    commodity: "NB3043",
  },
  {
    cargoTypeId: 1694,
    commodity: "NB3060",
  },
  {
    cargoTypeId: 1695,
    commodity: "NB3080",
  },
  {
    cargoTypeId: 1696,
    commodity: "Spectrasyn 4",
  },
  {
    cargoTypeId: 1697,
    commodity: "Sabicol 1214",
  },
  {
    cargoTypeId: 1698,
    commodity: "Decene-1",
  },
  {
    cargoTypeId: 1699,
    commodity: "Waste Oil",
  },
  {
    cargoTypeId: 1700,
    commodity: "MP DIOL",
  },
  {
    cargoTypeId: 1701,
    commodity: "Steam Coal",
  },
  {
    cargoTypeId: 1702,
    commodity: "Fines",
  },
  {
    cargoTypeId: 1703,
    commodity: "Sulphur",
  },
  {
    cargoTypeId: 1704,
    commodity: "Green Delayed Petcoke",
  },
  {
    cargoTypeId: 1705,
    commodity: "Mineral Sands",
  },
  {
    cargoTypeId: 1706,
    commodity: "Directly Reduced Iron",
  },
  {
    cargoTypeId: 1707,
    commodity: "Calcined Petcoke",
  },
  {
    cargoTypeId: 1708,
    commodity: "Phosphates",
  },
  {
    cargoTypeId: 1709,
    commodity: "Copper Concentrates",
  },
  {
    cargoTypeId: 1710,
    commodity: "Coke",
  },
  {
    cargoTypeId: 1711,
    commodity: "Nickel Concentrates",
  },
  {
    cargoTypeId: 1712,
    commodity: "River Sands",
  },
  {
    cargoTypeId: 1713,
    commodity: "Pig Iron",
  },
  {
    cargoTypeId: 1714,
    commodity: "Nitrates",
  },
  {
    cargoTypeId: 1715,
    commodity: "Steel Pipes",
  },
  {
    cargoTypeId: 1716,
    commodity: "Ferrous Scrap",
  },
  {
    cargoTypeId: 1717,
    commodity: "Steel Rails",
  },
  {
    cargoTypeId: 1718,
    commodity: "Tyre Cord",
  },
  {
    cargoTypeId: 1719,
    commodity: "Steel Billet",
  },
  {
    cargoTypeId: 1720,
    commodity: "Mill Scale",
  },
  {
    cargoTypeId: 1721,
    commodity: "Zircon Sand",
  },
  {
    cargoTypeId: 1722,
    commodity: "Heavy Melting Scrap 1 + 2",
  },
  {
    cargoTypeId: 1723,
    commodity: "Steel Wire",
  },
  {
    cargoTypeId: 1724,
    commodity: "Manganese Sinter",
  },
  {
    cargoTypeId: 1725,
    commodity: "Ferrochrome",
  },
  {
    cargoTypeId: 1726,
    commodity: "Palm Kernel Expeller",
  },
  {
    cargoTypeId: 1727,
    commodity: "Chrome Sand",
  },
  {
    cargoTypeId: 1728,
    commodity: "Foundry Sands",
  },
  {
    cargoTypeId: 1729,
    commodity: "Mono-Ammonium Phosphate",
  },
  {
    cargoTypeId: 1730,
    commodity: "Neodene IO810",
  },
  {
    cargoTypeId: 1731,
    commodity: "Tapioca",
  },
  {
    cargoTypeId: 1732,
    commodity: "C12-C14 Alpha Olefines",
  },
  {
    cargoTypeId: 1733,
    commodity: "C14-C18 Alpha Olefines",
  },
  {
    cargoTypeId: 1734,
    commodity: "OLOA 200H",
  },
  {
    cargoTypeId: 1735,
    commodity: "Palm Oils",
  },
  {
    cargoTypeId: 1736,
    commodity: "Voranol 8595",
  },
  {
    cargoTypeId: 1737,
    commodity: "Base Oil 3.0cST",
  },
  {
    cargoTypeId: 1738,
    commodity: "Base Oil 4.0cST",
  },
  {
    cargoTypeId: 1739,
    commodity: "Base Oil 6.0cST",
  },
  {
    cargoTypeId: 1740,
    commodity: "Base Oil 8.0cST",
  },
  {
    cargoTypeId: 1741,
    commodity: "Escaid 100",
  },
  {
    cargoTypeId: 1742,
    commodity: "Iso-Butylene",
  },
  {
    cargoTypeId: 1743,
    commodity: "Nexbase 3043",
  },
  {
    cargoTypeId: 1744,
    commodity: "1-Hexene",
  },
  {
    cargoTypeId: 1745,
    commodity: "Prowax 411",
  },
  {
    cargoTypeId: 1746,
    commodity: "Propylene",
  },
  {
    cargoTypeId: 1747,
    commodity: "Isobutylene",
  },
  {
    cargoTypeId: 1748,
    commodity: "Ground Nut Oil",
  },
  {
    cargoTypeId: 1749,
    commodity: "Saraline",
  },
  {
    cargoTypeId: 1750,
    commodity: "BASF Acetone",
  },
  {
    cargoTypeId: 1751,
    commodity: "Condensate 5E",
  },
  {
    cargoTypeId: 1752,
    commodity: "Ethanol - Denatured",
  },
  {
    cargoTypeId: 1753,
    commodity: "Ethanol - Anhydrous Denatured",
  },
  {
    cargoTypeId: 1754,
    commodity: "Voranol 8322",
  },
  {
    cargoTypeId: 1755,
    commodity: "Voranol CP6001",
  },
  {
    cargoTypeId: 1756,
    commodity: "Voranol 8010G",
  },
  {
    cargoTypeId: 1757,
    commodity: "Toluene Diisocyanate T80TI",
  },
  {
    cargoTypeId: 1758,
    commodity: "Propylene Glycol Technical Grade",
  },
  {
    cargoTypeId: 1759,
    commodity: "Toluene Diisocyanate T80TI",
  },
  {
    cargoTypeId: 1760,
    commodity: "Base Oil XHVI-3",
  },
  {
    cargoTypeId: 1761,
    commodity: "Americas Core 100",
  },
  {
    cargoTypeId: 1762,
    commodity: "Americas Core 150",
  },
  {
    cargoTypeId: 1763,
    commodity: "Americas Core 600",
  },
  {
    cargoTypeId: 1764,
    commodity: "Americas Core 2500",
  },
  {
    cargoTypeId: 1765,
    commodity: "ASN 275",
  },
  {
    cargoTypeId: 1766,
    commodity: "BOP Cyclodiene",
  },
  {
    cargoTypeId: 1767,
    commodity: "DCPD",
  },
  {
    cargoTypeId: 1768,
    commodity: "EHC 60",
  },
  {
    cargoTypeId: 1769,
    commodity: "EHC 65",
  },
  {
    cargoTypeId: 1770,
    commodity: "EHC 120",
  },
  {
    cargoTypeId: 1771,
    commodity: "Exxal 8 Alcohol",
  },
  {
    cargoTypeId: 1772,
    commodity: "Exxal 13 Alcohol",
  },
  {
    cargoTypeId: 1773,
    commodity: "Exxal 10 Alcohol",
  },
  {
    cargoTypeId: 1774,
    commodity: "Exxsol D 130 Fluid",
  },
  {
    cargoTypeId: 1775,
    commodity: "Exxsol D 100 Fluid",
  },
  {
    cargoTypeId: 1776,
    commodity: "Exxsol D 80 Fluid",
  },
  {
    cargoTypeId: 1777,
    commodity: "Flint Hills 600",
  },
  {
    cargoTypeId: 1778,
    commodity: "IPE",
  },
  {
    cargoTypeId: 1779,
    commodity: "Isopar E Fluid",
  },
  {
    cargoTypeId: 1780,
    commodity: "Isopar V Fluid",
  },
  {
    cargoTypeId: 1781,
    commodity: "Jayflex DINP",
  },
  {
    cargoTypeId: 1782,
    commodity: "Neo Decanoic Acid",
  },
  {
    cargoTypeId: 1783,
    commodity: "Neodene 9",
  },
  {
    cargoTypeId: 1784,
    commodity: "Prowax 310",
  },
  {
    cargoTypeId: 1785,
    commodity: "Prowax 320",
  },
  {
    cargoTypeId: 1786,
    commodity: "Resin Oil C9",
  },
  {
    cargoTypeId: 1787,
    commodity: "Solvent Naphtha H",
  },
  {
    cargoTypeId: 1788,
    commodity: "Solvent Naphtha H ND",
  },
  {
    cargoTypeId: 1789,
    commodity: "Solvent Naphtha M",
  },
  {
    cargoTypeId: 1790,
    commodity: "Solvent Naphtha M ND",
  },
  {
    cargoTypeId: 1791,
    commodity: "Spectasyn 100",
  },
  {
    cargoTypeId: 1792,
    commodity: "Spectrasyn 40",
  },
  {
    cargoTypeId: 1793,
    commodity: "Spectrasyn Elite 150",
  },
  {
    cargoTypeId: 1794,
    commodity: "Tetramer BR",
  },
  {
    cargoTypeId: 1795,
    commodity: "Tetramer L",
  },
  {
    cargoTypeId: 1796,
    commodity: "Helm ACS Acetone",
  },
  {
    cargoTypeId: 1797,
    commodity: "Exxal 11 Alcohol",
  },
  {
    cargoTypeId: 1798,
    commodity: "EPOXY RESIN - D.E.R. 330",
  },
  {
    cargoTypeId: 1799,
    commodity: "GLYCOL ETHER DE",
  },
  {
    cargoTypeId: 1800,
    commodity: "GLYCOL ETHER DM",
  },
  {
    cargoTypeId: 1801,
    commodity: "DIPROPLYLENE GLYCOL INDUSTRIAL GRADE (DPG-I)",
  },
  {
    cargoTypeId: 1802,
    commodity: "DIPROPYLENE GLYCOL FRAGRANCE GRADE (LOW ODOR)",
  },
  {
    cargoTypeId: 1803,
    commodity: "ARCOSOLV DPM",
  },
  {
    cargoTypeId: 1804,
    commodity: "GLYCOL ETHER EB",
  },
  {
    cargoTypeId: 1805,
    commodity: "MPDIOL",
  },
  {
    cargoTypeId: 1806,
    commodity: "N-METHYL-2PYRROLIDONE",
  },
  {
    cargoTypeId: 1807,
    commodity: "PM SOLVENT",
  },
  {
    cargoTypeId: 1808,
    commodity: "PM ACETATE",
  },
  {
    cargoTypeId: 1809,
    commodity: "ARCONATE PROPYLENE CARBONATE",
  },
  {
    cargoTypeId: 1810,
    commodity: "TERT BUTYL ACETATE (TBA)",
  },
  {
    cargoTypeId: 1811,
    commodity: "TRIPROPYLENE GLYCOL ACRYLATE GRADE (TPG-A)",
  },
  {
    cargoTypeId: 1812,
    commodity: "C4s",
  },
  {
    cargoTypeId: 1813,
    commodity: "Butyl Di Glycol",
  },
  {
    cargoTypeId: 1814,
    commodity: "Maya Crude Oil",
  },
  {
    cargoTypeId: 1815,
    commodity: "Hamaca Crude Oil",
  },
  {
    cargoTypeId: 1816,
    commodity: "Vasconia Crude Oil",
  },
  {
    cargoTypeId: 1817,
    commodity: "Petrozuata Heavy Synthetic Crude",
  },
  {
    cargoTypeId: 1818,
    commodity: "Glacial Acetic Acid (GAA)",
  },
  {
    cargoTypeId: 1819,
    commodity: "Morichal 16 Crude Oil",
  },
  {
    cargoTypeId: 1820,
    commodity: "Kuwait Export Crude Oil",
  },
  {
    cargoTypeId: 1821,
    commodity: "Neodene 10IO",
  },
  {
    cargoTypeId: 1822,
    commodity: "Heartcut Distillate",
  },
  {
    cargoTypeId: 1823,
    commodity: "Cepsa Acetone",
  },
  {
    cargoTypeId: 1824,
    commodity: "DPG FG",
  },
  {
    cargoTypeId: 1825,
    commodity: "High Flash Kero",
  },
  {
    cargoTypeId: 1826,
    commodity: "Clairsol",
  },
  {
    cargoTypeId: 1827,
    commodity: "Premium Gasoline",
  },
  {
    cargoTypeId: 1828,
    commodity: "Ketrul D70",
  },
  {
    cargoTypeId: 1829,
    commodity: "WVNS SRAR",
  },
  {
    cargoTypeId: 1830,
    commodity: "VGO",
  },
  {
    cargoTypeId: 1831,
    commodity: "Tudalen 4529",
  },
  {
    cargoTypeId: 1832,
    commodity: "Ago",
  },
  {
    cargoTypeId: 1833,
    commodity: "Aluminium",
  },
  {
    cargoTypeId: 1834,
    commodity: "Aluminium Hydroxide",
  },
  {
    cargoTypeId: 1835,
    commodity: "Azeri Crude Oil",
  },
  {
    cargoTypeId: 1836,
    commodity: "Bintulu Crude Oil",
  },
  {
    cargoTypeId: 1837,
    commodity: "Black Oil",
  },
  {
    cargoTypeId: 1838,
    commodity: "Black Products",
  },
  {
    cargoTypeId: 1839,
    commodity: "Breakbulk",
  },
  {
    cargoTypeId: 1840,
    commodity: "Bulk",
  },
  {
    cargoTypeId: 1841,
    commodity: "Carbon Black Oil",
  },
  {
    cargoTypeId: 1842,
    commodity: "Clean Crude Oil",
  },
  {
    cargoTypeId: 1843,
    commodity: "Clean Fuel Oil",
  },
  {
    cargoTypeId: 1844,
    commodity: "Coke Breeze",
  },
  {
    cargoTypeId: 1845,
    commodity: "Concentrates",
  },
  {
    cargoTypeId: 1846,
    commodity: "Dry Cargo",
  },
  {
    cargoTypeId: 1847,
    commodity: "Furnace Oil",
  },
  {
    cargoTypeId: 1848,
    commodity: "Gen Cargo",
  },
  {
    cargoTypeId: 1849,
    commodity: "General Bulk",
  },
  {
    cargoTypeId: 1850,
    commodity: "HBI",
  },
  {
    cargoTypeId: 1851,
    commodity: "Kirkuk Crude Oil",
  },
  {
    cargoTypeId: 1852,
    commodity: "Light Crude Oil",
  },
  {
    cargoTypeId: 1853,
    commodity: "Malampaya Condensate",
  },
  {
    cargoTypeId: 1854,
    commodity: "Methonal",
  },
  {
    cargoTypeId: 1855,
    commodity: "Murban Crude Oil",
  },
  {
    cargoTypeId: 1856,
    commodity: "Nickel Ore",
  },
  {
    cargoTypeId: 1857,
    commodity: "Petrol",
  },
  {
    cargoTypeId: 1858,
    commodity: "Petroleum",
  },
  {
    cargoTypeId: 1859,
    commodity: "Petroleum Products",
  },
  {
    cargoTypeId: 1860,
    commodity: "Products",
  },
  {
    cargoTypeId: 1861,
    commodity: "Pyrites",
  },
  {
    cargoTypeId: 1862,
    commodity: "Rebco",
  },
  {
    cargoTypeId: 1863,
    commodity: "Refined",
  },
  {
    cargoTypeId: 1864,
    commodity: "Sand",
  },
  {
    cargoTypeId: 1865,
    commodity: "Silica Sand",
  },
  {
    cargoTypeId: 1866,
    commodity: "Soya",
  },
  {
    cargoTypeId: 1867,
    commodity: "Tapis Blend Crude Oil",
  },
  {
    cargoTypeId: 1868,
    commodity: "Timber",
  },
  {
    cargoTypeId: 1869,
    commodity: "Transit Route",
  },
  {
    cargoTypeId: 1870,
    commodity: "Various Bulk",
  },
  {
    cargoTypeId: 1871,
    commodity: "White Cargo",
  },
  {
    cargoTypeId: 1872,
    commodity: "White Products",
  },
  {
    cargoTypeId: 1873,
    commodity: "Lotte Meg",
  },
  {
    cargoTypeId: 1874,
    commodity: "Naphthalene (Crude)",
  },
  {
    cargoTypeId: 1875,
    commodity: "Polymogas",
  },
  {
    cargoTypeId: 1876,
    commodity: "Spectrasyn Plus 3.6",
  },
  {
    cargoTypeId: 1877,
    commodity: "Base Oil 2.0cST",
  },
  {
    cargoTypeId: 1878,
    commodity: "Integra Phenol",
  },
  {
    cargoTypeId: 1879,
    commodity: "Gasoline Blendstock",
  },
  {
    cargoTypeId: 1880,
    commodity: "Blendstock (BHC)",
  },
  {
    cargoTypeId: 1881,
    commodity: "peg400",
  },
  {
    cargoTypeId: 1882,
    commodity: "Voranol NC632",
  },
  {
    cargoTypeId: 1883,
    commodity: "Caradol SP30-47",
  },
  {
    cargoTypeId: 1884,
    commodity: "Crude Corn Oil",
  },
  {
    cargoTypeId: 1885,
    commodity: "Octanol",
  },
  {
    cargoTypeId: 1886,
    commodity: "ISOPAR N",
  },
  {
    cargoTypeId: 1887,
    commodity: "Isopar G",
  },
  {
    cargoTypeId: 1888,
    commodity: "Crude Palm Oil (ISCC)",
  },
  {
    cargoTypeId: 1889,
    commodity: "Prowax 401",
  },
  {
    cargoTypeId: 1890,
    commodity: "Transmix",
  },
  {
    cargoTypeId: 1891,
    commodity: "RBD PALM OIL SG",
  },
  {
    cargoTypeId: 1892,
    commodity: "RBD PALM OIL SG",
  },
  {
    cargoTypeId: 1893,
    commodity: "RBD Palm Stearin IV34",
  },
  {
    cargoTypeId: 1894,
    commodity: "PMF IV45 DK",
  },
  {
    cargoTypeId: 1895,
    commodity: "Lauric Acid",
  },
  {
    cargoTypeId: 1896,
    commodity: "RBD Palm Oil SG",
  },
  {
    cargoTypeId: 1897,
    commodity: "RBD Palm Oil SG",
  },
  {
    cargoTypeId: 1898,
    commodity: "RBD PMF IV45 UK",
  },
  {
    cargoTypeId: 1899,
    commodity: "RBD Paml Kernel Stearin IV7 SG",
  },
  {
    cargoTypeId: 1900,
    commodity: "RBD Paml Kernel Oil MB",
  },
  {
    cargoTypeId: 1901,
    commodity: "NBD Palm Stearin IV34 SG",
  },
  {
    cargoTypeId: 1902,
    commodity: "RBD Palm Kernel Oil MB",
  },
  {
    cargoTypeId: 1903,
    commodity: "Nessol D100",
  },
  {
    cargoTypeId: 1904,
    commodity: "Hexene SP",
  },
  {
    cargoTypeId: 1905,
    commodity: "ECH 65",
  },
  {
    cargoTypeId: 1906,
    commodity: "ECH 120",
  },
  {
    cargoTypeId: 1907,
    commodity: "Spectrasyn 100",
  },
  {
    cargoTypeId: 1908,
    commodity: "Isohexane SP",
  },
  {
    cargoTypeId: 1909,
    commodity: "ME1214",
  },
  {
    cargoTypeId: 1910,
    commodity: "SPKFAD",
  },
  {
    cargoTypeId: 1911,
    commodity: "SOLVENT NAPHTHA L",
  },
  {
    cargoTypeId: 1912,
    commodity: "Isoprene Monomer",
  },
  {
    cargoTypeId: 1913,
    commodity: "SPLIT PARM KERNEL FATTY ACID DISTILLATE (SPKFAD)",
  },
  {
    cargoTypeId: 1914,
    commodity: "SPLIT PALM KERNEL FATTY ACID DISTILLATE (SPKFAD)",
  },
  {
    cargoTypeId: 1915,
    commodity: "Unleaded 97 Gasoline",
  },
  {
    cargoTypeId: 1916,
    commodity: "Hexane SP",
  },
  {
    cargoTypeId: 1917,
    commodity: "Exxsol DSP 100/140",
  },
  {
    cargoTypeId: 1918,
    commodity: "SN 700",
  },
  {
    cargoTypeId: 1919,
    commodity: "Soda Ash",
  },
  {
    cargoTypeId: 1920,
    commodity: "NBD PALM OLEIN IV56 SG",
  },
  {
    cargoTypeId: 1921,
    commodity: "RBD PALM OLEIN IV56 MB",
  },
  {
    cargoTypeId: 1922,
    commodity: "RBD PALM STEARIN IV12",
  },
  {
    cargoTypeId: 1923,
    commodity: "RBD PALM KERNEL STEARIN IV7",
  },
  {
    cargoTypeId: 1924,
    commodity: "RBD PMF IV45 DK",
  },
  {
    cargoTypeId: 1925,
    commodity: "RBD PMF IV45 SE",
  },
  {
    cargoTypeId: 1926,
    commodity: "PAO HFFA",
  },
  {
    cargoTypeId: 1927,
    commodity: "OLEIC FATTY ACID",
  },
  {
    cargoTypeId: 1928,
    commodity: "HPFAD",
  },
  {
    cargoTypeId: 1929,
    commodity: "Caromax 28E",
  },
  {
    cargoTypeId: 1930,
    commodity: "Caromax 28A",
  },
  {
    cargoTypeId: 1931,
    commodity: "Rutile",
  },
  {
    cargoTypeId: 1932,
    commodity: "SCN - Pygas",
  },
  {
    cargoTypeId: 1933,
    commodity: "SCN - Pygas",
  },
  {
    cargoTypeId: 1934,
    commodity: "Liquefied Natural Gas (LNG)",
  },
  {
    cargoTypeId: 1935,
    commodity: "Normal Butane (LPG)",
  },
  {
    cargoTypeId: 1936,
    commodity: "Ammonia (liquid) (LPG)",
  },
  {
    cargoTypeId: 1937,
    commodity: "Liquefied Petroleum Gas (LPG)",
  },
  {
    cargoTypeId: 1938,
    commodity: "MGN (methylglutaronitrile)",
  },
  {
    cargoTypeId: 1939,
    commodity: "PALMITIC ACID",
  },
  {
    cargoTypeId: 1940,
    commodity: "Bio-Naphtha",
  },
  {
    cargoTypeId: 1941,
    commodity: "Vehicles",
  },
  {
    cargoTypeId: 1942,
    commodity: "Paper/Pulp",
  },
  {
    cargoTypeId: 1943,
    commodity: "DK1",
  },
  {
    cargoTypeId: 1944,
    commodity: "Light Hydro",
  },
  {
    cargoTypeId: 1945,
    commodity: "MCB",
  },
  {
    cargoTypeId: 1946,
    commodity: "EBOB",
  },
  {
    cargoTypeId: 1947,
    commodity: "ATRES",
  },
  {
    cargoTypeId: 1948,
    commodity: "LSSR",
  },
  {
    cargoTypeId: 1949,
    commodity: "Formulation Water",
  },
  {
    cargoTypeId: 1950,
    commodity: "WaxRex 1270",
  },
  {
    cargoTypeId: 1951,
    commodity: "Sommentor 34",
  },
  {
    cargoTypeId: 1952,
    commodity: "EHC50 US",
  },
  {
    cargoTypeId: 1953,
    commodity: "Amasil Na",
  },
  {
    cargoTypeId: 1954,
    commodity: "Luprocid Na",
  },
  {
    cargoTypeId: 1955,
    commodity: "DPHG",
  },
  {
    cargoTypeId: 1956,
    commodity: "PMF IV 33",
  },
  {
    cargoTypeId: 1957,
    commodity: "Petrinex 6R",
  },
  {
    cargoTypeId: 1958,
    commodity: "Caromax 28 LNA",
  },
  {
    cargoTypeId: 1959,
    commodity: "GTL Diesel",
  },
  {
    cargoTypeId: 1960,
    commodity: "GTL Naphtha",
  },
  {
    cargoTypeId: 1961,
    commodity: "UNDENATURED GNS",
  },
  {
    cargoTypeId: 1962,
    commodity: "DENATURED GNS (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1963,
    commodity: "DENATURED ANHYDROUS GNS (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1964,
    commodity: "DENATURED ANHYDROUS SYNTHETIC (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1965,
    commodity: "UNDENATURED ANHYDROUS GRADE B",
  },
  {
    cargoTypeId: 1966,
    commodity: "DENATURED ANHYDROUS GRADE B (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1967,
    commodity: "DENATURED HYDROUS GRADE B (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1968,
    commodity: "UNDENATURED ANHYDROUS FUEL",
  },
  {
    cargoTypeId: 1969,
    commodity: "DENATURED ANHYDROUS FUEL (DENATURED WITH BITREX)",
  },
  {
    cargoTypeId: 1970,
    commodity: "DENATURED ANHYDROUS FUEL (DENATURED WITH GASOLINE)",
  },
  {
    cargoTypeId: 1971,
    commodity: "Primol 352",
  },
  {
    cargoTypeId: 1972,
    commodity: "ME1216",
  },
  {
    cargoTypeId: 1973,
    commodity: "Solvesso 150",
  },
  {
    cargoTypeId: 1974,
    commodity: "Baseoil SN600",
  },
  {
    cargoTypeId: 1975,
    commodity: "Nexbase 3080",
  },
  {
    cargoTypeId: 1976,
    commodity: "Solvesso 100",
  },
  {
    cargoTypeId: 1977,
    commodity: "French BOB",
  },
  {
    cargoTypeId: 1978,
    commodity: "Eurograde",
  },
  {
    cargoTypeId: 1979,
    commodity: "Nessol D100+",
  },
  {
    cargoTypeId: 1980,
    commodity: "SOLGAD",
  },
  {
    cargoTypeId: 1981,
    commodity: "SOLGAD 150",
  },
  {
    cargoTypeId: 1982,
    commodity: "SOLGAD 150 ULN",
  },
  {
    cargoTypeId: 1983,
    commodity: "SOLGAD 100",
  },
  {
    cargoTypeId: 1984,
    commodity: "EB Solvent",
  },
  {
    cargoTypeId: 1985,
    commodity: "Unleaded Gasoline Low Aromatic (ULPLA)",
  },
  {
    cargoTypeId: 1986,
    commodity: "Hard IP Soybean Oil - (CR IP)",
  },
  {
    cargoTypeId: 1987,
    commodity: "High Oleic Sunflower seed oil - (HOSUN)",
  },
  {
    cargoTypeId: 1988,
    commodity: "Semi Refined Cotton oil - (SR CSO)",
  },
  {
    cargoTypeId: 1989,
    commodity: "CRUDE PALM OLEIN (COL)",
  },
  {
    cargoTypeId: 1990,
    commodity: "Ethylol 99",
  },
  {
    cargoTypeId: 1991,
    commodity: "Iso-Propylol",
  },
  {
    cargoTypeId: 1992,
    commodity: "Ethylol 95",
  },
  {
    cargoTypeId: 1993,
    commodity: "Sumitomo Phenol",
  },
  {
    cargoTypeId: 1994,
    commodity: "Nexbase 3060",
  },
  {
    cargoTypeId: 1995,
    commodity: "PMDI 27",
  },
  {
    cargoTypeId: 1996,
    commodity: "VORALUX",
  },
  {
    cargoTypeId: 1997,
    commodity: "VORALUX HL420",
  },
  {
    cargoTypeId: 1998,
    commodity: "NEODOL 1",
  },
  {
    cargoTypeId: 1999,
    commodity: "Ethanol 99.9 UN",
  },
  {
    cargoTypeId: 2000,
    commodity: "Ethanol 99.9 UN UHPE",
  },
  {
    cargoTypeId: 2001,
    commodity: "Bio Fuel Oil",
  },
  {
    cargoTypeId: 2002,
    commodity: "RBD Palm Olein IV56",
  },
  {
    cargoTypeId: 2003,
    commodity: "RBD Palm Olein IV56 LFFA",
  },
  {
    cargoTypeId: 2004,
    commodity: "RBD Palm Kernel Stearin IV7",
  },
  {
    cargoTypeId: 2005,
    commodity: "POME (Palm Oil Mill Effluent)",
  },
  {
    cargoTypeId: 2006,
    commodity: "Renewable Diesel",
  },
  {
    cargoTypeId: 2007,
    commodity: "ARCOL POLYOL",
  },
  {
    cargoTypeId: 2008,
    commodity: "Muriate of Potash (MOP)",
  },
  {
    cargoTypeId: 2009,
    commodity: "Potash",
  },
  {
    cargoTypeId: 2010,
    commodity: "RBD PMF IV33 SG",
  },
  {
    cargoTypeId: 2011,
    commodity: "RBD PALM KERNEL OIL SG",
  },
  {
    cargoTypeId: 2012,
    commodity: "IBC name ALCOHOL (C12-C16) POLY(7-19)ETHOXYLATES",
  },
  {
    cargoTypeId: 2013,
    commodity: "ALCOHOL (C12-C16) POLY(7-19)ETHOXYLATES",
  },
  {
    cargoTypeId: 2014,
    commodity: "SABICOL L7",
  },
  {
    cargoTypeId: 2015,
    commodity: "Ground Granulated Blast Furnace Slag (GGBFS )",
  },
  {
    cargoTypeId: 2016,
    commodity: "Granulated Blast Furnace Slag (GBFS)",
  },
  {
    cargoTypeId: 2017,
    commodity: "Dry Fly Ash",
  },
  {
    cargoTypeId: 2018,
    commodity: "Wet Fly Ash",
  },
  {
    cargoTypeId: 2019,
    commodity: "Bottom Fly Ash",
  },
  {
    cargoTypeId: 2020,
    commodity: "FGD Gypsum",
  },
  {
    cargoTypeId: 2021,
    commodity: "Iron Silicate Stone (ISS)",
  },
  {
    cargoTypeId: 2022,
    commodity: "Iron Silicate Granules (ISG)",
  },
  {
    cargoTypeId: 2023,
    commodity: "Cement in containers",
  },
  {
    cargoTypeId: 2024,
    commodity: "Ground Granulated Blast Furnace Slag (GGBFS)",
  },
  {
    cargoTypeId: 2025,
    commodity: "Bitumen 400R",
  },
  {
    cargoTypeId: 2026,
    commodity: "BUTYL TRI GLYCOL",
  },
  {
    cargoTypeId: 2027,
    commodity: "Pygas",
  },
  {
    cargoTypeId: 2028,
    commodity: "Toluene Equinor",
  },
  {
    cargoTypeId: 2029,
    commodity: "TDAE",
  },
  {
    cargoTypeId: 2030,
    commodity: "TRAE",
  },
  {
    cargoTypeId: 2031,
    commodity: "DER 331",
  },
  {
    cargoTypeId: 2032,
    commodity: "Isobutyl Acetate",
  },
  {
    cargoTypeId: 2033,
    commodity: "100 Octane",
  },
  {
    cargoTypeId: 2034,
    commodity: "RBD Palm Kernel Olein",
  },
  {
    cargoTypeId: 2035,
    commodity: "Prowax 610",
  },
  {
    cargoTypeId: 2036,
    commodity: "Prowax 660",
  },
  {
    cargoTypeId: 2037,
    commodity: "Heavy Cat Cracked Gasoline (HCCG)",
  },
  {
    cargoTypeId: 2038,
    commodity: "Carbon Tetrachloride Technical",
  },
  {
    cargoTypeId: 2039,
    commodity: "fr pmf IV33",
  },
  {
    cargoTypeId: 2040,
    commodity: "fr poo IV61",
  },
  {
    cargoTypeId: 2041,
    commodity: "Shea Butter",
  },
  {
    cargoTypeId: 2042,
    commodity: "C Shea oil",
  },
  {
    cargoTypeId: 2043,
    commodity: "C Shea Stearin IV36",
  },
  {
    cargoTypeId: 2044,
    commodity: "RX OBL 801",
  },
  {
    cargoTypeId: 2045,
    commodity: "nytex 4700",
  },
  {
    cargoTypeId: 2046,
    commodity: "nytro taurus",
  },
  {
    cargoTypeId: 2047,
    commodity: "nytex 810",
  },
  {
    cargoTypeId: 2048,
    commodity: "nytex 840",
  },
  {
    cargoTypeId: 2049,
    commodity: "nytro lyra-x",
  },
  {
    cargoTypeId: 2050,
    commodity: "Benzene Count Energy",
  },
  {
    cargoTypeId: 2051,
    commodity: "Urea (Bagged)",
  },
  {
    cargoTypeId: 2052,
    commodity: "Nitrate of Potash (NOP)",
  },
  {
    cargoTypeId: 2053,
    commodity: "Nitrogen, phosphorus, and potassium - (NPK)",
  },
  {
    cargoTypeId: 2054,
    commodity: "Urea (Granular)",
  },
  {
    cargoTypeId: 2055,
    commodity: "Heavy Wax Distillate",
  },
  {
    cargoTypeId: 2056,
    commodity: "Urea (Prilled)",
  },
  {
    cargoTypeId: 2057,
    commodity: "MEG TG",
  },
  {
    cargoTypeId: 2058,
    commodity: "Toluene BASF",
  },
  {
    cargoTypeId: 2059,
    commodity: "YUBASE 3",
  },
  {
    cargoTypeId: 2060,
    commodity: "TSP (Triple Super Phosphate)",
  },
  {
    cargoTypeId: 2061,
    commodity: "Triple Super Phosphate (TSP)",
  },
  {
    cargoTypeId: 2062,
    commodity: "Kocosol 150",
  },
  {
    cargoTypeId: 2063,
    commodity: "Crude Sunflower Oil",
  },
  {
    cargoTypeId: 2064,
    commodity: "high oleic sunflower oil",
  },
  {
    cargoTypeId: 2065,
    commodity: "Mixed Soft Acid Oil",
  },
  {
    cargoTypeId: 2066,
    commodity: "Rape Seed Oil",
  },
  {
    cargoTypeId: 2067,
    commodity: "Crude Degummed Rapeseed Oil",
  },
  {
    cargoTypeId: 2068,
    commodity: "Refined Palm Oil",
  },
  {
    cargoTypeId: 2069,
    commodity: "Crude Fatty Acid Distillate",
  },
  {
    cargoTypeId: 2070,
    commodity: "Sunflower oil",
  },
  {
    cargoTypeId: 2071,
    commodity: "Altis Acetone",
  },
  {
    cargoTypeId: 2072,
    commodity: "nytex 8012",
  },
  {
    cargoTypeId: 2073,
    commodity: "Kolmar Phenol",
  },
  {
    cargoTypeId: 2074,
    commodity: "Soya Hull Pellets",
  },
  {
    cargoTypeId: 2075,
    commodity: "HWD",
  },
  {
    cargoTypeId: 2076,
    commodity: "E10 BOB",
  },
  {
    cargoTypeId: 2077,
    commodity: "RBD PALM OLEIN IV64",
  },
  {
    cargoTypeId: 2078,
    commodity: "Yubase 4 Plus",
  },
  {
    cargoTypeId: 2079,
    commodity: "Fluorspar",
  },
  {
    cargoTypeId: 2080,
    commodity: "High Sulphur Diesel",
  },
  {
    cargoTypeId: 2081,
    commodity: "E5 BOB",
  },
  {
    cargoTypeId: 2082,
    commodity: "E5 BOB",
  },
  {
    cargoTypeId: 2083,
    commodity: "Hydrotreated Vegetable Oil (HVO)",
  },
  {
    cargoTypeId: 2084,
    commodity: "Toluene ICC Handels GmbH",
  },
  {
    cargoTypeId: 2176,
    commodity: "Iron Ore Briquettes",
  },
  {
    cargoTypeId: 2111,
    commodity: "Alloy",
  },
];

interface CargoType {
  cargoTypeId: number;
  commodity: string;
}
