import React, { memo, ReactNode } from "react";
import "./Main.scss";

function Main(props: Props) {
  const { children } = props;

  return <main data-test="main">{children}</main>;
}

const Memo = memo(Main);

export { Memo as Main };

interface Props {
  children: ReactNode;
}
