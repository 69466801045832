import { observable, decorate, computed, action } from "mobx";
import uuid from "uuid";

class UnsavedChanges {
  get exist() {
    const dependencies = Object.values(this.dependencies);

    return dependencies.some((fn) => fn());
  }

  dependencies: Dependencies = {};

  register = (dependency: Dependency): Unregister => {
    const id = uuid();

    this.dependencies[id] = dependency;

    return this.unregister.bind(null, id);
  };

  unregister = (id: string) => {
    const dependencies = { ...this.dependencies };

    delete dependencies[id];

    this.dependencies = dependencies;
  };
}

decorate(UnsavedChanges, {
  exist: computed,
  dependencies: observable,
  register: action,
  unregister: action,
});

export const unsavedChanges = new UnsavedChanges();

interface Dependencies {
  [id: string]: Dependency;
}

type Dependency = () => boolean;
type Unregister = () => void;
