import React from "react";
import classNames from "classnames";
import { NumberField, SelectField, SelectFieldProps, StatusMessages, TextareaField } from "@/components";
import { Group } from "../../group";
import { Row } from "../../row";
import "./Cargo.size.scss";

export function CargoSize(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "cargo-size");

  const { className, status, ...rest } = props;

  const value = props.value || defaultCargoSize;

  function onChange(name: keyof CargoSize, inputValue: string | number | null) {
    props.onChange?.({ ...value, [name]: inputValue });
  }

  function onOptionChange(option: Option) {
    const next = { ...value, option };

    if (option === "MIN/MAX") next.variance = null;

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...value, notes });
  }

  return (
    <Group className={className} label="Cargo Size">
      <Row>
        <NumberField
          {...rest}
          label="Size"
          desc="Cargo weight"
          placeholder="Metric tons, e.g. 120,000"
          status={status?.children?.value}
          value={value.value}
          onChange={onChange.bind(null, "value")}
          dataTest={`${props.dataTest}/cargo-size-value`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="+ / - %"
          placeholder="e.g. 3"
          desc="Cargo weight variance (%)"
          className={classNames("cargo-size-variance", {
            hidden: value.option === "MIN/MAX",
          })}
          status={status?.children?.variance}
          value={value.variance}
          tabIndex={value.option === "MIN/MAX" ? -1 : props.tabIndex}
          onChange={onChange.bind(null, "variance")}
          dataTest={`${props.dataTest}/cargo-size-variance`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Option"
          className="cargo-size-option"
          desc={`Cargo size option
      
      MIN/MAX - the lowest / highest acceptable load weight.
      
      MOLCHOP - More or Less in Charterer's Option - Option allowed to a voyage charterer to load up to a certain quantity, normally expressed as a percentage or a number of tons, over or under a quantity specified in the contract of carriage. This option may be sought if the charterer is not certain of the exact quantity that will be available at the time of loading.
      
      MOLOO - More or Less in Owner's Option - Option allowed to a shipowner to carry up to a certain quantity, normally expressed as a percentage or number of tons, over or under a quantity specified in the voyage charter. This option may be sought if the shipowner is not certain what the ship's cargo capacity will be, taking into consideration bunkers, stores and fresh water, or if he wants flexibility to adjust the ship's trim.
      `}
          data={OPTIONS}
          status={status?.children?.option}
          value={value.option}
          onChange={onOptionChange}
          dataTest={`${props.dataTest}/cargo-size-option`}
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <TextareaField
        {...props}
        label="Notes"
        desc="Cargo size notes"
        className="trade-cargo-size-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-cargo-size-notes`}
      />
      <StatusMessages status={status} />
    </Group>
  );
}

const defaultCargoSize = {
  value: null,
  variance: null,
  option: null,
  notes: null,
};

const OPTIONS = ["MIN/MAX", "MOLOO", "MOLCHOPT"] as const;

export interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: CargoSize | null;
  onChange?: (value: CargoSize) => void;
}

interface CargoSize {
  value?: null | number;
  unit?: null | string;
  variance?: null | number;
  option?: null | Option;
  notes?: null | string;
}

type Option = (typeof OPTIONS)[number];

export type CargoSizeType = CargoSize;
export type FocusEvent = React.FocusEvent<HTMLInputElement>;
