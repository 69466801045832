import { RECORDS_PER_PAGE } from "@/constants";
import axios, { AxiosInstance, CancelToken, AxiosRequestConfig } from "axios";
import { Response } from "@/axios";
import { auth, termsetLayouts } from "@/model";
import { config } from "@/config";
import { JSONSchema7 } from "json-schema";

class Trade {
  setup = async () => {
    this.axios = axios.create({ baseURL: config.tradeURL });

    await auth.trade.promise;

    Object.assign(this.axios.defaults.headers.common, {
      "clarksons.cloud.logintoken": auth.central.token,
      authorization: `Bearer ${auth.trade.token}`,
    });
  };

  // prettier-ignore
  getToken = async (centralToken = auth.central.token, config?: AxiosRequestConfig): GetTokenRes => {
    const res = await this.axios.post("/token?admin", null, {
      // @ts-ignore
      ignore401: true,
      headers: {
        "clarksons.cloud.logintoken": centralToken,
        ...config?.headers,
      },
      ...config,
    });

    return res;
  };

  // prettier-ignore
  searchOrderTemplates = async (search: string, localFilter?: TradeLocalFilter, cancelToken?: CancelToken): SearchOrderTemplatesRes => {
    const url = "/v1/admin/ordertemplates?";
    const urlParams = new URLSearchParams({ encodedTerm: search });

    if(localFilter?.orderType) {
      urlParams.append("orderType", localFilter.orderType);
    }
    if(localFilter?.companyId) {
      urlParams.append("companyId", localFilter.companyId);
    }

    return await this.axios.get(`${url}${urlParams}`, { cancelToken });   
  };

  // prettier-ignore
  getOrderTemplate = async (id: string, companyId?: string | null): GetOrderTemplateRes => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const res = await this.axios.get(`/v1/admin/ordertemplates/${id}/${companyId}`);

    return res;
  };

  // prettier-ignore
  putOrderTemplate = async (data: TradeOrderTemplate, companyId?: string | null): PutOrderTemplateRes => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId || "" : "";
    data = sanitizeEntity(data);

    const res = await this.axios.put(`/v1/admin/ordertemplates/${companyId}`, data);

    return res;
  };

  // prettier-ignore
  deleteOrderTemplate = async (id: string, companyId?: string | null): DeleteOrderTemplateRes => { 
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const res = await this.axios.delete(
      `/v1/admin/ordertemplates/${id}/${companyId}`
    );

    return res;
  };

  getOrderTemplateSchema = async (): GetOrderTemplateSchemaRes => {
    const res = await this.axios.get("/ordertemplates/schema");

    return res;
  };

  // prettier-ignore
  searchTermsets = async (search: string, localFilter?: TradeLocalFilter, cancelToken?: CancelToken, pageNumber?: number): GetTermsetsRes => {
    const url = "/v1/admin/termsets?";
    const urlParams = new URLSearchParams({ encodedTerm: search });
    const take = RECORDS_PER_PAGE;
    const skip = pageNumber ? RECORDS_PER_PAGE * (pageNumber - 1) : 0; 
    // Separated as URLSearchParams should be string values
    const paginationUrlParams = `&take=${take}&skip=${skip}`;

    if(localFilter?.termsetType) {
      urlParams.append("termsetType", localFilter.termsetType);
    }
    if(localFilter?.companyId) {
      urlParams.append("companyId", localFilter.companyId);
    }
    if(localFilter?.["content.proformaLayoutId"]) {
      const proformaLayoutName = termsetLayouts.dataById[localFilter["content.proformaLayoutId"]]?.data?.name;

      if(proformaLayoutName) {
        urlParams.append("proformaLayoutName", proformaLayoutName);
      }
    }

    return await this.axios.get(`${url}${urlParams}${paginationUrlParams}`, { cancelToken }); 
  };

  getTermsets = async (): GetTermsetsRes => {
    const res = await this.axios.get(`/termsets`);

    return res;
  };

  // prettier-ignore
  putTermset = async (data: TradeTermset, companyId?: string | null): PutTermsetRes => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId || "" : "";
    data = sanitizeEntity(data);

    const res = await this.axios.put(`/v1/admin/termsets/${companyId}`, data);

    return res;
  };

  // prettier-ignore
  validateTermset = async (data: TradeTermset): ValidateTermsetRes => {
    data = sanitizeEntity(data);
    data.content =  {...data.content,
       templateDetails : { displayName: data.name },
       orderDetails: {
        // shared section
        orderTypeId: data.typeId === 3 ? 1 : data.typeId, // arc does not have definition of coa, using voy instead as walkaround
        arcContactId: 6023,
        cargoType: 1676,

        // voy section
        loadPortId: "3A193407-D8BA-4645-9F5A-4848D977BE95",
        dischargePortId: "3A193407-D8BA-4645-9F5A-4848D977BE95",

        // tct section
        deliveryLocationId: "6264655B-A58F-46A8-B54A-09EFC9C7C0D1",
        viaLocationId: "6264655B-A58F-46A8-B54A-09EFC9C7C0D1",
        redeliveryLocationId: "24E925BE-F4EF-48A7-8364-BAE9917D324E"
      }
     }; // for validation purposes only
    const res = await this.axios.post(`/termsetimport/validate`, data.content);
    return res;
  }

  getTermset = async (id: string, companyId?: string | null): GetTermsetRes => {
    // is not /v1/admin/ because Trade needs the endpoint.
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const url = `/termsets/${id}/${companyId}`;
    const res = await this.axios.get(url);

    return res;
  };

  // prettier-ignore
  deleteTermset = async (id: string, companyId?: string | null): DeleteTermsetRes => { 
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const url = `/v1/admin/termsets/${id}/${companyId}`;
    const res = await this.axios.delete(url);

    return res;
  };

  getTermsetSchema = async (): GetOrderTemplateSchemaRes => {
    const res = await this.axios.get("/termsets/schema");

    return res;
  };

  getTermsetLayouts = async (): GetTermsetsLayoutsRes => {
    const res = await this.axios.get(`/proforma/layouts`);

    res.data?.forEach((el: any) => {
      el.id = el.key;
      el.name = el.text;
    });

    res.data.sort((firstValue: any, secondValue: any) => String(firstValue.text).localeCompare(String(secondValue.text)));

    return res;
  };

  getTermsetLayout = async (id: number): GetTermsetsLayoutRes => {
    const res = await this.axios.get(`/proforma/layouts/${id}`);

    res.data?.keys.sort();

    return res;
  };

  getTermsetHandlebars = async (): GetTermsetHandlebarsRes => {
    const res = await this.axios.get(`v1/admin/termsets/handlebars`);

    return res;
  };

  // prettier-ignore
  searchAccounts = async (search: string, cancelToken?: CancelToken): SearchAccountsRes => {
    const escapedSearch = encodeURIComponent(search);
    const url = `/accounts?q=${escapedSearch}&legalEntitiesOnly=false`;
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  getArcAccountByName = async (accountName: string, cancelToken?: CancelToken): SearchArcAccountByNameRes => {
    const url = `/arcContacts/${accountName}`;
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  searchArcAccountByName = async (searchTerm: string, take = 50, cancelToken?: CancelToken): SearchArcAccountByNameRes => {
    const url = `/arcContacts/${searchTerm}/${take}`;
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  getArcAccounts = async (cancelToken?: CancelToken): SearchArcAccountsRes => {
    const url = "/arcContacts";
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  getAccount = async (id: string, cancelToken?: CancelToken): GetAccountRes => {
    const url = `/v1/admin/accounts/${id}`;
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  putAccount = async (data: TradeAccount): PostAccountRes => {
    const res = await this.axios.post(`/accounts/false`, data);

    return res;
  };

  // prettier-ignore
  parsePeriod = async (input: string, cancelToken?: CancelToken): ParsePeriodRes => {
    const url = `/laycandate?encodedTerm=${input}`;
    const res = await this.axios.get(url, { cancelToken });

    return res;
  };

  // prettier-ignore
  searchLocations = async (search: string, cancelToken?: CancelToken): SearchLocationsRes => {
    const res = await this.axios.get(`/locations/${search}`, { cancelToken });

    return res;
  };

  getCompanies = async (): GetCompaniesRes => {
    const res = await this.axios.get(`/companies`);

    return res;
  };
}

export const trade = new Trade();

function sanitizeEntity(data: any) {
  if (typeof data.name === "string") data.name = data.name.trim();

  return data;
}

interface Trade {
  axios: AxiosInstance;
}

export type GetTokenRes = Promise<Response<TradeToken>>;
type GetOrderTemplateRes = Promise<Response<TradeOrderTemplate>>;
type ParsePeriodRes = Promise<Response<TradePeriod>>;
type SearchAccountsRes = Promise<Response<TradeAccountSearchItem[]>>;
type SearchArcAccountByNameRes = Promise<Response<ArcAccountByName[]>>;
type SearchArcAccountsRes = Promise<Response<ArcAccount[]>>;
type GetAccountRes = Promise<Response<TradeAccount>>;
type PostAccountRes = Promise<Response<TradeAccount & { errorMessage: string }>>;
type SearchOrderTemplatesRes = Promise<Response<TradeOrderTemplate[]>>;
type SearchLocationsRes = Promise<Response<TradeLocationSearchItem[]>>;
type GetCompaniesRes = Promise<Response<TradeCompany[]>>;
type PutOrderTemplateRes = Promise<Response<TradeOrderTemplate>>;
type DeleteOrderTemplateRes = Promise<Response<undefined>>;
type GetOrderTemplateSchemaRes = Promise<Response<JSONSchema7>>;
type GetTermsetsRes = Promise<Response<{ content: TradeTermset[]; totalCount: number }>>;
type GetTermsetRes = Promise<Response<TradeTermset>>;
type PutTermsetRes = Promise<Response<TradeTermset>>;
type ValidateTermsetRes = Promise<Response<TradeTermset>>;
type DeleteTermsetRes = Promise<Response<undefined>>;
type GetTermsetsLayoutsRes = Promise<Response<TradeTermsetLayout[]>>;
type GetTermsetsLayoutRes = Promise<Response<TradeTermsetLayout>>;
type GetTermsetHandlebarsRes = Promise<Response<any>>;

type TradeToken = string;

interface TradeLocationSearchItem {
  document?: TradeLocation;
  "@search.text"?: string;
}
export interface TradeAccountSearchItem {
  document?: TradeAccount;
  "@search.text"?: string;
}

export interface ArcAccountByName {
  contactId: number;
  name: string;
  gainAccountId: null | number;
  active: boolean;
}

export interface ArcAccount {
  id: number;
  name: string;
}

export interface TradeOrderTemplate {
  id?: string;
  name?: null | string;
  orderType?: null | string;
  companyId?: null | string;
  companyName?: null | string;
  template?: null | {
    coa?: null | TradeOrderTemplateCOA;
    order?: null | TradeOrderTemplateOrder;
    voyage?: null | TradeOrderTemplateVOY;
    tct?: null | TradeOrderTemplateTCT;
  };
  isDeleted?: boolean;
}

interface TradeOrderTemplateCOA {
  coaCargoSize?: null | TradeCOACargoSize;
  nominations?: null | TradeNominations;
  period?: null | TradePeriod;
  liftings?: null | TradeLiftings;
  notes?: null | string;
}

interface TradeOrderTemplateOrder {
  chartererAccount?: null | TradeChartererAccount;
  cargoType?: null | TradeCargoType;
  laycan?: null | TradePeriod;
  addressCommission?: null | TradeCommission;
  brokerCommission?: null | TradeCommission;
}

interface TradeOrderTemplateVOY {
  cargoSize?: null | TradeCargoSize;
  loadLocation?: null | TradeLocation;
  dischargeLocation?: null | TradeLocation;
  notes?: null | string;
}

interface TradeOrderTemplateTCT {
  vesselSize?: null | TradeVesselSize;
  deliveryLocation?: null | TradeLocation;
  viaLocation?: null | TradeLocation;
  redeliveryLocation?: null | TradeLocation;
  duration?: null | TradeUnitDuration;
  notes?: null | string;
}

export interface TradeCompany {
  companyId?: null | string;
  name?: null | string;
}

export interface TradeAccount {
  id: string;
  accountId: string;
  companyId?: null | string;
  accountName?: null | string;
  arcContactId?: null | string;
  gainAccountId?: null | string;
  gainAccountGroupId?: null | string;
  isLegalEntity?: null | boolean;
  relatedArcAccount?: ArcAccountByName;
}

export interface TradeCOACargoSize {
  display?: null | string;
  unit?: null | string;
  min?: null | number;
  max?: null | number;
  variance?: null | number;
  option?: null | string;
  notes?: null | string;
}

export interface TradeNominations {
  display?: null | string;
  noticePerLaycan?: null | number;
  laycanSpread?: null | number;
  finalLaycanNotice?: null | number;
  notes?: null | string;
}

export interface TradeLiftings {
  min?: null | number;
  max?: null | number;
  dispersal?: null | "Fairly even spread" | "Monthly" | "Adhoc";
  display?: null | string;
  notes?: null | string;
}

export interface TradeCargoSize {
  display?: null | string;
  option?: null | "MIN/MAX" | "MOLOO" | "MOLCHOPT";
  unit?: null | string;
  value?: null | number;
  variance?: null | number;
}

export interface TradeCargoType {
  arcId?: null | number;
  name?: null | string;
  notes?: null | string;
}

type TradeChartererAccount = TradeAccount;

export interface TradePeriod {
  end: string;
  start: string;
  style: number;
  styleDescription: string;
  term: string;
}

export interface TradeUnitDuration {
  unit?: null | "Days" | "Months";
  min?: null | number;
  max?: null | number;
  notes?: null | string;
}

export interface TradeCommission {
  value?: null | number;
}

export interface TradeVesselSize {
  vesselSizeFull?: null | string;
  vesselSizeAbbreviation?: null | string;
  sizeFrom?: null | number;
  sizeTo?: null | number;
  notes?: null | string;
}

export interface TradeLocation {
  country?: null | string;
  countryCode?: null | string;
  countryId?: null | string;
  locationId?: null | string;
  name?: null | string;
  zone?: null | string;
  zoneId?: null | string;
  parents?: null | string[];
  notes?: null | string;
  safeBerthsMin?: null | number; // #todo double-check types with backend
  safeBerthsMax?: null | number;
  safePortsMin?: null | number;
  safePortsMax?: null | number;
  safeAnchoragesMin?: null | number;
  safeAnchoragesMax?: null | number;
}

export type TradeTermset = {
  id?: string;
  typeId?: number;
  orderType?: TradeOrderType;
  companyId?: null | string;
  companyName?: null | string;
  name?: null | string;
  version?: null | number;
  content?: null | {
    proformaLayoutId?: null | number;
    proformaLayoutName?: null | string;
    proformaOtherName?: null | string;
    templateDetails?: null | { displayName?: null | string };
    orderDetails?: null | {
      orderTypeId?: null | number;
      arcContactId?: null | number;
      cargoType?: null | number;
      loadPortId?: null | string;
      dischargePortId?: null | string;
      deliveryLocationId?: null | string;
      viaLocationId?: null | string;
      redeliveryLocationId?: null | string;
    };
    mainTermTemplates?: null | TradeTermsetItem[];
  };
  isDeleted?: boolean;
  updated?: string | null;
  updatedBy?: TradeUser;
  created?: string | null;
  createdBy?: TradeUser;
  published?: string;
  publishedBy?: TradeUser;
  isPublished?: boolean;
};

interface TradeTermsetItem {
  title?: null | string;
  cpmProformaKey?: null | string;
  content?: null | string;
  termId?: null | string;
}

export interface TradeTermsetLayout {
  id?: number;
  name?: null | string;
  proformaKey?: null | string;
  keys?: null | string[];
  ownerCpmProformaKeyOverride?: null;
  chartererCpmProformaKeyOverride?: null;
}

export interface TradeUser {
  Company: string;
  CompanyId: string;
  CompanyRoles: string[];
  Desk: string;
  DeskId: string;
  Division: string;
  DivisionId: string;
  Features: null;
  Location: string;
  LocationId: string;
  SystemUserId: number;
  UserEmail: string;
  UserName: string;
  aud: string;
  email: string;
  exp: number;
  iat: number;
  isCtradeAdministrator: boolean;
  isMaritechUser: boolean;
  iss: string;
  memberships: Membership[];
  name: string;
  sub: string;
}

interface Membership {
  companyRoles: null | string[];
  id: string;
  level: string;
  name: string;
}

type TradeOrderType = "Unknown" | "coa" | "std" | "voy" | "tct" | null;

export interface TradeTermsetHandlebarMap {
  coa: TradeTermsetHandlebar[];
  voy: TradeTermsetHandlebar[];
  tct: TradeTermsetHandlebar[];
}
export interface TradeTermsetHandlebar {
  name: string;
  key: string;
}

export interface TradeLocalFilter {
  companyId?: string;
  orderType?: string;
  termsetType?: string;
  "content.proformaLayoutId"?: number;
  proformaLayoutName?: string;
  "document.isLegalEntity"?: boolean;
}
