import { useRef, useState } from "react";

export function useShallowState<T extends Shallow>(
  initialState: T
): readonly [T, (partialState?: Shallow | undefined) => void, T] {
  const [, setCounter] = useState(0);
  const state = useRef<T>({ ...initialState });

  function setState(partialState?: Shallow) {
    Object.assign(state.current, partialState);

    setCounter((counter) => counter + 1);
  }

  return [state.current, setState, { ...state.current }] as const;
}

interface Shallow {
  [name: string]: any;
}
