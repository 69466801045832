import { setup as configSetup } from "@/config";
import { setup as apiSetup } from "@/api";
import { setup as modelSetup, auth } from "@/model";
import { setup as seaHeaderSetup } from "@/sea.header";
import { setup as axiosSetup } from "@/axios";
import { setup as devSetup } from "@/dev";

export async function setup(): Promise<void> {
  axiosSetup();

  await configSetup();

  apiSetup();

  await auth.setup();

  await modelSetup();

  seaHeaderSetup();

  devSetup();
}

export function preauthSetup(): void {
  devSetup();
}
