import { action, observable, decorate } from "mobx";
import { TradeCargoType } from "@/api";
import { CARGO_TYPES } from "@/constants";

class CargoTypes {
  data: TradeCargoType[] = [];

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = () => {
    const data = CARGO_TYPES.map((el) => ({
      arcId: el.cargoTypeId,
      name: el.commodity,
    }));

    if (data) this.setData(data);
  };

  setData = (data: TradeCargoType[]) => {
    this.data = data;

    this.resolve();
  };
}

decorate(CargoTypes, {
  data: observable,
  setData: action,
});

export const cargoTypes = new CargoTypes();

interface CargoTypes {
  resolve: () => void;
}
