import React from "react";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../container";
import { Number, NumberProps } from "@/components";

export function NumberField(props: NumberFieldProps): JSX.Element {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <Number {...rest} />
    </Container>
  );
}

export interface NumberFieldProps extends FieldProps, NumberProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
