import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { cn, useShallowState, get } from "@/util";
import { ARROW_KEY, EDIT_KEY, SPECIAL_KEY } from "@/constants";
import { globalDropdown } from "@/model";
import { DropdownProps, DropdownItem as Item, DropdownInstance, ImgProps } from "@/components";
import { InputProps, InputFocusEvent } from "../../Input";
import { Container } from "../../container";
import "./Select.scss";
import { Icon } from "../../icon";

function Select(props: SelectProps) {
  props = { ...props };

  const clearable = props.clearable ?? true;
  let {
    img,
    data,
    value,
    display,
    onFilter,
    dataTest,
    filterKey,
    noDataStatus,
    getDropdownImg,
    dropdownStatus,
    nonFilterSelect,
    dropdownClassName,
  } = props;

  const [state, setState] = useShallowState<State>(defaultState);

  if (getDropdownImg) {
    img = state.shadowFocus ? undefined : getDropdownImg(value);
  }

  if (value && display) value = display(value);
  if (!value) value = "";

  const originalData = data;
  data = state.filtered || originalData;

  if (!data || !data.length) dropdownStatus = dropdownStatus || noDataStatus;

  let inputValue = value;
  if (state.shadowFocus) inputValue = state.search;
  if (nonFilterSelect) inputValue = value;

  const input = useRef<HTMLInputElement>(null);
  const field = useRef<HTMLFieldSetElement>(null);
  const dropdown = useRef<DropdownInstance>(null);
  const searchIndexTimeoutId = useRef(-1);

  props.className = cn(props.className, "select", {
    open: state.open,
  });

  useEffect(setInputRef, []);
  useEffect(setIndex, [state.indexSearch]);
  useEffect(controlDropdown, [state.open]);
  useEffect(updateDropdown, [data, dropdownStatus, dropdown, display, getDropdownImg, dropdownClassName]);

  function setInputRef() {
    if (props.input) props.input.current = input.current;

    return unsetInputRef;
  }

  function unsetInputRef() {
    if (props.input) props.input.current = null;
  }

  function updateDropdown() {
    if (!dropdown.current?.open) return;

    globalDropdown.update({
      data,
      dropdown,
      display,
      dataTest,
      status: dropdownStatus,
      getImg: getDropdownImg,
      className: dropdownClassName,
    });
  }

  function controlDropdown() {
    if (state.open) showDropdown();
    // --- //
    else globalDropdown.hide();
  }

  async function showDropdown() {
    const target = field.current || input.current;

    if (target) {
      const item = await globalDropdown.show({
        target,
        display,
        data,
        dropdown,
        dataTest,
        status: dropdownStatus,
        getImg: getDropdownImg,
        className: dropdownClassName,
      });

      setState({ open: false, shadowFocus: false, search: "", filtered: null });

      if (item) props.onChange?.(item);
    }
  }

  function setIndex() {
    if (!state.indexSearch) return;

    const { index = 0, setIndex, data, list } = dropdown.current || {};

    function reducer(search: string, acc: any, item: Item, i: number) {
      if (startsWith(search, itemFilterSelector(item))) {
        acc.array.push(i);
        acc.map[i] = acc.array.length - 1;
      }

      return acc;
    }

    function findAll(search: string) {
      return data?.reduce(reducer.bind(null, search), { array: [], map: {} });
    }

    function resolveNextIndex(search: string) {
      const match = findAll(search);
      const matchIndex = match?.map[index];
      const indexOfLastMatch = match?.array[match?.array.length - 1];

      let nextIndex;

      // if (search.length > 1 && match.array.length) nextIndex = index;
      // --- //
      if (typeof matchIndex !== "number") nextIndex = match?.array[0];
      // --- //
      else if (index === indexOfLastMatch) nextIndex = match?.array[0];
      // --- //
      else nextIndex = match?.array[matchIndex + 1];

      return nextIndex;
    }

    let nextIndex = resolveNextIndex(state.indexSearch);

    if (typeof nextIndex !== "number") {
      const lastChar = state.indexSearch.slice(-1);

      nextIndex = resolveNextIndex(lastChar);
    }

    if (typeof nextIndex === "number") {
      setIndex?.(nextIndex);

      list?.current?.scrollToItem(nextIndex, "smart");
    }
  }

  function updateIndexSearch(key: string) {
    clearTimeout(searchIndexTimeoutId.current);

    searchIndexTimeoutId.current = setTimeout(setState.bind(null, { indexSearch: "" }), 400);

    setState({ indexSearch: state.indexSearch + key });
  }

  function itemFilterSelector(item: Item) {
    if (typeof filterKey === "function") {
      item = filterKey(item);

      // --- //
    } else if (filterKey) {
      item = get(item, filterKey);
    }

    return item;
  }

  function itemFilterer(search: string, item: Item) {
    item = itemFilterSelector(item);

    const str = `${item}`.toLowerCase();

    search = `${search}`.toLowerCase().trim().replaceAll("  ", " ");

    const res = startsWith(search, str) || str.split(" ").some(startsWith.bind(null, search)) || str.includes(search);

    return res;
  }

  function filter(search: string) {
    if (!originalData) return;

    if (!search) return setState({ filtered: null });

    const filtered = originalData.filter(itemFilterer.bind(null, search));

    setState({ filtered });
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (nonFilterSelect) return;

    const search = e.currentTarget.value;

    setState({ search });

    if (!onFilter) return filter(search);

    onFilter(search);
  }

  function onClick() {
    setState({ open: true, search: "", shadowFocus: true });
  }

  function onFocus(e: InputFocusEvent) {
    setState({ open: true, search: "", shadowFocus: true });

    props.onFocus?.(e);
  }

  function onBlur(e: InputFocusEvent) {
    setState({ open: false, search: "", shadowFocus: false, filtered: null });

    props.onBlur?.(e);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (nonFilterSelect && !ARROW_KEY[e.key]) {
      updateIndexSearch(e.key);
    }

    if (e.key === "Enter") e.preventDefault();

    if (state.shadowFocus) return;

    if (EDIT_KEY[e.key]) {
      setState({ open: true, shadowFocus: true, search: "", filtered: null });

      return;
    }

    if (!SPECIAL_KEY[e.key]) {
      setState({ open: true, shadowFocus: true });
    }
  }

  return (
    <Container {...props} onFocus={onFocus} onBlur={onBlur} img={img} clearable={clearable}>
      <Icon icon="chevron-down" align="right" />
      <input
        value={inputValue}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
        onClick={onClick}
        placeholder={props.placeholder}
        disabled={props.disabled}
        ref={input}
        data-test={`${props.dataTest}/input`}
      />
    </Container>
  );
}

function startsWith(substr: string, str: string) {
  return str.toLocaleLowerCase().startsWith(substr.toLowerCase());
}

const defaultState = {
  search: "",
  indexSearch: "",
  shadowFocus: false,
  filtered: null,
  open: false,
} as State;

const { setTimeout, clearTimeout } = window;

const Observer = observer(Select);

export { Observer as Select };

export interface SelectProps extends InputProps<Item> {
  data?: DropdownProps["data"];
  onFilter?: (search: string) => void;
  display?: DropdownProps["display"];
  dropdownClassName?: string;
  dropdownStatus?: Status;
  getDropdownImg?: (value: Item) => ImgProps["img"] | undefined;
  filterKey?: string | number | ((item: Item) => string | number);
  noDataStatus?: Status;
  nonFilterSelect?: boolean;
}

interface State {
  open: boolean;
  search: string;
  indexSearch: string;
  shadowFocus: boolean;
  filtered?: DropdownProps["data"];
}
