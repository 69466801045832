import React, { memo } from "react";
import { FieldProps } from "@/components";
import { EDITOR_TYPE, EditorType } from "./type";
// import "./Editor.scss";

function Editor(props: EditorProps) {
  props = { ...props };

  const EditorType = props.type && EDITOR_TYPE[props.type];

  if (!EditorType) {
    console.error(`Unrecognized editor type: ${props.type}`, props);

    return null;
  }

  return <EditorType {...props} />;
}

const Memo = memo(Editor);

export { Memo as Editor };

export interface EditorProps extends Omit<FieldProps, "type"> {
  type: EditorType;
}
