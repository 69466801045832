import { TextField } from "./text";
import { NumberField } from "./number";
import { TextareaField } from "./textarea";
import { SelectField } from "./select";
import { HandlebarField } from "./handlebar";

export const FIELD_TYPE = {
  text: TextField,
  number: NumberField,
  select: SelectField,
  textarea: TextareaField,
  handlebar: HandlebarField,
};

export type FieldType = keyof typeof FIELD_TYPE;
