import React, { useMemo } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { TRADE_DATA_DEF } from "@/constants";
import { termsetLayouts } from "@/model";
import { TradeTermsetLayout as Layout } from "@/api";
import { SelectField, SelectFieldProps } from "@/components";
import "./Trade.termset.layout.scss";

function TradeTermsetLayout(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "trade-termset-layout");

  const { data, dataById } = termsetLayouts;

  const _data = useMemo(() => data.map((model) => model.data), [data]);

  const id = props.value;

  const value = id ? dataById[id]?.data : undefined;

  function onChange(value: Layout) {
    props.onChange?.(value?.id);
  }

  return (
    <SelectField
      {...props}
      data={_data}
      value={value}
      noDataStatus={noDataStatus}
      onChange={onChange}
      display={TRADE_DATA_DEF.termsetLayout.display}
      dataTest={`${props.dataTest}/trade-termset-layout`}
      filterKey="text"
    />
  );
}

const noDataStatus = {
  message: "No Proformas found",
};

const Observer = observer(TradeTermsetLayout);

export { Observer as TradeTermsetLayout };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: Layout["id"];
  onChange?: (value: Layout["id"]) => void;
}
