import React, { useMemo } from "react";
import { Entity, Config } from "../Entity.layout";
import { dialog } from "@/model";
import { wait } from "@/util";

export function useEntityActions(entity: Entity | undefined, config: Config): EntityActions | null {
  return useMemo(createEntityActions.bind(null, entity, config), [entity, config]);
}

function createEntityActions(entity: Entity | undefined, config: Config) {
  if (!entity) return null;

  return new EntityActions(entity, config);
}

class EntityActions {
  constructor(entity: Entity, config: Config) {
    this.entity = entity;
    this.config = config;
  }

  publish = async () => {
    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Publishing";

    const res = await this.entity.publish?.();

    if (!res?.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Publish Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-publish-fail`,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;

    dialog.show({
      status: {
        type: "success",
        title: "Successful Publishment",
        message: `The ${this.config.view.entity.name} was successfuly published.`,
      },
      dataTest: `${this.config.dataTest}-publish-success`,
    });
  };

  unpublish = async () => {
    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Unpublishing";

    const res = await this.entity.unpublish?.();

    if (!res?.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Unpublish Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-unpublish-fail`,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;

    dialog.show({
      status: {
        type: "success",
        title: "Successful Unpublishment",
        message: `The ${this.config.view.entity.name} was successfuly unpublished.`,
      },
      dataTest: `${this.config.dataTest}-unpublish-success`,
    });
  };

  getData = async () => {
    const isNew = `${this.entity.data?.id}`.includes("$");

    if (this.entity.tempStatus?.locked) return;

    if (isNew) {
      Object.assign(this.entity.data, this.config.view.entity.newEntityData);
    }

    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Fetching schema";

    const schemaRes = await this.entity.getSchema();

    if (schemaRes && !schemaRes.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Schema Fetch Failure`;
      this.entity.status.dump = { res: schemaRes, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: this.config.dataTest,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.message = "Fetching data";

    const res = isNew ? null : await this.entity.getData();

    if (!isNew && !res?.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Data Fetch Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-data-fetch-fail`,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;
  };

  postData = async (data: any) => {
    const isNew = this.entity.data?.id?.includes("$");

    this.entity.status.code = null;
    this.entity.status.type = null;
    this.entity.status.title = null;
    this.entity.status.dump = null;
    this.entity.status.code = null;

    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Saving";

    const res = await this.entity.postData(data);

    if (!res?.ok && !this.entity.status?.overrideBaseBehavior) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Save Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      if (this.config.view.entity.apiErrorDef?.[res.data]) {
        this.entity.status.code = res.data;

        return;
      }

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-save-fail`,
      });

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;

    await wait(0);

    if (!res?.ok && this.entity.status?.overrideBaseBehavior) {
      return;
    }

    this.config.routes.entity?.go(
      {
        id: res?.data?.id,
        companyId: res.data.companyId,
      },
      {
        ignoreUnsavedChanges: true,
      }
    );

    if (this.config.parent) {
      const companyId = res.data?.companyId;
      const id = res.data?.id;

      this.config.parent.entitiesById[`${companyId}@${id}`].data = res?.data;
    }

    dialog.show({
      status: {
        type: "success",
        title: isNew ? "Successful Creation" : "Successful Update",
        message: isNew
          ? `The ${this.config.view.entity.name} was successfuly created.`
          : `The ${this.config.view.entity.name} changes were successfully saved.`,
      },
      dataTest: `${this.config.dataTest}-save-success`,
    });
  };

  deleteData = async () => {
    // if (this.entity.tempStatus.locked) return;

    if (!this.entity.deleteData) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} doesn't support Delete operation`;

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-delete-fail`,
      });
      return;
    }

    const { res: shouldDelete } = await dialog.show({
      status: {
        type: "warning",
        title: `${this.config.view.entity.name} Deletion`,
        message: (
          <>
            <div>Are you sure you want to delete the {this.config.view.entity.name}:</div>
            <br />
            <div className="entity-actions-entity-name">{this.entity.data?.name}</div>
          </>
        ),
      },
      dataTest: `${this.config.dataTest}-deletion-confirm`,
    });

    if (!shouldDelete) return;

    // this.entity.tempStatus.locked = true;
    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Deleting";

    const res = await this.entity.deleteData();

    if (!res?.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Deletion Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-deletion-fail`,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;

    dialog.show({
      status: {
        type: "success",
        title: "Successful Deletion",
        message: (
          <>
            <div>Successful deletion of the {this.config.view.entity.name}:</div>
            <br />
            <div className="entity-actions-entity-name">{this.entity.data?.name}</div>
          </>
        ),
      },
      dataTest: `${this.config.dataTest}-deletion-success`,
    });

    this.config.routes.parent?.go();

    return;
  };

  cloneData = async () => {
    const { routes } = this.config;

    if (!this.entity.cloneData) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} doesn't support Clone operation`;

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-clone-fail`,
      });
      return;
    }

    this.entity.tempStatus.loading = true;
    this.entity.tempStatus.message = "Cloning";

    const res = await this.entity.cloneData();

    if (!res?.ok) {
      this.entity.status.type = "error";
      this.entity.status.title = `${this.config.view.entity.name} Clone Failure`;
      this.entity.status.dump = { res, entity: this.entity };

      dialog.show({
        status: this.entity.status,
        dataTest: `${this.config.dataTest}-clone-fail`,
      });

      this.entity.tempStatus.loading = false;
      this.entity.tempStatus.message = null;

      return;
    }

    this.entity.tempStatus.loading = false;
    this.entity.tempStatus.message = null;

    const timeoutId = setTimeout(() => {
      routes.entity?.go({ id: res.data.id, companyId: res.data.companyId });

      dialog.hide();
    }, 5000);

    const { res: dialogRes } = await dialog.show({
      status: {
        type: "success",
        title: "Successful Cloning",
        message: `You will be redirected to the cloned ${this.config.view.entity.name} shortly.`,
      },
      actions: [
        {
          label: `Go to the cloned ${this.config.view.entity.name}`,
          onClick: () => {
            clearTimeout(timeoutId);

            routes.entity?.go({
              id: res.data.id,
              companyId: res.data.companyId,
            });

            return true;
          },
          variant: "contained",
        },
      ],
      suppressStatusActions: true,
      dataTest: `${this.config.dataTest}-clone-success`,
    });

    if (!dialogRes) {
      clearTimeout(timeoutId);
    }

    return;
  };
}

interface EntityActions {
  entity: Entity;
  config: Config;
}
