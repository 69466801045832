import React, { memo, useMemo, useRef, useEffect } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  AllModules,
  LicenseManager,
  ColDef,
  GridReadyEvent,
  GridApi,
  SuppressKeyboardEventParams,
} from "@ag-grid-enterprise/all-modules";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { cn, wait } from "@/util";
import { INPUT_HEIGHT } from "@/constants";
import { Input, LoadingOverlay, Icon } from "./framework.components";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "./Aggrid.scss";

function Aggrid(props: Props) {
  props = { ...props };

  props.columnDefs = useMemo(() => mapColumnDefs(props.columnDefs), [props.columnDefs]);

  const aggrid = useRef<HTMLDivElement>(null);
  const api = useRef<GridApi>();
  const context = useRef<any>({});

  context.current.aggrid = aggrid;

  useEffect(skipHeaderFocus, []);
  useEffect(deriveRowData, [props.rowData]);

  function skipHeaderFocus() {
    async function handler(e: KeyboardEvent) {
      await wait(1);

      if (e.key !== "Tab") return;

      if (!aggrid.current?.contains(document.activeElement)) return;

      if (!document.activeElement?.className.includes("ag-header-cell")) return;

      api.current?.setFocusedCell(0, "title");
    }

    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }

  function onGridReady(params: GridReadyEvent) {
    api.current = params.api;

    // @ts-ignore
    window.gridApi = params.api;

    if (props.rowData) params.api.setRowData(props.rowData);

    if (props.onGridReady) props.onGridReady(params);
  }

  function deriveRowData() {
    if (props.rowData) api.current?.setRowData(props.rowData);
  }

  return (
    <div className="aggrid ag-theme-alpine" ref={aggrid}>
      <AgGridReact
        {...props}
        context={context.current}
        onGridReady={onGridReady}
        headerHeight={INPUT_HEIGHT + 0.5}
        rowHeight={INPUT_HEIGHT + 12}
        suppressKeyboardEvent={suppressKeyboardEvent}
        loadingOverlayComponent="loadingOverlay"
        rowSelection="single"
        animateRows={true}
        suppressPropertyNamesCheck={true}
        rowDragManaged={true}
        immutableData={true}
        stopEditingWhenGridLosesFocus={true}
        frameworkComponents={frameworkComponents}
        modules={modules}
      />
    </div>
  );
}

function mapColumnDefs(columnDefs?: ColDef[]) {
  return columnDefs?.map((col) => {
    col = { ...col };

    if (col.rowDrag) {
      col.cellClass = cn(col.cellClass, "aggrid-row-drag");
      col.width = 36;
      col.suppressMenu = true;
      col.suppressMovable = true;
      col.suppressNavigable = true;
      col.pinned = true;
    }

    return col;
  });
}

function suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
  const { event, editing } = params;

  if (!editing) return false;
  if (event.key === "Escape") return true;
  if (event.key === "Enter") return true;

  return false;
}

const frameworkComponents = {
  input: Input,
  loadingOverlay: LoadingOverlay,
  icon: Icon,
};

const modules = [...AllModules, ClientSideRowModelModule];

const Memo = memo(Aggrid);

export { Memo as Aggrid };

LicenseManager.setLicenseKey(
  "H_Clarkson_&_Co_Fort_Single_Application_3_Devs__6_December_2020_[v2]_MTYwNzIxMjgwMDAwMA==6a82d327ce5df386e74420d2e698e52b"
);

type Props = AgGridReactProps;
