import React, { memo, useState, useEffect } from "react";
import { Box, IconButton, TablePagination } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { DisplayedRowsFragment } from "./_DisplayedRowsFragment";
import { DisplayedPageFragment } from "./_DisplayedPageFragment";
import { MUI_DEFAULT_PAGE, PAGINALION_STORE_KEY, RECORDS_PER_PAGE } from "@/constants";
import { convertMUIToPageNumber, convertToMUIPageNumber, storage as storageUtil } from "@/util";
import "./Pagination.scss";

const Pagination = ({ totalCount, shownItemsCount, toDefaultPage, savedPageNumber, onPageChange }: PaginationProps) => {
  const storage = storageUtil.session;
  const savedMUIPage = convertToMUIPageNumber(savedPageNumber);
  const [MUIPage, setPage] = useState(savedMUIPage);

  useEffect(() => {
    if (toDefaultPage) goTo(MUI_DEFAULT_PAGE);
  }, [toDefaultPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newMUIPage: number) => {
    goTo(newMUIPage);
  };

  const goTo = (newMUIPage: number) => {
    setPage(newMUIPage);
    storage.set(PAGINALION_STORE_KEY, newMUIPage);
    onPageChange(newMUIPage);
  };

  const next = () => {
    goTo(MUIPage + 1);
  };

  const prev = () => {
    goTo(MUIPage - 1);
  };

  const getTotalPages = () => {
    return Math.ceil(totalCount / RECORDS_PER_PAGE);
  };

  const getShownRecordsRange = () => {
    let from, to;

    if (shownItemsCount) {
      from = 1 + MUIPage * RECORDS_PER_PAGE;
      to = from + shownItemsCount - 1;
    }

    return { from: from, to: to };
  };

  const renderDisplayedRowsFragment = () => {
    const { from, to } = getShownRecordsRange();

    return <DisplayedRowsFragment from={from} to={to} totalRecords={totalCount} />;
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage } = props;
    const MUIPage = page;
    const currentPage = convertMUIToPageNumber(MUIPage);
    const lastMUIPage = Math.ceil(count / rowsPerPage) - 1;
    const firstMUIPage = MUI_DEFAULT_PAGE;

    const handleBackButtonClick = () => prev();
    const handleNextButtonClick = () => next();

    return (
      <Box sx={{ flexShrink: 0, display: "flex" }}>
        <IconButton
          className="square-button"
          onClick={handleBackButtonClick}
          disabled={MUIPage === firstMUIPage}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <DisplayedPageFragment currentPage={currentPage} totalPages={getTotalPages()} goTo={goTo} />
        <IconButton
          className="square-button"
          onClick={handleNextButtonClick}
          disabled={MUIPage >= lastMUIPage}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    );
  };

  return (
    <TablePagination
      component="div"
      page={MUIPage}
      count={totalCount}
      rowsPerPage={RECORDS_PER_PAGE}
      labelDisplayedRows={renderDisplayedRowsFragment}
      onPageChange={handleChangePage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

const Memo = memo(Pagination);

export { Memo as Pagination };

interface PaginationProps {
  totalCount: number;
  shownItemsCount: number;
  toDefaultPage: boolean;
  savedPageNumber: number;
  onPageChange: (newPageNumber: number) => void;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
