import React, { memo, ReactNode, HTMLAttributes } from "react";
import { cn } from "@/util";
import { Icon, IconProps } from "@/components";
import "./Button.scss";

function Button(props: ButtonProps) {
  const { children, icon, variant, dom, ...rest } = props;

  rest.className = cn(rest.className, variant, { icon });

  return (
    <button {...rest} ref={dom}>
      <Icon icon={icon} />
      {children && <span>{children}</span>}
    </button>
  );
}

Button.defaultProps = {
  variant: "text",
};

const Memo = memo(Button);

export { Memo as Button };

interface Props extends HTMLAttributes<HTMLButtonElement> {
  icon?: IconProps["icon"];
  variant?: Variant;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  dom?: DOMRef;
}

type Variant = "contained" | "text" | "outlined";

type MouseEvent = React.MouseEvent<HTMLButtonElement>;

type DOMRef = React.MutableRefObject<HTMLButtonElement | null>;

export type ButtonProps = Props;
export type ButtonMouseEvent = MouseEvent;
