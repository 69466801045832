import React, { useState, useRef } from "react";
import { convertToMUIPageNumber } from "@/util";

export const DisplayedPageFragment = ({ totalPages, currentPage, goTo }: DisplayedPageFragmentProps) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState<number | null>(currentPage);

  const validateNumberInput = (newValue) => {
    return typeof newValue === "number" && newValue >= 1 && newValue <= totalPages;
  };

  const handleChange = (e) => {
    const formattedValue = Number(e.target.value.replace(/[^0-9]/g, "").replace(/^0+/, ""));

    setValue(formattedValue ? formattedValue : null);
  };

  const handleBlur = (e) => {
    const newPage = Number(e.currentTarget.getAttribute("value"));

    if (newPage && validateNumberInput(newPage) && newPage !== currentPage) {
      const newMUIPage = convertToMUIPageNumber(newPage);

      setValue(newPage);
      goTo(newMUIPage);
    } else {
      setValue(currentPage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
    if (e.key === "Escape") {
      setValue(currentPage);
      setTimeout(() => {
        (inputRef.current as HTMLInputElement | null)?.blur();
      }, 0);
    }
  };

  return (
    <div className="displayed-page-wrapper">
      <input
        ref={inputRef}
        type="number"
        min={1}
        max={totalPages}
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <div className="of-total-wrapper">
        <span> of </span>
        <div className="total-pages-container">{totalPages}</div>
      </div>
    </div>
  );
};

interface DisplayedPageFragmentProps {
  currentPage: number;
  totalPages: number;
  goTo: (newMUIPage: number) => void;
}
