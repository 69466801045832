import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { cn } from "@/util";
import { sidebar } from "@/model";
import "./Sidebar.scss";

function Sidebar(): JSX.Element {
  const [visible, setVisible] = useState<boolean>();
  const { content, open, hide } = sidebar;

  useEffect(() => {
    open ? setVisible(true) : setVisible(false);
  }, [open]);

  return (
    <div className={cn("sidebar", { visible })}>
      {open && <div className="sidebar-backdrop" onClick={hide} />}
      <div className="sidebar-content">{content}</div>
    </div>
  );
}

const Observer = observer(Sidebar);

export { Observer as Sidebar };
