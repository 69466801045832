import React, { useRef } from "react";
import classNames from "classnames";
import { InputKeyboardEvent } from "@/components";
import { Text, TextProps } from "../text";

export function Number(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "number");

  const { min = 0, max = Infinity, value } = props;
  const initialShadowStr = typeof value === "number" ? toString(value) : "";
  const shadowStr = useRef(initialShadowStr);
  let display = "";

  if (typeof value === "number") {
    const valStr = toString(value);
    const valStrDecimal = valStr.includes(".");
    const shadowStrDecimal = shadowStr.current.includes(".");
    const shadowStrDecimals = shadowStr.current.split(".")[1];
    display = valStr;

    if (shadowStrDecimal && !valStrDecimal) {
      display = `${valStr}.${(shadowStrDecimals || "").slice(0, maximumFractionDigits)}`;
    }
  }

  if (shadowStr.current === "" && !value) {
    display = "";
  }

  function onChange(next: number | null, inputValue?: string) {
    if (typeof inputValue === "string" && !inputValue) {
      next = null;
    }

    props.onChange?.(next);
  }

  function onInputChange(inputValue: string) {
    shadowStr.current = inputValue;

    const next = minMax(toNumber(inputValue));

    onChange(next, inputValue);
  }

  function onKeyDown(e: InputKeyboardEvent) {
    const isNotAllowed = !KEY_MAP[e.key];
    const preventSecondDot = display.includes(".") && e.key === ".";
    const isCtrlHotkey = e.ctrlKey && CTRL_KEY_MAP[e.key];

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      let increment = 1;

      if (e.altKey) increment = 0.1;
      if (e.shiftKey) increment = 10;
      if (e.ctrlKey) increment = 100;
      if (e.key === "ArrowDown") increment = -increment;

      const next = minMax(toNumber(display) + increment);

      onChange(next);

      return;
    }

    if (isCtrlHotkey) return;

    if (isNotAllowed || preventSecondDot) {
      e.preventDefault();

      return;
    }
  }

  function minMax(value: number) {
    value = Math.max(value, min);
    value = Math.min(value, max);

    return value;
  }

  return (
    <Text
      {...props}
      desc={`${props.desc}

Increment/decrement with arrow up/down keys: Ctrl ±100, Shift ±10, Alt ±0.1`}
      value={display}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      placeholder={props.placeholder}
    />
  );
}

function toNumber(string: string) {
  const replaced = string.replace(/,/g, "");
  const number = window.Number(replaced);
  const formatted = formatter.format(number);
  const formattedReplaced = formatted.replace(/,/g, "");
  const res = window.Number(formattedReplaced);

  return res;
}

function toString(number: number) {
  const res = formatter.format(number);

  return res;
}

const maximumFractionDigits = 3;
const formatter = new Intl.NumberFormat("en-UK", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits,
});

const KEY_MAP: KeyMap = {
  "0": true,
  "1": true,
  "2": true,
  "3": true,
  "4": true,
  "5": true,
  "6": true,
  "7": true,
  "8": true,
  "9": true,
  ".": true,
  Enter: true,
  Esc: true,
  Backspace: true,
  Tab: true,
  Control: true,
  ArrowUp: true,
  ArrowDown: true,
  ArrowLeft: true,
  ArrowRight: true,
};

const CTRL_KEY_MAP: KeyMap = {
  a: true,
  c: true,
  x: true,
  z: true,
  v: true,
  ArrowUp: true,
  ArrowDown: true,
};

interface Props extends Omit<TextProps, "value" | "onChange"> {
  value?: number | null;
  onChange?: (value: number | null) => void;
  min?: number;
  max?: number;
}

type KeyMap = {
  [key: string]: boolean;
};

export type NumberProps = Props;
