import { action, observable, decorate } from "mobx";
import { trade, TradeAccount } from "@/api";
import { dialog } from "@/model";
import { JSONSchema7 } from "json-schema";

class OwnerAccount {
  entityKey = null as string | null;
  status = {} as Status;
  tempStatus = {} as Status;
  data = null as TradeAccount | null;
  preventGlobalError = true;

  get schema() {
    return schema;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getSchema = async () => {};

  getData = async () => {
    if (!this.data?.id) return;

    const tradeRes = await trade.getAccount(this.data.id);

    if (tradeRes.ok && tradeRes.data) {
      tradeRes.data.companyId = tradeRes.data.accountId;
      this.data = tradeRes.data;
    }

    if (this.data.accountName) {
      const arcRes = await trade.getArcAccountByName(this.data.accountName);

      if (arcRes.ok && arcRes.data) {
        this.data.relatedArcAccount = arcRes.data.find(
          (x) => `${x.contactId}` === this.data?.arcContactId && x.name === this.data.accountName
        );
      }
      if (!arcRes.ok) {
        dialog.show({
          status: {
            type: "error",
            overrideBaseBehavior: true,
            title: "Retriving Failed",
            message: "Arc failed to return related contact",
          },
          dataTest: `retrieve-error`,
        });
      }
    }

    return tradeRes;
  };

  postData = async (data: TradeAccount) => {
    const res = await trade.putAccount(data);

    if (!res?.ok) {
      this.status.overrideBaseBehavior = true;

      dialog.show({
        status: {
          type: "error",
          overrideBaseBehavior: true,
          title: "Saving Failed",
          message: res.data?.errorMessage,
        },
        dataTest: `save-error`,
      });
      return;
    }

    if (res.data) {
      res.data.companyId = res.data?.accountId;
    }

    if (res.data?.accountName) {
      const arcRes = await trade.getArcAccountByName(res.data.accountName);

      if (arcRes.ok && arcRes.data) {
        res.data.relatedArcAccount = arcRes.data.find(
          (x) => `${x.contactId}` === res.data?.arcContactId && x.name === res.data.accountName
        );
      }
      if (!arcRes.ok) {
        dialog.show({
          status: {
            type: "error",
            overrideBaseBehavior: true,
            title: "Retriving Failed",
            message: "Arc failed to return related contact",
          },
          dataTest: `retrieve-error`,
        });
      }
    }

    return res;
  };
}

decorate(OwnerAccount, {
  data: observable,
  status: observable,
  tempStatus: observable,
  getData: action,
  postData: action,
});

const schema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    accountName: {
      type: "string",
    },
    arcContactId: {
      type: "string",
    },
    gainAccountId: {
      type: ["string", "null"],
    },
    isLegalEntity: {
      type: "boolean",
    },
    relatedArcAccount: {
      type: "object",
      properties: {
        name: {
          type: ["string", "null"],
        },
      },
      required: ["name"],
    },
  },
  required: ["accountName", "arcContactId", "isLegalEntity"],
} as JSONSchema7;

export { OwnerAccount };
