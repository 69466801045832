import React, { memo } from "react";
import { useFormikContext } from "formik";
import { ElementHOC } from "../Element";
import "./Column.scss";
import { cn } from "@/util";

function Column(props) {
  const { element } = props;
  const state = useFormikContext();
  let { children, disabled } = element;

  if (disabled) disabled = disabled(state);

  if (!children) return null;

  return (
    <div className={cn("form-column", { disabled })} data-test={`${element.label}/form-column`}>
      <Header element={element} />
      {children.map((el, i) => ElementHOC(el, i))}
    </div>
  );
}

function Header(props) {
  const { element } = props;
  const { label } = element;

  if (!label) return null;

  return <div className="form-column-header">{label}</div>;
}

const MemorizedColumn = memo(Column);

export { MemorizedColumn as Column };
