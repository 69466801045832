import { action, observable, decorate } from "mobx";
import { ReactNode } from "react";

class Sidebar {
  content: Content = null;
  contentId: ContentId = null;
  open = false;

  show = (params: ShowParams) => {
    const { content, contentId } = params;

    this.content = content;
    this.contentId = contentId;
    this.open = true;
  };

  hide = () => {
    this.content = null;
    this.contentId = null;
    this.open = false;
  };
}

decorate(Sidebar, {
  content: observable,
  contentId: observable,
  open: observable,
  show: action,
  hide: action,
});

export const sidebar = new Sidebar();

interface ShowParams {
  content: Content;
  contentId: string;
}

type Content = ReactNode;
type ContentId = string | null;
