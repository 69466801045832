import React, { useRef, useState } from "react";
import classNames from "classnames";
import { emptyFn } from "@/util";
import { TextField, TextFieldProps, InputFocusEvent } from "@/components";
import { Row } from "../../row";
import "./Period.scss";

export function Period(props: TextFieldProps): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "period");

  const { className, value, onChange, ...rest } = props;

  const inputRef = useRef<any>();
  const [inputValue, setInputValue] = useState<string>("");
  const [inputFocus, setInputFocus] = useState(false); // eslint-disable-line

  function onInputChange(value: string) {
    setInputValue(value);

    onChange?.(value);
  }

  function onOutputFocus(e: InputFocusEvent) {
    e.preventDefault();

    inputRef.current.focus();
  }

  function onInputFocus(e: InputFocusEvent) {
    setInputFocus(true);

    props.onFocus && props.onFocus(e);
  }

  function onInputBlur(e: InputFocusEvent) {
    setInputValue("");
    setInputFocus(false);

    props.onBlur && props.onBlur(e);
  }

  return (
    <Row className={className}>
      <TextField
        {...rest}
        label={props.label}
        className="period-input"
        value={inputValue}
        placeholder="Date range, e.g. Q4"
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        input={inputRef}
        dataTest={`${props.dataTest}/period`}
      />
      <TextField
        {...rest}
        label=" "
        className="period-output"
        value={value}
        onChange={emptyFn}
        onFocus={onOutputFocus}
        tabIndex={-1}
        dataTest={`${props.dataTest}/period-value`}
        clearable
      />
    </Row>
  );
}

export type PeriodProps = TextFieldProps;
