import { action, observable, decorate, computed } from "mobx";
import { TestContext } from "yup";
import { JSONSchema7 } from "json-schema";
import { set } from "@/util";
import { TradeOrderTemplate, trade, TradeCargoSize, TradeCOACargoSize, TradeUnitDuration, TradeLiftings } from "@/api";
import { orderTemplates } from "../order.templates";
import * as _ from "lodash";
import { Response } from "@/axios";

class Template {
  entityKey = null as string | null;
  status = {} as Status;
  tempStatus = {} as Status;
  data = null as TradeOrderTemplate | null;

  get deleted(): boolean {
    return !!this.data?.isDeleted;
  }

  get schema(): JSONSchema7 | null {
    return schema.value;
  }

  getSchema = async (): Promise<Response<JSONSchema7> | undefined> => {
    if (schema.value) return;

    const res = await trade.getOrderTemplateSchema();

    if (res.ok && res.data) schema.value = enchanceSchema(res.data);

    return res;
  };

  getData = async (): Promise<Response<TradeOrderTemplate> | undefined> => {
    if (!this.data?.id) return;

    const res = await trade.getOrderTemplate(this.data.id, this.data?.companyId);

    if (res.ok && res.data) this.data = res.data;

    return res;
  };

  postData = async (data: TradeOrderTemplate): Promise<Response<TradeOrderTemplate>> => {
    const res = await trade.putOrderTemplate(data, this.data?.companyId);

    if (res.ok && res.data) this.data = res.data;

    return res;
  };

  deleteData = async (): Promise<Response<undefined> | undefined> => {
    if (!this.data?.id) return;

    const res = await trade.deleteOrderTemplate(this.data?.id, this.data?.companyId);

    if (res.ok) {
      this.data.isDeleted = true;

      setTimeout(orderTemplates.deleteEntity.bind(null, this.data.id), 500);
    }

    return res;
  };

  cloneData = async (): Promise<Response<TradeOrderTemplate> | undefined> => {
    if (!this.data?.id || !this.data?.companyId) return;

    const dataRes = await this.getData();

    if (!dataRes?.ok) return dataRes;

    // eslint-disable-next-line import/namespace
    const clone = _.omit(this.data, "id");

    clone.name = `Copy of ${clone.name}`;

    const putRes = await trade.putOrderTemplate(clone, this.data?.companyId);

    return putRes;
  };
}

decorate(Template, {
  data: observable,
  status: observable,
  tempStatus: observable,
  deleted: computed,
  getData: action,
  postData: action,
  deleteData: action,
  cloneData: action,
});

const schema = observable({ value: null as JSONSchema7 | null });

function enchanceSchema(schema: JSONSchema7) {
  const voyageCargoSize = "properties.template.properties.voyage.properties.cargoSize.test";
  const coaCargoSize = "properties.template.properties.coa.properties.coaCargoSize.test";
  const tctDuration = "properties.template.properties.tct.properties.duration.test";
  const coaLiftings = "properties.template.properties.coa.properties.liftings.test";

  set(schema, voyageCargoSize, voyageCargoSizeValidation);
  set(schema, tctDuration, voyageDurationValidation);
  set(schema, coaLiftings, coaLiftingsValidation);
  set(schema, coaCargoSize, [coaCargoSizeValidation, coaCargoSizeMinMaxValidation]);

  return schema;
}

const voyageCargoSizeValidation = {
  test: validateCargoSize,
  name: "voyage-cargo-size",
  message: "Something is wrong with VOY Cargo Size",
};

const coaCargoSizeValidation = {
  test: validateCargoSize,
  name: "coa-cargo-size",
  message: "Something is wrong with COA Cargo Size",
};

const coaCargoSizeMinMaxValidation = {
  test: validateMinMax,
  name: "coa-cargo-size",
  message: "Something is wrong with COA Cargo Size",
};

const voyageDurationValidation = {
  test: validateMinMax,
  name: "voyage-duration",
  message: "Something is wrong with TC Duration",
};

const coaLiftingsValidation = {
  test: validateMinMax,
  name: "coa-liftings",
  message: "Something is wrong with COA Liftings",
};

// prettier-ignore
function validateCargoSize(this: TestContext, cargoSize: TradeCargoSize | TradeCOACargoSize | null) {
  if (!cargoSize) return true;

  const { option, variance } = cargoSize;

  const error = this.createError();

  if (typeof variance === "number") {
    if (option === "MIN/MAX" && variance !== 0) {
      error.message = "Variance value should be 0 with MIN/MAX option";
      error.path = `${error.path}.variance`;

      return error;
    }

    if (option === "MOLCHOPT" && !variance) {
      error.message = "Variance value should be above 0 with MOLCHOPT option";
      error.path = `${error.path}.variance`;

      return error;
    }

    if (option === "MOLOO" && !variance) {
      error.message = "Variance value should be above 0 with MOLOO option";
      error.path = `${error.path}.variance`;

      return error;
    }
  }

  return true;
}

// prettier-ignore
function validateMinMax(this: TestContext, value: TradeUnitDuration | TradeLiftings | TradeCOACargoSize) {
  if (!value) return true;

  const { min, max } = value;

  const error = this.createError();

  if (typeof min === "number" && typeof max === "number" && min > max) {
    error.message = "Min should be less than or equal to Max";
    error.path = `${error.path}.min`;

    return error;
  }

  return true;
}

export { Template };
