import axios from "axios";
import { action, observable, decorate } from "mobx";
import { keyBy } from "@/util";
import { trade, TradeAccountSearchItem } from "@/api";
import { OwnerAccount } from "./owner.account";

class OwnerAccounts {
  entities = [] as OwnerAccount[];
  entitiesById = proxyEntitiesById({}) as EntitiesById;
  searchStatus = {} as Status;
  cancel = axios.CancelToken.source();
  contextMenuEnabled = false;

  searchEntities = async (search: string) => {
    this.cancel.cancel();
    this.cancel = axios.CancelToken.source();

    this.entities = [];

    const res = await trade.searchAccounts(search, this.cancel.token);

    return res;
  };

  setEntities = (data: any[]) => {
    const accounts = data.map(mapAccount).sort();
    const accountsById = keyBy(accounts, "entityKey");

    this.entities = accounts;
    this.entitiesById = proxyEntitiesById({
      ...accountsById,
      ...this.entitiesById,
    });
  };
}

decorate(OwnerAccounts, {
  entities: observable,
  searchStatus: observable,
  searchEntities: action,
  setEntities: action,
});

function mapAccount(data: TradeAccountSearchItem) {
  const ownerAccount = new OwnerAccount();

  if (data.document) {
    ownerAccount.data = data.document;
    ownerAccount.data.companyId = data.document.accountId;
    ownerAccount.entityKey = `${ownerAccount.data.accountId}@${ownerAccount.data.id}`;
  }

  return ownerAccount;
}

function proxyEntitiesById(entitiesById: EntitiesById) {
  return new Proxy(observable(entitiesById), {
    get: (target: EntitiesById, entityKey: string) => {
      if (!target[entityKey]) {
        const [accountId, id] = entityKey.split("@");

        const account = new OwnerAccount();

        account.entityKey = entityKey;
        account.data = { id: id, accountId: accountId, companyId: accountId };

        target[entityKey] = account;
      }

      return target[entityKey];
    },
  });
}

export const ownerAccounts = new OwnerAccounts();

type EntitiesById = Record<string, OwnerAccount>;
