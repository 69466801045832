import { Text } from "./text";
import { Number } from "./number";
import { Email } from "./email";
import { Select } from "./select";
import { Textarea } from "./textarea";
import { Handlebar } from "./handlebar";

export const INPUT_TYPE = {
  text: Text,
  email: Email,
  number: Number,
  select: Select,
  textarea: Textarea,
  handlebar: Handlebar,
};

export type InputType = keyof typeof INPUT_TYPE;
