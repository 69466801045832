import React, { memo, useEffect, useState } from "react";
import { useSimpleEffect } from "@/util";
import { auth } from "@/model";
import { TradeLocalFilter } from "@/api";
import { Input } from "@/components";
import { Config } from "@/components/entity.layout/Entity.layout";
import { useParentActions } from "@/components/entity.layout/parent.actions/parent.actions";
import { Row } from "@/components/editor/row";
import { TradeOrderType } from "@/components/editor/type/trade.order.type";
import { TradeCompany } from "@/components/editor/type/trade.company";
import { TradeOrderType2 } from "@/components/editor/type/trade.order.type2";
import { TradeAccountLegalState } from "@/components/editor/type/trade.account.legal.state";
import { TradeTermsetLayout } from "@/components/editor/type/trade.termset.layout";
import { DEFAULT_PAGE } from "@/constants";
import "./Search.scss";

function Search(props: Props) {
  const [searchValue, setSearchValue] = useState("");
  const [localFilter, _setLocalFilter] = useState(defaultState);
  const [toDefaultPage, setToDefaultPage] = useState(false);
  const { parent, view, dataTest } = props.config;
  const { list } = view;
  const { search } = list;
  const { placeholder } = search;
  const actions = useParentActions(props.config);

  useEffect(init, [parent]);
  useEffect(() => setToDefaultPage(false), [props.pageNumber]);
  useSimpleEffect(actions?.searchEntities.bind(null, searchValue, localFilter, toDefaultPage ? DEFAULT_PAGE : props.pageNumber), [
    searchValue,
    localFilter,
    toDefaultPage,
    props.pageNumber,
  ]);

  function init() {
    parent?.setEntities([]);

    if (parent) {
      parent.searchStatus.message = "Waiting for your input";
    }
  }

  function setLocalFilter(key, value) {
    _setLocalFilter({ ...localFilter, [key]: value });
    handleSearchParamsChange();
  }

  function handleSearchParamsChange() {
    props.onSearchChange();
    setToDefaultPage(true);
  }

  return (
    <>
      <div className="entity-search">
        <Input
          value={searchValue}
          onChange={(searchValue) => {
            setSearchValue(searchValue);
            handleSearchParamsChange();
          }}
          placeholder={placeholder}
          icon="search"
          type="text"
          dataTest={`${dataTest}-entity-search`}
        />
        <>
          <Row>
            {view.entity.name !== "Owner Account" && auth.trade.user?.isMaritechUser && (
              <TradeCompany
                value={localFilter.companyId}
                onChange={setLocalFilter.bind(null, "companyId")}
                placeholder="Filter by Company"
                dataTest={`${dataTest}-entity-search-company`}
                clearable={true}
              />
            )}
            {view.entity.name === "Order Template" && (
              <TradeOrderType
                value={localFilter.orderType}
                onChange={setLocalFilter.bind(null, "orderType")}
                placeholder="Filter by Order Type"
                dataTest={`${dataTest}-entity-search-orderType`}
                clearable={true}
              />
            )}
            {view.entity.name === "Termset" && (
              <>
                <TradeOrderType2
                  value={localFilter.termsetType}
                  onChange={setLocalFilter.bind(null, "termsetType")}
                  placeholder="Filter by Termset Type"
                  dataTest={`${dataTest}-entity-search-termsetType`}
                  clearable={true}
                />
                {!auth.trade.user?.isMaritechUser && (
                  <TradeTermsetLayout
                    value={localFilter["content.proformaLayoutId"]}
                    onChange={setLocalFilter.bind(null, "content.proformaLayoutId")}
                    placeholder="Filter by Proforma"
                    dataTest={`${dataTest}-entity-search-proformaLayout`}
                    clearable={true}
                  />
                )}
              </>
            )}
            {view.entity.name === "Owner Account" && (
              <>
                <TradeAccountLegalState
                  value={localFilter["document.isLegalEntity"]}
                  onChange={setLocalFilter.bind(null, "document.isLegalEntity")}
                  placeholder="Filter by Legal Entity state"
                  dataTest={`${dataTest}-entity-search-legalState`}
                  clearable={true}
                />
              </>
            )}
          </Row>
          {view.entity.name === "Termset" && auth.trade.user?.isMaritechUser && (
            <Row>
              <>
                <TradeTermsetLayout
                  value={localFilter["content.proformaLayoutId"]}
                  onChange={setLocalFilter.bind(null, "content.proformaLayoutId")}
                  placeholder="Filter by Proforma"
                  dataTest={`${dataTest}-entity-search-proformaLayout`}
                  clearable={true}
                />
              </>
            </Row>
          )}
        </>
      </div>
    </>
  );
}

const defaultState = {
  companyId: "",
  orderType: "",
  termsetType: "",
  "content.proformaLayoutId": undefined,
} as TradeLocalFilter;

const Memo = memo(Search);

export { Memo as Search };

interface Props {
  config: Config;
  pageNumber?: number;
  onSearchChange: () => void;
}
