import React, { useImperativeHandle, useRef, useState, forwardRef, Ref } from "react";
import { ICellEditorParams, ColDef } from "@ag-grid-enterprise/all-modules";
import { cn, wait, useSimpleEffect } from "@/util";
import { Editor } from "@/components";
import "./Input.scss";

function InputFrameworkComponent(props: ICellEditorParams, ref: Ref<any>) {
  const { colDef, context } = props;
  const [value, setValue] = useState(props.value);
  const field = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const aggrid = context.aggrid.current as HTMLDivElement;
  let { cellEditorProps } = colDef as FancyColDef;

  cellEditorProps = { ...cellEditorProps };

  cellEditorProps.className = cn(cellEditorProps.className, "aggrid-input", {
    visible: true,
  });

  useImperativeHandle(ref, current.bind(null, value));
  useSimpleEffect(intialize, []);
  useSimpleEffect(focusInput, []);

  async function intialize() {
    await wait(3);

    resolveStyle();
  }

  function resolveStyle() {
    const viewport = aggrid.querySelector(".ag-body-viewport");

    if (!field.current || !viewport) return;

    const v = viewport.getBoundingClientRect();
    const f = field.current.getBoundingClientRect();
    const isBottomHalf = f.top > v.top + v.height / 2;

    if (isBottomHalf) field.current.style.setProperty("bottom", "0");
  }

  async function focusInput() {
    await wait(5);

    input.current?.focus();
  }

  function onChange(value: any) {
    setValue(value);
  }

  return (
    <div className="aggrid-input-wrapper">
      <Editor {...cellEditorProps} value={value} onChange={onChange} field={field} input={input} />
    </div>
  );
}

function current(value: any) {
  return {
    getValue: () => {
      return value;
    },
    // isPopup: () => true, // https://ag-grid.zendesk.com/hc/en-us/requests/13982
  };
}

const ForwardRef = forwardRef(InputFrameworkComponent);

export { ForwardRef as Input };

interface FancyColDef extends ColDef {
  cellEditorProps: any;
}
