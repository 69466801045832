import React from "react";
import { observer } from "mobx-react";
import { cn } from "@/util";
import { Icon, IconProps } from "@/components";
import "./Status.icon.scss";

function StatusIcon(props: Props) {
  props = { ...props };

  if (!props.status?.type) return null;

  props.className = cn(props.className, "status-icon", props.status?.type);

  return <Icon {...props} name={ICON[props.status.type]} />;
}

const Observer = observer(StatusIcon);

export { Observer as StatusIcon };

const ICON = {
  error: "alert-note",
  warning: "warning",
  info: "alert-note",
  confirm: "confirm",
  success: "done",
};

interface Props extends IconProps {
  status?: Status;
}
