import React, { memo, useMemo, useRef, useEffect } from "react";
import { Formik, Form as FormikForm } from "formik";
import { DEVELOPMENT } from "@/config";
import { isEqual, cloneDeep } from "@/util";
import { unsavedChanges } from "@/model";
import { ElementHOC } from "./element";
import { JSONSchemaToYup } from "./util";
import "./Form.scss";

function Form(props) {
  const { elements, def, id, context, mutateInitialValues, mutateValues, hidden, ...formik } = props;
  const _context = useRef();

  useEffect(register, [id]);

  function register() {
    const unregisterUnsavedChanges = unsavedChanges.register(() => {
      const _context = context.current;

      return !isEqual(_context?.values, _context?.initialValues);
    });

    return () => unregisterUnsavedChanges();
  }

  const _elements = useMemo(() => {
    function iterate(el) {
      if (Array.isArray(el)) el = { type: "row", children: el };

      if (typeof el === "string") el = { name: el };

      el = { ...def[el.name], ...el };

      if (el.children) el.children = el.children.map(iterate);

      return el;
    }

    return elements.map(iterate);
  }, [elements, def]);

  const yupFromJSON = useMemo(() => {
    if (props.JSONSchema) {
      return JSONSchemaToYup(props.JSONSchema, def);
    }
  }, [props.JSONSchema, def]);

  const validationSchema = props.validationSchema || yupFromJSON;

  const initialValues = useMemo(() => {
    const res = props.initialValues ? cloneDeep(props.initialValues) : validationSchema?.cast();

    if (mutateInitialValues) mutateInitialValues(res);

    return res;
  }, [props.initialValues, validationSchema, mutateInitialValues]);

  return (
    <Formik {...formik} initialValues={initialValues} validationSchema={validationSchema}>
      {(formikContext) => {
        consoleLog(formikContext);

        if (mutateValues) mutateValues(formikContext.values);

        if (context) context.current = formikContext;

        _context.current = formikContext;

        return (
          <FormikForm hidden={hidden}>
            {_elements.map((el, i) => ElementHOC(el, i))}
            <button type="submit" hidden></button>
          </FormikForm>
        );
      }}
    </Formik>
  );
}

function consoleLog(context) {
  context = { ...context };

  if (DEVELOPMENT) {
    console.log(context);
  } else {
    window.clearTimeout(timeoutId);
    window.setTimeout(console.log.bind(null, context), 2000);
  }
}
const timeoutId = -1;

const Memo = memo(Form);

export { Memo as Form };
