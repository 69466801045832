import React, { useMemo } from "react";
import { observable } from "mobx";
import { TradeOrderTemplate } from "@/api";
import { TRADE_DATA_DEF } from "@/constants";
import { RouteSeed, companies, auth, orderTemplates, router } from "@/model";
import { EntityLayout, Entity, EntityLayoutConfig } from "@/components/entity.layout";
import { FormikContextType } from "formik";

export const orderTemplatesRoute: RouteSeed = {
  path: "/order-templates",
  comp: OrderTemplates,
  sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
  icon: "worksheet",
  desc: "Order Templates",
  pageTitle: "Order Templates",
  children: {
    template: {
      path: "/:companyId/:id",
      comp: OrderTemplate,
      sidenav: ["orderTemplates", "termsets", "ownerAccounts"],
      pageTitle: "Order Templates",
    },
  },
  auth: (auth) => auth.authorised,
};

function OrderTemplates(routeProps: any): JSX.Element {
  const config = useMemo(getConfig, []);

  return <EntityLayout config={config} {...routeProps} />;
}

function OrderTemplate(): JSX.Element {
  const config = useMemo(getConfig, []);

  return <Entity config={config} />;
}

function getConfig(): EntityLayoutConfig {
  config.parent = orderTemplates;
  config.routes.parent = router.get("orderTemplates");
  config.routes.entity = router.get("orderTemplates.template");

  return config;
}

const config = {
  className: "order-templates",
  dataTest: "order-template",
  routes: {},
  view: {
    storageKey: "order-template",
    list: {
      columns: [
        {
          key: "data.name",
          label: "Template name",
        },
      ],
      header: {
        title: "Order Templates",
        button: {
          label: "New Template",
        },
      },
      search: {
        placeholder: "Search by name",
      },
      body: {
        loadingMessage: "Searching for templates",
        header: {
          title: "Template name",
        },
      },
    },
    entity: {
      name: "Order Template",
      newEntityData: {
        name: "New Order Template",
      },
      form: {
        elements: [
          [
            "orderType",
            "name",
            {
              name: "companyId",
              visible: () => auth.trade.user?.isMaritechUser,
            },
          ],
          {
            type: "row",
            label: "Order Terms",
            children: ["template.order.chartererAccount", "template.order.addressCommission", "template.order.brokerCommission"],
          },
          "template.order.cargoType",
          [
            {
              type: "column",
              label: "VOY terms",
              disabled: (context: Context) => context.values?.orderType !== "std",
              children: [
                "template.voyage.cargoSize",
                "template.voyage.loadLocation",
                "template.voyage.dischargeLocation",
                "template.voyage.notes",
              ],
            },
            {
              type: "column",
              label: "TC terms",
              disabled: (context: Context) => context.values?.orderType !== "std",
              children: [
                "template.tct.duration",
                "template.tct.vesselSize",
                "template.tct.deliveryLocation",
                "template.tct.viaLocation",
                "template.tct.redeliveryLocation",
                "template.tct.notes",
              ],
            },
            {
              type: "column",
              label: "COA terms",
              disabled: (context: Context) => context.values?.orderType !== "coa",
              children: [
                "template.coa.coaCargoSize",
                "template.coa.liftings",
                "template.coa.nominations",
                "template.coa.loadLocation",
                "template.coa.dischargeLocation",
                "template.coa.notes",
              ],
            },
          ],
        ],
        mutateInitialValues: mutateValues,
        mutateValues,
        def: TRADE_DATA_DEF.orderTemplate,
        context: observable({ current: null }),
      },
    },
  },
} as EntityLayoutConfig;

function mutateValues(data?: TradeOrderTemplate) {
  if (typeof data !== "object") return;

  if (!data.orderType) data.orderType = "std";

  if (!data.companyId) data.companyId = auth.trade.user?.CompanyId;

  const companyById = companies.dataById[`${data.companyId}`];

  data.companyName = companyById?.name;
}

type Context = FormikContextType<TradeOrderTemplate>;
