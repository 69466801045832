import React, { Children, cloneElement, ReactNode } from "react";
import classNames from "classnames";
import { useShallowState } from "@/util";
import { InputProps, InputFocusEvent } from "../Input";
import { Icon } from "../icon";
import "./Container.scss";

export function Container(props: Props): JSX.Element {
  const { desc, status, img, icon, dataTest, value } = props;
  const clearable = props.clearable && value;
  const [state, setState] = useShallowState(defaultState);
  const className = classNames(props.className, status?.type, {
    icon: img || icon,
    value,
    status,
    clearable,
    ...state,
  });
  const children = Children.map(props.children, mapChild);

  function mapChild(child: ReactNode) {
    // @ts-ignore
    return cloneElement(child, { ...child?.props, onFocus, onBlur });
  }

  function clearValue() {
    props.onChange?.(null);
  }

  function onFocus(e: InputFocusEvent) {
    setState({ focus: true });

    props?.onFocus?.(e);
  }

  function onBlur(e: InputFocusEvent) {
    setState({ focus: false });

    props?.onBlur?.(e);
  }

  return (
    <sea-input class={className} title={desc}>
      <Icon icon={icon} img={img} dataTest={dataTest} />
      {children}
      <Icon onClick={clearValue} hidden={!clearable} icon="close" align="right" dataTest={dataTest} />
    </sea-input>
  );
}

const defaultState = {
  focus: false,
} as State;

interface Props extends InputProps {
  children: React.ReactNode;
}

interface State {
  focus: boolean;
}
