import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Header } from "./header";
import { cn, convertMUIToPageNumber, storage } from "@/util";
import { MUI_DEFAULT_PAGE, PAGINALION_STORE_KEY } from "@/constants";
import { globalDropdown } from "@/model";
import { Icon } from "@/components";
import { Search } from "./search";
import { Body } from "./body";
import { Config } from "../Entity.layout";
import "./Entity.list.scss";

function EntityList(props: Props) {
  const { routes, view } = props.config;
  const storageCollapseKey = `${view.storageKey}-list-collapsed`;
  const [hovered, setHovered] = useState(false);
  const [collapsed, collapse] = useState(storage.local.get(storageCollapseKey));
  const savedPageNumber = convertMUIToPageNumber(storage.session.get(PAGINALION_STORE_KEY, MUI_DEFAULT_PAGE));
  const [pageNumber, setPageNumber] = useState(savedPageNumber);
  const [toDefaultPage, setToDefaultPage] = useState(false);

  useEffect(persistCollapse, [collapsed]);

  function onMouseEnter() {
    setHovered(true);
  }

  async function onMouseLeave() {
    if (globalDropdown.open) await globalDropdown.promise;

    setHovered(false);
  }

  function persistCollapse() {
    storage.local.set(storageCollapseKey, collapsed);
  }

  function handlePageChange(newMUIPageNumber: number) {
    const pageNumber = convertMUIToPageNumber(newMUIPageNumber);

    setPageNumber(pageNumber);
    setToDefaultPage(false);
  }

  function handleSearchChange() {
    setToDefaultPage(true);
  }

  return (
    <div
      className={cn("entity-list", {
        collapsed: routes.entity?.active && collapsed,
        hovered,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="entity-list-width-control">
        {routes.entity?.active && (
          <Icon
            className="entity-list-collapse"
            icon={collapsed ? "pin" : "unpinned"}
            onClick={collapse.bind(null, !collapsed)}
          />
        )}
        <div className="entity-list-overflow-control">
          <div className="entity-list-content">
            <Header config={props.config} />
            <Search config={props.config} pageNumber={pageNumber} onSearchChange={handleSearchChange} />
            <Body
              config={props.config}
              toDefaultPage={toDefaultPage}
              savedPageNumber={savedPageNumber}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Observer = observer(EntityList);

export { Observer as EntityList };

interface Props {
  config: Config;
}
