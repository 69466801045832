import React from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Status.messages.scss";

export function StatusMessages(props: Props): JSX.Element {
  return (
    <status-messages>
      <TransitionGroup component={null}>{MessagesHOC(props)}</TransitionGroup>
    </status-messages>
  );
}

function MessagesHOC(props: Props) {
  const { status, hidden } = props;

  if (!status || hidden) return null;

  const statuses = extractStatuses(status);

  const messages = statuses.map(MessageHOC);

  return messages;
}

function MessageHOC(status: Status) {
  const key = status.type + status.message;
  const timeout = { enter: 0, appear: 250, exit: 250 };

  return (
    <CSSTransition timeout={timeout} classNames="visibility" key={key}>
      <Message status={status} />
    </CSSTransition>
  );
}

function Message(props: { status: Status }) {
  if (!props.status?.message) return null;

  const { type, message } = props.status;
  const className = classNames(type);

  return <status-message class={className}>{message}</status-message>;
}

function extractStatuses(status: Status, res?: Status[]) {
  res = res || [];

  const { children } = status;

  res.push(status);

  if (!children) return res;

  const statuses = Object.values(children);

  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i];

    extractStatuses(status, res);
  }

  return res;
}

interface Props {
  hidden?: boolean;
  status?: Status;
}
