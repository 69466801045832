/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { get } from "@/util";

window.yup = yup;

export function JSONSchemaToYup(schema, def, path, root) {
  let res;

  if (schema.$ref) {
    schema = { ...schema, ...get(root, schema.$ref.split("/").slice(1)) };
  }

  const required = (schema.required || []).reduce((acc, key) => {
    acc[key] = true;

    return acc;
  }, {});

  const type = [].concat(schema.type).reduce((types, type) => {
    if (!SUPPORTED_TYPE[type]) {
      console.error(`Schema contains unsupported type "${type}"`, schema);
    }

    types[type] = true;

    if (type === "integer") {
      types.number = true;
    }

    return types;
  }, {});

  if (type.object) {
    const object = {};
    const entries = Object.entries(schema.properties);

    for (let i = 0; i < entries.length; i += 1) {
      const [key, child] = entries[i];

      const childPath = path ? `${path}.${key}` : key;

      object[key] = JSONSchemaToYup(child, def, childPath, root || schema);

      if (required[key]) {
        object[key] = object[key].required(`Required`);
      }
    }

    res = yup.object(object);

    // --- //
  } else if (type.array) {
    res = yup.array();

    // --- //
  } else if (type.string) {
    res = yup.string();

    // --- //
  } else if (type.boolean) {
    res = yup.boolean();

    // --- //
  } else if (type.number) {
    res = yup.number();

    if (type.integer) res = res.integer();

    if (schema.minimum !== undefined) res = res.min(schema.minimum);
    if (schema.maximum !== undefined) res = res.max(schema.maximum);

    // --- //
  }

  if (schema.enum) {
    let enums = [].concat(schema.enum);

    if (type.null) enums = enums.concat(null);

    res = res.oneOf(enums);
  }

  if (schema.test) {
    const tests = [].concat(schema.test);

    tests.forEach((test) => {
      res = res.test(test);
    });
  }

  if (type.null) {
    res = res.default(null);
    res = res.nullable(true);
  }

  res = res.strict(true);

  res = res.typeError("Required");

  return res;
}

const SUPPORTED_TYPE = {
  object: true,
  string: true,
  number: true,
  integer: true,
  boolean: true,
  array: true,
  null: true,
};
