import React from "react";
import { Text, TextProps } from "../text";

export function Email(props: TextProps): JSX.Element {
  props = { ...props };

  props.className = `${props.className} email`;

  return <Text {...props} />;
}
