import React, { useRef } from "react";
import axios from "axios";
import classNames from "classnames";
import { trade, TradePeriod as TradePeriodType } from "@/api";
import { Period, PeriodProps } from "../period";

export function TradePeriod(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-period");

  const { value } = props;

  const display = value
    ? `${new Date(value.start).toLocaleDateString("en-GB", options)} - ${new Date(value.end).toLocaleDateString(
        "en-GB",
        options
      )}`
    : "";

  // eslint-disable-next-line import/no-named-as-default-member
  const canceller = useRef(axios.CancelToken.source());

  async function onChange(value: string) {
    canceller.current.cancel();

    // eslint-disable-next-line import/no-named-as-default-member
    canceller.current = axios.CancelToken.source();

    const res = await trade.parsePeriod(value, canceller.current.token);

    if (res.status && res.status < 400) props.onChange?.(res.data || null);
  }

  return <Period {...props} value={display} onChange={onChange} dataTest={`${props.dataTest}/trade-period`} />;
}

const options = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

interface Props extends Omit<PeriodProps, "value" | "onChange"> {
  value?: TradePeriodType;
  onChange?: (value: TradePeriodType | null) => void;
}
