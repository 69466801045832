import React from "react";
import { keyBy } from "@/util";
import classNames from "classnames";
import { TradeLiftings as TradeLiftingsType } from "@/api";
import { SelectField, SelectFieldProps, TextareaField, NumberField, StatusMessages } from "@/components";
import { Group } from "../../group";
import { Row } from "../../row";
import "./Trade.liftings.scss";

export function TradeLiftings(props: Props): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "trade-liftings");

  const { value, status, ...rest } = props;

  const { min, max, dispersal } = value || {};

  function onChange(name: keyof TradeLiftingsType, inputValue: number | null) {
    const next = {
      ...value,
      [name]: inputValue,
    };

    props.onChange?.(next);
  }

  function onDispersalChange(dispersal: Dispersal) {
    const next = {
      ...value,
      dispersal: dispersal?.value || null,
    };

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.({ ...props.value, notes });
  }

  return (
    <Group className={props.className} label={props.label}>
      <Row>
        <NumberField
          {...rest}
          label="Min"
          desc="Decides what is the minimum value for liftings option"
          placeholder="e.g. 5"
          className="trade-liftings-min"
          status={status?.children?.min}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}/trade-liftings-min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Decides what is the maximum value for liftings option"
          placeholder="e.g. 10"
          className="trade-liftings-max"
          status={status?.children?.max}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}/trade-liftings-max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Dispersal"
          desc="Dispersal defines the lifting option type"
          className="trade-liftings-dispersal"
          data={DISPERSAL_OPTIONS}
          display={display}
          filterKey="name"
          status={status?.children?.dispersal}
          value={DISPERSAL_OPTION[dispersal as keyof typeof DISPERSAL_OPTION]}
          onChange={onDispersalChange}
          dataTest={`${props.dataTest}/trade-liftings-dispersal`}
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Liftings notes"
        className="trade-liftings-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}/trade-liftings-notes`}
      />
    </Group>
  );
}

function display(option: Dispersal) {
  return option.name;
}

const DISPERSAL_OPTIONS = [
  { value: "Fairly even spread", name: "Fairly even spread" },
  { value: "Monthly", name: "Monthly" },
  { value: "Adhoc", name: "Ad hoc" },
];

const DISPERSAL_OPTION = keyBy(DISPERSAL_OPTIONS, "value");

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: TradeLiftingsType;
  onChange?: (value: TradeLiftingsType) => void;
}

interface Dispersal {
  name: string;
  value: TradeLiftingsType["dispersal"];
}
