import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { cn } from "@/util";
import { Route } from "@/model";
import { FormProps } from "../form";
import { EntityList } from "./entity.list";
import { TradeLocalFilter } from "@/api";
import "./Entity.layout.scss";

function EntityLayout(props: Props) {
  props.config.className = cn(props.config.className, "entity-layout");
  props.config.view.entity.apiErrorDef = ERROR_CODE;

  return (
    <div className={props.config.className}>
      <EntityList config={props.config} />
      {props.children}
    </div>
  );
}

const ERROR_CODE = {
  DUPLICATE_NAME: true,
} as Record<string, boolean>;

const Observer = observer(EntityLayout);

export { Observer as EntityLayout };

interface Props {
  config: Config;
  children?: ReactNode;
}

export interface Config {
  parent?: Parent;

  routes: {
    parent?: Route;
    entity?: Route;
  };

  view: {
    storageKey: string;
    entity: {
      apiErrorDef?: typeof ERROR_CODE;
      newEntityData: EntityData;
      name: string;
      form: Omit<FormProps, "initialValues" | "onSubmit">;
    };
    list: {
      columns: ListColumn[];
      header: {
        title: string;
        button: {
          label: string;
        };
      };
      search: {
        placeholder: string;
      };
      body: {
        loadingMessage: string;
        header: {
          title: string;
        };
      };
    };
  };

  className: string;
  dataTest: string;
}

interface Parent {
  entities: Entity[];
  entitiesById: Record<string, Entity>;
  searchStatus: Status;
  contextMenuEnabled: boolean;
  totalRecords: number;

  searchEntities: (search: string, localFilter: TradeLocalFilter, pageNumber?: number) => any;
  setEntities: (data: EntityData[]) => void;
  setTotal: (totalCount: number) => void;
}

export interface Entity {
  entityKey: string | null;
  data: EntityData | null;
  status: Status;
  tempStatus: Status;
  deleted?: boolean;
  schema: any;

  publish?: () => any;
  unpublish?: () => any;
  getSchema: () => any;
  getData: () => any;
  postData: (data: any) => any;
  cloneData?: () => any;
  deleteData?: () => any;
}

type EntityData = {
  id?: string;
  [key: string]: any | undefined;
};

interface ListColumn {
  key?: string;
  label: string;
  name?: string;
  value?: ReactNode | ((template: Entity) => ReactNode);
}
