import axios from "axios";
import { action, observable, decorate } from "mobx";
import { keyBy } from "@/util";
import { trade, TradeLocalFilter, TradeOrderTemplate } from "@/api";
import { Template } from "./template";

class OrderTemplates {
  entities = [] as Template[];
  entitiesById = proxyEntitiesById({}) as EntitiesById;
  searchStatus = {} as Status;

  // eslint-disable-next-line import/no-named-as-default-member
  cancel = axios.CancelToken.source();
  contextMenuEnabled = true;

  searchEntities = async (search: string, localFilter: TradeLocalFilter) => {
    this.cancel.cancel();

    // eslint-disable-next-line import/no-named-as-default-member
    this.cancel = axios.CancelToken.source();

    const res = await trade.searchOrderTemplates(search, localFilter, this.cancel.token);

    return res;
  };

  setEntities = (data: any[]) => {
    const templates = data.map(mapTemplate);
    const templatesById = keyBy(templates, "entityKey");

    this.entities = templates;
    this.entitiesById = proxyEntitiesById({
      ...this.entitiesById,
      ...templatesById,
    });
  };

  deleteEntity = (id: string) => {
    if (this.entities) {
      this.entities = this.entities.filter((item) => item.data?.id !== id);
    }
  };
}

decorate(OrderTemplates, {
  entities: observable,
  searchStatus: observable,
  searchEntities: action,
  setEntities: action,
  deleteEntity: action,
});

function mapTemplate(data: TradeOrderTemplate) {
  const template = new Template();

  template.data = data;
  template.entityKey = `${data.companyId}@${data.id}`;

  return template;
}

function proxyEntitiesById(entitiesById: EntitiesById) {
  return new Proxy(observable(entitiesById), {
    get: (target: EntitiesById, entityKey: string) => {
      if (!target[entityKey]) {
        const [companyId, id] = entityKey.split("@");

        const template = new Template();

        template.entityKey = entityKey;
        template.data = { id, companyId };

        target[entityKey] = template;
      }

      return target[entityKey];
    },
  });
}

export const orderTemplates = new OrderTemplates();

type EntitiesById = Record<string, Template>;

type Status = {
  type?: "error" | "warning" | "success";
  loading?: boolean;
  message?: string;
};
