import React from "react";
import classNames from "classnames";
import { COUNTRIES_BY_ALPHA2_CODE, COUNTRIES_BY_ALPHA3_CODE } from "@/constants";
import { SelectField, SelectFieldProps } from "@/components";

import "./Location.scss";

export function Location(props: LocationProps): JSX.Element {
  props = { ...props };

  props.className = classNames(props.className, "location");

  return (
    <SelectField {...props} getDropdownImg={getDropdownImg} placeholder="e.g. UK, USA" dropdownClassName="location-dropdown" />
  );
}

function getDropdownImg(location: Location) {
  if (!location) return;

  const countryCode = location.countryCode;
  const COUNTRY = COUNTRIES_BY_ALPHA2_CODE[countryCode] || COUNTRIES_BY_ALPHA3_CODE[countryCode];

  if (!COUNTRY) return;

  const { alpha2Code, name } = COUNTRY;

  return {
    src: `/country.flags/${alpha2Code.toLocaleLowerCase()}.svg`,
    title: name,
  };
}

export type LocationProps = SelectFieldProps;

interface Location {
  countryCode: string;
}
