import React, { memo } from "react";
import { useField, useFormikContext } from "formik";
import { cn } from "@/util";
import { Editor } from "@/components";
import "./Field.scss";

function Field(props) {
  const { element } = props;
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(element.name); // eslint-disable-line no-unused-vars

  let { error, touched } = meta;

  error = convertError(error);

  touched = touched || submitCount > 0;

  const status = touched && error;

  const tabIndex = element.disabled ? -1 : 0;

  function onChange(value) {
    value = handleEmptiness(value);

    helpers.setValue(value);
  }

  const editorProps = {
    ...element,
    className: cn(element.className, "form-field"),
    value: field.value,
    onChange,
    onBlur: helpers.setTouched,
    status: status,
    tabIndex,
    dataTest: element.name,
  };

  return <Editor {...editorProps} />;
}

function convertError(error) {
  if (typeof error === "string") {
    return {
      type: "error",
      message: error,
    };
  }

  if (typeof error === "object") {
    const res = {
      type: "error",
      children: {},
    };
    const entries = Object.entries(error);

    for (let i = 0; i < entries.length; i += 1) {
      const [key, error] = entries[i];

      res.children[key] = convertError(error);
    }

    return res;
  }
}

function handleEmptiness(value) {
  if (value === "") return null;
  if (value === null) return null;
  if (value === undefined) return null;
  if (Number.isNaN(value)) return null;

  if (typeof value === "string") return value;
  if (typeof value === "number") return value;
  if (typeof value === "boolean") return value;

  const entries = Object.entries(value);
  let allChildrenEmtpty = true;

  for (let i = 0; i < entries.length; i++) {
    let [childKey, childValue] = entries[i];

    childValue = handleEmptiness(childValue);

    value[childKey] = childValue;

    if (childValue !== null) allChildrenEmtpty = false;
  }

  if (allChildrenEmtpty) return null;

  return value;
}

const Memo = memo(Field);

export { Memo as Field };
