// Internal logic of MUI TablePagination need 0 to be first page, but our BE and UI are working with actual 1 as first page;
import { DEFAULT_PAGE, MUI_DEFAULT_PAGE } from "@/constants";

export const convertToMUIPageNumber = (pageNumber: number) => {
  const MUIPageNumber = pageNumber >= 1 ? pageNumber - 1 : MUI_DEFAULT_PAGE;

  return MUIPageNumber;
};

export const convertMUIToPageNumber = (MUIPageNumber: number) => {
  const pageNumber = MUIPageNumber >= 0 ? MUIPageNumber + 1 : DEFAULT_PAGE;

  return pageNumber;
};
