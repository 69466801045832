import jwtdecode from "jwt-decode";
import { useEffect, useState, useCallback, DependencyList } from "react";

export function isStr(val: any): boolean {
  return typeof val === "string";
}

export function isFn(val: any): boolean {
  return typeof val === "function";
}

export function isArr(val: any): boolean {
  return Array.isArray(val);
}

export function useSimpleEffect(effect: (() => void) | undefined | null, deps: DependencyList): void {
  function _effect() {
    effect?.();
  }

  useEffect(_effect, deps);
}

export function useForceUpdate(): () => void {
  const [, _update] = useState(false);

  const update = useCallback(() => {
    _update((flag) => !flag);
  }, []);

  return update;
}

export function useDebounce(ms?: number, fn?: () => void, deps?: any[]): void {
  useEffect(() => {
    if (!fn) return;

    if (!ms) return fn();

    const timeoutId = setTimeout(fn, ms);

    return () => clearTimeout(timeoutId);
  }, deps);
}

/* eslint-disable @typescript-eslint/no-empty-function */
export function emptyFn(): void {}

export function wait(ms: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function jwtDecode(jwt: any, defaultValue?: any): any {
  if (typeof jwt !== "string") return defaultValue;

  try {
    return jwtdecode(jwt);
  } catch {
    return defaultValue;
  }
}

export type Merge<T, R> = Omit<T, keyof R> & R;
