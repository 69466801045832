import "mobx-react/batchingForReactDom";
import React from "react";
import { render } from "react-dom";
import { setup, preauthSetup } from "@/setup";
import { Root, Preauth } from "@/components";

function preauth() {
  preauthSetup();

  render(<Preauth />, window.react.root);
}

async function main() {
  await setup();

  render(<Root />, window.react.root);
}

preauth();
main();
