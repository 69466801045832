import React from "react";
import "./Group.scss";

export function Group(props: Props): JSX.Element {
  return (
    <sea-field-group class={props.className}>
      <sea-field-group-label>{props.label}</sea-field-group-label>
      {props.children}
    </sea-field-group>
  );
}

interface Props {
  label?: string;
  className?: string;
  children: React.ReactNode;
}
